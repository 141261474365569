import React from 'react';
import {Col, Container, Row} from "react-bootstrap";


const Credentials = props => {

    return (

        <Container className={"circle-credentials"}>
            <h2 className={"about-us-head"}>Opinie klientów</h2>
            <Row>
                <Col lg={4} className={"testimonial-item"}>
                    <div className={"testimonial-item-wrapper"}>
                        <p>Educado poleciła mi znajoma. Nie sądziłam, że tak bardzo przyzwyczaję się do tej platformy
                            szkoleniowej. Wszystko mam pod ręką. Nie musze już „polować” na tematy szkoleń które mnie
                            interesują. </p>
                        <h5>Anna Kuleja</h5>
                        <h6>Główna Księgowa</h6>
                    </div>
                </Col>
                <Col className={"testimonial-item"} lg={4}>
                    <div className={"testimonial-item-wrapper"}>
                        <p>W Educado spodobało mi się oznaczenie aktualności szkoleń. Dzięki temu nie muszę nigdzie
                            szukać informacji o tym, czy przepisy opisane w szkoleniu w dalszym ciągu obowiązują.</p>
                        <h5>Monika Brzeska</h5>
                        <h6>Specjalista ds. Kadr i Płac</h6>
                    </div>
                </Col>
                <Col lg={4} className={"testimonial-item"}>
                    <div className={"testimonial-item-wrapper"}>
                        <p>Wykupiłem dostępy do Educado ze względu na dostęp do bogatej bazy szkoleń o bardzo
                            różnorodnej tematyce. Nie musimy ograniczać się do jednego modułu tematycznego. Mamy dostęp
                            do wszystkich szkoleń Educado.</p>
                        <h5>Maciej Kondracki</h5>
                        <h6>Dyrektor działu kadrowo-księgowego</h6>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Credentials;