import React from 'react';
import {Button, Card, Col, Container, Row, Spinner} from "react-bootstrap";
import {iriFormatId} from "../../common/apiUtils";
import {strNormalize} from "../../common/utils";

class ExpertListContainer extends React.Component {

    render() {
        const {experts, isFetching} = this.props;

        if (isFetching)
            return (<Spinner animation="grow"/>);

        return (<Container className="mt-5">
            <Row>
                {experts && experts.map((expert, index) => (
                    <Col key={index} md={6} lg={4} xs={12} className="mb-4 expert-list-item">
                        <Card.Link className="expert-content-item"
                                   href={`/ekspert/${strNormalize(expert.name)}-${strNormalize(expert.surname)}/${iriFormatId(expert)}`}>
                            {expert.imageUrl ?

                                <div className={"expert-item-img"}
                                     style={{backgroundImage: `url(${expert.imageUrl})`}}/>
                                :
                                <div className={"expert-item-img"} style={{backgroundImage: `/expert-default.png`}}/>
                            }
                            <div className="expert-item-text">
                                <h4>{expert.nameAndSurname}</h4>
                                <p>{expert.academicDegree}</p>
                                <Button>Szkolenia eksperta</Button>
                            </div>
                        </Card.Link>
                    </Col>
                ))}
            </Row>
        </Container>)
    }
}

export default ExpertListContainer;
