import React from 'react';
import {Alert, Button, Container, Row, Spinner} from "react-bootstrap";
import {APP_URL} from "../../../constants";
import {strNormalize} from "../../../common/utils";

class WebinarCertificatesList extends React.Component {

    componentDidMount() {
        const {profileWebinarCertificateFetch, isFetching} = this.props.children;
        if (!isFetching) {
            profileWebinarCertificateFetch();
        }
    }

    getCertificate(product) {
        let tmp = APP_URL + product.certificatesUsers[0];
        window.open(tmp, '_blank');
    }

    render() {
        const {certificates} = this.props.children;

        if (!certificates)
            return (<Spinner animation="grow"/>);

        if (certificates.length === 0)
            return (<Alert variant="info">Brak dostępnych certyfikatów</Alert>);
        return (
            <Container className={"profile-certificates-list"}>
                {certificates && certificates.map((product, index) => (
                    <Row key={index} className={"profile-certificate-item-wrapper"}>
                        <Container className={"profile-certificate-item"}>
                            <h2><a
                                href={`/${strNormalize(product.subType)}/${strNormalize(product.name)}/${(product.id)}`}>{product.name}</a>
                            </h2>
                            {/*<p>9:30 23-09-2021</p>*/}
                            {product.certificatesUsers && product.certificatesUsers.length > 0 ?
                                <Button onClick={this.getCertificate.bind(this, product)}
                                        className={"webinar-download-certificate active"}>Pobierz Certyfikat</Button> :
                                <Button disabled className={"webinar-download-certificate disabled"}>Pobierz
                                    Certyfikat</Button>}
                        </Container>
                    </Row>
                ))}
            </Container>
        );
    }
}

export default WebinarCertificatesList;