import {
    BANER_REQUEST,
    BANER_RECEIVED,
    BANER_ERROR,
} from "../constants";

export const baner = (state = {
    isFetching: false
}, action) => {
    switch (action.type) {
        case BANER_REQUEST:
            state = {
                ...state,
                isFetching: true
            };
            return state;
        case BANER_RECEIVED:
            state = {
                ...state,
                baner: action.data['hydra:member'],
                isFetching: false
            };
            return state;
        case BANER_ERROR:
            state = {
                ...state,
                isFetching: false,
                baner: null,
                error: action.error
            };
            return  state;
        default:
            return state;
    }
}