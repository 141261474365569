import {requests} from "../agent";

import {
    PROFILER_ERROR,
    PROFILER_FETCH_CATEGORIES_RECEIVED,
    PROFILER_PRODUCTS_RECEIVED, PROFILER_PROFILE_UPDATE_SETTINGS_RECEIVED,
    PROFILER_REQUEST,
    PROFILER_UPDATE_SETTINGS_RECEIVED,
} from "../constants";

/**
 * set default profiler state
 * @returns {{type: string}}
 */
export const profilerRequest = () => ({
    type: PROFILER_REQUEST,
});


/**
 * Set profiler method error
 * @param error
 * @returns {{type: string, error: *}}
 */
export const profilerError = (error) => ({
    type: PROFILER_ERROR,
    error
});

/**
 * Set profiler method error
 * @param categories
 * @returns {Function}
 */
export const profilerUpdateSettings = (categories) => {
    let body = {
        categories: categories
    }

    return (dispatch) => {
        dispatch(profilerRequest());
        return requests.post(`/users/settings`, body, true)
            .then(response => dispatch(profilerUpdateSettingsReceived(response)))
            .catch((error => dispatch(profilerError(error))));
    }

}
/**
 * Set profiler method error
 * @param categories
 * @returns {Function}
 */
export const profilerProfileUpdateSettings = (categories) => {
    let body = {
        categories: categories
    }

    return (dispatch) => {
        dispatch(profilerRequest());
        return requests.post(`/users/settings`, body, true)
            .then(response => dispatch(profilerProfileUpdateSettingsReceived(response)))
            .catch((error => dispatch(profilerError(error))));
    }

}

export const profilerUpdateSettingsReceived = (categories) => ({
    type: PROFILER_UPDATE_SETTINGS_RECEIVED,
    categories
});
export const profilerProfileUpdateSettingsReceived = (categories) => ({
    type: PROFILER_PROFILE_UPDATE_SETTINGS_RECEIVED,
    categories
});

/**
 * Get profiler by type
 * @param type
 * @returns {function(*): Q.Promise<any>}
 */
export const profilerFetchCategories = () => {
    return (dispatch) => {
        dispatch(profilerRequest());
        return requests.get(`/users/settings/categories`, true)
            .then(response => dispatch(profilerFetchCategoriesReceived(response)))
            .catch((error => dispatch(profilerError(error))));
    }
};

export const profilerProductsReceived = (data) => ({
    type: PROFILER_PRODUCTS_RECEIVED,
    data
});

/**
 * Get profiler by type
 * @returns {function(*): Q.Promise<any>}
 */
export const profilerFetchProducts = () => {
    return (dispatch) => {
        dispatch(profilerRequest());
        return requests.get(`/trainings/user_personalized`, true)
            .then(response => dispatch(profilerProductsReceived(response)))
            .catch((error => dispatch(profilerError(error))));
    }
};

/**
 * Get profiler by type
 * @returns {function(*): Q.Promise<any>}
 */

export const profilerFetchCategoriesReceived = (data) => {
    return {
        type: PROFILER_FETCH_CATEGORIES_RECEIVED,
        data
    }
};