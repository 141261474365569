import React from 'react';
import {Button, Card, Col} from "react-bootstrap";
import {strNormalize} from "../../../../../common/utils";
import dateFormat from "dateformat";
import striptags from "striptags";

const WebinarFreeListItems = props => {
    const {product, auth, id} = props;
    function checkAccessBrand(auth, product, id) {
        if (!auth || !auth.userData) {
            return false;
        }

        if (window.location.pathname === '/') {
            if (product.categories[0].id === 71 && auth.userData.brands.some(brand => brand.brandId === 1)) {
                return true;
            }
            if (product.categories[0].id === 14 && auth.userData.brands.some(brand => brand.brandId === 2)) {
                return true;
            }
        } else {
            if (id === 71 && auth.userData.brands.some(brand => brand.brandId === 1)) {
                return true;
            }
            if (id === 14 && auth.userData.brands.some(brand => brand.brandId === 2)) {
                return true;
            }
        }

        return false;
    }

    const hasAccess = checkAccessBrand(auth, product, id);

    return (
        <div className={"index-list-item"}>
            <div className={"card-img-top"} width={"120"} height={"147"}
                 style={{backgroundImage: `url(${product.imageUrl})`}}/>
            <div
                className={`webinar-labels category-${product.categories[0].id} ${product.archive ? 'with-overdue' : ''}`}>
                <div className={"archived-label"}>{ hasAccess ? 'Webinar' : 'Webinar bezpłatny'}</div>
                {product.categories ?
                    <div
                        className={`category-label`}>{product.categories.map((category, index) => (

                        <span
                            key={index}>{category.name} {product.categories.length > index + 1 && product.categories.length > 1 ? ' | ' : ''}</span>

                    ))}</div>
                    : ''
                }
                {product.archive ? <div className={"archived-label overdue"}>Archiwalne</div> : ''}
            </div>
            <Card.Body>
                <a className={"title-link"} href={`/${strNormalize(product.type)}/${strNormalize(product.name)}/${(product.id)}`}><Card.Title
                    title={product.name}>{product.name.length > 50 ? product.name.substring(0, 50) + '...' : product.name}</Card.Title></a>
                {product.experts ?

                    <div className={"expert-wrapper"}>{product.experts.map((expert, index) => (
                        <div className={"webinar-item-expert"}
                             key={index}>&nbsp;{expert.name} {expert.surname}{product.experts.length > index + 1 && product.experts.length > 1 ? ', ' : ''}</div>
                    ))}</div>
                    : <div className={"webinar-item-expert"}></div>}
                <div className={"webinar-dates-container card-text"}>

                    {product && product.timetable.map((date, index) => (
                        <div key={index}
                             className={`${date.expired ? 'webinar-date-item expired' : 'webinar-date-item'}`}>
                            <span>
                                {product.categories[0].id === 71 ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                                         fill="none">
                                        <rect x="1.5" y="1.5" width="9" height="9" rx="4.5" stroke="#4AA5D8"
                                              strokeWidth="3"/>
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                                         fill="none">
                                        <rect x="1.5" y="1.5" width="9" height="9" rx="4.5" stroke="#FDD26E"
                                              strokeWidth="3"/>
                                    </svg>
                                }
                                {index === 0 ? <>1&nbsp;</> : index + 1} TERMIN</span>
                            <p>
                                <span
                                    className={"time"}>{dateFormat(Date.parse(date.eventStartDate), 'HH:MM')}</span>
                                <span
                                    className="date">{dateFormat(Date.parse(date.eventStartDate), 'dd-mm-yyyy')}</span>
                            </p>
                        </div>
                    ))}
                </div>
            </Card.Body>
            <Card.Text className="item-lead webinar-item-lead ">
                {product.shortDescription ? <span>{striptags(product.shortDescription)}</span> : ''}

            </Card.Text>


            {hasAccess ?
                <div className={'button-wrapper webinar-free-wrapper'}>
                    <Button href={`/${strNormalize(product.type)}/${strNormalize(product.name)}/${(product.id)}`}
                            className="educado-btn educado-btn-black">Oglądaj bezpłatnie</Button>
                    {/*<a href={`/${strNormalize(product.type)}/${strNormalize(product.name)}/${(product.id)}`}*/}
                    {/*   className="training-btn">Więcej o webinarze</a>*/}
                </div>
                :
                <div className={'button-wrapper webinar-free-wrapper'}>

                    <Button href={`/${strNormalize(product.type)}/${strNormalize(product.name)}/${(product.id)}`} className="educado-btn educado-btn-black">Zapisz się</Button>
                    {/*<a href={`/${strNormalize(product.type)}/${strNormalize(product.name)}/${(product.id)}`}*/}
                    {/*   className="training-btn">Więcej o webinarze</a>*/}
                </div>
            }
        </div>
    );
}


export default WebinarFreeListItems;