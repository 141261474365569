import React from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";

const HomeHeroContainer = props => {

    const {auth} = props;

    return (
        <Container className={'homepage-hero-banner'}>
            <Row>
                {auth && auth.isAuthenticated ?
                    <Col md={12}>
                        <h1>Witaj {auth.userData.name},<br/>zobacz najnowsze szkolenia dla Ciebie.
                        </h1>
                    </Col>
                    :
                    <Col md={8}>
                        <h1>Wejdź do świata szkoleń on-line {window.innerWidth > 767 ? <br/> : ''}dla specjalistów w swojej
                            dziedzinie!</h1>
                    </Col>
                }
            </Row>
            {auth && auth.isAuthenticated ? '' :
            <div className={"homepage-hero-banner-container"}>
                <div className={"hero-baner-item oswiata"}>
                    <div className={'hero-container-content'}>
                        <div className={"logo-wrapper"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="66" height="63"
                                 viewBox="0 0 66 63" fill="none">
                                <path
                                    d="M54.5933 37.6029C55.694 34.7442 56.3544 31.4457 56.3544 28.1472C56.3544 12.7542 43.8067 0 28.1772 0C12.5477 0 0 12.5343 0 28.1472C0 43.76 12.7678 56.2943 28.1772 56.2943C32.8 56.2943 37.2027 55.1948 40.945 53.2157C42.2658 58.4933 47.1087 62.6714 53.0524 62.6714C59.8765 62.6714 65.6 57.1739 65.6 50.1371C65.6 43.5401 60.757 38.2626 54.5933 37.6029ZM37.2027 30.5661L25.0953 37.6029C22.894 38.9223 21.1329 37.8228 21.1329 35.184V21.1104C21.1329 18.4716 22.894 17.592 25.0953 18.6915L37.2027 25.7283C39.404 27.0477 39.404 29.2467 37.2027 30.5661Z"
                                    fill="#4AA5D8"/>
                            </svg>
                            <span>oświata</span>
                            <p>Szkolenia on-line dla dyrektorów,<br/> nauczycieli i pedagogów<br/></p>
                        </div>
                        <Button href="/oswiata" title="szkolenia oświata"
                                className={"educado-btn educado-btn-white"}>Zobacz
                            szkolenia</Button>
                    </div>
                </div>
                <div className={"hero-baner-item kik"}>
                    <div className={'hero-container-content'}>
                        <div className={"logo-wrapper"}>

                            <svg xmlns="http://www.w3.org/2000/svg" width="66" height="63"
                                 viewBox="0 0 66 63" fill="none">
                                <path
                                    d="M54.5933 37.6029C55.694 34.7442 56.3544 31.4457 56.3544 28.1472C56.3544 12.7542 43.8067 0 28.1772 0C12.5477 0 0 12.5343 0 28.1472C0 43.76 12.7678 56.2943 28.1772 56.2943C32.8 56.2943 37.2027 55.1948 40.945 53.2157C42.2658 58.4933 47.1087 62.6714 53.0524 62.6714C59.8765 62.6714 65.6 57.1739 65.6 50.1371C65.6 43.5401 60.757 38.2626 54.5933 37.6029ZM37.2027 30.5661L25.0953 37.6029C22.894 38.9223 21.1329 37.8228 21.1329 35.184V21.1104C21.1329 18.4716 22.894 17.592 25.0953 18.6915L37.2027 25.7283C39.404 27.0477 39.404 29.2467 37.2027 30.5661Z"
                                    fill="#FDD26E"/>
                            </svg>
                            <span>księgowość i kadry</span>
                            <p>Szkolenia on-line dla kadr, działów<br/> HR i księgowości w firmach<br/> i
                                biurach
                                rachunkowych</p>
                        </div>
                        <Button href="/ksiegowosc-i-kadry" title="szkolenia kik"
                                className={"educado-btn educado-btn-white"}>Zobacz
                            szkolenia</Button>
                    </div>
                </div>
            </div>}
        </Container>
    )

}

export default HomeHeroContainer;