import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import WebinarForm from "./WebinarForm";
import dateFormat from "dateformat";
import {Helmet} from "react-helmet";
import ExpertContainer from "../../Modules/Experts/Sliders/ExpertSliderContainer";
import AwardsSlider from "../../Modules/AwardsBanner/AwardsSlider";
import ProductContent from "../ProductContent";
import RightSection from "../RightSection";
import {parseTrainingTime, strNormalize} from "../../../common/utils";
import striptags from "striptags";



class WebinarRegistration extends React.Component {

    checkDate() {
        const {isFetching, product} = this.props;

        if (!isFetching && Date.parse(product.timetable[0].eventStartDate) - 3600000 < Date.parse(new Date())) {
            return (
                <Container className={"webinar-offer-container"}>
                    <h4 id={"WebinarForm"}>Webinar niedostępny</h4>
                    <p>Jeśli masz więcej pytań, skontaktuj się z Centrum Obsługi Klienta</p>
                </Container>

            )
        } else {
            return (
                <Container className={"webinar-offer-container"}>
                    <h4 id={"WebinarForm"}>Kup Webinar</h4>
                    <h2>{product.name}</h2>
                    {product && product.additionalFields.priceGross && product.additionalFields.priceNet ?
                        <h3>
                            <span><strong>{product.additionalFields.priceGross.toFixed(2)}</strong> zł <small>brutto</small></span>{product.additionalFields.priceNet.toFixed(2)}
                            <small> zł netto</small>
                        </h3> : ''}
                    <p>Cena dotyczy jednego dostępu, w dalszych krokach istnieje możliwość zakupu
                        produktu dla wielu stanowisk.</p>
                    <p>Posiadasz kod rabatowy? Wpisz go w kolejnym kroku.</p>
                    <Button href={`/rejestracja-webinar/${product.id}`}
                            className={"webinar-btn reverse"}>Kup dostęp</Button>
                </Container>
            )
        }
    }
    checkButton() {
        const {isFetching, product} = this.props;

        if (!isFetching && Date.parse(product.timetable[0].eventStartDate) - 3600000 < Date.parse(new Date())) {
            return
        } else {
            return (
                <Button href={`/rejestracja-webinar/${product.id}`} className={"webinar-btn reverse"}>Kup
                    dostęp</Button>
            )
        }
    }

    render() {
        const {product} = this.props;

        return (
            <>
                <Container fluid className={"webinar-wave-container"}>
                    <Container className={"webinar-wave-container-content webinar-paid-wave-container"}>
                        <Row>
                            <Col lg={4} md={6} className={'webinar-data'}><h1>{product.name}</h1>
                                <h2>Ekspert:
                                    {product.experts ?
                                        <>{product && product.experts.map((expert, key) => (
                                            <span key={key}>&nbsp;
                                                {expert.name} {expert.surname}{product.experts.length > key + 1 && product.experts.length > 1 ? ', ' : ''}
                                            </span>
                                        ))}
                                        </>
                                        : ''
                                    }
                                </h2>
                                {product.shortDescription ? <p>{striptags(product.shortDescription)}</p> : ''}
                                <span
                                    className={"webinar-duration white"}>{parseTrainingTime(product.timetable[0].duration)}</span>
                                <div className={"webinar-dates-container"}>
                                    {product && product.timetable.map((date, index) => (
                                        <div key={index}
                                             className={date.expired ? 'webinar-date-item expired' : 'webinar-date-item'}>
                                            <span><strong>LIVE z czatem</strong></span><p>
                                            <span
                                                className={"time"}>{dateFormat(Date.parse(date.eventStartDate), 'HH:MM')}</span><span
                                            className="date">
                                            &nbsp;| {dateFormat(Date.parse(date.eventStartDate), 'dd-mm-yyyy')}</span>
                                        </p></div>
                                    ))}

                                </div>
                                {this.checkButton()}
                            </Col>
                            <Col lg={8} md={6} className={"webinar-image"}>
                                <div className={"webinar-image-badge"}>
                                    <div className={"webinar-image-badge-type"}>webinar płatny</div>
                                    <div
                                        className={`webinar-image-badge-category badge-${strNormalize(product.categories[0].name)}`}>{product.categories[0].name}</div>
                                </div>
                                {product.imageUrl
                                    ? <img src={product.imageUrl} alt={product.name}/>
                                    : <img src={"/zaslepka.png"} alt={"webinar"}/>}

                                {/*<div className={"webinar-benefit-container"}>*/}
                                {/*    <div className={"webinar-benefit-item item-1"}><img src={"/green/chat.svg"}*/}
                                {/*                                                        alt={"45-minutowe wystąpienie eksperta"}/><span>Czat "na żywo" z ekspertem<br/>w trakcie webinaru</span>*/}
                                {/*    </div>*/}
                                {/*    <div className={"webinar-benefit-item item-2"}><img src={"/green/clock.svg"}*/}
                                {/*                                                        alt={"48-godzinny dostęp do nagrania"}/><span>72-godzinny dostęp<br/>do nagrania</span>*/}
                                {/*    </div>*/}
                                {/*    <div className={"webinar-benefit-item item-3"}><img*/}
                                {/*        src={"/green/certificate.svg"}*/}
                                {/*        alt={"Imienny certyfikat"}/><span>Imienny certyfikat</span>*/}
                                {/*    </div>*/}
                                {/*    <div className={"webinar-benefit-item item-3"}><img*/}
                                {/*        src={"/green/materials.svg"}*/}
                                {/*        alt={"Imienny certyfikat"}/><span>Dodatkowe materiały<br/>szkoleniowe</span>*/}
                                {/*    </div>*/}
                                {/*    <div className={"webinar-benefit-item item-3"}><img*/}
                                {/*        src={"/green/materials.svg"}*/}
                                {/*        alt={"Imienny certyfikat"}/><span>Prezentacje PDF<br/>ze szkolenia</span>*/}
                                {/*    </div>*/}
                                {/*    <div className={"webinar-benefit-item item-3"}><img*/}
                                {/*        src={"/green/ekspert.svg"}*/}
                                {/*        alt={"Imienny certyfikat"}/><span>60-minutowe <br/>wystąpienie eksperta</span>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Container fluid className={"webinar-form-section"}>
                    <Container>
                        <Row>
                            <Col className={"webinar-details-container"} lg={8} md={6}>
                                <Container className={"webinar-details-container-content"}>
                                    <ProductContent children={this.props}/>
                                    <h3 className={"mt-5"}>Co otrzymasz po zapisie?</h3>
                                    <div className={"webinar-benefit-container webinar-paid-benefit-container"}>
                                        <div className={"webinar-benefit-item item-1"}><img src={"/green/chat.svg"}
                                                                                            alt={"45-minutowe wystąpienie eksperta"}/><span>Czat "na żywo" z ekspertem<br/>w trakcie webinaru</span>
                                        </div>
                                        <div className={"webinar-benefit-item item-2"}><img src={"/green/clock.svg"}
                                                                                            alt={"48-godzinny dostęp do nagrania"}/><span>72-godzinny dostęp<br/>do nagrania</span>
                                        </div>
                                        <div className={"webinar-benefit-item item-3"}><img
                                            src={"/green/certificate.svg"}
                                            alt={"Imienny certyfikat"}/><span>Imienny certyfikat</span>
                                        </div>
                                        <div className={"webinar-benefit-item item-3"}><img
                                            src={"/green/materials.svg"}
                                            alt={"Imienny certyfikat"}/><span>Dodatkowe materiały<br/>szkoleniowe</span>
                                        </div>
                                        <div className={"webinar-benefit-item item-3"}><img
                                            src={"/green/materials.svg"}
                                            alt={"Imienny certyfikat"}/><span>Prezentacje PDF<br/>ze szkolenia</span>
                                        </div>
                                        <div className={"webinar-benefit-item item-3"}><img
                                            src={"/green/ekspert.svg"}
                                            alt={"Imienny certyfikat"}/><span>60-minutowe <br/>wystąpienie eksperta</span>
                                        </div>
                                    </div>
                                    <h3 className={"mt-5"}>Szczegóły organizacyjne</h3>
                                    <p>Link do webinarium wysyłamy na podany przy rejestracji adres mailowy Uczestnika/Uczestników, dzień przed terminem wydarzenia oraz w dniu wydarzenia. Prezentacja oraz imienny certyfikat zostaną przesłane mailowo kilka dni po webinarium.
                                    </p>
                                    <p>Odtwarzanie nie wymaga instalowania dodatkowych programów. Rekomendujemy korzystanie z przeglądarki Chrome lub Firefox oraz zapewnienie stabilnego łącza internetowego.</p>
                                    {this.checkButton()}

                                </Container>
                            </Col>
                            <Col className={"webinar-form-container"} lg={4} md={6}>
                                {this.checkDate()}
                                <RightSection product={product}/>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                {product.ads && product.ads.map((item, index) => {
                    if (item.zone === "leaderboard") {
                        return (
                            <Container key={index} className={"text-center mb-5"}>
                                <div className={"product-banner banner-front"}>
                                    {item.url ? <a target={'_blank'} href={item.url} rel='noreferrer'>
                                        <img alt={item.name} src={item.imageUrl}/>
                                    </a> : ''}

                                    <Helmet>
                                        <script>{item.js}</script>
                                    </Helmet>
                                </div>
                            </Container>
                        )
                    }
                    return '';
                })}
                <ExpertContainer/>
                <AwardsSlider/>
            </>
        )
    }
}

export default WebinarRegistration;
