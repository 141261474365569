import React from "react";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {strNormalize} from "../../../common/utils";
import {iriFormatId} from "../../../common/apiUtils";
import connect from "react-redux/es/connect/connect";

const mapStateToProps = state => ({
    ...state.product,
    webinar: state.webinar,

});

const mapDispatchToProps = {}

class WebinarThx extends React.Component {

    componentDidMount() {
        window.sessionStorage.removeItem('webinarDate');
        window.scroll(0,0);
    }

    getExpert(product) {
        return (
            <h3>
                <span>Ekspert: </span>
                {product.experts.map((expert, key) => (
                    <a href={`/ekspert/${strNormalize(expert.name)}-${strNormalize(expert.surname)}/${iriFormatId(expert)}`}
                       className="d-inline-block" key={key}>
                        &nbsp;{expert.name} {expert.surname}{product.experts.length > key + 1 && product.experts.length > 1 ? ', ' : ''}
                    </a>
                ))}
            </h3>
        )
    }

    render() {
        const {product, isFetching} = this.props;

        if (isFetching || !product) {
            return (

                <Container className="home-main-container">
                    <Row>
                        <Container className="home-products-container">
                            <br/><br/><Spinner animation="grow"/>
                        </Container>
                    </Row>
                </Container>
            );
        }
        return (
            <Row>
                <Container fluid className={"webinar-wave-container webinar-thx"}>
                    <svg viewBox="0 0 1366 610.08" preserveAspectRatio={"none"}>
                        <path className={"cls-1"}
                              d={"M1366,0V131.7c-27.57,11.28-61.65,25.63-101.2,43C1152.7,224.07,1097.74,65.51,962.43,56.6S716.69,197.5,683.86,310.66,478.78,652.86,309.42,603.11C148.25,555.77,54.5,473.62,0,546.19V0Z"}/>
                    </svg>
                    <Container className={"webinar-wave-container-content"}>
                        <Row>
                            <Col md={6} lg={5} className={'webinar-thx-data'}>
                                <h2><span>DZIĘKUJEMY</span>ZA ZAPISANIE SIĘ NA BEZPŁATNY WEBINAR!</h2>
                                <p>Na podany w formularzu adres email otrzymasz wiadomość z linkiem do potwierdzenia adresu mailowego – prosimy o kliknięcie w link. Następnie otrzymasz kolejny mail z potwierdzeniem zapisu na webinarium oraz link aktywacyjny do wydarzenia.
                                </p>
                                <p>Po kliknięciu w link zostaniesz przeniesiony do okna transmisji szkolenia</p>
                                <p>Pamiętaj, że webinar na żywo odbywa się tylko raz o określonej porze</p>
                                <p>Sprawdź swoją pocztę elektroniczną. <strong>Jeśli wiadomości nie ma w głównym folderze, zajrzyj do zakładki spam, zakładki oferta lub innych katalogów.</strong></p>
                            </Col>
                            <Col md={6} lg={7} className={"webinar-image"}>
                                <div className={"webinar-image-badge"}>
                                    <div className={"webinar-image-badge-type"}>{product.subType}</div>
                                    <div className={`webinar-image-badge-category badge-${strNormalize(product.categories[0].name)}`}>{product.categories[0].name}</div>
                                </div>
                                {product.imageUrl
                                    ? <img src={product.imageUrl} alt={product.name}/>
                                    : <img src={"/zaslepka.png"} alt={"webinar"}/>
                                }

                                <h1>{product.name}</h1>
                                {this.getExpert(product)}
                                <h4>{window.sessionStorage.getItem('webinarDate')}</h4>
                            </Col>

                        </Row>
                    </Container>
                </Container>
                <Container className={"webinar-products-list"}>
                    <Row>
                        <h2 className="webinar-main-head">Rekomendowane dla Ciebie</h2>
                    </Row>
                </Container>
            </Row>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebinarThx);