import React from "react";
import {Button, Container, Form, FormControl, Nav, Navbar, NavDropdown, Row, Alert, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import Footer from "../Footer";
import {Helmet} from "react-helmet";
import {convertMetaDescription, convertMetaTitle} from "../../common/utils";


const AppOffline = (props) => {

    let content = false;

    if (props && props.appOffline) {
        content = true;
    }

    return (
        <>
            <Navbar id="main-menu" sticky="bottom" expand="lg" variant="dark">
                <Container>
                    <Navbar.Brand className="logo-header" href="/"><img alt="logo Educado" src="/logo-white.svg"/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="nav-category"/>
                    <Navbar.Collapse id="nav-category">
                        <Nav className="category-btn">
                            <NavDropdown title="Katalog szkoleń"/>
                        </Nav>
                        <Nav className="mr-auto search-form">
                            <Form className="mr-auto" inline action="/szukaj" method="get">
                                <FormControl name="phraze" type="text" placeholder="Czego szukasz?" className="" />
                                <Button  type="submit" variant="outline-light"><FontAwesomeIcon icon={faSearch} /></Button>
                            </Form>
                        </Nav>
                    </Navbar.Collapse>
                    <Nav className="user-info"/>
                </Container>
            </Navbar>
            <Container className="home-main-container">
                <Helmet>
                    <title>{convertMetaTitle('najlepsze szkolenia dla profesjonalistów')}</title>
                    <meta name="description" content={convertMetaDescription('Najlepsze szkolenia dla profesjonalistów')} />
                </Helmet>
                <Row>
                    <Container className="home-products-container">
                        <br/><br/>
                        {content
                            ? <Alert variant="danger">
                                <Alert.Heading>Przerwa techniczna.</Alert.Heading>
                                <p>
                                    Przepraszamy aplikacja educado jest tymczasowo niedostępna.
                                </p>
                                <hr/>
                                <p className="mb-0">
                                    W przypadku pytań prosimy o kontakt z działem obsługi klienta <a
                                    href="mailto:cok@wip.pl">cok@wip.pl</a>
                                </p>
                            </Alert>
                            : <><Spinner animation="grow"/></>
                        }
                    </Container>
                </Row>
            </Container>
            <Footer/>
        </>
    )
}

export default AppOffline;