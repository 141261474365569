import React from "react";
import RegisterCode from "./RegisterCode";
import RegisterForm from "./RegisterForm";
import connect from "react-redux/es/connect/connect";


const mapStateToProps = state => ({
    auth: state.auth
});

class AffliateCode extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            codeStatus: ''
        }
    }


    componentDidUpdate(prevProps) {

        const {auth} = this.props
        if (auth.isAuthenticated && !auth.decreaseLevel) {
            this.props.history.push("/");
        }
    }

    getStepTwoContent() {
        if (window.sessionStorage.getItem('code')) {
            return <RegisterForm children={this.props.children}/>;
        } else {
            return <RegisterCode children={this.props.children}/>;

        }
    }

    render() {

        return (
            <>
                {/*<RegisterCode children={this.props.children}/>*/}
                {this.getStepTwoContent()}
            </>
        )

    }

}
export default connect(mapStateToProps)(AffliateCode);
