
export const parseApiErrors = (error) => {
    return error.response.body.violations.reduce(
        (parsedErrors, violation) => {
            if (violation['propertyPath'] === 'user.email'
            || violation['propertyPath'] === 'buyer.email') {
                return {email: violation['message']};
            }
            parsedErrors[violation['propertyPath']] = violation['message'];
            return parsedErrors;
        },
        {}
    );
};

export const hydraPageCount = (collection) => {
    if (!collection['hydra:view'] || !collection['hydra:view']['hydra:last']) {
        return 1;
    }

    return Number(
        collection['hydra:view']['hydra:last'].match(/page=(\d+)/)[1]
    );
};

export const iriFormatId = (url) => {
    return Number(
        url["@id"].split('/').pop()
    );
};