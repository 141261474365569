import React from "react";
import {Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {getBreadcrumb} from "../../../common/utils";
import dateFormat from "dateformat";


class Payment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            paymentType: 'payu',
            error: false,
            type: 'payment',
            quantity: 1,
            code: '',
            codeMessage: false,
            discountCode: '',
            breadcrumb: [
                {'type': 'buyer', 'class': 'one', 'name': 'Krok 1 / 3 - DANE'},
                {'type': 'users', 'class': 'one', 'name': 'Krok 1 / 3 - DANE'},
                {'type': 'payment', 'class': 'two', 'name': 'Krok 2 / 3 - PODSUMOWANIE I PŁATNOŚĆ'},
                {'type': 'summary', 'class': 'three', 'name': 'KROK 3 / 3 -  POTWIERDZENIE I DODATKOWE INFORMACJE'}
            ]
        }
    }

    componentDidMount() {
        const {registerGetOrder, product, registerToken, registerWebinarPrice} = this.props.children;
        const tmp = JSON.parse(window.sessionStorage.getItem('discount'));
        if (tmp) {
            this.setState({
                quantity: tmp.quantity,
                discountCode: tmp.discountCode
            });
            registerWebinarPrice(product.id, tmp.discountCode, tmp.quantity)

        } else {
            registerWebinarPrice(product.id, this.state.discountCode, this.state.quantity)

        }
        if (registerToken)
            registerGetOrder(registerToken);
    }

    // componentDidUpdate(prevProps, PrevState) {
    //     const {registerToken, product, priceData, registerOrderPriceUpdate} = this.props.children
    //     if (this.props.children.priceData!== undefined &&  prevProps.children.priceData!==undefined && (this.props.children.priceData != prevProps.children.priceData)) {
    //         console.log(prevProps.children.priceData.discountApply)
    //         if (prevProps.children.priceData && (priceData.discountApply != prevProps.children.priceData.discountApply)) {
    //             registerOrderPriceUpdate(registerToken, product.id, priceData.discountCode, this.state.quantity)
    //         }
    //     }
    //
    // }

    processPayment() {
        if (this.state.paymentType) {
            const {registerPaymentComplete, registerToken, product} = this.props.children;

            registerPaymentComplete(product, this.state.paymentType, registerToken);
        } else {
            this.setState({error: 'Musisz wybrać sposób płatności!'})
        }
    }

    choosePaymentType(paymentType) {
        this.setState({paymentType: paymentType})
    }

    invoiceData() {
        const {user, buyer} = this.props.children;
        if (user && buyer) {
            return (
                <>
                    <div className={'payment-data-head-container'}>
                        <h5>Twoje dane do faktury</h5>
                        <button className={'btn btn-primary'} onClick={this.editInvoiceData.bind(this)}>zmień
                        </button>
                    </div>
                    <p>
                        <small>imię:</small>
                        {user.name}</p>
                    <p>
                        <small>nazwisko:</small>
                        {user.surname}</p>
                    <p>
                        <small>email:</small>
                        {user.email}</p>
                    {
                        buyer.tin && user.userType === "company" ?
                            <>
                                <hr/>
                                <p><strong>Dane płatnika:</strong></p>
                                <p>
                                    <small>adres:</small>
                                    {buyer.city}, {buyer.postalCode}, {buyer.street} {buyer.streetNumber} {buyer.apartmentNumber}
                                </p>
                                {buyer.tin ? <p>
                                    <small>NIP:</small>
                                    {buyer.tin}</p> : ''}
                            </>
                            :
                            <>
                                <hr/>
                                <p>
                                    <small>adres:</small>
                                    {user.city}, {user.postalCode}, {user.street} {user.streetNumber} {user.apartmentNumber}
                                </p>
                                {user.tin && user.userType === "company" ? <p>
                                    <small>NIP:</small>
                                    {user.tin}</p> : ''}
                            </>
                    }
                </>
            );
        }
        return;
    }


    handleCodeChange = (e) => {
        this.setState({code: e.target.value});

    }


    onDeleteDiscount() {
        const {registerWebinarPrice, registerOrderPriceUpdate, registerToken, product} = this.props.children;
        let body = {
            discountCode: null,
            quantity: this.state.quantity,
        }
        window.sessionStorage.setItem('discount', JSON.stringify(body));
        document.querySelector('.code-wrapper input').setAttribute('value', '')
        this.setState({code: '', codeMessage: false})
            registerOrderPriceUpdate(registerToken, product.id, 'null', this.state.quantity)
        return (registerWebinarPrice(product.id, '', this.state.quantity))
    }


    onSubmitDiscount(e) {
        e.preventDefault();

        const {registerWebinarPrice, registerOrderPriceUpdate, product, registerToken} = this.props.children;

        let body = {
            discountCode: this.state.code ? this.state.code : '',
            quantity: this.state.quantity,
        };
        window.sessionStorage.setItem('discount', JSON.stringify(body));
        registerWebinarPrice(product.id, this.state.code, this.state.quantity)
        if(this.state.code.length > 1) {
            registerOrderPriceUpdate(registerToken, product.id, this.state.code, this.state.quantity)
        } else {

        }

        this.setState({code: '', codeMessage: true})

        return this.setState({code: '',  codeMessage: true})


    }

    getPrice() {
        const {priceData} = this.props.children;
        if (priceData) {
            return (
                <div className={"price-container"}>
                    <h4>{priceData && priceData.discountApply ? priceData.discountPriceGross.toFixed(2) : priceData.totalPriceGross.toFixed(2)}
                        <small> zł</small>
                        <span> brutto</span>
                    </h4>
                    <h5>{priceData && priceData.discountApply ? priceData.discountPriceNet.toFixed(2) : priceData.totalPriceNet.toFixed(2)}
                        <small> zł netto</small>
                    </h5>
                </div>
            )
        }
    }

    editInvoiceData() {
        window.sessionStorage.setItem('regStep', 'PROGRESS');
        window.location.reload(false)
    }

    editUsersData() {
        window.sessionStorage.setItem('regStep', 'USERS');
        window.location.reload(false)
    }

    getDiscount() {
        const {priceData} = this.props.children;
        if (priceData && priceData.valid && priceData.discountApply && priceData.discountCode) {
            const tmp = priceData.totalPriceGross - priceData.discountPriceGross
            return tmp.toFixed(2)
        }
    }

    render() {
        const {product, user, buyer, priceData, isFetching} = this.props.children;
        if (!user) {
            return (<Spinner animation="grow"/>);

        }
        return (

            <Container>
                <Row className={"register-product-buyer-container"}>
                    <Form className="row">
                        <Col xs={{span: 12}} lg={{span: 8}} className={"rpa-login-form-container payment-container"}>
                            <div className={"background-wrapper"}>

                                <Container className={"buyer-form-container"}>
                                    {getBreadcrumb(this.state.breadcrumb, this.state.type, 'reg-breadcrumb payment')}
                                    <Row className={"payment-data-container"}>
                                        <Col md={5}>
                                            <div className={"payment-item"}>
                                                <h2>Dane nabywcy:</h2>
                                                <p>{user.name} {user.surname}</p>
                                                {user.userType === "company" ? <p>{user.companyName}</p> : ''}
                                                {user.userType === "company" && user.tin ? <p>NIP: {user.tin}</p> : ''}
                                                <p>{user.street} {user.streetNumber}{user.apartmentNumber ? '/' + user.apartmentNumber : ''}</p>
                                                <p>{user.postalCode} {user.city}</p>
                                                <p>{user.phoneNumber}</p>
                                                <p>{user.email}</p>
                                            </div>
                                            {user.userType === "company" && user.diffBuyer ?
                                                <div className={"payment-item"}>
                                                    <h2>Dane odbiorcy:</h2>
                                                    <p>{buyer.companyName}</p>
                                                    {buyer.tin ? <p>NIP: {buyer.tin}</p> : ''}
                                                    <p>{buyer.street} {buyer.streetNumber}{buyer.apartmentNumber ? '/' + buyer.apartmentNumber : ''}</p>
                                                    <p>{buyer.postalCode} {buyer.city}</p>
                                                    <p>{buyer.phoneNumber}</p>
                                                    <p>{buyer.email}</p>
                                                </div> : ''
                                            }
                                        </Col>
                                        <Col md={{span: 5, offset: 2}}>
                                            {window.sessionStorage.vatRelease && window.sessionStorage.vatRelease === 'true' ?
                                                <div className={"payment-item vat-release"}>
                                                    <p>Zaznaczyłeś opcję <strong>„Zwolnienie z VAT”.</strong>
                                                        Pobierz oświadczenie i prześlij podpisany skan dokumentu na
                                                        adres
                                                        cok@wip.pl, a płatność zostanie skorygowana.</p>
                                                    <Button href={"/oswiadczenie.doc"} target={'_blank'}
                                                            rel='noreferrer' className={'btn webinar-btn'}>Pobierz
                                                        oświadczenie</Button>
                                                </div> : ''}

                                            <div className={"payment-item form-box-gray"}>
                                                <div className={"form-box-discount"}>
                                                    <h3>Kod rabatowy</h3>
                                                    <p>Posiadasz kod rabatowy?</p>
                                                    <div className={"field-wrapper code-wrapper"}>
                                                        <div className={"form-group"}>
                                                            <input
                                                                className={"form-control"}
                                                                type="text"
                                                                value={this.state.code}
                                                                onChange={this.handleCodeChange}
                                                                placeholder="Kod rabatowy"
                                                            />
                                                        </div>
                                                        {priceData && priceData.discountApply ?
                                                        <Button disabled className="discount-code-btn"
                                                                onClick={this.onSubmitDiscount.bind(this)}>Przelicz
                                                            cenę</Button> : <Button className="discount-code-btn"
                                                                                    onClick={this.onSubmitDiscount.bind(this)}>Przelicz
                                                                cenę</Button>}
                                                        {priceData && priceData.info && this.state.codeMessage ?
                                                            <p className={priceData.discountApply ? '' : "invalid-code-message"}>{priceData.info}</p> : ''}
                                                        {priceData && priceData.discountApply ?
                                                            <Button className={"delete-code"}
                                                                    onClick={this.onDeleteDiscount.bind(this)}><strong>x</strong> usuń
                                                                kod rabatowy</Button>
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"payment-item payment-radio"}>
                                                <h2>Sposób płatności:</h2>
                                                <Form.Group
                                                    className={"form-group-container payment-method-container payu"}>
                                                    <Form.Check
                                                        key={1}
                                                        type="radio"
                                                        label={'Payu'}
                                                        name="reg-payment-radio"
                                                        id={`radio-1`}
                                                        onClick={this.choosePaymentType.bind(this, 'payu')}
                                                        defaultChecked
                                                    />
                                                    {user.userType === "company" ?
                                                    <Form.Check
                                                        className={"invoice"}
                                                        key={2}
                                                        type="radio"
                                                        label={'płacę przelewem na podstawie faktury'}
                                                        name="reg-payment-radio"
                                                        id={`radio-2`}
                                                        onClick={this.choosePaymentType.bind(this, 'invoice')}
                                                    /> : '' }
                                                </Form.Group>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Col>
                        <Col md lg={{span: 4, offset: 0, order: 2}} className={"rpa-offer"}>
                            <Container>
                                <div className={"register-offer-wrapper"}>
                                    <div className="register-info-block mb-5">
                                        <h2>{product && product.name ? product.name : ''}</h2>
                                        {product && product.experts ?
                                            <p>Eksperci:
                                                {product && product.experts.map((expert, key) => (
                                                    <span key={key}>&nbsp;
                                                        {expert.name} {expert.surname}{product.experts.length > key + 1 && product.experts.length > 1 ? ', ' : ''}
                                            </span>
                                                ))}
                                            </p>
                                            : ''
                                        }
                                        <p>Termin: {product && product.timetable[0].eventStartDate ?
                                            <><span
                                                className="date">{dateFormat(Date.parse(product.timetable[0].eventStartDate), 'dd-mm-yyyy')}</span></> : ''

                                        }
                                        </p>
                                        <p>Godzina: {product && product.timetable[0].eventStartDate ?
                                            <span
                                                className={"time"}>{dateFormat(Date.parse(product.timetable[0].eventStartDate), 'HH:MM')}</span>
                                            : ''}
                                        </p>
                                        {priceData && priceData.valid && priceData.discountApply && priceData.discountCode ?
                                            <>
                                                <h4 className={"green-dot"}>dodano kod
                                                    rabatowy {priceData.discountCode}</h4>

                                            </> : ''}
                                        <h4 className={"green-dot"}>kupujesz dostęp
                                            dla {this.state.quantity}  {this.state.quantity === 1 ? "uczestnika" : "uczestników" }</h4>
                                    </div>
                                    <div className="register-info-block mb-5">
                                        <h3 className={"payu"}>Do zapłaty
                                            {priceData && priceData.valid && priceData.discountApply && priceData.discountCode ?
                                                <span>rabaty łącznie: {this.getDiscount()} zł brutto</span>
                                                : ''
                                            }
                                        </h3>
                                        {this.getPrice()}

                                        <div className={"btn-wrapper"}>
                                            {isFetching ? '' :
                                                <>
                                            <Button className={'btn btn-reverse webinar-btn'}
                                                    onClick={this.state.quantity > 1 ? this.editUsersData : this.editInvoiceData}>Wstecz</Button>
                                            <Button className={"btn webinar-btn"}
                                                    onClick={this.processPayment.bind(this)}>Zapłać</Button></> }
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </Col>
                    </Form>
                </Row>
            </Container>
        )
    }

}

export default Payment;