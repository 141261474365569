import {
    APP_LOAD, MULTI_TABLE_GET_SECOND_VOUCHER_RESPONSE, MULTI_TABLE_GET_VOUCHER_RESPONSE,
    PROFILER_UPDATE_SETTINGS_RECEIVED,
    REGISTER_ACCOUNT_RECEIVED,
    REGISTER_AFFILIATE_ACCOUNT_RECEIVED,
    REGISTER_ORDER_ERROR,
    REGISTER_ORDER_RECEIVED,
    REGISTER_ORDER_UPDATE_RECEIVED,
    REGISTER_PAYMENT_ERROR,
    REGISTER_PAYMENT_RECEIVED,
    REGISTER_WEBINAR_PRICE_ERROR,
    USER_ERROR,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT
} from "./constants";
import {JwtAuth} from "./agent";

export const tokenMiddleware = store => next => action => {

    switch (action.type) {
        case USER_LOGIN_SUCCESS:
            window.localStorage.setItem('jwt', action.token);
            JwtAuth.setToken(action.token);
            if (window.location.pathname.indexOf("aktywacja-hasla") > -1) {
                window.location.replace('/');
            }
            break;
        case PROFILER_UPDATE_SETTINGS_RECEIVED:
            window.location.reload(false);
            break;
        case USER_LOGOUT:
        case USER_ERROR:
            //case CATEGORY_ERROR:
            window.sessionStorage.clear();
            window.localStorage.removeItem('jwt');
            JwtAuth.setToken();
            break;
        case REGISTER_ACCOUNT_RECEIVED:
            window.sessionStorage.setItem('regToken', action.data.id);
            break;
        case REGISTER_ORDER_RECEIVED:
            window.sessionStorage.setItem('regStep', action.status);
            break;
        case REGISTER_ORDER_UPDATE_RECEIVED:
            window.sessionStorage.setItem('regToken', action.data.id);
            window.sessionStorage.setItem('regStep', action.status);
            break;
        case REGISTER_AFFILIATE_ACCOUNT_RECEIVED:
            window.sessionStorage.removeItem('code');
            window.location.replace('/zarejestruj-kod/podziekowanie');
            break;
        case REGISTER_WEBINAR_PRICE_ERROR:
            window.sessionStorage.removeItem('discount');
            break;
        case MULTI_TABLE_GET_SECOND_VOUCHER_RESPONSE:
            if (window.sessionStorage.getItem('secondVoucher') === null) {
                window.sessionStorage.setItem('secondVoucher', action.data === null ? 'false' : 'true');
                window.sessionStorage.setItem('secondVoucherLimit', action.data.inviteLimit);
            }
            break;
        case MULTI_TABLE_GET_VOUCHER_RESPONSE:
            if (window.sessionStorage.getItem('voucher') === null) {
                window.sessionStorage.setItem('voucher', action.data === null ? 'false' : 'true');
                window.sessionStorage.setItem('voucherLimit', action.data.inviteLimit);

            }
            break;
        case REGISTER_PAYMENT_RECEIVED:
        case REGISTER_PAYMENT_ERROR:
        case REGISTER_ORDER_ERROR:
            window.sessionStorage.clear();
            break;
        case APP_LOAD:
            let cid = document.URL.replace(/.*cid=([^&]*).*|(.*)/, '$1');
            if (cid) {
                window.localStorage.setItem('cid', cid);
            }
            if (window.sessionStorage.getItem('entryUrl') === null) {
                window.sessionStorage.setItem('entryUrl', document.URL);
            }
            break;
        default:
    }

    next(action);
};