import React from 'react';
import {Alert, Card, Col, Container, Row} from "react-bootstrap";
import {strNormalize} from "../../../../common/utils";
import dateFormat from "dateformat";
import striptags from "striptags";
import TrainingListItems from "./training/TrainingListItem";
import WebinarPaidListItems from "./webinarPaid/WebinarPaidListItem";
import WebinarFreeListItems from "./webinarFree/WebinarFreeListItem";

const ListItems = props => {

    const {products, auth, id} = props;

    function getContent(type, product, auth, id) {
        switch (type) {
            case 'online':
                return (<TrainingListItems product={product} auth={auth} id={id} />);
            case 'webinar':
                return (<WebinarFreeListItems product={product} auth={auth} id={id} />);
            case 'webinarPaid':
                return (<WebinarPaidListItems product={product} auth={auth} id={id} />);
            default:
                return ('');
        }
    }


    if (!products)
        return (<Row>
            <Col lg={4} md={6} className={'mt-4 index-item-container'}>
                <a className={"list-item"} href={`/`}>&nbsp;</a>
            </Col>
        </Row>);

    if (products.length === 0)
        return (<Container className={"mt-5"}><Alert variant="info">Brak webinarów w kategorii!</Alert></Container>);

    return (
        <Container>
            <Row>
                {products && products.map((product, index) => (
                    <Col key={index} lg={6} xl={4} md={6} className={'mt-4 list-item-container'}>
                        {getContent(product.type, product, auth, id)}
                    </Col>
                ))}
            </Row>
        </Container>
    );

}


export default ListItems;