export const APP_URL = process.env.REACT_APP_URL;
export const CATEGORY_MAIN_ID = process.env.REACT_APP_MAIN_CATEGORY_ID;
export const APP_NAME = 'Educado';
export const APP_LOAD = 'APP_LOAD';
export const APP_REDIRECT = 'APP_REDIRECT';

export const PRODUCT_LIST_RECEIVED = 'PRODUCT_LIST_RECEIVED';
export const PRODUCT_REQUEST = 'PRODUCT_REQUEST';
export const PRODUCT_RECEIVED = 'PRODUCT_RECEIVED';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';
export const PRODUCT_UNLOAD = 'PRODUCT_UNLOAD';
export const PRODUCT_TYPES_RECEIVED = 'PRODUCT_TYPES_RECEIVED';
export const PRODUCT_DOCUMENT_RECEIVED = 'PRODUCT_DOCUMENT_RECEIVED';
export const PRODUCT_LIST_QUERY_REQUEST = 'PRODUCT_LIST_QUERY_REQUEST';
export const PRODUCT_LIST_QUERY_RECEIVED = 'PRODUCT_LIST_QUERY_RECEIVED';
export const PRODUCT_ADDITIONAL_LIST_QUERY_REQUEST = 'PRODUCT_ADDITIONAL_LIST_QUERY_REQUEST';
export const PRODUCT_ADDITIONAL_LIST_QUERY_RECEIVED = 'PRODUCT_ADDITIONAL_LIST_QUERY_RECEIVED';
export const PRODUCT_HOME_LIST_QUERY_REQUEST = 'PRODUCT_HOME_LIST_QUERY_REQUEST';
export const PRODUCT_HOME_LIST_QUERY_RECEIVED = 'PRODUCT_HOME_LIST_QUERY_RECEIVED';
export const PRODUCT_LIST_QUERY_ERROR = 'PRODUCT_LIST_QUERY_ERROR';
export const PRODUCT_LIST_QUERY_SLIDER_REQUEST = 'PRODUCT_LIST_QUERY_SLIDER_REQUEST';
export const PRODUCT_LIST_QUERY_SLIDER_RECEIVED = 'PRODUCT_LIST_QUERY_SLIDER_RECEIVED';
export const PRODUCT_DOCUMENT_ATTACHMENT_REQUEST = 'PRODUCT_DOCUMENT_ATTACHMENT_REQUEST';
export const VIDEO_CHAPTERS = 'VIDEO_CHAPTERS';

export const CATEGORY_REQUEST = 'CATEGORY_REQUEST';
export const CATEGORY_RECEIVED = 'CATEGORY_RECEIVED';
export const CATEGORY_LIST_RECEIVED = 'CATEGORY_LIST_RECEIVED';
export const CATEGORY_PRODUCTS_LIST_RECEIVED = 'CATEGORY_PRODUCTS_LIST_RECEIVED';
export const CATEGORY_ERROR = 'CATEGORY_ERROR';
export const CATEGORY_PRODUCTS_LIST_TAG_FILTER = 'CATEGORY_PRODUCTS_LIST_TAG_FILTER';
export const CATEGORY_ANCESTORS = 'CATEGORY_ANCESTORS';
export const CATEGORY_DESCENDANTS = 'CATEGORY_DESCENDANTS';
export const CATEGORY_ALL = 'CATEGORY_ALL';

export const EXPERT_REQUEST = 'EXPERT_REQUEST';
export const EXPERT_RECEIVED = 'EXPERT_RECEIVED';
export const EXPERT_LIST_RECEIVED = 'EXPERT_LIST_RECEIVED';
export const EXPERT_ERROR = 'EXPERT_ERROR';
export const EXPERT_LIST_SET_PAGE = 'EXPERT_LIST_SET_PAGE';

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SET_ID = 'USER_SET_ID';
export const USER_SET_DATA = 'USER_SET_DATA';
export const USER_ERROR = 'USER_ERROR';
export const USER_REQUEST = 'USER_REQUEST';

export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_SAVED_PRODUCTS_SUCCESS = 'PROFILE_SAVED_PRODUCTS_SUCCESS';
export const PROFILE_SAVED_PRODUCTS_LIST_RECEIVED = 'PROFILE_SAVED_PRODUCTS_LIST_RECEIVED';
export const PROFILE_SAVED_PRODUCTS_DELETE = 'PROFILE_SAVED_PRODUCTS_DELETE';
export const PROFILE_PROGRESS_PRODUCTS_LIST_RECEIVED = 'PROFILE_PROGRESS_PRODUCTS_LIST_RECEIVED';
export const PROFILE_COMPLETE_PRODUCTS_LIST_RECEIVED = 'PROFILE_COMPLETE_PRODUCTS_LIST_RECEIVED';
export const PROFILE_WEBINAR_REQUEST = 'PROFILE_WEBINAR_REQUEST';
export const PROFILE_WEBINAR_ACTIVE_REQUEST = 'PROFILE_WEBINAR_ACTIVE_REQUEST';
export const PROFILE_WEBINAR_ERROR = 'PROFILE_WEBINAR_ERROR';
export const PROFILE_WEBINAR_RECEIVED = 'PROFILE_WEBINAR_RECEIVED';
export const PROFILE_WEBINAR_ACTIVE_RECEIVED = 'PROFILE_WEBINAR_ACTIVE_RECEIVED';
export const PROFILE_WEBINAR_CERTIFICATE_RECEIVED = 'PROFILE_WEBINAR_CERTIFICATE_RECEIVED';
export const PROFILE_WEBINAR_CERTIFICATE_REQUEST = 'PROFILE_WEBINAR_CERTIFICATE_REQUEST';

export const PROFILE_VIDEO_SAVED_WATCH_SUCCESS = 'PROFILE_SAVED_VIDEO_WATCH_SUCCESS';
export const PROFILE_VIDEO_REQUEST = 'PROFILE_VIDEO_REQUEST';
export const PROFILE_VIDEO_WATCH_RECEIVED = 'PROFILE_VIDEO_WATCH_RECEIVED';
export const PROFILE_VIDEO_WATCH_LIST_RECEIVED = 'PROFILE_VIDEO_WATCH_LIST_RECEIVED';
export const PROFILE_VIDEO_ERROR = 'PROFILE_VIDEO_ERROR';
export const PROFILE_VIDEO_SET_TIME_WATCHED = 'PROFILE_VIDEO_SET_TIME_WATCHED';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const PROFILE_PASSWORD_REMINDER_RECEIVED = 'PROFILE_PASSWORD_REMINDER_RECEIVED';
export const PROFILE_NEW_PASSWORD_RECEIVED = 'PROFILE_NEW_PASSWORD_RECEIVED';

export const BANER_REQUEST = 'BANER_REQUEST';
export const BANER_RECEIVED = 'BANER_RECEIVED';
export const BANER_ERROR = 'BANER_ERROR';

export const PROFILER_REQUEST = 'PROFILER_REQUEST';
export const PROFILER_RECEIVED = 'PROFILER_RECEIVED';
export const PROFILER_PRODUCTS_RECEIVED = 'PROFILER_PRODUCTS_RECEIVED';
export const PROFILER_FETCH_CATEGORIES_RECEIVED = 'PROFILER_FETCH_CATEGORIES_RECEIVED';
export const PROFILER_UPDATE_SETTINGS_RECEIVED = 'PROFILER_UPDATE_SETTINGS_RECEIVED';
export const PROFILER_PROFILE_UPDATE_SETTINGS_RECEIVED = 'PROFILER_PROFILE_UPDATE_SETTINGS_RECEIVED';
export const PROFILER_ERROR = 'PROFILER_ERROR';

export const QUIZ_REQUEST = 'QUIZ_REQUEST';
export const QUIZ_RECEIVED = 'QUIZ_RECEIVED';
export const QUIZ_LIST_RECEIVED = 'QUIZ_LIST_RECEIVED';
export const QUIZ_QUESTION_LIST_RECEIVED = 'QUIZ_QUESTION_LIST_RECEIVED';
export const QUIZ_ERROR = 'QUIZ_ERROR';
export const QUIZ_QUESTION_SEND_RECEIVED = 'QUIZ_QUESTION_SEND_RECEIVED';
export const QUIZ_QUESTION_SUMMARY_RECEIVED = 'QUIZ_QUESTION_SUMMARY_RECEIVED';
export const QUIZ_NO_SUMMARY = 'QUIZ_NO_SUMMARY';

export const CLOSED_TRAININGS_REQUEST = 'CLOSED_TRAININGS_REQUEST';
export const CLOSED_TRAININGS_TESTIMONIALS_RECEIVED = 'CLOSED_TRAININGS_TESTIMONIALS_RECEIVED';
export const CLOSED_TRAININGS_CREDENTIALS_RECEIVED = 'CLOSED_TRAININGS_CREDENTIALS_RECEIVED';
export const CLOSED_TRAININGS_FORM_RECEIVED = 'CLOSED_TRAININGS_RECEIVED';
export const CLOSED_TRAININGS_ERROR = 'CLOSED_TRAININGS_ERROR';
export const SCHOOL_MULTI_FORM_RECEIVED = 'SCHOOL_MULTI_FORM_RECEIVED';
export const SCHOOL_MULTI_ERROR = 'SCHOOL_MULTI_ERROR';


export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_RECEIVED = 'SEARCH_RECEIVED';
export const SEARCH_ERROR = 'SEARCH_ERROR';
export const SEARCH_LIST_SET_PAGE = 'SEARCH_LIST_SET_PAGE';
export const SEARCH_TYPES = 'SEARCH_TYPES';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_OFFER_LIST_RECEIVED = 'REGISTER_OFFER_LIST_RECEIVED';
export const REGISTER_OFFER_RECEIVED = 'REGISTER_OFFER_RECEIVED';
export const REGISTER_OFFER_ERROR = 'REGISTER_OFFER_ERROR';
export const REGISTER_ACCOUNT_RECEIVED = 'REGISTER_ACCOUNT_RECEIVED';
export const REGISTER_ORDER_RECEIVED = 'REGISTER_ORDER_RECEIVED';
export const REGISTER_ORDER_ERROR = 'REGISTER_ORDER_ERROR';
export const REGISTER_NIP_RECEIVED = 'REGISTER_NIP_RECEIVED';
export const REGISTER_NIP_ERROR = 'REGISTER_NIP_ERROR';
export const REGISTER_SET_TOKEN = 'REGISTER_SET_TOKEN';
export const REGISTER_PAYMENT_RECEIVED = 'REGISTER_PAYMENT_RECEIVED';
export const REGISTER_PAYMENT_ERROR = 'REGISTER_PAYMENT_ERROR';
export const REGISTER_SET_ORDER = 'REGISTER_SET_ORDER';
export const REGISTER_GET_ORDER = 'REGISTER_GET_ORDER';
export const REGISTER_TIN_REQUEST = 'REGISTER_TIN_REQUEST';
export const REGISTER_ORDER_UPDATE_RECEIVED = 'REGISTER_ORDER_UPDATE_RECEIVED';
export const REGISTER_WEBINAR_PRICE_REQUEST = 'REGISTER_WEBINAR_PRICE_REQUEST';
export const REGISTER_WEBINAR_PRICE_RECEIVED = 'REGISTER_WEBINAR_PRICE_RECEIVED';
export const REGISTER_WEBINAR_PRICE_ERROR = 'REGISTER_WEBINAR_PRICE_ERROR';
export const REGISTER_WEBINAR_ADD_USER_REQUEST = 'REGISTER_WEBINAR_ADD_USER_REQUEST';
export const REGISTER_WEBINAR_ADD_USER_ERROR = 'REGISTER_WEBINAR_ADD_USER_ERROR';
export const REGISTER_WEBINAR_ADD_USER_RECEIVED = 'REGISTER_WEBINAR_ADD_USER_RECEIVED';
export const REGISTER_WEBINAR_DELETE_USER_REQUEST = 'REGISTER_WEBINAR_DELETE_USER_REQUEST';
export const REGISTER_WEBINAR_DELETE_USER_RECEIVED = 'REGISTER_WEBINAR_DELETE_USER_RECEIVED';
export const REGISTER_WEBINAR_GET_USER_REQUEST = 'REGISTER_WEBINAR_GET_USER_REQUEST';
export const REGISTER_WEBINAR_GET_USER_RECEIVED = 'REGISTER_WEBINAR_GET_USER_RECEIVED';

export const REGISTER_AFFILIATE_ERROR = 'REGISTER_AFFILIATE_ERROR';
export const REGISTER_CODE_REQUEST = 'REGISTER_CODE_REQUEST';
export const REGISTER_CODE_SUCCESS = 'REGISTER_CODE_SUCCESS';
export const REGISTER_AFFILIATE_ACCOUNT_RECEIVED = 'REGISTER_AFFILIATE_ACCOUNT_RECEIVED';


export const WEBINAR_REGISTER_RECEIVED = 'WEBINAR_REGISTER_RECEIVED';
export const WEBINAR_MAIL_CONFIRMATION_RECEIVED = 'WEBINAR_MAIL_CONFIRMATION_RECEIVED';
export const WEBINAR_ERROR = 'WEBINAR_ERROR';

export const NEWSLETTER_RECEIVED = 'NEWSLETTER_RECEIVED';
export const NEWSLETTER_ERROR = 'NEWSLETTER_ERROR';


export const MULTI_TABLE_REQUEST = 'MULTI_TABLE_REQUEST';
export const MULTI_TABLE_REINVITE_REQUEST = 'MULTI_TABLE_REINVITE_REQUEST';
export const MULTI_TABLE_ERROR = 'MULTI_TABLE_ERROR';
export const MULTI_TABLE_ADD_USER_ERROR = 'MULTI_TABLE_ADD_USER_ERROR';
export const MULTI_TABLE_LIST_RECEIVED = 'MULTI_TABLE_LIST_RECEIVED';
export const MULTI_SECOND_TABLE_LIST_RECEIVED = 'MULTI_SECOND_TABLE_LIST_RECEIVED';
export const MULTI_TABLE_DELETE_USER_REQUEST = 'MULTI_TABLE_DELETE_USER_REQUEST';
export const MULTI_TABLE_DELETE_USER_RECEIVED = 'MULTI_TABLE_DELETE_USER_RECEIVED';
export const MULTI_TABLE_GIVE_ADMIN_REQUEST = 'MULTI_TABLE_GIVE_ADMIN_REQUEST';
export const MULTI_TABLE_GIVE_ADMIN_RECEIVED = 'MULTI_TABLE_GIVE_ADMIN_RECEIVED';
export const MULTI_TABLE_REMOVE_ADMIN_REQUEST = 'MULTI_TABLE_REMOVE_ADMIN_REQUEST';
export const MULTI_TABLE_REMOVE_ADMIN_RECEIVED = 'MULTI_TABLE_REMOVE_ADMIN_RECEIVED';
export const MULTI_TABLE_REPEAT_INVITE_RECEIVED = 'MULTI_TABLE_REPEAT_INVITE_RECEIVED';
export const MULTI_TABLE_REGISTER_USER_REQUEST = 'MULTI_TABLE_REGISTER_USER_REQUEST';
export const MULTI_TABLE_REGISTER_USER_RECEIVED = 'MULTI_TABLE_REGISTER_USER_RECEIVED';
export const MULTI_TABLE_GET_VOUCHER_RESPONSE = 'MULTI_TABLE_GET_VOUCHER_RESPONSE';
export const MULTI_TABLE_GET_SECOND_VOUCHER_RESPONSE = 'MULTI_TABLE_GET_SECOND_VOUCHER_RESPONSE';
export const MULTI_TABLE_INVITE_CONFIRMATION_REQUEST = 'MULTI_TABLE_INVITE_CONFIRMATION_REQUEST';
export const MULTI_TABLE_INVITE_CONFIRMATION_RESPONSE = 'MULTI_TABLE_INVITE_CONFIRMATION_RESPONSE';


export const MULTI_FIRST_LOG_ERROR = 'MULTI_FIRST_LOG_ERROR';
export const MULTI_FIRST_LOG_FORM_RECEIVED = 'MULTI_FIRST_LOG_FORM_RECEIVED';
export const MULTI_TABLE_IMPORT_FILE_REQUEST = 'MULTI_TABLE_IMPORT_FILE_REQUEST';
export const MULTI_TABLE_IMPORT_FILE_RESPONSE = 'MULTI_TABLE_IMPORT_FILE_RESPONSE';
export const MULTI_TABLE_IMPORT_FILE_ERROR = 'MULTI_TABLE_IMPORT_FILE_ERROR';




