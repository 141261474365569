import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import {Router, Route}  from "react-router";
import {applyMiddleware, createStore} from 'redux';
import {createBrowserHistory} from 'history';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import reducer from "./reducer";
import thunkMiddleware from "redux-thunk";
import {tokenMiddleware} from "./middleware";

const store = createStore(
    reducer,
    applyMiddleware(thunkMiddleware, tokenMiddleware)
);

const history = createBrowserHistory();

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <Route path="/" component={App} />
        </Router>
    </Provider>,
  document.getElementById('educado')
);

serviceWorker.unregister();