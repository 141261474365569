import {
    MULTI_FIRST_LOG_ERROR, MULTI_FIRST_LOG_FORM_RECEIVED,
    REGISTER_ACCOUNT_RECEIVED,
    REGISTER_GET_ORDER,
    REGISTER_NIP_ERROR,
    REGISTER_NIP_RECEIVED,
    REGISTER_OFFER_ERROR,
    REGISTER_OFFER_LIST_RECEIVED,
    REGISTER_OFFER_RECEIVED,
    REGISTER_ORDER_ERROR,
    REGISTER_ORDER_RECEIVED,
    REGISTER_ORDER_UPDATE_RECEIVED,
    REGISTER_PAYMENT_ERROR,
    REGISTER_PAYMENT_RECEIVED,
    REGISTER_REQUEST,
    REGISTER_SET_ORDER,
    REGISTER_SET_TOKEN,
    REGISTER_TIN_REQUEST,
    REGISTER_WEBINAR_ADD_USER_ERROR,
    REGISTER_WEBINAR_ADD_USER_RECEIVED,
    REGISTER_WEBINAR_ADD_USER_REQUEST,
    REGISTER_WEBINAR_DELETE_USER_RECEIVED,
    REGISTER_WEBINAR_DELETE_USER_REQUEST,
    REGISTER_WEBINAR_GET_USER_RECEIVED,
    REGISTER_WEBINAR_GET_USER_REQUEST,
    REGISTER_WEBINAR_PRICE_ERROR,
    REGISTER_WEBINAR_PRICE_RECEIVED,
    REGISTER_WEBINAR_PRICE_REQUEST, SCHOOL_MULTI_ERROR, SCHOOL_MULTI_FORM_RECEIVED
} from "../constants";
import {SubmissionError} from "redux-form";
import {formValidate, minChar, requiredField, validateEmail, validateNip, validateTextByPattern} from "../common/form";
import {requests} from "../agent";

export const registerRequest = () => ({
    type: REGISTER_REQUEST,
});

export const registerOfferListReceived = (data) => {
    return {
        type: REGISTER_OFFER_LIST_RECEIVED,
        data
    }
};

export const registerOfferReceived = (data) => {
    return {
        type: REGISTER_OFFER_RECEIVED,
        data
    }
}

export const registerOfferError = (error) => ({
    type: REGISTER_OFFER_ERROR,
    error
});

export const registerOfferListFetch = (brand) => {
    return (dispatch) => {
        dispatch(registerRequest());
        return requests.get(`/subscriptions?brand=${brand}`)
            .then(response => dispatch(registerOfferListReceived(response)))
            .catch(error => dispatch(registerOfferError(error)))
    }
};

export const registerOfferFetch = (id) => {
    return (dispatch) => {
        dispatch(registerRequest());
        return requests.get(`/subscriptions/${id}`)
            .then(response => dispatch(registerOfferReceived(response)))
            .catch(error => dispatch(registerOfferError(error)))
    }
};

// aktualizowanie zamówienia
export const registerUserOrders = (userData, offer) => {
    let body = {
        cart: [{productId: parseInt(offer), quantity: 1}],
        user: userData,
        buyer: userData,
        meta: {
            cid: window.sessionStorage.getItem('cid'),
            utm_source: window.sessionStorage.getItem('utm_source'),
            utm_medium: window.sessionStorage.getItem('utm_medium'),
            utm_campaign: window.sessionStorage.getItem('utm_campaign'),

        },
    }


    return (dispatch) => {
        return requests.post(`/orders`, body, true)
            .then(response => dispatch(registerOrderUpdateReceived(response, offer, 'PROGRESS')))
            .catch((error) => {
                dispatch(registerError(error))
                throw new SubmissionError({
                    _error: 'Wystąpił problem z pobraniem danych klienta! Proszę odświeżyć stronę.'
                })
            })
    }
}

export const registerAccountReceived = (data, offer) => {
    return {
        type: REGISTER_ACCOUNT_RECEIVED,
        data,
        offer
    }
}

export const registerOrderReceived = (data, offer, status) => {
    if (data.buyer === null && data.user) {
        data.buyer = data.user;
        data.buyer.tin = null;
    }
    return {
        type: REGISTER_ORDER_RECEIVED,
        data,
        offer,
        status
    }
}

export const registerOrderUpdateReceived = (data, offer, status) => {
    if (data.buyer === null && data.user) {
        data.buyer = data.user;
        data.buyer.tin = null;
    }
    return {
        type: REGISTER_ORDER_UPDATE_RECEIVED,
        data,
        offer,
        status
    }
}


// STEP 1
export const registerAccount = (offer, fields) => {

    let mapFields = [
        {
            field: 'name',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole imię nie może pozostać puste!'
                },
                {
                    method: minChar,
                    text: 'Imię powinno zawierać minimum 3 znaki.',
                    params: {limit: 3}
                },
                {
                    method: validateTextByPattern,
                    text: 'Tylko litery, myślnik oraz spacja.',
                    params: {pattern: /^[a-zćęółśążźń /-]*$/iu}
                }
            ]
        },
        {
            field: 'surname',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole nazwisko nie może pozostać puste!'
                },
                {
                    method: minChar,
                    text: 'Nazwisko powinno zawierać minimum 3 znaki.',
                    params: {limit: 3}
                },
                {
                    method: validateTextByPattern,
                    text: 'Tylko litery, myślnik oraz spacja.',
                    params: {pattern: /^[a-zćęółśążźń /-]*$/iu}
                }
            ]
        },
        {
            field: 'email',
            text: 'Podany adres email nie jest prawidłowy!',
            method: validateEmail
        },
        {
            field: 'phone',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole telefon nie może pozostać puste!'
                },
                {
                    method: minChar,
                    text: 'Poprawny format telefonu +48 999 999 999',
                    params: {limit: 15}
                },
                {
                    method: validateTextByPattern,
                    text: 'Poprawny format telefonu +48 999 999 999',
                    params: {pattern: /^\+48 [0-9 ]{11}$/iu}
                }
            ]
        },
        {
            field: 'position',
            method: requiredField,
            text: 'Pole stanowisko nie może pozostać puste!'
        },
        {
            field: 'acceptTerms',
            text: 'Akceptacja regulaminu jest wymagana!',
            method: requiredField
        },
        {
            field: 'marketingAgreement',

        }
    ];

    let validate = formValidate(mapFields, fields)

    if (validate) {
        throw new SubmissionError(validate)
    } else {
        let body = {
            cart: [{productId: offer.id, quantity: 1}],
            user: fields,
            buyer: fields,
            meta: {
                cid: window.sessionStorage.getItem('cid'),
                utm_source: window.sessionStorage.getItem('utm_source'),
                utm_medium: window.sessionStorage.getItem('utm_medium'),
                utm_campaign: window.sessionStorage.getItem('utm_campaign'),
                entryUrl: window.sessionStorage.getItem('entryUrl')

            },
        }

        return (dispatch) => {
            return requests.post(`/orders`, body)
                .then(response => dispatch(registerAccountReceived(response, offer)))
                .catch(error => {
                    dispatch(registerError(error, offer))
                    throw new SubmissionError({
                        _error: error.response.body.violations[0].message!== undefined ? error.response.body.violations[0].message : 'Wystąpił problem z rejestracją klienta! Proszę odświeżyć stronę lub skontaktować się z Centrum Obsługi Klienta.'

                    })
                })
        }

    }
}

export const registerSetToken = (token) => {
    return {
        type: REGISTER_SET_TOKEN,
        token
    }
}

// STEP 2
export const registerOrder = (regToken, offer, fields, multi) => {

    let companyField;
    if (fields.userType === 'company') {
        if (fields.tin)
            fields.tin = fields.tin.toString();

        companyField = [
            {
                field: 'companyName',
                text: 'Pole nazwa firmy nie może pozostać puste!',
                method: requiredField
            },
            {
                field: 'tin',
                methods: [
                    {
                        method: requiredField,
                        text: 'Wprowadź numer NIP!'
                    },
                    {
                        method: validateNip,
                        text: 'Numer NIP jest nieprawidłowy!'
                    }
                ]
            },

        ];

        if (fields.diffBuyer) {
            let diffBuyer = [
                {
                    field: 'b_companyName',
                    text: 'Pole nazwa firmy nie może pozostać puste!',
                    method: requiredField
                },
                {
                    field: 'b_street',
                    text: 'Pole ulicy nie może pozostać puste!',
                    method: requiredField
                },
                {
                    field: 'b_streetNumber',
                    text: 'Pole nr domu nie może pozostać puste!',
                    method: requiredField,
                },
                {
                    field: 'b_city',
                    text: 'Pole miasto nie może pozostać puste!',
                    method: requiredField
                },
                {
                    field: 'b_postalCode',
                    methods: [
                        {
                            method: requiredField,
                            text: 'Pole kod pocztowy nie może pozostać puste!'
                        },
                        {
                            method: minChar,
                            text: 'Poprawny format kodu pocztowego 99-999',
                            params: {limit: 6}
                        },
                        {
                            method: validateTextByPattern,
                            text: 'Poprawny format kodu pocztowego 99-999',
                            params: {pattern: /^[0-9]{2}[-][0-9]{3}$/iu}
                        }
                    ]
                }
            ]
            companyField = [...companyField, ...diffBuyer];
        }
    }

    let mapFields = [
        {
            field: 'street',
            text: 'Pole ulicy nie może pozostać puste!',
            method: requiredField
        },
        {
            field: 'streetNumber',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole nr domu nie może pozostać puste!'
                },
                {
                    method: validateTextByPattern,
                    text: 'Pole domu musi zawierać liczbę!',
                    params: {pattern: /^[0-9][a-z0-9 ]*$/iu}
                }
            ]
        },
        {
            field: 'city',
            text: 'Pole miasto nie może pozostać puste!',
            method: requiredField
        },
        {
            field: 'postalCode',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole kod pocztowy nie może pozostać puste!'
                },
                {
                    method: minChar,
                    text: 'Poprawny format kodu pocztowego 99-999',
                    params: {limit: 6}
                },
                {
                    method: validateTextByPattern,
                    text: 'Poprawny format kodu pocztowego 99-999',
                    params: {pattern: /^[0-9]{2}[-][0-9]{3}$/iu}
                }
            ]
        }
    ];

    let validate = formValidate(companyField ? [...mapFields, ...companyField] : mapFields, fields);

    if (validate) {
        throw new SubmissionError(validate)
    } else {
        let userField = fields;
        let buyerField = fields;

        if (fields.diffBuyer) {
            buyerField = {
                apartmentNumber: fields.b_apartmentNumber,
                city: fields.b_city,
                companyName: fields.b_companyName,
                eInvoice: fields.eInvoice,
                postalCode: fields.b_postalCode,
                street: fields.b_street,
                streetNumber: fields.b_streetNumber,
                tin: fields.tin,
                userType: fields.userType,
                vatRelease: fields.vatRelease,
                discountCode: fields.discountCode
            };
        }

        let body = {
            seller: {
                type: "direct",
                siteUrl: `https://educado.pl/rejestracja/${offer.id}`
            },
            payment: {
                eInvoice: fields.eInvoice,
                vatExemption: fields.vatRelease,
                //discountCode: fields.discountCode
            },
            cart: [{productId: offer.id, quantity: 1}],
            user: userField,
            buyer: buyerField
        }
        if (multi) {
            return (dispatch) => {
                dispatch(registerRequest());
                return requests.put(`/orders/${regToken}`, body)
                    .then(response => dispatch(registerOrderReceived(response, offer, 'USERS')))
                    .catch((error) => {
                        dispatch(registerError(error, offer))
                        throw new SubmissionError({
                            _error: 'Wystąpił problem z rejestracją klienta! Proszę odświeżyć stronę.'
                        })
                    })
            }
        } else {
            return (dispatch) => {
                dispatch(registerRequest());
                return requests.put(`/orders/${regToken}`, body)
                    .then(response => dispatch(registerOrderReceived(response, offer, 'PAYMENT')))
                    .catch((error) => {
                        dispatch(registerError(error, offer))
                        throw new SubmissionError({
                            _error: 'Wystąpił problem z rejestracją klienta! Proszę odświeżyć stronę.'
                        })
                    })
            }
        }
    }
}
//update order price
export const registerOrderPriceUpdate = (regToken, offer, code, quant) => {
    let body = {
        cart: [{productId: offer, quantity: quant}],
        payment: {
            discountCode: code
        },
    };
    if (code) {
        return (dispatch) => {
            dispatch(registerRequest());
            return requests.put(`/orders/${regToken}`, body)
                .then(response => dispatch(registerOrderReceived(response, offer, 'PAYMENT')))
                .catch((error) => {
                    dispatch(registerError(error, offer))
                    throw new SubmissionError({
                        _error: 'Wystąpił problem z rejestracją klienta! Proszę odświeżyć stronę.'
                    })
                })
        }
    }
}


// REGISTER USERS
export const registerUsersList = (regToken, product, qty) => {

    let body = {
        cart: [{productId: product.id, quantity: qty}],
    }

    return (dispatch) => {
        dispatch(registerRequest());
        return requests.put(`/orders/${regToken}`, body)
            .then(response => dispatch(registerOrderReceived(response, product, 'PAYMENT')))
            .catch((error) => {
                dispatch(registerError(error, product))
                throw new SubmissionError({
                    _error: 'Wystąpił problem z rejestracją klienta! Proszę odświeżyć stronę.'
                })
            })
    }
}


export const registerError = (error, offer) => ({
    type: REGISTER_ORDER_ERROR,
    error,
    offer
})

export const registerSetOrder = (regToken, user, buyer) => {
    let tmp = {
        user: user,
        buyer: buyer
    };
    window.sessionStorage.setItem(regToken, JSON.stringify(tmp));
    return {
        type: REGISTER_SET_ORDER,
        regToken,
        user,
        buyer
    };
}

export const registerGetOrder = (regToken) => {
    let tmp = window.sessionStorage.getItem(regToken);
    let user;
    let buyer;
    if (tmp) {
        tmp = JSON.parse(tmp)
        user = tmp.user;
        buyer = tmp.buyer;
    }
    return {
        type: REGISTER_GET_ORDER,
        regToken,
        user,
        buyer
    }
}

export const registerPaymentReceived = (data, offer) => ({
    type: REGISTER_PAYMENT_RECEIVED,
    data,
    offer
})

export const registerPaymentError = (error, offer) => ({
    type: REGISTER_PAYMENT_ERROR,
    error,
    offer
})


export const registerPaymentComplete = (offer, type, id) => {

    let body = {};

    switch (type) {
        case 'payu':
            body = {
                payment: {
                    type: "payu",
                    redirectUrl: "https://educado.pl/zamowienie/podziekowanie"
                },
                meta: {
                    cid: window.sessionStorage.getItem('cid'),
                    utm_source: window.sessionStorage.getItem('utm_source'),
                    utm_medium: window.sessionStorage.getItem('utm_medium'),
                    utm_campaign: window.sessionStorage.getItem('utm_campaign'),
                    entryUrl: window.sessionStorage.getItem('entryUrl'),
                    orderUrl: window.location.href,
                },
            };
            break;
        case 'invoice':
            body = {
                payment: {
                    type: "invoice"
                },
                meta: {
                    cid: window.sessionStorage.getItem('cid'),
                    utm_source: window.sessionStorage.getItem('utm_source'),
                    utm_medium: window.sessionStorage.getItem('utm_medium'),
                    utm_campaign: window.sessionStorage.getItem('utm_campaign'),
                    entryUrl: window.sessionStorage.getItem('entryUrl'),
                    orderUrl: window.location.href,
                },
            };
            break;
        default:
            break;
    }

    return (dispatch) => {
        dispatch(registerRequest());
        return requests.put(`/orders_complete/${type}/${id}`, body)
            .then(response => dispatch(registerPaymentReceived(response, offer)))
            .catch((error) => {
                dispatch(registerPaymentError(error, offer))
                throw new SubmissionError({
                    _error: 'Wystąpił problem z przetwarzaniem zamówienia!'
                })
            })
    }

}

export const registerTinRequest = () => ({
    type: REGISTER_TIN_REQUEST,
});

export const registerTinReceived = (data) => {
    return {
        type: REGISTER_NIP_RECEIVED,
        data
    }
}

export const registerTinError = (error) => {
    return {
        type: REGISTER_NIP_ERROR,
        error
    }
}

export const registerTinFetch = (tin) => {
    let mapFields = [
        {
            field: 'tin',
            methods: [
                {
                    method: requiredField,
                    text: 'Wprowadź numer NIP!'
                },
                {
                    method: validateNip,
                    text: 'Numer NIP jest nieprawidłowy!'
                }
            ]
        }
    ];
    let validate = formValidate(mapFields, {tin})

    if (validate) {
        return (dispatch) => {
            dispatch(registerTinError(validate))
        }
    } else {
        tin = tin.replace(/-/g, '');
        return (dispatch) => {
            dispatch(registerTinRequest())
            return requests.get(`/gus/company/${tin}`)
                .then(response => dispatch(registerTinReceived(response)))
                .catch(error => dispatch(registerTinError(error)))
        }
    }
}

export const registerWebinarPrice = (id, discountCode, quantity) => {

    let body = {
        discountCode: discountCode ? discountCode : null,
        quantity: quantity ? quantity : 1,
    };

    return (dispatch) => {
        dispatch(registerWebinarPriceRequest());
        return requests.post(`/trainings/${id}/price_check`, body)
            .then(response => dispatch(registerWebinarPriceReceived(response, discountCode)))
            .catch((error) => {
                dispatch(registerWebinarPriceError(error))
                throw new SubmissionError({
                    _error: 'Wystąpił problem z przetwarzaniem zamówienia!'
                })
            })
    }
}

export const registerWebinarPriceRequest = () => {
    return {
        type: REGISTER_WEBINAR_PRICE_REQUEST,
    }
}

export const registerWebinarPriceReceived = (data, discountCode) => {
    return {
        type: REGISTER_WEBINAR_PRICE_RECEIVED,
        data,
        discountCode
    }
}
export const registerWebinarPriceError = (error) => {
    return {
        type: REGISTER_WEBINAR_PRICE_ERROR,
        error
    }
}


// metody komunikacji z api dla modyfikowania listy użytkowników w dostepie wielostanowiskowym przy webinarach płatnych


export const registerWebinarAddUser = (order, user) => {

    let body = {
        name: user.name,
        surname: user.surname,
        email: user.email,
        orderId: order
    };

    return (dispatch) => {
        dispatch(registerWebinarAddUserRequest());
        return requests.post(`/orders_additional_users`, body)
            .then(response => dispatch(registerWebinarAddUserReceived(response)))
            .then(() => dispatch(registerWebinarGetUser(order)))
            .catch((error) => {
                dispatch(registerWebinarAddUserError(error))
                throw new SubmissionError({
                    _error: 'Wystąpił problem z przetwarzaniem zamówienia!'
                })
            })
    }
}


export const registerWebinarAddUserRequest = () => {
    return {
        type: REGISTER_WEBINAR_ADD_USER_REQUEST,
    }
}

export const registerWebinarAddUserError = (error) => {
    return {
        type: REGISTER_WEBINAR_ADD_USER_ERROR,
        error
    }
}

export const registerWebinarAddUserReceived = (data) => {
    return {
        type: REGISTER_WEBINAR_ADD_USER_RECEIVED,
        data
    }
}


export const registerWebinarDeleteUser = (id, order) => {

    return (dispatch) => {
        dispatch(registerWebinarDeleteUserRequest());
        return requests.del(`/orders_additional_users/${id}?order=${order}`)
            .then(response => dispatch(registerWebinarDeleteUserReceived(response)))
            .then(() => dispatch(registerWebinarGetUser(order)))
            .catch((error) => {
                dispatch(registerWebinarAddUserError(error));
                throw new SubmissionError({
                    _error: 'Wystąpił problem z przetwarzaniem zamówienia!'
                })
            })
    }
}

export const registerWebinarDeleteUserRequest = (data) => {
    return {
        type: REGISTER_WEBINAR_DELETE_USER_REQUEST,
        data
    }
}

export const registerWebinarDeleteUserReceived = (data) => {
    return {
        type: REGISTER_WEBINAR_DELETE_USER_RECEIVED,
        data
    }
}


export const registerWebinarGetUser = (order) => {

    return (dispatch) => {
        dispatch(registerWebinarGetUserRequest());
        return requests.secGet(`/orders/${order}/additional_users/`)
            .then(response => dispatch(registerWebinarGetUserReceived(response)))
            .catch((error) => {
                dispatch(registerWebinarAddUserError(error));
                throw new SubmissionError({
                    _error: 'Wystąpił problem z przetwarzaniem zamówienia!'
                })
            })
    }
}


export const registerWebinarGetUserRequest = (data) => {
    return {
        type: REGISTER_WEBINAR_GET_USER_REQUEST,
        data
    }
}

export const registerWebinarGetUserReceived = (data) => {
    return {
        type: REGISTER_WEBINAR_GET_USER_RECEIVED,
        data
    }
}


/*
* Obsługa formularza do potwierdzania konta wielostanowiskowego
*
*
* */


export const multiFirstLogFormRequest = (fields) => {

    let mapFields = [
        {
            field: 'position',
            text: 'Pole stanowisko nie może pozostać puste',
            method: requiredField
        },
        {
            field: 'phone',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole telefon nie może pozostać puste!'
                },
                {
                    method: minChar,
                    text: 'Poprawny format telefonu +48 999 999 999',
                    params: {limit: 15}
                },
                {
                    method: validateTextByPattern,
                    text: 'Poprawny format telefonu +48 999 999 999',
                    params: {pattern: /^\+48 [0-9 ]{11}$/iu}
                }
            ]
        },
        {
            field: 'termsAndConditions',
            text: 'Akceptacja regulaminu jest wymagana!',
            method: requiredField
        },
        {
            field: 'marketingAgreement',
            text: 'Akceptacja pola jest wymagana!',
            method: requiredField
        }
    ];

    let validate = formValidate(mapFields, fields);

    if (validate) {
        throw new SubmissionError(validate)
    } else {
        let body = fields;
        return (dispatch) => {
            return requests.post(`/subusers/update`, body)
                .then(response => dispatch(multiFirstLogFormReceived(response)))
                .catch(error => dispatch(multiFirstLogError(error)))

        }

    }
};

export const multiFirstLogFormReceived = (data) => {
    return {
        type: MULTI_FIRST_LOG_FORM_RECEIVED,
        data,

    }
};

export const multiFirstLogError = (error) => ({
    type: MULTI_FIRST_LOG_ERROR,
    error
});
