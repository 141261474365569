import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {Form, Spinner} from "react-bootstrap";
import {profilePasswordReminder} from "../../actions/profile";
import {renderField} from "../../common/form";

const mapStateToProps = state => ({
    ...state.profile,
    auth: state.auth
});

const mapDispatchToProps = {
    profilePasswordReminder
};

class PasswordReminderForm extends React.Component {

    onSubmit(values) {
        const {profilePasswordReminder} = this.props;
        return profilePasswordReminder(
            values.email
        );
    }

    render() {
        const {form, handleSubmit, isFetching, error, submitSucceeded} = this.props;

        if (isFetching && !error) {
            return (<Spinner animation="border" variant="primary" />);
        }

        if (submitSucceeded) {
           return (
               <>
                   <div className="text-center">
                       <p>Weryfikacja danych przebiegła prawidłowo. Na Twój adres e-mail wysłaliśmy wiadomość z linkiem do zmiany hasła dostępu.</p>
                       <p>W razie jakichkolwiek wątpliwości prosimy o kontakt z Centrum Obsługi Klienta:</p>
                       <p>telefon: <strong>22 518 29 29</strong></p>
                       <p>e-mail: <a href="mailto:cok@wip.pl"><strong>cok@wip.pl</strong></a></p>
                   </div>
               </>
           )
        }

        return (
            <>
                <h3>Nie pamiętasz hasła ?</h3>
                <p>Jeśli chcesz ustawić nowe hasło, postępuj wg poniższych instrukcji</p>
                {error && <div className="alert alert-danger text-center">{error}</div>}
                    <Form id={form}
                          onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <Field name="email" placeholder="Podaj adres email" type="text"
                               component={renderField}/>
                        <button className="login-btn btn" variant="primary" type="submit">Resetuj hasło</button>
                    </Form>
            </>
        )
    }
}

export default reduxForm({
    form: 'PasswordReminder'
})(connect(mapStateToProps, mapDispatchToProps)(PasswordReminderForm));