import {requests} from "../agent";
import {
    EXPERT_REQUEST,
    EXPERT_RECEIVED,
    EXPERT_LIST_RECEIVED,
    EXPERT_ERROR,
    EXPERT_LIST_SET_PAGE
} from "../constants";

/**
 * set default Expert state
 * @returns {{type: *}}
 */
export const expertRequest = () => ({
    type: EXPERT_REQUEST,
});

/**
 * set Expert received
 * @param data
 * @returns {{data: *, type: *}}
 */
export const expertReceived = (data) => ({
    type: EXPERT_RECEIVED,
    data
});

/**
 * set Experts received
 * @param data
 * @returns {{data: *, type: *}}
 */
export const expertListReceived = (data) => ({
    type: EXPERT_LIST_RECEIVED,
    data
});

/**
 * set Experts pagination
 * @param page
 * @returns {{page: *, type: *}}
 */
export const expertListSetPage = (page) => ({
    type: EXPERT_LIST_SET_PAGE,
    page
});

/**
 * set Expert error
 * @param error
 * @returns {{type: *, error: *}}
 */
export const expertError = (error) => ({
    type: EXPERT_ERROR,
    error
});

/**
 * get Expert by id
 * @param id
 * @returns {function(*): *}
 */
export const expertFetch = (id) => {
    return (dispatch) => {
        dispatch(expertRequest());
        return requests.get(`/experts/${id}`)
            .then(response => dispatch(expertReceived(response)))
            .catch(error => dispatch(expertError(error)))
    }
};

/**
 * get Expert list by {page && limit}
 * @param page
 * @param limit
 * @returns {function(*): *}
 */
export const expertListFetch = (page = 1, limit = 9) => {
    return (dispatch) => {
        dispatch(expertRequest());
        return requests.get(`/experts?itemsPerPage=${limit}&page=${page}`)
            .then(response => dispatch(expertListReceived(response)))
            .catch(error => dispatch(expertError(error)))
    }
};

/**
 * get Expert Promoted list
 * @returns {function(*): *}
 */
export const expertListPromotedFetch = (limit = 10) => {
    return (dispatch) => {
        dispatch(expertRequest());
        return requests.get(`/experts?promoted=1&itemsPerPage=${limit}`)
            .then(response =>  dispatch(expertListReceived(response)))
            .catch(error => dispatch(expertError(error)))
    }
};