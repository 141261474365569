import React from "react";
import {Container, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {convertMetaDescription, convertMetaTitle, strNormalize} from "../../../common/utils";
import {iriFormatId} from "../../../common/apiUtils";
import {Helmet} from "react-helmet";
import WebinarPaidRegistration from "./WebinarPaidRegistration";
import WebinarPaidVideo from "./WebinarPaidVideo";
import {productDocumentFetch} from "../../../actions/product";
import Breadcrumb from "../../../common/breadcrumb";

const mapStateToProps = state => ({
    ...state.product,
    auth: state.auth,
    webinar: state.webinar,
    document: state.product.document


});

const mapDispatchToProps = {productDocumentFetch};

class WebinarPaid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        const {product, isFetching} = this.props.children
        if (product && !isFetching) {
            this.setState({
                    breadcrumbItems: [
                        {name: 'webinary'},
                        {
                            name: this.getSubtype(product.subType),
                            url: product.subType === 'online' ? `szkolenia/${this.getSubtype(product.subType)}` : `webinary/${strNormalize(this.getSubtype(product.subType))}`
                        },
                        {name: product.name}
                    ]
                }
            )
        }
    }

    getSubtype(type) {
        switch (type) {
            case 'webinar':
                return 'webinary bezpłatne'
            case 'webinarPaid':
                return 'webinary live z chatem'
            default:
                return 'szkolenia'

        }
    }
    componentDidUpdate(prevProps) {
        const {webinar} = this.props;


        if (webinar.received !== prevProps.webinar.received) {
            this.setState({received: true})
        }
    }

    getContent() {
        const {product} = this.props.children;

        if (!product.accessDenied || product.accessExpired || product.accessByKey) {
            return <WebinarPaidVideo children={this.props}/>;
        } else {
            return <WebinarPaidRegistration product={product}/>;
        }
    }


    render() {
        const {auth, product} = this.props.children;

        return (
            <>
                <Helmet>
                    <title>{'Webinar ' + convertMetaTitle(product.name)}</title>
                    <meta name="description"
                          content={convertMetaDescription(product.metaDescription ? product.metaDescription : product.name)}/>
                    <link rel="canonical"
                          href={`/${strNormalize(product.subType)}/${strNormalize(product.name)}/${iriFormatId(product)}`}/>
                </Helmet>
                <Container className={"position-relative"} fluid>
                    <Row>
                        {auth && auth.isAuthenticated ?
                            <>{product.accessDenied ? <div className={'position-absolute index-background'}>
                                {product.categories[0].name === "Oświata" ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1920" height="914"
                                         viewBox="0 0 1920 914"
                                         fill="none">
                                        <path
                                            d="M1920 0V419.456C1881.25 431.105 1833.35 445.923 1777.76 463.86C1620.19 514.842 1542.94 351.105 1352.76 341.904C1162.57 332.703 1007.35 487.405 961.209 604.26C915.064 721.114 672.956 957.633 434.91 906.258C208.375 857.373 76.6032 772.54 0 847.48V0H1920Z"
                                            fill="url(#paint0_linear_825_39506)"/>
                                        <defs>
                                            <linearGradient id="paint0_linear_825_39506" x1="909.426" y1="235.819"
                                                            x2="981.055"
                                                            y2="837.995" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#242936"/>
                                                <stop offset="1" stopColor="#32509F"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1920" height="914"
                                         viewBox="0 0 1920 914"
                                         fill="none">
                                        <path
                                            d="M1920 0.25V419.706C1881.25 431.355 1833.35 446.173 1777.76 464.11C1620.19 515.092 1542.94 351.355 1352.76 342.154C1162.57 332.953 1007.35 487.655 961.209 604.51C915.064 721.364 672.956 957.883 434.91 906.508C208.375 857.623 76.6032 772.79 0 847.73V0.25H1920Z"
                                            fill="#232936"/>
                                        <path
                                            d="M1920 0.25V419.706C1881.25 431.355 1833.35 446.173 1777.76 464.11C1620.19 515.092 1542.94 351.355 1352.76 342.154C1162.57 332.953 1007.35 487.655 961.209 604.51C915.064 721.364 672.956 957.883 434.91 906.508C208.375 857.623 76.6032 772.79 0 847.73V0.25H1920Z"
                                            fill="url(#paint0_linear_1301_18274)"/>
                                        <defs>
                                            <linearGradient id="paint0_linear_1301_18274" x1="403.394" y1="21.146"
                                                            x2="564.366" y2="1037.59" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#232936"/>
                                                <stop offset="0.197335" stopColor="#232936" stopOpacity="0.8"/>
                                                <stop offset="0.365393" stopColor="#232936" stopOpacity="0.3"/>
                                                <stop offset="0.449096" stopColor="#232936" stopOpacity="0.15"/>
                                                <stop offset="0.650905" stopColor="#FAB14C" stopOpacity="0.55"/>
                                                <stop offset="0.764992" stopColor="#FAA13F" stopOpacity="0.9"/>
                                                <stop offset="0.854733" stopColor="#FAA13F"/>
                                                <stop offset="1" stopColor="#FAA13F"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>}
                            </div> : ''}</>



                            :
                            <div className={'position-absolute index-background'}>
                                {product.categories[0].name === "Oświata" ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1920" height="914"
                                         viewBox="0 0 1920 914"
                                         fill="none">
                                        <path
                                            d="M1920 0V419.456C1881.25 431.105 1833.35 445.923 1777.76 463.86C1620.19 514.842 1542.94 351.105 1352.76 341.904C1162.57 332.703 1007.35 487.405 961.209 604.26C915.064 721.114 672.956 957.633 434.91 906.258C208.375 857.373 76.6032 772.54 0 847.48V0H1920Z"
                                            fill="url(#paint0_linear_825_39506)"/>
                                        <defs>
                                            <linearGradient id="paint0_linear_825_39506" x1="909.426" y1="235.819"
                                                            x2="981.055"
                                                            y2="837.995" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#242936"/>
                                                <stop offset="1" stopColor="#32509F"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1920" height="914"
                                         viewBox="0 0 1920 914"
                                         fill="none">
                                        <path
                                            d="M1920 0.25V419.706C1881.25 431.355 1833.35 446.173 1777.76 464.11C1620.19 515.092 1542.94 351.355 1352.76 342.154C1162.57 332.953 1007.35 487.655 961.209 604.51C915.064 721.364 672.956 957.883 434.91 906.508C208.375 857.623 76.6032 772.79 0 847.73V0.25H1920Z"
                                            fill="#232936"/>
                                        <path
                                            d="M1920 0.25V419.706C1881.25 431.355 1833.35 446.173 1777.76 464.11C1620.19 515.092 1542.94 351.355 1352.76 342.154C1162.57 332.953 1007.35 487.655 961.209 604.51C915.064 721.364 672.956 957.883 434.91 906.508C208.375 857.623 76.6032 772.79 0 847.73V0.25H1920Z"
                                            fill="url(#paint0_linear_1301_18274)"/>
                                        <defs>
                                            <linearGradient id="paint0_linear_1301_18274" x1="403.394" y1="21.146"
                                                            x2="564.366" y2="1037.59" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#232936"/>
                                                <stop offset="0.197335" stopColor="#232936" stopOpacity="0.8"/>
                                                <stop offset="0.365393" stopColor="#232936" stopOpacity="0.3"/>
                                                <stop offset="0.449096" stopColor="#232936" stopOpacity="0.15"/>
                                                <stop offset="0.650905" stopColor="#FAB14C" stopOpacity="0.55"/>
                                                <stop offset="0.764992" stopColor="#FAA13F" stopOpacity="0.9"/>
                                                <stop offset="0.854733" stopColor="#FAA13F"/>
                                                <stop offset="1" stopColor="#FAA13F"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>}
                            </div>}
                        <Container fluid className={"product-breadcrumb-wrapper"}>
                            <Container className={"mt-4"}>
                                <Breadcrumb id={product.categories[0].name === "Oświata" ? 71 : 14}
                                            items={this.state.breadcrumbItems}/>
                            </Container>
                        </Container>
                        {this.getContent()}
                    </Row>
                </Container>
            </>
        )
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(WebinarPaid);