import React from 'react';
import {categoryAncestorsFetch, categoryDescendantsFetch, categoryFetch,} from "../../actions/category";
import {connect} from "react-redux";
import {Col, Container, Row, Spinner} from "react-bootstrap";

import {productListQuerySlider} from "../../actions/product";

const mapStateToProps = state => ({
    ...state.category,
    productQuerySList: state.product.productQuerySList,
    auth: state.auth
});

const mapDispatchToProps = {
    categoryFetch,
    categoryAncestorsFetch,
    categoryDescendantsFetch,
    productListQuerySlider
}

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: '',
            typeFilter: {
                type: ['online']
            },
            filterCategory: {category: [this.props.match.params.id]},

        };
    }

    componentDidMount() {
        this.props.productListQuerySlider(this.state.typeFilter, this.state.filterCategory, 3);
        this.props.categoryFetch(this.props.match.params.id);


    }

    componentDidUpdate() {
        const {categories, isFetching} = this.props;

        if (categories && !isFetching && this.state.category !== categories.name) {
            this.setState({category: categories.name})
        }

        if (this.props.error) {
            if (!this.props.isFetching) {
                this.props.history.push(`/404`);

            } else {
                setInterval(() => {
                    this.props.history.push(`/404`);
                }, 15000); //lagi...
            }
        }
    }

    render() {
        const {isFetching, auth, productQuerySList} = this.props;
        if (isFetching) {
            return <Container className={"spinner-container"}><Spinner animation="border"/></Container>

        }
        return (
            <div className={"category-main-container"}>

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Category);