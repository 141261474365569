import React from "react";
import LoginForm from "../Auth/LoginForm";
import PasswordReminderForm from "../Auth/PasswordReminderForm";
import {Container, Modal, Row, Spinner} from "react-bootstrap";
import {APP_URL} from "../../constants";

class ProductDocuments extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            docContent: null,
            show: false,
            type: 'LoginForm'
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {document} = this.props.children;
        if (prevProps.children.document !== document) {
            this.setState({docContent: this.parseDocContent(document)})
        }
    }

    parseDocContent(document) {
        return (<>
            <h4>{document.title}</h4>
            <div dangerouslySetInnerHTML={{__html: document.content}}/>
            {
                document.attachments && document.attachments[0] ? <><h5>Pliki do pobrania:</h5> {
                    document.attachments.map((item, key) => {
                        if (item.attachmentUrl) {
                            return (<div className={'doc-attachment'} key={key} onClick={() => window.open(APP_URL + item.attachmentUrl)}>
                                {item.title}
                            </div>);
                        }
                        return '';
                    }
                )}</>
                : ''
            }
        </>);
    }

    hideModal() {
        this.setState({show: false});
    }

    getDocument(id) {
        const {auth, productDocumentFetch} = this.props.children;
        if (auth.isAuthenticated) {
            productDocumentFetch(id);
        } else {
            this.loginForm();
        }
        this.setState({show: true})
    }

    loginForm() {
        this.setState({
            type: 'LoginForm',
            docContent:
                <div className={'form-container-content col-xl-3 col-lg-4 col-md-6'}>
                    <h3>Treść dostępna tylko dla osób zalogowanych.</h3>
                    <LoginForm/>
                    <button className="pass-reminder" onClick={this.loginFormSwitch.bind(this)}>Nie
                        pamiętasz hasła?
                    </button>
                </div>

        })
    }

    loginFormSwitch() {
        let content;
        let type;
        if (this.state.type === 'LoginForm') {
            type = 'PasswordReminderForm';
            content = <div className={'form-container-content col-xl-3 col-lg-4 col-md-6'}>
                <PasswordReminderForm/>
                <button className="pass-reminder" onClick={this.loginFormSwitch.bind(this)}>Logowanie</button>
            </div>
        } else {
            type = 'LoginForm';
            content = <div className={'form-container-content col-xl-3 col-lg-4 col-md-6'}>
                <h3>Treść dostępna tylko dla osób zalogowanych.</h3>
                <LoginForm/>
                <button className="pass-reminder" onClick={this.loginFormSwitch.bind(this)}>Nie
                    pamiętasz hasła?
                </button>
            </div>
        }

        this.setState({
            type: type,
            docContent: content
        })
    }

    renderDocumentList() {
        const {product} = this.props.children;

        return (
            <ul className={"documentation-list"}>
                {product.documents && product.documents.map((item, key) => {
                        let eventClick = () => {
                            this.getDocument(item.id)
                        };
                        return (
                            <React.Fragment key={key}>
                                {(key === 0 && item.typeName) || (product.documents[key-1] && product.documents[key-1].typeName !== item.typeName) ? <h4 className={"documentation-head"}>{item.typeName}</h4> : ''}
                                <li onClick={eventClick}>{item.title}</li>
                            </React.Fragment>
                        );
                    })
                }
            </ul>
        );
    }

    render() {
        return (
            <>
                {this.renderDocumentList()}
                <Modal className="document-modal quiz-modal" show={this.state.show} onHide={this.hideModal.bind(this)}>
                    <Container className="modal-content-container">
                        <Row>
                            <Modal.Header closeButton>
                                <img alt="logo" src="/logo.svg" onClick={this.hideModal.bind(this)}/>
                            </Modal.Header>
                            <Modal.Body>
                                <Container className={"document-content"}>
                                    <Row>
                                        {
                                            this.state.docContent
                                                ? this.state.docContent
                                                : <div className={'document-modal-spinner'}><Spinner animation="grow"/><span>Proszę czekać, trwa wczytywanie materiałów.</span></div>
                                        }
                                    </Row>
                                </Container>
                            </Modal.Body>
                        </Row>
                    </Container>
                </Modal>
            </>
        )
    }
}

export default ProductDocuments;