import {
    PRODUCT_ADDITIONAL_LIST_QUERY_RECEIVED,
    PRODUCT_ADDITIONAL_LIST_QUERY_REQUEST,
    PRODUCT_DOCUMENT_RECEIVED,
    PRODUCT_ERROR, PRODUCT_HOME_LIST_QUERY_RECEIVED, PRODUCT_HOME_LIST_QUERY_REQUEST,
    PRODUCT_L_QUERY_SLIDER_RECEIVED,
    PRODUCT_L_QUERY_SLIDER_REQUEST,
    PRODUCT_LIST_QUERY_ERROR,
    PRODUCT_LIST_QUERY_RECEIVED,
    PRODUCT_LIST_QUERY_REQUEST,
    PRODUCT_LIST_QUERY_SLIDER_RECEIVED,
    PRODUCT_LIST_QUERY_SLIDER_REQUEST,
    PRODUCT_LIST_RECEIVED,
    PRODUCT_RECEIVED,
    PRODUCT_REQUEST,
    PRODUCT_TYPES_RECEIVED,
    PRODUCT_UNLOAD, VIDEO_CHAPTERS
} from "../constants";

export const product = (state = {
    isFetching: false,
    toRedirect: false,
    error: false
}, action) => {
    switch (action.type) {
        case PRODUCT_REQUEST:
            state = {
                ...state,
                isFetching: true
            };
            return state;
        case VIDEO_CHAPTERS:
            state = {
                ...state,
                chapters: action.data,
                isFetching: false
            };
            return state;
        case PRODUCT_RECEIVED:
            state = {
                ...state,
                product: action.data,
                isFetching: false
            };
            return state;
        case PRODUCT_LIST_RECEIVED:
            state = {
                ...state,
                productsList: action.data['hydra:member'],
                isFetching: false
            };
            return state;
        case PRODUCT_TYPES_RECEIVED:
            state = {
                ...state,
                types: action.types,
                isFetching: false
            };
            return state;
        case PRODUCT_ERROR:
            state = {
                ...state,
                isFetching: false,
                product: null,
                error: true
            };
            return state;
        case PRODUCT_UNLOAD:
            return {
                ...state,
                isFetching: false,
                product: null,
                productsList: null,
                types: null
            };
        case PRODUCT_DOCUMENT_RECEIVED:
            state = {
                ...state,
                document: action.document,
                isFetching: false
            };
            return state;
        case PRODUCT_LIST_QUERY_REQUEST:
            state = {
                ...state,
                isFetching: true,
            };
            return state;
        case PRODUCT_LIST_QUERY_RECEIVED:
            state = {
                ...state,
                productQueryList: action.data['result'],
                isFetching: false,
                totalQuery: action.data['total'],
                page: action.data['page']
            };
            return state;
        case PRODUCT_ADDITIONAL_LIST_QUERY_REQUEST:
            state = {
                ...state,
                isFetching: true,
            };
            return state;
        case PRODUCT_ADDITIONAL_LIST_QUERY_RECEIVED:
            state = {
                ...state,
                productAdditionalQueryList: action.data['result'],
                isFetching: false,
                totalAdditionalQuery: action.data['total'],
                additionalPage: action.data['page']
            };
            return state;
        case PRODUCT_HOME_LIST_QUERY_REQUEST:
            state = {
                ...state,
                isFetching: true,
            };
            return state;
        case PRODUCT_HOME_LIST_QUERY_RECEIVED:
            state = {
                ...state,
                productHomeQueryList: action.data['result'],
                isFetching: false,
                totalQuery: action.data['total'],
            };
            return state;
        case PRODUCT_LIST_QUERY_ERROR:
            state = {
                ...state,
                productsQueryList: null,
                error: action.error,
                isFetching: false,
                productQuerySliderList: null,
                totalQuery: null
            };
            return state;
        case PRODUCT_LIST_QUERY_SLIDER_REQUEST:
            state = {
                ...state,
                isFetching: true,
            };
            return state;
        case PRODUCT_LIST_QUERY_SLIDER_RECEIVED:
            state = {
                ...state,
                productQuerySList: action.data['result'],
                isFetching: false,
            };
            return state;
        default:
            return state;
    }
}