import React from "react";
import {Container, Row, Spinner, Tab, Tabs} from "react-bootstrap";
import ProfileProductContainer from "./ProfileProductContainer";
import ProfileUserContainer from "./ProfileUserContainer";
import ProfileWebinarContainer from "./ProfileWebinarContainer";
import {connect} from "react-redux";

import 'react-table-6/react-table.css';
import TableContainer from "./TableContainer";
import {multiTableGetSecondVoucher, multiTableGetVoucher} from "../../actions/multiTable";
import {multiTable} from "../../reducers/multiTable";

const mapStateToProps = state => ({
    voucher: state.multiTable.voucher,
    secondVoucher: state.multiTable.secondVoucher
});

const mapDispatchToProps = {
    multiTableGetVoucher,
    multiTableGetSecondVoucher
};

class Profile extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            active: undefined,
            subActive: undefined
        }
    }

    componentDidMount() {
        const {userData}= this.props.children
        if (window.location.pathname.includes('edit')) {
            this.setState({active: 'user'})
        } else {
            const voucherSys = window.sessionStorage.getItem('voucher');
            const secondVoucherSys = window.sessionStorage.getItem('secondVoucher');
            if (voucherSys === 'true') {
                this.setState({active: 'oswiata'})
            } else if (secondVoucherSys === 'true') {
                this.setState({active: 'kik'})

            }
        }
    }


    switchAction = (e) => {
        this.setState({
            active: e
        })
    }
    switchSubaction = (e) => {
        this.setState({
            subActive: e
        })
    }

    render() {
        const {isAuthenticated, decreaseLevel, isFetching, userData} = this.props.children;
        const {voucher, secondVoucher} = this.props
        if (!isAuthenticated || isFetching) {
            return (
                <Container className="mt-5">
                    <Spinner animation="grow"/>
                </Container>
            );
        }
        const voucherSys = window.sessionStorage.getItem('voucher');
        const secondVoucherSys = window.sessionStorage.getItem('secondVoucher');
        const oswiataOperator =  userData.brands.some(record => record.brandId === 1 && record.isOperator === true);
        const kikOperator =  userData.brands.some(record => record.brandId === 2 && record.isOperator === true);

        return (
            <Container className="profile-main-container" fluid>
                <Row className="profile-container mt-5 mb-5">
                    <Container className={"profile-container-content"}>
                        <Tabs
                            id="uncontrolled-tab-example"
                            activeKey={this.state.active}
                            onSelect={this.switchAction.bind(this)}
                            className={'main-profile-nav'}
                        > {userData.brands.some(brand => brand.brandId === 1) ?

                            <Tab key={1} tabClassName={'main-profile-tab'} eventKey={'oswiata'} title={<>
                                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28"
                                     fill="none">
                                    <path
                                        d="M24.1342 16.8C24.6208 15.5228 24.9128 14.0491 24.9128 12.5754C24.9128 5.69825 19.3658 0 12.4564 0C5.54698 0 0 5.6 0 12.5754C0 19.5509 5.6443 25.1509 12.4564 25.1509C14.5 25.1509 16.4463 24.6596 18.1007 23.7754C18.6846 26.1333 20.8255 28 23.453 28C26.4698 28 29 25.5439 29 22.4C29 19.4526 26.8591 17.0947 24.1342 16.8ZM16.4463 13.6561L11.094 16.8C10.1208 17.3895 9.34228 16.8982 9.34228 15.7193V9.43158C9.34228 8.25263 10.1208 7.85965 11.094 8.35088L16.4463 11.4947C17.4195 12.0842 17.4195 13.0667 16.4463 13.6561Z"
                                        fill={this.state.active === 'oswiata' ? '#4AA5D8' : '#232936'}
                                        fillOpacity={this.state.active === 'oswiata' ? '' : "0.6"}/>
                                </svg>
                                <span>Educado Oświata</span></>}>
                                <Tabs
                                    id="uncontrolled-tab-example2 oswiata"
                                    activeKey={this.state.subActive}
                                    onSelect={this.switchSubaction.bind(this)}
                                >
                                    {voucherSys === 'true' && oswiataOperator ?
                                    <Tab tabClassName={'oswiata'} title={<>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M20.774 18C21.5233 18 22.1193 17.5285 22.6545 16.8691C23.7499 15.5194 21.9513 14.4408 21.2654 13.9126C20.568 13.3756 19.7894 13.0714 19 13M18 11C19.3807 11 20.5 9.88071 20.5 8.5C20.5 7.11929 19.3807 6 18 6"
                                                stroke="#232936" strokeWidth="1.5" strokeLinecap="round" strokeOpacity={this.state.subActive === 'manage' ? '' : "0.6"}/>
                                            <path
                                                d="M3.22596 18C2.47666 18 1.88067 17.5285 1.34555 16.8691C0.250087 15.5194 2.04867 14.4408 2.73465 13.9126C3.43197 13.3756 4.21058 13.0714 5 13M5.5 11C4.11929 11 3 9.88071 3 8.5C3 7.11929 4.11929 6 5.5 6"
                                                stroke="#232936" strokeWidth="1.5" strokeLinecap="round" strokeOpacity={this.state.subActive === 'manage' ? '' : "0.6"}/>
                                            <path
                                                d="M8.0838 15.1112C7.06203 15.743 4.38299 17.0331 6.0147 18.6474C6.81178 19.436 7.69952 20 8.81563 20H15.1844C16.3005 20 17.1882 19.436 17.9853 18.6474C19.617 17.0331 16.938 15.743 15.9162 15.1112C13.5201 13.6296 10.4799 13.6296 8.0838 15.1112Z"
                                                stroke="#232936" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round" strokeOpacity={this.state.subActive === 'manage' ? '' : "0.6"}/>
                                            <path
                                                d="M15.5 7.5C15.5 9.433 13.933 11 12 11C10.067 11 8.5 9.433 8.5 7.5C8.5 5.567 10.067 4 12 4C13.933 4 15.5 5.567 15.5 7.5Z"
                                                stroke="#232936" strokeWidth="1.5" strokeOpacity={this.state.subActive === 'manage' ? '' : "0.6"}/>
                                        </svg>
                                        <span>Zarządzaj dostępami</span></>} eventKey={'manage'} key={11}>
                                        {isFetching ? <Spinner animation={'border'}/>
                                            :
                                        <TableContainer activeBrand={this.state.active} brand={1}/> }
                                        </Tab> : ''}


                                    <Tab tabClassName={'oswiata'} title={<>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M14 2H10C6.72077 2 5.08116 2 3.91891 2.81382C3.48891 3.1149 3.1149 3.48891 2.81382 3.91891C2 5.08116 2 6.72077 2 10C2 13.2792 2 14.9188 2.81382 16.0811C3.1149 16.5111 3.48891 16.8851 3.91891 17.1862C5.08116 18 6.72077 18 10 18H14C17.2792 18 18.9188 18 20.0811 17.1862C20.5111 16.8851 20.8851 16.5111 21.1862 16.0811C22 14.9188 22 13.2792 22 10C22 6.72077 22 5.08116 21.1862 3.91891C20.8851 3.48891 20.5111 3.1149 20.0811 2.81382C18.9188 2 17.2792 2 14 2Z"
                                                stroke="#232936" strokeOpacity={this.state.subActive === 'trainings' ? '' : "0.6"} strokeWidth="1.5"
                                                strokeLinecap="round"/>
                                            <path
                                                d="M14.5 22L14.1845 21.5811C13.4733 20.6369 13.2969 19.1944 13.7468 18M9.5 22L9.8155 21.5811C10.5267 20.6369 10.7031 19.1944 10.2532 18"
                                                stroke="#232936" strokeOpacity={this.state.subActive === 'trainings' ? '' : "0.6"} strokeWidth="1.5"
                                                strokeLinecap="round"/>
                                            <path d="M7 22H17" stroke="#232936" strokeOpacity={this.state.subActive === 'trainings' ? '' : "0.6"} strokeWidth="1.5"
                                                  strokeLinecap="round"/>
                                            <path
                                                d="M8 14C9.83846 11.4046 14.1188 11.263 16 14M14 8C14 9.10457 13.1046 10 12 10C10.8954 10 10 9.10457 10 8C10 6.89543 10.8954 6 12 6C13.1046 6 14 6.89543 14 8Z"
                                                stroke="#232936" strokeOpacity={this.state.subActive === 'trainings' ? '' : "0.6"} strokeWidth="1.5"
                                                strokeLinecap="round"/>
                                        </svg>
                                        <span>Moje szkolenia</span></>} eventKey={'trainings'} key={22}>
                                        <ProfileProductContainer brand={1}/>
                                    </Tab>
                                    <Tab tabClassName={'oswiata'} title={<>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22"
                                             viewBox="0 0 22 22" fill="none">
                                            <circle cx="10.9999" cy="10.9994" r="4.71429" fill="#232936"
                                                    fillOpacity={this.state.subActive === 'webinars' ? '' : "0.6"}/>
                                            <circle cx="11" cy="11" r="10" stroke="#232936" strokeOpacity={this.state.subActive === 'webinars' ? '' : "0.6"}
                                                    strokeWidth="2"/>
                                        </svg>
                                        <span>Webinary</span></>} eventKey={'webinars'} key={33}>
                                        <ProfileWebinarContainer brand={1}/>
                                    </Tab>
                                </Tabs>

                            </Tab> : '' }
                            {userData.brands.some(brand => brand.brandId === 1) ?
                            <Tab tabClassName={'main-profile-tab'} key={2} eventKey={'kik'} title={<>
                                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28"
                                     fill="none">
                                    <path
                                        d="M24.1342 16.8C24.6208 15.5228 24.9128 14.0491 24.9128 12.5754C24.9128 5.69825 19.3658 0 12.4564 0C5.54698 0 0 5.6 0 12.5754C0 19.5509 5.6443 25.1509 12.4564 25.1509C14.5 25.1509 16.4463 24.6596 18.1007 23.7754C18.6846 26.1333 20.8255 28 23.453 28C26.4698 28 29 25.5439 29 22.4C29 19.4526 26.8591 17.0947 24.1342 16.8ZM16.4463 13.6561L11.094 16.8C10.1208 17.3895 9.34228 16.8982 9.34228 15.7193V9.43158C9.34228 8.25263 10.1208 7.85965 11.094 8.35088L16.4463 11.4947C17.4195 12.0842 17.4195 13.0667 16.4463 13.6561Z"
                                        fill={this.state.active === 'kik' ? '#FDD26E' : '#232936'}
                                        fillOpacity={this.state.active === 'kik' ? '' : "0.6"}/>
                                </svg>
                                <span>Educado Księgowość i kadry</span></>}>

                                <Tabs
                                    id="uncontrolled-tab-example3 kik"
                                    activeKey={this.state.subActive}
                                    onSelect={this.switchSubaction.bind(this)}
                                >
                                    {secondVoucherSys && kikOperator ?
                                    <Tab tabClassName={'kik'} title={<>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M20.774 18C21.5233 18 22.1193 17.5285 22.6545 16.8691C23.7499 15.5194 21.9513 14.4408 21.2654 13.9126C20.568 13.3756 19.7894 13.0714 19 13M18 11C19.3807 11 20.5 9.88071 20.5 8.5C20.5 7.11929 19.3807 6 18 6"
                                                stroke="#232936" strokeWidth="1.5" strokeLinecap="round"/>
                                            <path
                                                d="M3.22596 18C2.47666 18 1.88067 17.5285 1.34555 16.8691C0.250087 15.5194 2.04867 14.4408 2.73465 13.9126C3.43197 13.3756 4.21058 13.0714 5 13M5.5 11C4.11929 11 3 9.88071 3 8.5C3 7.11929 4.11929 6 5.5 6"
                                                stroke="#232936" strokeWidth="1.5" strokeLinecap="round"/>
                                            <path
                                                d="M8.0838 15.1112C7.06203 15.743 4.38299 17.0331 6.0147 18.6474C6.81178 19.436 7.69952 20 8.81563 20H15.1844C16.3005 20 17.1882 19.436 17.9853 18.6474C19.617 17.0331 16.938 15.743 15.9162 15.1112C13.5201 13.6296 10.4799 13.6296 8.0838 15.1112Z"
                                                stroke="#232936" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                            <path
                                                d="M15.5 7.5C15.5 9.433 13.933 11 12 11C10.067 11 8.5 9.433 8.5 7.5C8.5 5.567 10.067 4 12 4C13.933 4 15.5 5.567 15.5 7.5Z"
                                                stroke="#232936" strokeWidth="1.5"/>
                                        </svg>
                                        <span>Zarządzaj dostępami</span></>} eventKey={'manage'} key={11}>
                                        <TableContainer  activeBrand={this.state.active} brand={2}/>
                                    </Tab> : ''}
                                    <Tab tabClassName={'kik'} title={<>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M14 2H10C6.72077 2 5.08116 2 3.91891 2.81382C3.48891 3.1149 3.1149 3.48891 2.81382 3.91891C2 5.08116 2 6.72077 2 10C2 13.2792 2 14.9188 2.81382 16.0811C3.1149 16.5111 3.48891 16.8851 3.91891 17.1862C5.08116 18 6.72077 18 10 18H14C17.2792 18 18.9188 18 20.0811 17.1862C20.5111 16.8851 20.8851 16.5111 21.1862 16.0811C22 14.9188 22 13.2792 22 10C22 6.72077 22 5.08116 21.1862 3.91891C20.8851 3.48891 20.5111 3.1149 20.0811 2.81382C18.9188 2 17.2792 2 14 2Z"
                                                stroke="#232936" strokeOpacity="0.6" strokeWidth="1.5"
                                                strokeLinecap="round"/>
                                            <path
                                                d="M14.5 22L14.1845 21.5811C13.4733 20.6369 13.2969 19.1944 13.7468 18M9.5 22L9.8155 21.5811C10.5267 20.6369 10.7031 19.1944 10.2532 18"
                                                stroke="#232936" strokeOpacity="0.6" strokeWidth="1.5"
                                                strokeLinecap="round"/>
                                            <path d="M7 22H17" stroke="#232936" strokeOpacity="0.6" strokeWidth="1.5"
                                                  strokeLinecap="round"/>
                                            <path
                                                d="M8 14C9.83846 11.4046 14.1188 11.263 16 14M14 8C14 9.10457 13.1046 10 12 10C10.8954 10 10 9.10457 10 8C10 6.89543 10.8954 6 12 6C13.1046 6 14 6.89543 14 8Z"
                                                stroke="#232936" strokeOpacity="0.6" strokeWidth="1.5"
                                                strokeLinecap="round"/>
                                        </svg>
                                        <span>Moje szkolenia</span></>} eventKey={'trainings'} key={22}>
                                        <ProfileProductContainer brand={2}/>
                                    </Tab>
                                    <Tab tabClassName={'kik'} title={<>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22"
                                             viewBox="0 0 22 22" fill="none">
                                            <circle cx="10.9999" cy="10.9994" r="4.71429" fill="#232936"
                                                    fillOpacity="0.6"/>
                                            <circle cx="11" cy="11" r="10" stroke="#232936" strokeOpacity="0.6"
                                                    strokeWidth="2"/>
                                        </svg>
                                        <span>Webinary</span></>} eventKey={'webinars'} key={33}>
                                        <ProfileWebinarContainer brand={2}/>
                                    </Tab>


                                </Tabs>
                            </Tab> : ''}
                            <Tab tabClassName={'main-profile-tab'} key={3} eventKey={'user'} title={<>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none">
                                    <path
                                        d="M18 9.35714V10.5M18 9.35714C16.9878 9.35714 16.0961 8.85207 15.573 8.08517M18 9.35714C19.0122 9.35714 19.9039 8.85207 20.427 8.08517M15.573 8.08517L14.5004 8.78571M15.573 8.08517C15.2637 7.63159 15.0833 7.08643 15.0833 6.5C15.0833 5.91364 15.2636 5.36854 15.5729 4.915M20.427 8.08517L21.4996 8.78571M20.427 8.08517C20.7363 7.63159 20.9167 7.08643 20.9167 6.5C20.9167 5.91364 20.7364 5.36854 20.4271 4.915M18 3.64286C19.0123 3.64286 19.9041 4.148 20.4271 4.915M18 3.64286C16.9877 3.64286 16.0959 4.148 15.5729 4.915M18 3.64286V2.5M20.4271 4.915L21.5 4.21429M15.5729 4.915L14.5 4.21429"
                                        stroke="#232936" strokeOpacity={this.state.active === 'user' ? '' : "0.6"}
                                        strokeWidth="1.5"
                                        strokeLinecap="round"/>
                                    <path
                                        d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2C7.40013 2 8.1002 2 8.63498 2.27248C9.10538 2.51217 9.48783 2.89462 9.72752 3.36502C10 3.8998 10 4.59987 10 6C10 7.40013 10 8.1002 9.72752 8.63498C9.48783 9.10538 9.10538 9.48783 8.63498 9.72752C8.1002 10 7.40013 10 6 10C4.59987 10 3.8998 10 3.36502 9.72752C2.89462 9.48783 2.51217 9.10538 2.27248 8.63498C2 8.1002 2 7.40013 2 6Z"
                                        stroke="#232936" strokeOpacity={this.state.active === 'user' ? '' : "0.6"}
                                        strokeWidth="1.5"/>
                                    <path
                                        d="M2 18C2 16.5999 2 15.8998 2.27248 15.365C2.51217 14.8946 2.89462 14.5122 3.36502 14.2725C3.8998 14 4.59987 14 6 14C7.40013 14 8.1002 14 8.63498 14.2725C9.10538 14.5122 9.48783 14.8946 9.72752 15.365C10 15.8998 10 16.5999 10 18C10 19.4001 10 20.1002 9.72752 20.635C9.48783 21.1054 9.10538 21.4878 8.63498 21.7275C8.1002 22 7.40013 22 6 22C4.59987 22 3.8998 22 3.36502 21.7275C2.89462 21.4878 2.51217 21.1054 2.27248 20.635C2 20.1002 2 19.4001 2 18Z"
                                        stroke="#232936" strokeOpacity={this.state.active === 'user' ? '' : "0.6"}
                                        strokeWidth="1.5"/>
                                    <path
                                        d="M14 18C14 16.5999 14 15.8998 14.2725 15.365C14.5122 14.8946 14.8946 14.5122 15.365 14.2725C15.8998 14 16.5999 14 18 14C19.4001 14 20.1002 14 20.635 14.2725C21.1054 14.5122 21.4878 14.8946 21.7275 15.365C22 15.8998 22 16.5999 22 18C22 19.4001 22 20.1002 21.7275 20.635C21.4878 21.1054 21.1054 21.4878 20.635 21.7275C20.1002 22 19.4001 22 18 22C16.5999 22 15.8998 22 15.365 21.7275C14.8946 21.4878 14.5122 21.1054 14.2725 20.635C14 20.1002 14 19.4001 14 18Z"
                                        stroke="#232936" strokeOpacity={this.state.active === 'user' ? '' : "0.6"}
                                        strokeWidth="1.5"/>
                                </svg>
                                <span>Moje Dane</span></>}>
                                <ProfileUserContainer/>
                            </Tab>
                        </Tabs>


                    </Container>
                </Row>
            </Container>
        );
    }

}

export default connect (mapStateToProps, mapDispatchToProps) (Profile);