import React from 'react';
import {Button, Container, Form, FormControl, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {DropdownSubmenu} from "../common/submenu/DropdownSubmenu";
import {categoryAllFetch} from "../actions/category";
import {connect} from "react-redux";
import Auth from "./Auth";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {strNormalize} from "../common/utils";
import {multiTableGetSecondVoucher, multiTableGetVoucher} from "../actions/multiTable";


const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    userData: state.auth.userData,
    voucher: state.multiTable.voucher,
    secondVoucher: state.multiTable.voucher

});

const mapDispatchToProps = {multiTableGetVoucher,
    multiTableGetSecondVoucher}

class Header extends React.Component {

    componentDidMount() {
        const {userData, multiTableGetVoucher, multiTableGetSecondVoucher} = this.props;
        if( userData && window.sessionStorage.getItem('voucher') ===  null) {
            multiTableGetVoucher();
        }
        if ( userData && window.sessionStorage.getItem('secondVoucher') ===  null) {
            multiTableGetSecondVoucher()
        }


    }

    getCategories(item) {
        return (
            <div className={"categories-wrapper"}>
                <NavDropdown.Item href={`/${item}/szkolenia`}>Szkolenia VOD</NavDropdown.Item>
                <NavDropdown.Item href={`/${item}/webinary/webinary-live-z-chatem`}>Webinary LIVE</NavDropdown.Item>
                <NavDropdown.Item href={`/${item}/webinary/webinary-bezplatne`}>Webinary</NavDropdown.Item>
                <NavDropdown.Item href={`/${item}`}>Wszystko</NavDropdown.Item>

            </div>

        )
    }

    renderUser() {
        const {userData, userLogout} = this.props;
        const voucher = window.sessionStorage.getItem('voucher');
        const secondVoucher = window.sessionStorage.getItem('secondVoucher');
        if (null === userData) {
            return (<i className="fas fa-spinner fa-spin"/>);
        }
        return (
            <NavDropdown title={``}>
                {voucher === 'true' || secondVoucher === 'true' ?
                    <NavDropdown.Item href={`/profile`}>Panel Administratora</NavDropdown.Item> : ''

            }
                <NavDropdown.Item href={`/profile/edit`}>Konto</NavDropdown.Item>
                <NavDropdown.Item href="#" onClick={userLogout}>Wyloguj</NavDropdown.Item>
            </NavDropdown>
        );
    }


    render() {
        const {isAuthenticated} = this.props;

        return (
            <Navbar id="main-menu" className={isAuthenticated ? 'logged' : ''} sticky="bottom" expand="lg"
                    variant="dark">
                <Container>
                    <Navbar.Brand className="logo-header" href="/"><img alt="logo Educado"
                                                                        src="/logo-white.svg"/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="nav-category"/>


                    <Navbar.Collapse id="nav-category">
                        <Nav className="category-btn">
                            <NavDropdown className="main-categories" title="wybierz kategorię ">
                                <NavDropdown className="oswiata" title="oświata">
                                    {this.getCategories('oswiata')}
                                </NavDropdown>
                                <NavDropdown className="kik" title="Księgowość i kadry">
                                    {this.getCategories('ksiegowosc-i-kadry')}
                                </NavDropdown>
                            </NavDropdown>
                        </Nav>


                        <Nav className="mr-auto search-form">
                            <Form className="mr-auto" inline action="/szukaj" method="get">
                                <FormControl name="phraze" type="text" placeholder="Czego szukasz?" className=""/>
                                <Button type="submit" variant="outline-light"><FontAwesomeIcon
                                    icon={faSearch}/></Button>
                            </Form>
                        </Nav>
                    </Navbar.Collapse>
                    {isAuthenticated ? '' :
                        <NavDropdown className={"contact-btn category-btn"} title={"Oferta"}>
                            <a href={"/oswiata/abonament"} className={"oswiata"} title={"oferta oświata"}>Educado
                                oświata</a>
                            <a href={"/ksiegowosc-i-kadry/abonament"} className={"kik"}
                               title={"oferta księgowość i kadry"}>Educado Księgowośc
                                i kadry</a>
                        </NavDropdown>}
                    <Nav className="user-info">
                        {isAuthenticated ? this.renderUser() : <Nav><Auth/></Nav>}
                    </Nav>
                </Container>
            </Navbar>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)