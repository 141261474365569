import React from 'react';
import {categoryDescendantsFetch} from "../../../../actions/category";
import {connect} from "react-redux";
import {Button, Container, Row, Spinner} from "react-bootstrap";
import ListContainer from "../../../Modules/Index/Lists/ListContainer";
import SliderContainer from "../../../Modules/Index/Sliders/SliderContainer";
import Breadcrumb from "../../../../common/breadcrumb";

const mapStateToProps = state => ({
    category_descendants: state.category.category_descendants,
    auth: state.auth
});

const mapDispatchToProps = {
    categoryDescendantsFetch,
}

class CategoryWebinarsFreeIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCategory: [this.props.id],
            breadcrumbItems: [
                {name: 'webinary'},
                {name: 'webinary bezpłatne'}
            ]
        };
    }

    componentDidMount(prevProps) {
        const {category_descendants} = this.props;
        if (!category_descendants) {
            this.props.categoryDescendantsFetch(this.props.id);
        }

    }

    componentDidUpdate() {
        if (this.props.error) {
            if (!this.props.isFetching) {
                this.props.history.push(`/404`);

            } else {
                setInterval(() => {
                    this.props.history.push(`/404`);
                }, 15000); //lagi...
            }
        }
    }

    render() {
        const {isFetching, id, category_descendants} = this.props;

        return (
            <>
                <Breadcrumb id={this.props.id} items={this.state.breadcrumbItems}/>
                <div className={"container-content"}>
                    <Container>
                        <SliderContainer id={id} type={['webinar']}/>
                    </Container>
                    {category_descendants && category_descendants.length > 0 ?
                        <Container className={"filter-container"}>
                            <span>Filtruj po kategorii</span>
                            {category_descendants && category_descendants.map((item) => (
                                <Button
                                    key={item.id}
                                    id={item.id}
                                    label={item.name}
                                    variant={""}
                                    onClick={() => this.setState({currentCategory: [item.id]})}
                                    className={`btn-filter cat-${this.props.id} ${this.state.currentCategory.includes(item.id) ? 'active' : 'non-active'}`}>
                                    {item.name}
                                </Button>
                            ))}
                            <Button
                                label={'Pokaż wszystkie'}
                                variant={""}
                                onClick={() => this.setState({currentCategory: [id]})}
                                className={`btn-filter ${this.state.currentCategory.includes(id) ? 'active' : 'non-active'}`}>
                                Pokaż wszystkie
                            </Button>
                        </Container> : '' }
                    <Row>
                        <Container>
                            <h3 className={"headline-small text-center home-products-header"}>bezpłatne webinary</h3>
                        </Container>
                    </Row>
                    <ListContainer {...this.props} type={['webinar']} currentCategory={this.state.currentCategory}/>
                </div>
            </>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryWebinarsFreeIndex);