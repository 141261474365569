import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {Button, Container, Form} from "react-bootstrap";
import {connect} from "react-redux";
import {renderField} from "../../../common/form";
import {closedTrainingFormRequest, schoolMultiFormRequest} from "../../../actions/closedTrainings";

const mapStateToProps = state => ({
    ...state.closedTrainings,
    error: state.closedTrainings.error
});

const mapDispatchToProps = {
    schoolMultiFormRequest
};
export const acceptTerms = <div><span>*</span> Znam i akceptuję warunki <a href="/regulamin" target={"_blank"}
                                                            rel={"noopener noreferrer"}>Regulaminu</a> i <a
    href="https://www.wip.pl/polityka-prywatnosci" target={"_blank"} rel={"noopener noreferrer"}>Polityki
    Prywatności </a></div>;
export const marketingAgreement = <div><span>*</span> Chcę otrzymywać informacje drogą elektroniczną i telefoniczną o dodatkowych
    promocjach, rabatach dotyczących produktów własnych WIP i partnerów WIP. Wiem, że moja zgoda może być wycofana w
    dowolnym momencie.</div>;

class SchoolMultiForm extends React.Component {


    onSubmit(values) {

        const {schoolMultiFormRequest, brand} = this.props;

        let tmp = {
            name: values.name,
            surname: values.surname,
            email: values.email,
            phone: values.phone,
            position: values.position,
            termsAgreement: values.termsAgreement,
            marketingAgreement: values.marketingAgreement,
            cid: localStorage.cid,
            url: window.location.href,
            brand: brand
        };

        return schoolMultiFormRequest(tmp, brand)

    }

    render() {
        const {form, handleSubmit, error} = this.props;

        return (

            <Container className={"school-form-container-content"}>
                {error &&
                <div className="alert alert-danger">Wystąpił błąd! Odśwież stronę lub skontaktuj się z Centrum Obsługi Klienta.</div>}
                <h2 id={"formularz-zgloszeniowy"}><svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M46.668 6.66699H33.3346C22.4039 6.66699 16.9385 6.66699 13.0643 9.37973C11.631 10.3833 10.3843 11.63 9.3807 13.0634C6.66797 16.9375 6.66797 22.4029 6.66797 33.3337C6.66797 44.2643 6.66797 49.7297 9.3807 53.604C10.3843 55.0373 11.631 56.284 13.0643 57.2877C16.9385 60.0003 22.4039 60.0003 33.3346 60.0003H46.668C57.5986 60.0003 63.064 60.0003 66.9383 57.2877C68.3716 56.284 69.6183 55.0373 70.622 53.604C73.3346 49.7297 73.3346 44.2643 73.3346 33.3337C73.3346 22.4029 73.3346 16.9375 70.622 13.0634C69.6183 11.63 68.3716 10.3833 66.9383 9.37973C63.064 6.66699 57.5986 6.66699 46.668 6.66699Z"
                        stroke="#777777" strokeWidth="1.5" strokeLinecap="round"/>
                    <path
                        d="M48.3346 73.3333L47.283 71.937C44.9123 68.7897 44.3243 63.9813 45.824 60M31.668 73.3333L32.7196 71.937C35.0903 68.7897 35.6783 63.9813 34.1786 60"
                        stroke="#777777" strokeWidth="1.5" strokeLinecap="round"/>
                    <path d="M23.332 73.333H56.6654" stroke="#777777" strokeWidth="1.5"
                          strokeLinecap="round"/>
                    <path
                        d="M26.668 46.6667C32.7962 38.0153 47.064 37.5433 53.3346 46.6667M46.668 26.6667C46.668 30.3486 43.6833 33.3333 40.0013 33.3333C36.3193 33.3333 33.3346 30.3486 33.3346 26.6667C33.3346 22.9848 36.3193 20 40.0013 20C43.6833 20 46.668 22.9848 46.668 26.6667Z"
                        stroke="#2EE3A9" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>Nasz konsultant zadzwoni do Ciebie w celu przedstawienia szczegółów oferty</h2>
                <Form className="" id={form} onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Field name="name" type="text"
                           component={renderField} placeholder="Imie"/>
                    <Field name="surname" type="text"
                           component={renderField} placeholder="Nazwisko"/>
                    <Field name="phone"
                           type="text"
                           component={renderField}
                           placeholder="Telefon"
                           mask={{
                               pattern: "+48 999 999 999",
                               text: 'Prawidłowy format +48 999 999 999'
                           }}
                    />
                    <Field name="email" type="text"
                           component={renderField} placeholder="E-mail"/>
                    <Field name="position" type="text"
                           component={renderField} placeholder="Stanowisko"/>
                    <h4><span>*</span> Pola oznaczone gwiazdką są obowiązkowe</h4>
                    <Field name="termsAgreement" type="checkbox"
                           component={renderField} label={acceptTerms} className={"acceptTerms"}/>
                    <Field name="marketingAgreement" type="checkbox"
                           component={renderField} label={marketingAgreement}/>
                    <div className={"button-wrapper"}><Button className="educado-btn educado-btn-full" type="submit">Wyślij</Button></div>
                </Form>
            </Container>
        )
    }
}

export default reduxForm({
    form: 'SchoolMultiForm'
})(connect(mapStateToProps, mapDispatchToProps)(SchoolMultiForm));