import React from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";
import Credentials from "./Credentials";
import {convertMetaDescription, convertMetaTitle} from "../../common/utils";
import Helmet from "react-helmet";


const AboutUs = props => {

    return (
        <>
            <Helmet>
                <title>{convertMetaTitle('o platformie')}</title>
                <meta name={"description"} content={convertMetaDescription("Co to jest Educado?")}/>
            </Helmet>
            <Container className={"about-us-container"} fluid>
                <Row className={"static-main-badge"}>
                    <Container>
                        <h1>Co to jest Educado?</h1>
                    </Container>
                </Row>
                <Row className={"static-second-badge"}>
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <h3>Educado to platforma ze szkoleniami online dla profesjonalistów. Obejmuje szkolenia
                                    z
                                    różnych dziedzin: księgowości, kadr, płac, HR, rachunkowości, ochrony środowiska,
                                    transportu, RODO, zamówień publicznych, oświaty i innych. </h3>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row className={"about-us-definition"}>
                    <Container>
                        <Row>
                            <Col md={5}>
                                <h2>Dlaczego warto?</h2>
                                <p>Każdy, kto wykupi abonament w Educado otrzymuje nieograniczony dostęp do wszystkich
                                    szkoleń online dostępnych na platformie przez cały okres obowiązywania abonamentu,
                                    wraz
                                    z dostępem do dodatkowych materiałów edukacyjnych, powiązanych aktów prawnych,
                                    testów i
                                    certyfikatów. Szkolenia dostępne na Educado prowadzone są przez najlepszych
                                    ekspertów
                                    praktyków. </p>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row className={"about-us-benefits-section"}>
                    <Container>
                        <Row>
                            <Col md={4} className={'item-container item-1'}>
                                <div className={"item-wrapper"}>
                                    <div className={"item-badge"}></div>
                                    <Card.Body>
                                        <Card.Title>JESTEŚ NA BIEŻĄCO</Card.Title>
                                        <Card.Text className={"benefit-content"}>
                                            W Educado znajdziesz szkolenia na najbardziej aktualne tematy.
                                        </Card.Text>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col md={4} className={'item-container item-2'}>
                                <div className={"item-wrapper"}>
                                    <div className={"item-badge"}></div>
                                    <Card.Body>
                                        <Card.Title>W KAŻDYM TEMACIE</Card.Title>
                                        <Card.Text className={"benefit-content"}>
                                            Szkolenia online na platformie Educado dotyczą różnych dziedzin.
                                        </Card.Text>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col md={4} className={'item-container item-3'}>
                                <div className={"item-wrapper"}>
                                    <div className={"item-badge"}></div>
                                    <Card.Body>
                                        <Card.Title>NIC CIĘ NIE ZASKOCZY</Card.Title>
                                        <Card.Text className={"benefit-content"}>
                                            Eksperci Educado pomogą Ci przygotować się nawet na największe zmiany w
                                            prawie.
                                        </Card.Text>
                                    </Card.Body>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row className={"about-us-badge"}>
                    <Container>
                        <a href={`/abonament`}>
                            <h3>Zdobywaj wiedzę z Educado! Dołącz do grona uzytkowników Educado <span>już dziś!</span></h3>
                        </a>
                    </Container>
                </Row>
                <Row className={"about-us-circle-section"}>
                    {/*<Container className={"circle-categories"}>*/}
                    {/*    <h2 className={"about-us-head"}>Kategorie</h2>*/}
                    {/*    <Row>*/}
                    {/*        <Col md={6} lg={4} className={"category-item-wrapper"}>*/}
                    {/*            <a className="category-item category-item-5" href={`/kadry-prawo-pracy/90`}*/}
                    {/*               title={"asd"}>*/}
                    {/*                <div className="item-badge"></div>*/}
                    {/*                <div className="item-title">Kadry, prawo pracy</div>*/}
                    {/*            </a>*/}
                    {/*        </Col>*/}
                    {/*        <Col md={6} lg={4} className={"category-item-wrapper"}>*/}
                    {/*            <a className="category-item category-item-5" href={`/ksiegowosc/14`}*/}
                    {/*               title={"asd"}>*/}
                    {/*                <div className="item-badge"></div>*/}
                    {/*                <div className="item-title">Księgowość</div>*/}
                    {/*            </a>*/}
                    {/*        </Col>*/}
                    {/*        <Col md={6} lg={4} className={"category-item-wrapper"}>*/}
                    {/*            <a className="category-item category-item-5" href={`/ochrona-danych-osobowych/73`}*/}
                    {/*               title={"asd"}>*/}
                    {/*                <div className="item-badge"></div>*/}
                    {/*                <div className="item-title">Ochrona danych osobowych</div>*/}
                    {/*            </a>*/}
                    {/*        </Col>*/}
                    {/*        <Col md={6} lg={4} className={"category-item-wrapper"}>*/}
                    {/*            <a className="category-item category-item-5" href={`/oswiata/71`}*/}
                    {/*               title={"asd"}>*/}
                    {/*                <div className="item-badge"></div>*/}
                    {/*                <div className="item-title">Oświata</div>*/}
                    {/*            </a>*/}
                    {/*        </Col>*/}
                    {/*        <Col md={6} lg={4} className={"category-item-wrapper"}>*/}
                    {/*            <a className="category-item category-item-5" href={`/zamowienia-publiczne/72`}*/}
                    {/*               title={"asd"}>*/}
                    {/*                <div className="item-badge"></div>*/}
                    {/*                <div className="item-title">Zamowienia publiczne</div>*/}
                    {/*            </a>*/}
                    {/*        </Col>*/}
                    {/*        <Col md={6} lg={4} className={"category-item-wrapper"}>*/}
                    {/*            <a className="category-item category-item-5" href={`/srodowisko/86`}*/}
                    {/*               title={"asd"}>*/}
                    {/*                <div className="item-badge"></div>*/}
                    {/*                <div className="item-title">Środowisko</div>*/}
                    {/*            </a>*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*</Container>*/}
                    {/*<Container className={"circle-partners"}>*/}
                    {/*<h2 className={"about-us-head"}>Z kim współpracujemy?</h2>*/}
                    {/*<Row>*/}
                    {/*<Col md={3} className={"partner-item item-1"}>*/}
                    {/*<img src="" title={""} alt={""}/>*/}
                    {/*</Col>*/}
                    {/*<Col md={3} className={"partner-item item-2"}>*/}
                    {/*<img src="" title={""} alt={""}/>*/}
                    {/*</Col>*/}
                    {/*<Col md={3} className={"partner-item item-3"}>*/}
                    {/*<img src="" title={""} alt={""}/>*/}
                    {/*</Col>*/}
                    {/*<Col md={3} className={"partner-item item-4"}>*/}
                    {/*<img src="" title={""} alt={""}/>*/}
                    {/*</Col>*/}
                    {/*</Row>*/}
                    {/*</Container>*/}
                    <Credentials/>
                </Row>
                <Row className={"about-us-contact"}>
                    <Container>
                        <h4>Masz pytanie? Zadzwoń <span>22 518 29 29</span></h4>
                    </Container>
                </Row>
            </Container>
        </>
    );

}

export default AboutUs;