import React from 'react';
import {Container, Modal, Row, Col} from "react-bootstrap";
import {APP_URL} from "../../constants";
import queryString from "query-string";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";

class ProductSocialMedia extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }

    showModal() {
        this.setState({show: true});
    }

    hideModal() {
        this.setState({show: false});
    }

    createEmail() {
        const {url, title, auth} = this.props;

        let email = auth.userData.email;
        let subject = title;
        let emailBody = `${APP_URL}${url}`;
        document.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody;
        this.setState({show: false});
    }

    copyToClipboard() {
        navigator.clipboard.writeText(`https://educado.pl${this.props.url}`);
        this.setState({show: false});
    }

    createFacebookPost() {
        const {url, title} = this.props;
        let queryParams = {
         //   'url':`${APP_URL}${url}`,
            'url':`https://educado.pl/${url}`,
            'quote':title,
            'hashtag': '#educado'
        };

        let tmp = `https://www.facebook.com/sharer/sharer.php?${queryString.stringify(queryParams)}`;

        window.open(tmp, '_blank');
        this.setState({show: false});
    }

    createTwitterPost() {
        const {url, title} = this.props;
        let queryParams = {
            //   'url':`${APP_URL}${url}`,
            'url':`https://educado.pl/${url}`,
            'text':title,
            'hashtags': '#educado'
        };

        let tmp = `https://twitter.com/share?${queryString.stringify(queryParams)}`;

        window.open(tmp, '_blank');
        this.setState({show: false});
    }

    createLinkedin() {
        const {url, title} = this.props;
        let queryParams = {
            //   'url':`${APP_URL}${url}`,
            'url':`https://educado.pl/${url}`,
            'mini': 'true',
            'title':title
        };

        let tmp = `https://linkedin.com/shareArticle?${queryString.stringify(queryParams)}`;

        window.open(tmp, '_blank');
        this.setState({show: false});
    }

    render() {
        return (
            <>
                <button className="btn btn-media" onClick={this.showModal.bind(this)}></button>
                <Modal className="social-media-modal" show={this.state.show} onHide={this.hideModal.bind(this)}>
                    <Container>
                        <Row>
                            <Col><h3>Udostępnij przez</h3></Col>
                        </Row>
                        <Row>
                            <Col>
                                <button className="btn-facebook btn-social-item" onClick={this.createFacebookPost.bind(this)} title="Facebook">Facebook</button>
                            </Col>
                            <Col>
                                <button className="btn-twitter btn-social-item" onClick={this.createTwitterPost.bind(this)} title="Twitter">Twitter</button>
                            </Col>
                        </Row>
                        <Row>
                            {/*<Col>*/}
                                {/*<button className="btn-email btn-social-item" onClick={this.createEmail.bind(this)} title="E-mail"><i><FontAwesomeIcon icon={faEnvelope} /></i>E-mail</button>*/}
                            {/*</Col>*/}
                            <Col>
                                <button className="btn-linkedin btn-social-item" onClick={this.createLinkedin.bind(this)} title="linkedin">Linkedin</button>
                            </Col>
                            <Col>
                                <button className="btn-copy-to-clipboard btn-social-item" onClick={this.copyToClipboard.bind(this)} title="Kopiuj link"><i><FontAwesomeIcon icon={faCopy} /></i>
                                    Kopiuj link</button>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                            {/*<Col>*/}
                                {/*<button className="btn-copy-to-clipboard btn-social-item" onClick={this.copyToClipboard.bind(this)} title="Kopiuj link"><i><FontAwesomeIcon icon={faCopy} /></i>*/}
                                    {/*Kopiuj link</button>*/}
                            {/*</Col>*/}
                        {/*</Row>*/}
                    </Container>
                </Modal>
            </>
        )
    }
}


export default ProductSocialMedia;