import {
    MULTI_SECOND_TABLE_LIST_RECEIVED,
    MULTI_TABLE_ADD_USER_ERROR,
    MULTI_TABLE_DELETE_USER_RECEIVED,
    MULTI_TABLE_DELETE_USER_REQUEST,
    MULTI_TABLE_ERROR,
    MULTI_TABLE_GET_SECOND_VOUCHER_RESPONSE,
    MULTI_TABLE_GET_VOUCHER_RESPONSE,
    MULTI_TABLE_GIVE_ADMIN_RECEIVED,
    MULTI_TABLE_GIVE_ADMIN_REQUEST, MULTI_TABLE_IMPORT_FILE_ERROR,
    MULTI_TABLE_IMPORT_FILE_REQUEST, MULTI_TABLE_IMPORT_FILE_RESPONSE,
    MULTI_TABLE_INVITE_CONFIRMATION_REQUEST,
    MULTI_TABLE_INVITE_CONFIRMATION_RESPONSE,
    MULTI_TABLE_LIST_RECEIVED,
    MULTI_TABLE_REGISTER_USER_RECEIVED,
    MULTI_TABLE_REGISTER_USER_REQUEST,
    MULTI_TABLE_REMOVE_ADMIN_RECEIVED,
    MULTI_TABLE_REMOVE_ADMIN_REQUEST,
    MULTI_TABLE_REPEAT_INVITE_RECEIVED,
    MULTI_TABLE_REQUEST,

} from "../constants";

export const multiTable = (state = {
    multiList: null,
    multiSecondList: null,
    isFetching: false,
    deleteStatus: false,
    giveAdmin: null,
    removeAdmin: null,
    repeatInvite: null,
    newUserReceived: false,
    error: false,
    errorMsg: null,
    token: null,
    fileImportFetch: false,
    fileImportData: null,
    fileImportError: null,
    accountsCreated: null
}, action) => {
    switch (action.type) {
        case MULTI_TABLE_IMPORT_FILE_REQUEST:
            return {
                ...state,
                fileImportFetch: true,
                fileImportData: null,
                fileImportError: null,
            }
        case MULTI_TABLE_IMPORT_FILE_RESPONSE:
            return {
                ...state,
                fileImportFetch: false,
                fileImportData: action.data
            }
        case MULTI_TABLE_IMPORT_FILE_ERROR:
            const importTmp = action.error.response.text;
            const importTmp2 = JSON.parse(importTmp)
            return {
                ...state,
                fileImportFetch: false,
                fileImportError: importTmp2.error,
                fileImportErrorSuccess: importTmp2.accountsCreated
            }
        case MULTI_TABLE_REQUEST:
            return  {
                ...state,
                isFetching: true,
            }
        case MULTI_TABLE_ERROR:
            return {
                ...state,
                error: true,
                errorMsg: action.error,
                isFetching: false
            }
        case MULTI_TABLE_ADD_USER_ERROR:
            const tmp = action.error.response.text;
            const tmp2 = JSON.parse(tmp)
            return {
                ...state,
                error: true,
                errorMsg: tmp2.error,
                isFetching: false
            };
        case MULTI_TABLE_INVITE_CONFIRMATION_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case MULTI_TABLE_INVITE_CONFIRMATION_RESPONSE:
            return {
                ...state,
                isFetching: false,
                token: action.data.token
            }
        case MULTI_TABLE_LIST_RECEIVED:
            return {
                ...state,
                multiList: action.data,
                isFetching: false
            };
        case MULTI_SECOND_TABLE_LIST_RECEIVED:
            return {
                ...state,
                multiSecondList: action.data,
                isFetching: false
            };
        case MULTI_TABLE_DELETE_USER_REQUEST:
            return {
                ...state,
                deletedId: null,
                success: false,
                isFetching: true
            };
        case MULTI_TABLE_DELETE_USER_RECEIVED:
            return {
                ...state,
                success: action.data.success,
                deletedId: action.data.deletedId,
                isFetching: false
            };
        case MULTI_TABLE_GIVE_ADMIN_REQUEST:
            return {
                ...state,
                removeAdmin: null,
                giveAdmin: false,
                isFetching: true
            };
        case MULTI_TABLE_GIVE_ADMIN_RECEIVED:
            return {
                ...state,
                giveAdmin: action.data,
                isFetching: false
            };
        case MULTI_TABLE_REMOVE_ADMIN_REQUEST:
            return {
                ...state,
                giveAdmin: null,
                removeAdmin: false,
                isFetching: true
            };
        case MULTI_TABLE_REMOVE_ADMIN_RECEIVED:
            return {
                ...state,
                removeAdmin: action.data,
                isFetching: false
            };
        case MULTI_TABLE_REPEAT_INVITE_RECEIVED:
            return {
                ...state,
                repeatInvite: action.data,
                isFetching: false
            };
        case MULTI_TABLE_REGISTER_USER_REQUEST:
            return {
                ...state,
                newUserReceived: false,
                isFetching: true,
                error: false
            };
        case MULTI_TABLE_REGISTER_USER_RECEIVED:
            return {
                ...state,
                newUserReceived: true,
                isFetching: false,
                error: false
            };
        case MULTI_TABLE_GET_VOUCHER_RESPONSE:
            return {
                ...state,
                voucher: action.data,
                isFetching: false,
                error: false
            };
        case MULTI_TABLE_GET_SECOND_VOUCHER_RESPONSE:
            return {
                ...state,
                secondVoucher: action.data,
                isFetching: false,
                error: false
            };
        default:
            return state;
    }
}