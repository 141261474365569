import React from 'react';
import {Button, Card, Col} from "react-bootstrap";
import {strNormalize} from "../../../../../common/utils";
import dateFormat from "dateformat";
import striptags from "striptags";

const TrainingListItems = props => {
    const {product, id, auth} = props;

    function checkAccessBrand(auth, product, id) {
        if (!auth || !auth.userData) {
            return false;
        }

        if (window.location.pathname === '/') {
            if (product.categories[0].id && product.categories[0].id === 71 && auth.userData.brands.some(brand => brand.brandId === 1)) {
                return true;
            }
            if (product.categories[0].id && product.categories[0].id === 13 && auth.userData.brands.some(brand => brand.brandId === 2)) {
                return true;
            }
        } else {
            if (id === 71 && auth.userData.brands.some(brand => brand.brandId === 1)) {
                return true;
            }
            if (id === 13 && auth.userData.brands.some(brand => brand.brandId === 2)) {
                return true;
            }
        }

    }

    const hasAccess = checkAccessBrand(auth, product, id);
    return (
        <div className={"index-list-item"}>
            <div className={"card-img-top"} width={"120"} height={"147"}
                 style={{backgroundImage: `url(${product.imageUrl})`}}/>
            <div
                className={`webinar-labels category-${product.categories[0].id ? product.categories[0].id : ''} ${product.archive ? 'with-overdue' : ''}`}>
                <div
                    className={"archived-label"}>Szkolenie
                </div>
                {product.categories ?
                    <div
                        className={`category-label`}>{product.categories.map((category, index) => (

                        <span
                            key={index}>{category.name} {product.categories.length > index + 1 && product.categories.length > 1 ? ' | ' : ''}</span>

                    ))}</div>
                    : ''
                }
                {product.archive ? <div className={"archived-label overdue"}>Archiwalne</div> : ''}
            </div>
            <Card.Body>
                <a className={"title-link"} href={`/${strNormalize(product.type)}/${strNormalize(product.name)}/${(product.id)}`}><Card.Title
                    title={product.name}>{product.name.length > 68 ? product.name.substring(0, 65) + '...' : product.name}</Card.Title></a>
                {product.experts ?

                    <div className={"expert-wrapper"}>{product.experts.map((expert, index) => (
                        <div className={"webinar-item-expert"}
                             key={index}>&nbsp;{expert.name} {expert.surname}{product.experts.length > index + 1 && product.experts.length > 1 ? ', ' : ''}</div>
                    ))}</div>
                    : <div className={"webinar-item-expert"}></div>}

                <div className={'item-info-container'}><p>Stan prawny
                    na: <span>{dateFormat(product.legalStatusDate, 'dd.mm.yyyy')}</span></p></div>
            </Card.Body>
            <Card.Text className="item-lead">
                {product.shortDescription ? <span>{striptags(product.shortDescription)}</span> : ''}

            </Card.Text>

            {hasAccess ?
                <div className={'button-wrapper'}>
                    <Button href={`/${strNormalize(product.type)}/${strNormalize(product.name)}/${(product.id)}`}
                            className="educado-btn educado-btn-black">Oglądaj</Button>
                </div>
                :
                <div className={'button-wrapper'}>
                    <Button href={product.categories[0].id && product.categories[0].id === 71 ? '/oswiata/abonament' : '/ksiegowosc-i-kadry/abonament'}
                            className="educado-btn educado-btn-black">Kup w abonamencie</Button>
                    <a href={`/${strNormalize(product.type)}/${strNormalize(product.name)}/${(product.id)}`}
                       className="training-btn">Więcej o szkoleniu</a>
                </div>
            }
        </div>

    );

}


export default TrainingListItems;