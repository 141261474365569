import React from "react";
import {Container, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {convertMetaDescription, convertMetaTitle} from "../../../common/utils";


class Thx extends React.Component {


    render() {

        return (
            <>
                <Helmet>
                    <title>{convertMetaTitle('rejestracja podsumowanie')}</title>
                    <meta name="description" content={convertMetaDescription('Dziękujemy za zakup usługi educado.')}/>
                </Helmet>
                <Container fluid className={"register-thx-container"}>
                    <Row>
                        <Container className={"thx-introduction-container"}><p><strong>Dziękujemy za zamówienie.</strong> Zapraszamy do świata profesjonalnych
                            szkoleń <a href="/" title="educado.pl"><span>Educado.pl</span></a>, gdzie znajdziesz certyfikowane szkolenia z różnych dziedzin.</p>
                        </Container></Row>
                    <Row className={"bg-white"}><Container className={"hints-container"}><p className="hint hint-1">Po zalogowaniu do <a href="/" title="educado.pl"><span>Educado</span></a> w <strong>Mojej Strefie</strong> możesz
                        zaznaczyć swoje ulubione kategorie szkoleń.</p><p className="hint hint-2">Jeśli wybrałeś
                        płatność na podstawie Faktury elektronicznej, dokument otrzymasz formacie PDF od nadawcy “Wiedza
                        i Praktyka”.<br/>Jeśli nie otrzymasz faktury w ciągu 24 godzin, sprawdź skrzynkę SPAM lub
                        skontaktuj się z Centrum Obsługi Klienta 22 518 29 29 lub poprzez <a
                            href="mailto:formularze@wip.pl">formularze@wip.pl</a></p></Container></Row>
                    <Row><Container className={"adventages-container"}><p>Ulubione kategorie szkoleń możesz zmienić w każdej chwili
                        w <strong>Mojej Strefie.</strong><br/>Dostęp
                        do <strong>Mojej Strefy</strong> jest aktywny po zalogowaniu do platformy <a href="/" title="educado.pl"><span>educado.pl</span></a></p>
                        <h4>Od teraz, przez cały czas trwania abonamentu:</h4>
                        <ul>
                            <li>masz dostęp do całej, stale rozwijanej i aktualizowanej bazy szkoleń online o
                                różnorodnej tematyce,
                            </li>
                            <li>możesz wykonywać testy, aby sprawdzić swoją wiedzę i wygenerować certyfikat
                                poświadczający udział w szkoleniu online.
                            </li>
                        </ul>
                    </Container></Row>
                </Container>
            </>
        )

    }

}

export default Thx;