import React from "react";
import {Container, Row, Spinner} from "react-bootstrap";
import {
    registerAccount,
    registerGetOrder,
    registerOfferFetch,
    registerOrder,
    registerPaymentComplete,
    registerSetOrder,
    registerSetToken,
    registerTinFetch,
    registerUserOrders,
    registerUsersList
} from "../../actions/register";
import {connect} from "react-redux";
import Account from "./account";
import Buyer from "./buyer";
import {productFetch, productOfferFetch} from "../../actions/product";

const mapStateToProps = state => ({
    ...state.product,
    ...state.register,
    auth: state.auth
});

const mapDispatchToProps = {
    registerOfferFetch,
    registerAccount,
    registerOrder,
    registerTinFetch,
    registerSetToken,
    registerPaymentComplete,
    productOfferFetch,
    registerSetOrder,
    registerGetOrder,
    registerUserOrders,
    productFetch,
    registerUsersList,
};

class RegistrationWebinar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type: 'account',
            steps: [
                {'type': 'account'},
                {'type': 'buyer'},
            ]
        }
    }

    componentDidMount() {
        this.props.productOfferFetch(this.props.match.params.id);
        const token = window.sessionStorage.getItem('regToken');
        if (token) {
            this.props.registerSetToken(token)
            this.setState({type: 'buyer'})
        }
        const {status, registerToken, auth} = this.props;
        const tmp = window.sessionStorage.getItem('regToken');

        if (auth.isAuthenticated && tmp === null && !registerToken && status !== 'PROGRESS' ) {
            this.props.registerUserOrders(auth.userData, this.props.match.params.id);
            this.setState({type: 'buyer'});
        }

    }

    componentDidUpdate(prevProps) {
        const {status, product, registerToken, registerSetToken, payment, paymentError, auth} = this.props;
        if(product && auth.isAuthenticated) {
            if (auth.userData.brands.some(brand => brand.brandId === product.brand.id && (brand.role === "ROLE_FREE_USER" || brand.role === "ROLE_FALLBACK_USER"))) {
            } else {
                this.props.history.push("/");
            }
        }
        // if (auth.isAuthenticated && (!auth.userData.accessInfo.role === "ROLE_FALLBACK_USER" || !auth.userData.accessInfo.role === "ROLE_FREE_USER"))  {
        //     this.props.history.push("/");
        // }

        if (payment && prevProps.payment !== payment && payment.redirectUrl) {
            window.location.replace(payment.redirectUrl);
        } else if (payment && prevProps.payment !== payment && payment.thxContent) {
            window.location.replace('https://educado.pl/zamowienie/podziekowanie');
        } else if (paymentError && prevProps.paymentError !== paymentError) {
            this.setState({type: 'account'})
        }

        if (registerToken && prevProps.registerToken !== registerToken && status === 'PROGRESS') {
            registerSetToken(registerToken);
            this.setState({type: 'buyer'})
        }

    }

    getContent() {
        switch (this.state.type) {
            case 'buyer':
                return <Buyer children={this.props}/>;
            default:
                return <Account children={this.props}/>;
        }
    }

    render() {
        const {product, isFetching} = this.props;

        if (!product && isFetching) {
            return (
                <Container className="home-main-container spinner-register-container">
                    <Row>
                        <Container className="home-products-container">
                            <br/><br/><Spinner animation="grow"/>
                        </Container>
                    </Row>
                </Container>
            )
        }

        return (
            <Container fluid>
                <Row>
                    <Container className={"registration-account-container"}>
                        <Row>
                            {this.getContent()}
                        </Row>
                    </Container>
                </Row>
            </Container>
        )
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationWebinar);