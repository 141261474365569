import React from 'react';
import {Card, Col, Container, Image, Row, Spinner} from "react-bootstrap";
import ExpertProductList from "./ExpertProductList";
import {connect} from "react-redux";
import {expertFetch} from "../../actions/expert";
import {Helmet} from "react-helmet";
import {convertMetaDescription, convertMetaTitle} from "../../common/utils";

const mapStateToProps = state => ({
    ...state.expert,
    ...state.auth,
});

const mapDispatchToProps = {
    expertFetch
}

class Expert extends React.Component {

    componentDidMount() {
        this.props.expertFetch(this.props.match.params.id);
    }

    componentDidUpdate() {
        if (this.props.error) {
            if (!this.props.isFetching) {
                this.props.history.push(`/404`);

            } else {
                setInterval(() => {
                    this.props.history.push(`/404`);
                }, 15000); //lagi...
            }
        }
    }

    render() {
        const {experts, auth, isAuthenticated} = this.props;
        if (!experts)
            return (
                <Container className={"spinner-container"}>
                    <Spinner animation="grow"/>
                </Container>);

        return (
            <Container fluid>
                <Helmet>
                    <title>{convertMetaTitle(experts.nameAndSurname)}</title>
                    <meta name="description" content={convertMetaDescription(experts.shortDescription)}/>
                </Helmet>
                <Row className={"expert-index-return-btn"}>
                    <Container>
                        <a href={`/ekspert-list`}>Wszyscy eksperci</a>
                    </Container>
                </Row>
                <Row>
                    <Container className={"expert-index-description-container"}>
                        <Card>
                            <Row>
                                <Col className={"expert-index-image"} xs={12} sm={6} lg={5}>
                                    {experts.imageUrl ?
                                        <Image title={experts.nameAndSurname} alt={experts.nameAndSurname}
                                               src={experts.imageUrl}/> :
                                        <Image title={experts.nameAndSurname} alt={experts.nameAndSurname}
                                               src={"/expert-default.png"}/>}
                                </Col>
                                <Col className={"expert-index-content"} xs={12} sm={6} lg={7}>
                                    <Card.Body>
                                        <h5>Ekspert</h5>
                                        <h1>{experts.nameAndSurname}</h1>
                                        {experts.academicDegree
                                            ? <h5 className={"expert-title"}>{experts.academicDegree}</h5>
                                            : ''
                                        }
                                        <Card.Text dangerouslySetInnerHTML={{__html: experts.description}}/>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </Row>
                <ExpertProductList auth={auth} isAuthenticated={isAuthenticated} experts={experts}/>
            </Container>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Expert);