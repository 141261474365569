import React from 'react';
import {Button, Container, Row, Col} from "react-bootstrap";
import ExpertContainer from "../Modules/Experts/Sliders/ExpertSliderContainer";
import {Helmet} from "react-helmet";
import {convertMetaDescription, convertMetaTitle} from "../../common/utils";
import HomeProfilerContainer from "../Modules/Home/Profiler/HomeProfilerContainer";
import HomePromotionContainer from "../Modules/Home/Banners/HomePromotionContainer";
import AwardsSlider from "../Modules/AwardsBanner/AwardsSlider";
import connect from "react-redux/es/connect/connect";
import PromotionContainer from "../Modules/PromotionBanner/PromotionContainer";
import ListContainer from "../Modules/Index/Lists/ListContainer";
import AdditionalListContainer from "../Modules/Index/Lists/AdditionalListContainer";
import HomeHeroBanner from "../Modules/Home/Banners/HomeHeroBanner";

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = {}


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCategory: [],
        };

    }

    render() {
        const {auth} = this.props;

        return (
            <Container className="home-main-container" fluid>
                <Helmet>
                    <title>{convertMetaTitle('Najlepsze szkolenia dla profesjonalistów')}</title>
                    <meta name="description" content={convertMetaDescription('Educado - świat najlepszych szkoleń. ' +
                        'Educado to platforma ze szkoleniami online dla profesjonalistów. Educado to szkolenia dla księgowych, ' +
                        'dla kadrowych, dla działów płac, dla HR, dla biur rachunkowych, dla ochrony środowiska, dla transportu, ' +
                        'z RODO, z zamówień publicznych, dla pracowników oświaty (nauczycieli, dyrektorów, logopedów) i innych. ' +
                        'Korzystając z abonamnetu Educado, szkolisz się wtedy, kiedy chcesz, zupełnie bezpiecznie i komfortowo ' +
                        'przy ekranie swojego komputera. Dzięki ekspertom Educado jesteś na wszystko przygotowany. ' +
                        'W Educado możesz sprawdzać swoją wiedzę i generować certyfikaty potwierdzające udział w szkoleniach.')}/>
                </Helmet>
                <Row>
                    <Container fluid className={"index-page-main-container"}>
                        <div className={'container-content-wrapper'}>
                            <Row>
                                <div className={'position-absolute index-background'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1920" height="711"
                                         viewBox="0 0 1920 711" fill="none">
                                        <path
                                            d="M-149 716C-149 716 -97.9323 562.096 78.55 587.117C220.855 609.463 617.797 701.828 796.5 697C894.351 697 993.844 658.894 1019.29 586C1044.75 513.106 1042.9 500.192 1025.5 415.5C1008.1 330.808 973.869 155.47 1210 127.5C1446.13 99.5303 1554.97 213.31 1786.5 197.5C2018.03 181.69 2046 100.798 2046 100.798H2068L2020.23 0L-149 8.68741e-07V716Z"
                                            fill="#232936"/>
                                    </svg>

                                </div>
                            </Row>
                            <HomeHeroBanner {...this.props}/>
                            <Container className={'container-content-wrapper'}>
                                <div className={"container-content"}>
                                    {auth && auth.isAuthenticated ? '' : <HomePromotionContainer/>}
                                    <Container className={"filter-container"}>
                                        <span>Filtruj po kategorii</span>
                                        <Button
                                            key={71}
                                            id={71}
                                            label={'Oświata'}
                                            variant={""}
                                            onClick={() => this.setState({currentCategory: [71]})}
                                            className={`btn-filter cat-71 ${this.state.currentCategory.includes(71) ? 'active' : 'non-active'}`}>
                                            Oświata
                                        </Button>
                                        <Button
                                            key={14}
                                            id={14}
                                            label={'Księgowość i kadry'}
                                            variant={""}
                                            onClick={() => this.setState({currentCategory: [14]})}
                                            className={`btn-filter cat-14 ${this.state.currentCategory.includes(14) ? 'active' : 'non-active'}`}>
                                            Księgowość i kadry
                                        </Button>

                                        <Button
                                            label={'Pokaż wszystkie'}
                                            variant={""}
                                            onClick={() => this.setState({currentCategory: []})}
                                            className={`btn-filter ${this.state.currentCategory.length === 0 ? 'active' : 'non-active'}`}>
                                            Pokaż wszystkie
                                        </Button>
                                    </Container>
                                    <Row>
                                        <Container>
                                            <h3 className={"headline-small text-center home-products-header"}>Najnowsze
                                                szkolenia</h3>
                                        </Container>
                                    </Row>
                                    <ListContainer {...this.props} type={['online']}
                                                   currentCategory={this.state.currentCategory}/>

                                    <Row>
                                        <Container>
                                            <h3 className={"headline-small text-center home-products-header"}>Najnowsze
                                                webinary</h3>
                                        </Container>
                                    </Row>
                                    <AdditionalListContainer {...this.props}
                                                             type={['webinar', 'webinarpaid']}
                                                             currentCategory={this.state.currentCategory}/>
                                </div>
                            </Container>
                        </div>
                        <Row>
                            <PromotionContainer/>
                            <ExpertContainer/>
                            <AwardsSlider/>
                        </Row>
                    </Container>
                </Row>
            </Container>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);