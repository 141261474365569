import React from 'react';
import striptags from 'striptags';
import {Button, Carousel, Col, Container, Row} from "react-bootstrap";
import {parseTrainingTime, strNormalize} from "../../../../../common/utils";


const TrainingSliderItem = props => {

    const {product, auth, id} = props;

    return (
        <Col lg={6} className={"slider-details-container"}>
            <Container className="sliders-details-container-content">
                <div className="wrapper">
                    <div className="slider-item-type"><span>Szkolenie</span></div>
                    {product.categories ?
                        <div
                            className={`slider-category-item-container category${id}`}>{product.categories.map((category, key) => (
                            <span className="home-product-category-item" key={key}>{category.name}</span>

                        ))}</div>
                        : ''
                    }
                    <h2>{product.name}</h2>
                    {product.experts ?
                        <h3>Ekspert: {product.experts.map((expert, key) => (
                            <a className="d-inline-block"
                               href={`/ekspert/${strNormalize(expert.name)}-${strNormalize(expert.surname)}/${expert.id}`}
                               title={`${expert.name} ${expert.surname}`}
                               key={key}>&nbsp;{expert.name} {expert.surname}{product.experts.length > key + 1 && product.experts.length > 1 ? ', ' : ''}</a>

                        ))}</h3>
                        : ''
                    }
                    {product.experts ? <p className={'expert-desc'}>{product.experts[0].academicDegree}</p> : ''}
                    <div className={'slider-details-text'}>
                        <div className={"slider-text-info"}>
                            {id === 71 ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                    <path d="M17.5 2.5H12.5C8.40096 2.5 6.35145 2.5 4.89864 3.51727C4.36114 3.89362 3.89362 4.36114 3.51727 4.89864C2.5 6.35145 2.5 8.40096 2.5 12.5C2.5 16.599 2.5 18.6485 3.51727 20.1014C3.89362 20.6389 4.36114 21.1064 4.89864 21.4827C6.35145 22.5 8.40096 22.5 12.5 22.5H17.5C21.599 22.5 23.6485 22.5 25.1014 21.4827C25.6389 21.1064 26.1064 20.6389 26.4827 20.1014C27.5 18.6485 27.5 16.599 27.5 12.5C27.5 8.40096 27.5 6.35145 26.4827 4.89864C26.1064 4.36114 25.6389 3.89362 25.1014 3.51727C23.6485 2.5 21.599 2.5 17.5 2.5Z" stroke="#4AA5D8" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M18.125 27.5L17.7306 26.9764C16.8416 25.7961 16.6211 23.993 17.1835 22.5M11.875 27.5L12.2694 26.9764C13.1584 25.7961 13.3789 23.993 12.8165 22.5" stroke="#4AA5D8" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M8.75 27.5H21.25" stroke="#4AA5D8" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M10 17.5C12.2981 14.2557 17.6485 14.0788 20 17.5M17.5 10C17.5 11.3807 16.3807 12.5 15 12.5C13.6193 12.5 12.5 11.3807 12.5 10C12.5 8.61929 13.6193 7.5 15 7.5C16.3807 7.5 17.5 8.61929 17.5 10Z" stroke="#4AA5D8" strokeWidth="1.5" strokeLinecap="round"/>
                                </svg> :
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                    <path d="M17.5 2.5H12.5C8.40096 2.5 6.35145 2.5 4.89864 3.51727C4.36114 3.89362 3.89362 4.36114 3.51727 4.89864C2.5 6.35145 2.5 8.40096 2.5 12.5C2.5 16.599 2.5 18.6485 3.51727 20.1014C3.89362 20.6389 4.36114 21.1064 4.89864 21.4827C6.35145 22.5 8.40096 22.5 12.5 22.5H17.5C21.599 22.5 23.6485 22.5 25.1014 21.4827C25.6389 21.1064 26.1064 20.6389 26.4827 20.1014C27.5 18.6485 27.5 16.599 27.5 12.5C27.5 8.40096 27.5 6.35145 26.4827 4.89864C26.1064 4.36114 25.6389 3.89362 25.1014 3.51727C23.6485 2.5 21.599 2.5 17.5 2.5Z" stroke="#FDD26E" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M18.125 27.5L17.7306 26.9764C16.8416 25.7961 16.6211 23.993 17.1835 22.5M11.875 27.5L12.2694 26.9764C13.1584 25.7961 13.3789 23.993 12.8165 22.5" stroke="#FDD26E" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M8.75 27.5H21.25" stroke="#FDD26E" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M10 17.5C12.2981 14.2557 17.6485 14.0788 20 17.5M17.5 10C17.5 11.3807 16.3807 12.5 15 12.5C13.6193 12.5 12.5 11.3807 12.5 10C12.5 8.61929 13.6193 7.5 15 7.5C16.3807 7.5 17.5 8.61929 17.5 10Z" stroke="#FDD26E" strokeWidth="1.5" strokeLinecap="round"/>
                                </svg>
                            }
                            <p>Oglądaj w dowolnym momencie</p>
                        </div>
                        <div className={"slider-text-info"}>
                            {id === 71 ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                     viewBox="0 0 30 30" fill="none">
                                    <path
                                        d="M15 7.5V15L20 17.5M27.5 15C27.5 21.9036 21.9036 27.5 15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15Z"
                                        stroke="#4AA5D8" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                     viewBox="0 0 30 30" fill="none">
                                    <path
                                        d="M15 7.5V15L20 17.5M27.5 15C27.5 21.9036 21.9036 27.5 15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15Z"
                                        stroke="#FDD26E" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                </svg>
                            }
                            <p>Czas trwania: {parseTrainingTime(product.videosLength)}</p>
                        </div>

                    </div>

                </div>
                <div className={"slider-button-wrapper"}>
                {auth && auth.isAuthenticated ?
                    <div className={'slider-button-container justify-content-end'}>
                        <Button
                            className="btn-watch educado-btn educado-btn-full"
                            href={`/${strNormalize(product.type)}/${strNormalize(product.name)}/${product.id}`}
                            title={product.name}>Oglądaj</Button>
                    </div> :
                    <div className={'slider-button-container'}>
                        <Button
                            href={`/${strNormalize(product.type)}/${strNormalize(product.name)}/${product.id}`}
                            title={product.name} className={"educado-btn-text"}>
                            Więcej o szkoleniu
                        </Button>
                        <Button
                            className="educado-btn educado-btn-full"
                            href={id === 71 ? `/oswiata/abonament` : `/ksiegowosc-i-kadry/abonament`}
                            title={product.name}>Kup w abonamencie</Button>
                    </div>}
                </div>
            </Container>
        </Col>
    )
}

export default (TrainingSliderItem);