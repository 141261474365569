import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {convertMetaDescription, convertMetaTitle, getBreadcrumb} from "../../common/utils";


class Thx extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'codeSummary',
            breadcrumb: [
                {'type': 'codeForm', 'name': 'KROK 1. Kod kuponu'},
                {'type': 'codeAccount', 'name': 'KROK 2. Rejestracja konta'},
                {'type': 'codeSummary', 'name': 'KROK 3. Podsumowanie'}
            ]
        }
    }


    render() {

        return (
            <>
                <Helmet>
                    <title>{convertMetaTitle('Rejestracja kodu podziękowanie')}</title>
                    <meta name="description"
                          content={convertMetaDescription('Dziękujemy za rejestrację kodu educado.')}/>
                </Helmet>
                <Container fluid>
                    <Row>
                        <Container className={"affiliate-code-container"}>
                            <Row>
                                <Col xs={{span: 12, order: 1}} lg={{span: 9, order: 1}}
                                     className={"affiliate-code-content-thx"}>
                                    {getBreadcrumb(this.state.breadcrumb, this.state.type, 'reg-breadcrumb code-breadcrumb')}
                                    <h1>Potwierdzenie rejestracji</h1>
                                    <p>Rejestracja została zakończona, a Twoje konto na platformie Educado.pl jest
                                        aktywne.
                                        <br/><br/>
                                        <strong>Od teraz, przez cały czas trwania abonamentu:</strong><br/><br/>
                                        <ul>
                                            <li>masz dostęp bazy szkoleń online zgodnej z posiadanym przez Ciebie kodem
                                                dostępu.
                                            </li>
                                            <li>możesz wykonywać testy, aby sprawdzić swoją wiedzę i wygenerować
                                                certyfikat poświadczający udział w szkoleniu online.
                                            </li>
                                        </ul>
                                    </p>

                                </Col>
                                <Col xs={{span: 12, order: 2}} lg={{span: 3, order: 2}}
                                     className={"affiliate-code-content-right affiliate-code-content-right-thx"}>
                                    <p> W razie pytań skontaktuj
                                        się z naszym Centrum Obsługi Klienta:<br/> Od poniedziałku do piątku w godz
                                        8.00–16.00
                                        <br/>infolinia: 22 518 29 29<br/>e-mail: <a href="mailto:cok@wip.pl"
                                                                                    title="mail cok">cok@wip.pl</a>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Row>
                </Container>
            </>
        )

    }

}

export default Thx;