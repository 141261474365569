import {combineReducers} from "redux";
import {product} from "./reducers/product";
import {category} from "./reducers/category";
import {expert} from "./reducers/expert";
import {reducer as formReducer} from 'redux-form';
import {auth} from "./reducers/auth";
import {routerReducer} from "react-router-redux";
import {profile} from "./reducers/profile";
import {baner} from "./reducers/baner";
import {quiz} from "./reducers/quiz";
import {register} from "./reducers/register";
import {profileVideo} from "./reducers/profileVideo";
import {search} from "./reducers/search";
import {profiler} from "./reducers/profiler";
import {affiliateCode} from "./reducers/affiliateCode";
import {webinar} from "./reducers/webinar";
import {closedTrainings} from "./reducers/closedTrainings";
import {multiTable} from "./reducers/multiTable";

export default combineReducers({
    product,
    category,
    expert,
    auth,
    profiler,
    closedTrainings,
    router: routerReducer,
    form: formReducer,
    profile,
    baner,
    affiliateCode,
    quiz,
    register,
    profileVideo,
    search,
    webinar,
    multiTable,
})