import React from 'react';
import {Button, Container, Row} from "react-bootstrap";
import {convertMetaDescription, convertMetaTitle} from "../../common/utils";
import Helmet from "react-helmet";
import ListContainer from "../Modules/Index/Lists/ListContainer";

const NotFound = () => {

    return (
        <>
            <Helmet>
                <title>{convertMetaTitle('Błąd')}</title>
                <meta name={"description"} content={convertMetaDescription("Brak strony")}/>
                <meta name="robots" content="noindex" />
            </Helmet>
            <Container fluid>
                <Row className="mt-5 mb-5">
                    <Container className={"mb-4 mt-5 notfound-desc"}>
                        <div className={"image-container"}><img src="./static/404.svg" alt="404"/></div>
                        <h3>Niestety nie znaleźliśmy tej strony</h3>
                        <Button className="btn-contact" href={"/"}>Idź do strony głównej</Button>
                    </Container>
                    <Container>
                        <ListContainer hideButton={true} type={['online']}
                                       currentCategory={[]}/>
                    </Container>
                </Row>
            </Container>
        </>
    );

}

export default NotFound;