import React from "react";

class ProductFavorite extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            favoriteProduct: false
        };
    }

    componentDidMount() {
        const {isUserSaved} = this.props.children.product;
        if (isUserSaved) {
            this.setState({favoriteProduct: isUserSaved})
        }
    }

    actionProductToFavorites(productId, favoriteProduct) {
        const {profileDeleteProduct, profileSavedProduct} = this.props.children;
        if (favoriteProduct) {
            profileDeleteProduct(productId);
            this.setState({favoriteProduct: false});
        } else {
            profileSavedProduct(productId);
            this.setState({favoriteProduct: true});
        }
    }

    render() {

        const {product} = this.props.children;

        return (
            <>
                <div className="product-tools float-right">
                    <button title={!this.state.favoriteProduct ? "Dodaj do ulubionych" : "Usuń z ulubionych"} className="btn" onClick={
                        product && this.actionProductToFavorites.bind(this, product.id, this.state.favoriteProduct) }>
                        <i className={!this.state.favoriteProduct ? 'non-active' : 'active'}/>
                    </button>
                </div>
            </>
        )
    }

}

export default ProductFavorite;