import {requests} from "../agent";
import {
    CATEGORY_REQUEST,
    CATEGORY_RECEIVED,
    CATEGORY_LIST_RECEIVED,
    CATEGORY_PRODUCTS_LIST_RECEIVED,
    CATEGORY_ERROR,
    CATEGORY_PRODUCTS_LIST_TAG_FILTER,
    CATEGORY_ANCESTORS,
    CATEGORY_DESCENDANTS,
    CATEGORY_ALL,
    CATEGORY_MAIN_ID
} from "../constants";
import queryString from "query-string";

/**
 * set category api request
 * @returns {{type: string}}
 */
export const categoryRequest = () => ({
    type: CATEGORY_REQUEST,
});

/**
 * set category data
 * @param data
 * @returns {{data: *, type: string}}
 */
export const categoryReceived = (data) => ({
    type: CATEGORY_RECEIVED,
    data
});

/**
 * set categoryList data
 * @param data
 * @returns {{data: *, type: string}}
 */
export const categoryListReceived = (data) => ({
    type: CATEGORY_LIST_RECEIVED,
    data
});

/**
 * set Category Ancestors data
 * @param list
 * @returns {{type: string, list: *}}
 */
export const categoryAncestorsReceived = (list) => ({
    type: CATEGORY_ANCESTORS,
    list
});

/**
 * set category descendants data
 * @param list
 * @returns {{type: string, list: *}}
 */
export const categoryDescendantsReceived = (id, list) => ({
    type: CATEGORY_DESCENDANTS,
    list,
    id
});

/**
 * set category aLL
 * @param id
 * @param list
 * @returns {{id: *, type: string, list: *}}
 */
export const categoryAllReceived = (id, list) => ({
    type: CATEGORY_ALL,
    id,
    list
});

/**
 * set category product list
 * @param list
 * @returns {{type: string, list: *}}
 */
export const categoryProductsListReceived = (list) => ({
    type: CATEGORY_PRODUCTS_LIST_RECEIVED,
    list
});

/**
 * set category product list filter by tag
 * @param queryParams
 * @returns {{queryParams: *, type: string}}
 */
export const categoryProductsListTagFilter = (queryParams) => ({
    type: CATEGORY_PRODUCTS_LIST_TAG_FILTER,
    queryParams
});

/**
 * set category error
 * @param error
 * @returns {{type: string, error: *}}
 */
export const categoryError = (error) => ({
    type: CATEGORY_ERROR,
    error
});

/**
 * get category by id
 * @param id
 * @returns {function(*): Q.Promise<any>}
 */
export const categoryFetch = (id) => {
    return (dispatch) => {
        dispatch(categoryRequest());
        return requests.get(`/categories/${id}`)
            .then(response => dispatch(categoryReceived(response)))
            .catch(error => dispatch(categoryError(error)))
    }
};

/**
 * get category all
 * @returns {function(*): Q.Promise<any>}
 */
export const categoryListFetch = () => {
    return (dispatch) => {
        dispatch(categoryRequest());
        return requests.get('/categories')
            .then(response => dispatch(categoryListReceived(response)))
            .catch(error => dispatch(categoryError(error)))
    }
};

/**
 * get category products list by product id and query params
 * @param id
 * @param queryParams
 * @returns {function(*): Q.Promise<any>}
 */
export const categoryProductsListFetch = (id, queryParams = null) => {
    let url = `/categories/${id}/nested_trainings`;
    if (queryParams) {
        url += `?${queryString.stringify(queryParams, {arrayFormat: 'bracket'})}`;
    }
    return (dispatch) => {
        dispatch(categoryRequest());
        return requests.get(url)
            .then(response => dispatch(categoryProductsListReceived(response)))
            .catch(error => dispatch(categoryError(error)))
    }
};

/**
 * get category ancestor, start for id params
 * @param id
 * @returns {function(*): Q.Promise<any>}
 */
export const categoryAncestorsFetch = (id) => {
    return (dispatch) => {
        dispatch(categoryRequest());
        return requests.get(`/categories/ancestors/${id}`)
            .then(response => dispatch(categoryAncestorsReceived(response)))
            .catch(error => dispatch(categoryError(error)))
    }
};

/**
 * get category descendants, finish to id params
 * @param id
 * @returns {function(*): Q.Promise<any>}
 */
export const categoryDescendantsFetch = (id) => {
    if (!id) {
        id = CATEGORY_MAIN_ID;
    }
    return (dispatch) => {
        dispatch(categoryRequest());
        return requests.get(`/categories/descendants/${id}`)
            .then(response => dispatch(categoryDescendantsReceived(id, response)))
            .catch(error => dispatch(categoryError(error)))
    }
};

/**
 * get category all
 * @param id
 * @returns {function(*): Q.Promise<any>}
 */
export const categoryAllFetch = (id = null) => {
    if (!id) {
        id = CATEGORY_MAIN_ID;
    }
    return (dispatch) => {
        dispatch(categoryRequest());
        return requests.get(`/categories/descendants/${id}`)
            .then(response => dispatch(categoryAllReceived(id, response)))
            .catch(error => dispatch(categoryError(error)))
    }
};
