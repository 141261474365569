import React from "react";
import {Pagination} from "react-bootstrap";

class ExpertListPagination extends React.Component {

    constructor(props) {
        super(props);
        const {pageCount} = this.props;
        this.elements = [];
        for (let i = 1; i <= pageCount; i++) {
            this.elements.push(i);
        }
    }

    render() {
        const {currentPage, setPage, prevPage, nextPage} = this.props;

        return (
            <Pagination>
                <Pagination.Prev onClick={prevPage}/>
                {this.elements && this.elements.map(page => {
                    const onClick = () => {
                        setPage(page);
                    };
                    return (
                        <Pagination.Item key={page} className={`${currentPage === page && "active"}`}
                                         onClick={onClick}>{page}</Pagination.Item>
                    );
                })}
                <Pagination.Next onClick={nextPage}/>
            </Pagination>
        )
    }
}

export default ExpertListPagination;