import React from 'react';
import {Helmet} from "react-helmet";


class UserCom extends React.Component {

    render() {
        const {isAuthenticated, userData} = this.props.children;
        let civ;
        if (isAuthenticated){
            const now = new Date().toISOString();
            civ = {
                'apiKey': 'SJhl3j',
                'name': `${userData.name} ${userData.surname}`,
                'user_id': `${userData.email}`,
                'email': `${userData.email}`,
                'status': '2',
                'date_attr': now
            }
        } else {
            civ = {
                'apiKey': 'SJhl3j'
            }
        }
        const civJson = JSON.stringify(civ);
        return (
            <>
                <Helmet>
                    <script data-cfasync="false" type="text/javascript">
                        {'window.civchat = ' + civJson}
                    </script>
                </Helmet>
            </>
        )
    }
}

export default (UserCom);