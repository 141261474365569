import React from 'react';
import {Alert, Button, Card, Col, Row, Spinner} from "react-bootstrap";
import dateFormat from "dateformat";
import {strNormalize} from "../../../common/utils";
import striptags from "striptags";

class WebinarStartedList extends React.Component {

    componentDidMount() {
        const {profileWebinarActiveFetch, isFetching} = this.props.children;
        if (!isFetching) {
            profileWebinarActiveFetch();
        }
    }


    render() {
        const {activeTrainings, alert} = this.props.children;

        if (!activeTrainings)
            return (<Spinner animation="grow"/>);

        if (activeTrainings.length === 0)
            return (<Alert variant="info">Brak produktów!</Alert>);

        return (
            <Row>
                {activeTrainings && activeTrainings.map((product, index) => (
                    <Col key={index} lg={6} xl={4} md={6} className={'mt-4 list-item-container'}>

                        <div className={"index-list-item"}>
                            <div className={"card-img-top"} width={"120"} height={"147"}
                                 style={{backgroundImage: `url(${product.imageUrl})`}}/>
                            <div
                                className={`webinar-labels webinar-paid-labels category-${product.categories[0].id} ${product.archive ? 'with-overdue' : ''}`}>
                                <div
                                    className={"archived-label"}>Webinar live</div>
                                <div className={"archived-label"}>Chat
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16"
                                         fill="none">
                                        <path
                                            d="M13.125 7.68752C13.1272 8.51244 12.9344 9.3262 12.5625 10.0625C12.1215 10.9449 11.4436 11.687 10.6047 12.2058C9.76572 12.7246 8.7989 12.9996 7.8125 13C6.98758 13.0022 6.17382 12.8094 5.4375 12.4375L1.875 13.625L3.0625 10.0625C2.69058 9.3262 2.49785 8.51244 2.5 7.68752C2.50038 6.70112 2.77538 5.7343 3.2942 4.89536C3.81302 4.05642 4.55516 3.3785 5.4375 2.93752C6.17382 2.5656 6.98758 2.37287 7.8125 2.37502H8.125C9.42772 2.44689 10.6582 2.99674 11.5807 3.9193C12.5033 4.84187 13.0531 6.0723 13.125 7.37502V7.68752Z"
                                            stroke="#232936" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                {product.categories ?
                                    <div
                                        className={`category-label`}>{product.categories.map((category, index) => (

                                        <span
                                            key={index}>{category.name} {product.categories.length > index + 1 && product.categories.length > 1 ? ' | ' : ''}</span>

                                    ))}</div>
                                    : ''
                                }
                                {product.archive ? <div className={"archived-label overdue"}>Archiwalne</div> : ''}
                            </div>
                            <Card.Body>
                                <Card.Title
                                    title={product.name}>{product.name.length > 60 ? product.name.substring(0, 60) + '...' : product.name}</Card.Title>
                                {product.experts ?

                                    <div className={"expert-wrapper"}>{product.experts.map((expert, index) => (
                                        <div className={"webinar-item-expert"}
                                             key={index}>&nbsp;{expert.name} {expert.surname}{product.experts.length > index + 1 && product.experts.length > 1 ? ', ' : ''}</div>
                                    ))}</div>
                                    : <div className={"webinar-item-expert"}></div>}
                                <div className={"webinar-dates-container card-text"}>

                                    {product && product.timetable.map((date, index) => (
                                        <div key={index}
                                             className={`${date.expired ? 'webinar-date-item expired' : 'webinar-date-item'}`}>
                            <span>
                                {product.categories[0].id === 71 ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                         fill="none">
                                        <rect x="1.5" y="1.5" width="13" height="13" rx="6.5" stroke="#4AA5D8"
                                              strokeWidth="3"/>
                                        <rect x="5" y="5" width="6" height="6" rx="3" fill="#4AA5D8"/>
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                         fill="none">
                                        <rect x="1.5" y="1.5" width="13" height="13" rx="6.5" stroke="#FDD26E"
                                              strokeWidth="3"/>
                                        <rect x="5" y="5" width="6" height="6" rx="3" fill="#FDD26E"/>
                                    </svg>
                                }
                                LIVE z chatem</span>
                                            <p>
                                <span
                                    className={"time"}>{dateFormat(Date.parse(date.eventStartDate), 'HH:MM')}</span>
                                                <span
                                                    className="date">{dateFormat(Date.parse(date.eventStartDate), 'dd-mm-yyyy')}</span>
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </Card.Body>
                            <Card.Text className="item-lead webinar-item-lead ">
                                {product.shortDescription ? <span>{striptags(product.shortDescription)}</span> : ''}

                            </Card.Text>
                            <div className={'button-wrapper'}>
                                <Button
                                    href={`/${strNormalize(product.subType)}/${strNormalize(product.name)}/${(product.id)}`}
                                    className="educado-btn educado-btn-black">Oglądaj</Button>
                            </div>
                        </div>

                    </Col>
                ))}
            </Row>
        );
    }
}

export default WebinarStartedList;