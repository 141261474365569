import React from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {convertMetaDescription, convertMetaTitle, getBreadcrumb} from "../../common/utils";
import {Field, reduxForm} from "redux-form";
import connect from "react-redux/es/connect/connect";
import {renderField} from "../../common/form";
import {registerCodeAttempt} from "../../actions/affiliateCode";

const mapDispatchToProps = {
    registerCodeAttempt
};

const mapStateToProps = state => ({
    ...state.affiliateCode,
});

class RegisterCode extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type: 'codeForm',
            code: '',
            focus: true,

            breadcrumb: [
                {'type': 'codeForm', 'name': 'KROK 1. Kod kuponu'},
                {'type': 'codeAccount', 'name': 'KROK 2. Rejestracja konta'},
                {'type': 'codeSummary', 'name': 'KROK 3. Podsumowanie'}
            ]
        }
        this.focus = this.focus.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const {valid, isFetching} = this.props;
        if (valid && !isFetching && valid === !prevProps.valid) {
            window.sessionStorage.setItem('code', this.state.code)
            window.location.reload(false);
        }
    }


    onSubmit(value) {
        const {registerCodeAttempt} = this.props;
        this.setState({'code': value.code})

        if (value.code && this.state.focus) {
            this.setState({focus: false});
        }

        return registerCodeAttempt(
            value.code
        );
    }

    focus() {
        this.setState({focus: true});
    }

    render() {
        const {handleSubmit, valid, message, isFetching} = this.props;

        return (
            <>
                <Helmet>
                    <title>{convertMetaTitle('Rejestracja kodu krok pierwszy')}</title>
                    <meta name="description"
                          content={convertMetaDescription('Rejestracja kodu kuponu uprawniającego do korzystania z zasobów educado.pl')}/>
                </Helmet>
                <Container fluid>
                    <Row>
                        <Container className={"affiliate-code-container"}>
                            <Row>
                                <Col xs={{span: 12, order: 1}} lg={{span: 9, order: 1}}
                                     className={"affiliate-code-register-code"}>
                                    {getBreadcrumb(this.state.breadcrumb, this.state.type, 'reg-breadcrumb code-breadcrumb')}

                                    <h1>Tutaj aktywuj dostęp do platformy szkoleniowej Educado</h1>
                                    <Form id={'affiliate-code-form'}
                                          onFocus={this.onFocus}
                                          onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                                        <div
                                            className={valid === false && message && !this.state.focus && !isFetching ? 'error field-wrapper' : 'field-wrapper'}>
                                            {valid === false && message && !this.state.focus && !isFetching ?
                                                <div className="alert alert-danger">{message}</div> : ''}
                                            <label>Kod kuponu: </label>
                                            <Field name="code" type="text" onFocus={this.focus} component={renderField}
                                                   placeholder="wpisz kod"/>

                                        </div>
                                        <Button className="login-btn" variant="primary" type="submit">Zastosuj
                                            kod</Button>
                                    </Form>

                                </Col>
                                <Col xs={{span: 12, order: 2}} lg={{span: 3, order: 2}}
                                     className={"affiliate-code-content-right affiliate-code-content-right-thx"}>
                                    <h2>Aktywuj kupon i zyskaj dostęp serwisu Educado!</h2><p> W razie pytań skontaktuj
                                    się z naszym Centrum Obsługi Klienta:<br/> Od poniedziałku do piątku w godz
                                    8.00–16.00
                                    <br/>infolinia: 22 518 29 29<br/>e-mail: <a href="mailto:cok@wip.pl"
                                                                                title="mail cok">cok@wip.pl</a>
                                </p>
                                </Col>
                            </Row>
                        </Container>
                    </Row>
                </Container>
            </>
        )

    }

}

export default reduxForm({
    form: 'RegisterCode'
})(connect(mapStateToProps, mapDispatchToProps)(RegisterCode));