import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {Row, Col, Container, Form, Modal} from "react-bootstrap";
import {connect} from 'react-redux';
import {profileChangePassword} from "../../actions/profile";
import {renderField} from "../../common/form";

const mapDispatchToProps = {
    profileChangePassword
};

class ProfilePasswordForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }

    showModal() {
        this.setState({show: true});
    }

    hideModal() {
        this.setState({show: false});
    }

    onSubmit(values) {
        const {profileChangePassword} = this.props;
        return profileChangePassword(
            values.oldPassword,
            values.newPassword,
            values.reNewPassword
        );
    }

    render() {
        const {form, handleSubmit, error, submitSucceeded} = this.props;

        if (submitSucceeded) {
            return (
                <>
                    <div className="alert alert-primary text-center">
                        <p>Hasło zostało zmienione.</p>
                    </div>
                </>
            )
        }

        return (
            <React.Fragment>
                <button className="btn btn-link" onClick={this.showModal.bind(this)}>Zmień hasło</button>
                <Modal className="login-modal" show={this.state.show} onHide={this.hideModal.bind(this)}>
                    <Container className="modal-content-container">
                        <Row>
                            <Modal.Header closeButton>
                                <img alt="logo" src="/logo.svg"/>
                            </Modal.Header>
                            <Modal.Body>
                                <Container>
                                    <Row>
                                        {error && <div className="alert alert-danger text-center">{error}</div>}
                                        <Col md={6} lg={4} xl={3} className={"form-container-content"}>
                                            <Form id={form}
                                                  onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                                                <Field name="oldPassword" placeholder="Aktualne hasło" type="password"
                                                       component={renderField}/>
                                                <Field name="newPassword" placeholder="Nowe hasło" type="password"
                                                       component={renderField}/>
                                                <Field name="reNewPassword" placeholder="Powtórz nowe hasło" type="password"
                                                       component={renderField}/>
                                                <button className="login-btn btn" variant="primary" type="submit">Zmień hasło</button>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Body>
                        </Row>
                    </Container>
                </Modal>
            </React.Fragment>
        )
    }
}

export default reduxForm({
    form: 'ProfilePasswordForm'
})(connect(null, mapDispatchToProps)(ProfilePasswordForm));