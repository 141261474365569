import React from 'react';
import {Alert, Button, Card, Col, Row, Spinner} from "react-bootstrap";
import dateFormat from "dateformat";
import {strNormalize} from "../../../common/utils";
import striptags from "striptags";

class ProductFavouriteList extends React.Component {

    componentDidMount() {
        const {profileSavedProductsFetch, isFetching} = this.props.children;
        if (!isFetching) {
            profileSavedProductsFetch();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {profileSavedProductsFetch, alert} = this.props.children;

        if (alert !== prevProps.children.alert) {
            profileSavedProductsFetch();
        }
    }

    deleteProductFavorite(productId) {
        this.props.children.profileDeleteProduct(productId);
    }

    render() {
        const {trainings, alert, categoryId} = this.props.children;

        if (!trainings)
            return (<Spinner animation="grow"/>);

        if (trainings.length === 0)
            return (<Alert variant="info">Brak produktów!</Alert>);

        return (
            <Row>
                {trainings && trainings.map((product, index) => (
                    <Col key={index} lg={6} xl={4} md={6} className={'mt-4 list-item-container favourite-list-container'}>
                        <div className={"index-list-item"}>
                            <div className={"card-img-top"} width={"120"} height={"147"}
                                 style={{backgroundImage: `url(${product.training.imageUrl})`}}/>
                            <div className={`webinar-labels category-${categoryId} ${product.training.archive ? 'with-overdue' : ''}`}>
                                <div
                                    className={"archived-label"}>Szkolenie
                                </div>
                                    <div
                                        className={`category-label`}>
                                        <span>{categoryId === 71 ? 'oświata' : 'księgowość i kadry'}</span>
                                   </div>


                                {product.training.archive ? <div className={"archived-label overdue"}>Archiwalne</div> : ''}
                            </div>
                            <Card.Body>
                                <Card.Title
                                    title={product.training.name}>{product.training.name.length > 68 ? product.training.name.substring(0, 65) + '...' : product.training.name}</Card.Title>
                                {product.training.experts ?

                                    <div className={"expert-wrapper"}>{product.training.experts.map((expert, index) => (
                                        <div className={"webinar-item-expert"}
                                             key={index}>&nbsp;{expert.name} {expert.surname}{product.training.experts.length > index + 1 && product.training.experts.length > 1 ? ', ' : ''}</div>
                                    ))}</div>
                                    : <div className={"webinar-item-expert"}></div>}

                                <div className={'item-info-container'}><p>Stan prawny
                                    na: <span>{dateFormat(product.training.legalStatusDate, 'dd.mm.yyyy')}</span></p></div>
                            </Card.Body>
                            <Card.Text className="item-lead">
                                {product.training.shortDescription ? <span>{striptags(product.training.shortDescription)}</span> : ''}

                            </Card.Text>
                                <div className={'button-wrapper'}>
                                    <Button href={`/${strNormalize(product.training.subType)}/${strNormalize(product.training.name)}/${(product.training.id)}`}
                                            className="educado-btn educado-btn-black">Oglądaj</Button>
                                </div>

                        </div>
                        {
                            alert
                                ? <Spinner animation="grow" variant="warning"/>
                                : <button
                                    className={"btn-profile-delete"}
                                    onClick={this.deleteProductFavorite.bind(this, product.training.id)}>
                                    usuń
                                </button>
                        }
                    </Col>
                ))}
            </Row>
        );
    }
}

export default ProductFavouriteList;