import React from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import {convertMetaDescription, convertMetaTitle} from "../../common/utils";
import Helmet from "react-helmet";

const Contact = () => {

    return (
        <>
            <Helmet>
                <title>{convertMetaTitle('kontakt')}</title>
                <meta name={"description"} content={convertMetaDescription("kontakt")}/>
            </Helmet>
            <Container className={"contact-container"} fluid>
                <Row className={"static-main-badge"}>
                    <Container>
                        <h1>Kontakt</h1>
                    </Container>
                </Row>
                <Row className={"static-second-badge"}>
                    <Container>
                        <Row>
                            <Col md={6}>
                                <h3>Jeśli nie znalazłaś/eś odpowiedzi na swoje pytania w zakładce „Pomoc”, napisz lub
                                    zadzwoń do nas. Postaramy się pomóc w każdej sprawie związanej dotyczącej
                                    korzystania z
                                    naszej platformy szkoleniowej.</h3>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row className={"contact-content-section"}>
                    <Container className={"contact-content-container"}>
                        <Row>
                            <Col lg={4}>
                                <Button href={"https://kontakt.wip.pl/"} className={"btn-contact"}>Napisz do
                                    nas</Button>
                                <h3>Zadzwoń<span>22 518 29 29</span></h3>
                                <p>Jesteśmy do państwa dyspozycji od poniedziałku do piątku w godzinach 8:00 - 16:00</p>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row className={"contact-legal-section"}>
                    <Container className={"contact-legal-container"}>
                        <Row>
                            <Col lg={3} md={4}>
                                <h3>Adres do korespondencji</h3>
                                <p>Wiedza i Praktyka sp.z o.o.</p>
                                <p>ul. Paderewskiego 167</p>
                                <p>05-278 Sulejówek</p>
                                <p>faks: 22 617 60 10</p>
                            </Col>
                            <Col lg={3} md={4}>
                                <h3>Dane Firmowe</h3>
                                <p>NIP: 526-19-92-256</p>
                                <p>REGON: 012725225</p>
                                <p>KRS: 0000098264</p>
                                <p>Nr rejestrowy BDO: 000008579</p>
                                <p>Sąd Rejonowy dla m.st. Warszawy</p>
                                <p>XIII Wydział Gospodarczy Krajowego Rejestru Sądowego</p>
                                <p>Kapitał zakładowy: 200 000 zł.</p>
                            </Col>
                            <Col lg={3} md={4}>
                                <h3>Siedziba firmy</h3>
                                <p>Wiedza i Praktyka sp.z o.o.</p>
                                <p>ul. Łotewska 9a</p>
                                <p>03-918 Warszawa</p>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>
        </>
    );

}

export default Contact;