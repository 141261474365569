import {
    PROFILE_COMPLETE_PRODUCTS_LIST_RECEIVED,
    PROFILE_ERROR,
    PROFILE_NEW_PASSWORD_RECEIVED,
    PROFILE_PASSWORD_REMINDER_RECEIVED,
    PROFILE_PROGRESS_PRODUCTS_LIST_RECEIVED,
    PROFILE_REQUEST,
    PROFILE_SAVED_PRODUCTS_DELETE,
    PROFILE_SAVED_PRODUCTS_LIST_RECEIVED,
    PROFILE_SAVED_PRODUCTS_SUCCESS,
    PROFILE_WEBINAR_ACTIVE_RECEIVED, PROFILE_WEBINAR_ACTIVE_REQUEST,
    PROFILE_WEBINAR_CERTIFICATE_RECEIVED,
    PROFILE_WEBINAR_CERTIFICATE_REQUEST,
    PROFILE_WEBINAR_ERROR,
    PROFILE_WEBINAR_RECEIVED,
    PROFILE_WEBINAR_REQUEST
} from "../constants";

export const profile = (state = {
    activeTrainings: null,
    data: null,
    trainings: null,
    isFetching: false,
    webTranings: null
}, action) => {
    switch (action.type) {
        case PROFILE_SAVED_PRODUCTS_SUCCESS:
            return {
                ...state,
                data: action.data
            };
        case PROFILE_REQUEST:
            state = {
                ...state,
                trainings: null,
                isFetching: true
            };
            return state;
        case PROFILE_COMPLETE_PRODUCTS_LIST_RECEIVED:
            return {
                ...state,
                completeProducts: action.data['hydra:member'] ? action.data['hydra:member'] : action.data,
                isFetching: false
            };
        case PROFILE_PROGRESS_PRODUCTS_LIST_RECEIVED:
            return {
                ...state,
                progressProducts: action.data['hydra:member'] ? action.data['hydra:member'] : action.data,
                isFetching: false
            };
        case PROFILE_SAVED_PRODUCTS_LIST_RECEIVED:
            return {
                ...state,
                alert: false,
                trainings: action.data['hydra:member'] ? action.data['hydra:member'] : action.data,
                favoriteProduct: action.data.isSaved ? action.data.isSaved : false,
                isFetching: false
            };
        case PROFILE_SAVED_PRODUCTS_DELETE:
            return {
                ...state,
                alert: 'Szkolenie zostało usunięte.',//action.data,
                isFetching: false
            };
        case PROFILE_PASSWORD_REMINDER_RECEIVED:
            return {
                ...state,
                success: action.data.success,
                isFetching: false
            };
        case PROFILE_NEW_PASSWORD_RECEIVED:
            return {
                ...state,
                success: action.data.success,
                isFetching: false
            };
        case PROFILE_ERROR:
            return {
                ...state,
                data: null,
                watchVideo: action.error && action.error.message === 'Not Found' ? null : 1,
                error: action.error,
                isFetching: false
            };
        case PROFILE_WEBINAR_REQUEST:
            return {
                ...state,
                trainings: null,
                isFetching: true
            };
        case PROFILE_WEBINAR_ACTIVE_REQUEST:
            return {
                ...state,
                activeTrainings: null,
                isFetching: true
            };
        case PROFILE_WEBINAR_RECEIVED:
            return {
                ...state,
                webTrainings: action.data,
                isFetching: false
            };
        case PROFILE_WEBINAR_ACTIVE_RECEIVED:
            return {
                ...state,
                activeTrainings: action.data,
                isFetching: false
            };
        case PROFILE_WEBINAR_CERTIFICATE_REQUEST:
            return {
                ...state,
                certificates: null,
                isFetching: true
            };
        case PROFILE_WEBINAR_CERTIFICATE_RECEIVED:
            return {
                ...state,
                certificates: action.data['hydra:member'] ? action.data['hydra:member'] : action.data,
                isFetching: false
            };
        case PROFILE_WEBINAR_ERROR:
            return {
                ...state,
                trainings: null,
                isFetching: false
            };
        default:
            return state;
    }
}