import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {convertMetaDescription, convertMetaTitle} from "../../common/utils";
import Helmet from "react-helmet";

const BecomeExpert = props => {

    return (
        <>
            <Helmet>
                <title>{convertMetaTitle('zostań ekspertem Educado')}</title>
                <meta name={"description"} content={convertMetaDescription("Zostań ekspertem Educado")}/>
            </Helmet>
            <Container className={"become-expert-container"} fluid>
                <Row className={"static-main-badge"}>
                    <Container>
                        <h1>Zostań Ekspertem Educado</h1>
                    </Container>
                </Row>
                <Row className={"static-second-badge"}>
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <h3>Masz w sobie wytrwałość, pasję i chęć do dzielenia się wiedzą? Szukasz nowej ścieżki
                                    rozwoju zawodowego? Otwórz „nowe drzwi” z Educado!</h3>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row className={"become-expert-definition"}>
                    <Container className={"become-expert-container"}>
                        <Row>
                            <Col lg={5}>
                                <h2>Dlaczego warto zostać Ekspertem Educado</h2>
                                <p>Ty też możesz dołączyć do grona Ekspertów Educado. Współpraca z Educado to nie tylko
                                    szansa na podbudowanie domowego budżetu. Dla niektórych to po prostu sposób na
                                    życie.
                                    Dla innych to ogromna szansa na rozwój osobisty. Educado nie tylko korzysta z wiedzy
                                    i
                                    doświadczenia swoich Ekspertów, ale również wspiera ich w rozwoju zawodowym.
                                    Pomagamy
                                    przełamywać największe bariery nie tylko początkującym, ale również „szkoleniowym
                                    wyjadaczom”. Razem realizujemy wyjątkowe w skali Polski wydarzenia online. Z
                                    ekspertami
                                    Educado łączy nas codzienna pasja w pozyskiwaniu i dzieleniu się wiedzą.</p>

                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row className={"three-steps-container-badge"}>
                    <Container>
                        <h3>3 proste kroki</h3>
                    </Container>
                </Row>
                <Row className={"three-steps-container-content"}>
                    <Container>
                        <Row>
                            <Col md={4} className={"three-steps-item"}>
                                <div className={"item-badge item-1"}></div>
                                <div className={"item-content"}>
                                    <p>Przygotuj swoje „Bio” oraz linki do wcześniejszych nagrań wideo (o ile takie
                                        posiadasz).</p>
                                </div>
                            </Col>
                            <Col md={4} className={"three-steps-item"}>
                                <div className={"item-badge item-2"}></div>
                                <div className={"item-content"}>
                                    <p>Sporządź listę tematów szkoleń i webinariów, które chciałbyś zrealizować z
                                        Educado.</p>
                                </div>
                            </Col>
                            <Col md={4} className={"three-steps-item"}>
                                <div className={"item-badge item-3"}></div>
                                <div className={"item-content"}>
                                    <p>Wyślij je na adres e-mail redakcja@educado.pl </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row className={"expert-contact-section"}>
                    <Container>
                        <Row>
                            <Col md={12} className={"partners-contact-content"}>
                                <h4>Napisz do nas</h4>
                                <h5>Kontakt w sprawie współpracy <a
                                    href="mailto:redakcja@educado.pl">redakcja@educado.pl</a></h5>
                            </Col>

                        </Row>
                    </Container>
                </Row>
            </Container>
        </>
    );

}

export default BecomeExpert;