import React from 'react';
import {Button, Carousel, Col, Container, Row, Spinner} from "react-bootstrap";
import Clock from "../../common/clock";
import {productListQuerySlider} from "../../actions/product";
import connect from "react-redux/es/connect/connect";
import {strNormalize} from "../../common/utils";
import dateFormat from "dateformat";
import striptags from "striptags";

const mapStateToProps = state => ({
    ...state.product,
});

const mapDispatchToProps = {
    productListQuerySlider
}

class PaidWebinarTimer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            typeFilter: {
                type: ['webinarpaid']
            },
            filterCategory: {category: []},

        };
    }

    componentDidMount() {
        this.props.productListQuerySlider(this.state.typeFilter, this.state.filterCategory, 3, 'nearest');

    }

    timerDate (item) {
        let tmp = []
        item.map((data) => {if (!data.expired) {tmp.push(data.eventStartDate)} })
        if (tmp.length === 0) {
          tmp.push(Date())
        }
        return (tmp[0])
    }

    render() {
        const {productQuerySList, isFetching} = this.props;

        if (isFetching) {
            return <Container className={"spinner-timer-container"}><Spinner animation="border"/></Container>
        }

        return (
            <Container>
                <Row className={"homepage-timer-container"}>
                    <Container className="home-timer-slider-container">
                        <Carousel interval={7000} >

                            {productQuerySList && productQuerySList.map((item, index) => (

                                <Carousel.Item key={index}>
                                    <Row>
                                        <Col lg={6} className={"homepage-timer-image"}>
                                            <img className={"img-responsive"}
                                                 src={item.imageUrl}
                                                 alt={item.name}/>
                                        </Col>
                                        <Col lg={6} className={"hompage-timer-details"}>
                                            <Container className="timer-details-container">
                                                <div className="homepage-timer-webinar-details paid-timer-webinar-details">
                                                    <div className="webinar-type-badge">webinar płatny</div>
                                                    <h2>{item.name}</h2>
                                                    {item.experts ?
                                                        <h3>Ekspert:{item.experts.map((expert, index) => (
                                                            <a className={"webinar-item-expert"}
                                                               href={`/ekspert/${strNormalize(expert.name)}-${strNormalize(expert.surname)}/${expert.id}`}
                                                               key={index}>&nbsp;{expert.name} {expert.surname}{item.experts.length > index + 1 && item.experts.length > 1 ? ', ' : ''}</a>
                                                        ))}</h3>
                                                        : ''
                                                    }
                                                    {item.shortDescription ? <p>{striptags(item.shortDescription)}</p> : '' }

                                                    <div className={"webinar-dates-container card-text"}>
                                                        {item && item.timetable.map((data, index) => (
                                                            <div key={index} className={data.expired ? 'webinar-date-item expired' : 'webinar-date-item'}>
                                                                <span>LIVE z czatem</span><p>
                                                                <span className={"time"}>{dateFormat(Date.parse(data.eventStartDate), 'HH:MM')}</span>
                                                                <span className={"date"}> | {dateFormat(Date.parse(data.eventStartDate), 'dd-mm-yyyy')}</span></p>
                                                            </div> : ''
                                                        ))}


                                                    </div>
                                                    <div className={"hompage-timer-component"}>
                                                        <span>Start za:</span>
                                                        <Clock deadline={this.timerDate(item.timetable)}/>
                                                    </div>
                                                    <div className={"price-wrapper"}>
                                                    <div className={"price-container webinar-paid-price"}><p><span>{item.additionalFields.priceGross.toFixed(2)}</span> zł brutto</p></div>
                                                    <Button
                                                        href={`/${strNormalize(item.type)}/${strNormalize(item.name)}/${item.id}`}
                                                        className="webinar-btn webinar-btn-white" type="submit">Kup teraz</Button></div>
                                                </div>
                                            </Container>
                                        </Col>
                                    </Row>
                                </Carousel.Item>
                            ))}

                        </Carousel>
                    </Container>
                </Row>
            </Container>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaidWebinarTimer);