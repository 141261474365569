import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import {parseTrainingTime, strNormalize} from "../../../common/utils";
import ProductVimeoPlayer from "../video/ProductVimeoPlayer";
import {deviceType} from "react-device-detect";
import dateFormat from "dateformat";
import {Helmet} from "react-helmet";
import ProductContent from "../ProductContent";
import RightSection from "../RightSection";
import striptags from "striptags";

class WebinarVideo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            'paused': false,
            'error': false,
            'vimeoId': null,
            'videoId': null,
        };
        this.setDeviceType();

    }

    setDeviceType() {
        switch (deviceType) {
            case 'browser':
                this.width = '815px';
                break;
            case 'tablet':
                this.width = '690px';
                break;
            case 'phone':
                this.width = '340px';
                break;
            default:
        }
    }

    handlePlayerPause() {
        this.setState({paused: true});
    }

    handlePlayerPlay() {
        this.setState({paused: false});
    }


    render() {
        const {product, auth} = this.props.children
        let tmp = false;
        const date = product.timetable.find(function (el) {
            return !el.expired
        });
        if (product.timetable) {
            product.timetable.forEach((el) => {
                if (!el.expired) {
                    tmp = true
                }
            });
        }
        return (
            <>
                <Container fluid
                           className={`webinar-wave-container webinar-video-container ${auth && auth.isAuthenticated ? strNormalize(product.categories[0].name) + '' : 'not-logged'}`}>
                    <Container className={"webinar-wave-container-content"}>
                        <Row>
                            <Col lg={3} md={12} className={'webinar-data'}><h1>{product.name}</h1>
                                <h2>Ekspert:

                                    {product.experts ?
                                        <>{product && product.experts.map((expert, key) => (
                                            <span key={key}>&nbsp;
                                                {expert.name} {expert.surname}{product.experts.length > key + 1 && product.experts.length > 1 ? ', ' : ''}
                                            </span>
                                        ))}
                                        </>
                                        : ''
                                    }
                                </h2>
                                <span
                                    className={"webinar-duration white"}>{parseTrainingTime(product.timetable[0].duration)}</span>
                                <div className={"webinar-dates-container"}>
                                    {product && product.timetable.map((date, index) => (
                                        <div key={index}
                                             className={date.expired ? 'webinar-date-item expired' : 'webinar-date-item'}>
                                            <span>{index === 0 ? <>1&nbsp;</> : index + 1} TERMIN</span><p>
                                            <span
                                                className={"time"}>{dateFormat(Date.parse(date.eventStartDate), 'HH:MM')}</span><span
                                            className="date">
                                             &nbsp;| {dateFormat(Date.parse(date.eventStartDate), 'dd-mm-yyyy')}</span>
                                        </p>
                                        </div>
                                    ))}
                                </div>
                                {/*{product.categories[0].name === "Oświata" ?*/}
                                {/*    <Button className={"webinar-btn reverse btn btn-primary"}*/}
                                {/*            href={"https://www.lp-wip.pl/szkola-2"}>Educado dla szkół</Button> : ''}*/}

                                {product.accessDenied ?
                                    <Button href={product.categories[0].name === "Oświata" ? "/oswiata/abonament" : '/ksiegowosc-i-kadry/abonament'}
                                            className={"webinar-btn webinar-btn-white"}>Kup w
                                        abonamencie</Button>
                                    : ''
                                }
                            </Col>
                            <Col lg={9} md={12}
                                 className={!product.accessExpired && !product.accessDenied && product.videos[0] ? "webinar-image" : "webinar-image webinar-image-denied"}>
                                {!product.accessExpired && !product.accessDenied && product.videos[0] ?
                                    <ProductVimeoPlayer
                                        className="video-box"
                                        width={this.width}
                                        video={product.videos[0].vimeoId}
                                        onPause={this.handlePlayerPause.bind(this)}
                                        onPlay={this.handlePlayerPlay.bind(this)}

                                    />
                                    :
                                    <>
                                        <div className={"webinar-image-badge"}>
                                            <div className={"webinar-image-badge-type"}>webinar bezpłatny</div>
                                            <div
                                                className={`webinar-image-badge-category badge-${strNormalize(product.categories[0].name)}`}>{product.categories[0].name}</div>
                                        </div>
                                        <div className={"opacity-background"} style={{width: `${this.width}`}}>
                                        </div>
                                        <h3>{product.accessDeniedMessage} {tmp ?
                                            <p>Start: {dateFormat(Date.parse(date.eventStartDate), 'HH:MM')} | {dateFormat(Date.parse(date.eventStartDate), 'dd.mm.yyyy')}</p> : ''}</h3>
                                        {product.imageUrl
                                            ? <img src={product.imageUrl} alt={product.name}/>
                                            : <img src={"/zaslepka.png"} alt={"webinar"}/>}
                                    </>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Container fluid className={"webinar-form-section webinar-video-details-section"}>
                    <Container>
                        <Row>
                            <Col className={"webinar-details-container"} lg={8} md={6}>
                                <Container className={"webinar-details-container-content"}>
                                    <ProductContent children={this.props.children}/>
                                </Container>
                            </Col>
                            <Col className={"webinar-form-container"} lg={4} md={6}>
                                <RightSection product={product}/>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                {product.ads && product.ads.map((item, index) => {
                    if (item.zone === "leaderboard") {
                        return (
                            <Container className={"text-center mb-5"} key={index}>
                                <div className={"product-banner banner-front"}>
                                    {item.url ? <a target={'_blank'} href={item.url} rel='noreferrer'>
                                        <img alt={item.name} src={item.imageUrl}/>
                                    </a> : ''}

                                    <Helmet>
                                        <script>{item.js}</script>
                                    </Helmet>
                                </div>
                            </Container>
                        )
                    }
                    return '';
                })}
            </>
        )
    }
}

export default WebinarVideo;
