import React from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {convertMetaDescription, convertMetaTitle, getBreadcrumb} from "../../common/utils";
import {Field, reduxForm} from "redux-form";
import connect from "react-redux/es/connect/connect";
import {codeRenderField} from "../../common/form";
import {registerAffiliateAccount} from "../../actions/affiliateCode";

export const acceptTerms = <div>Znam i akceptuję warunki <a href={"/regulamin-promocji.pdf"} target={"_blank"} rel={"noreferrer"}>Regulaminu
    promocji</a> oraz <a href="/regulamin" target={"_blank"}
                         rel={"noopener noreferrer"}>Regulaminu</a> i <a
    href="https://www.wip.pl/polityka-prywatnosci" target={"_blank"} rel={"noopener noreferrer"}>Polityki
    Prywatności </a><span> *</span></div>;
export const marketingAgreement = <div>Chcę otrzymywać informacje drogą elektroniczną i telefoniczną o dodatkowych
    promocjach,
    rabatach dotyczących produktów własnych WIP i partnerów WIP. Wiem, że moja zgoda może być wycofana w dowolnym
    momencie.</div>;

const mapDispatchToProps = {
    registerAffiliateAccount
};

class RegisterForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type: 'codeAccount',
            error: false,
            breadcrumb: [
                {'type': 'codeForm', 'name': 'KROK 1. Kod kuponu'},
                {'type': 'codeAccount', 'name': 'KROK 2. Rejestracja konta'},
                {'type': 'codeSummary', 'name': 'KROK 3. Podsumowanie'}
            ]
        }
    }

    onSubmit(values) {
        if (window.sessionStorage.getItem('code') === null) {
            window.location.reload(false);
        } else {
            const {registerAffiliateAccount} = this.props;
            let tmp = {
                name: values.name,
                surname: values.surname,
                email: values.email,
                phone: values.phone,
                position: values.position,
                acceptTerms: values.acceptTerms,
                marketingAgreement: values.marketingAgreement
            };
            return registerAffiliateAccount(tmp);
        }
    }

    render() {
        const {handleSubmit, error} = this.props;

        return (
            <>
                <Helmet>
                    <title>{convertMetaTitle('Rejestracja kodu krok drugi')}</title>
                    <meta name="description"
                          content={convertMetaDescription('Rejestracja kodu kuponu uprawniającego do korzystania z zasobów educado.pl')}/>
                </Helmet>
                <Container fluid>
                    <Row>
                        <Container className={"affiliate-code-container"}>
                            <Row>
                                <Col xs={{span: 12, order: 1}} lg={{span: 9, order: 1}}
                                     className={"affiliate-code-register-code"}>
                                    {getBreadcrumb(this.state.breadcrumb, this.state.type, 'reg-breadcrumb code-breadcrumb')}

                                    <h1>Rejestracja konta</h1>
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    <Form id={'affiliate-code-account-form'}
                                          onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                                        <Field name="name" type="text"
                                               component={codeRenderField} placeholder="Imię"/>
                                        <Field name="surname" type="text"
                                               component={codeRenderField} placeholder="Nazwisko"/>
                                        <Field name="email" type="text"
                                               component={codeRenderField} placeholder="E-mail"/>
                                        <Field name="phone"
                                               type="text"
                                               component={codeRenderField}
                                               placeholder="Numer telefonu"
                                               mask={{
                                                   pattern: "+48 999 999 999",
                                                   text: 'Prawidłowy format +48 999 999 999'
                                               }}
                                        />
                                        <Field name="position" type="text"
                                               component={codeRenderField} placeholder="Stanowisko"/>
                                        <div className={'marketing-terms'}>
                                            <Field name="acceptTerms" type="checkbox"
                                                   component={codeRenderField} label={acceptTerms}/>
                                            <Field name="marketingAgreement" type="checkbox"
                                                   component={codeRenderField} label={marketingAgreement}/>
                                        </div>
                                        <Button className="login-btn" variant="primary" type="submit">Załóż
                                            konto</Button>
                                    </Form>
                                </Col>
                                <Col xs={{span: 12, order: 2}} lg={{span: 3, order: 2}}
                                     className={"affiliate-code-content-right affiliate-code-content-right-thx"}>
                                    <h2>Aktywuj kupon i zyskaj dostęp serwisu Educado!</h2>
                                    <p> W razie pytań skontaktuj
                                        się z naszym Centrum Obsługi Klienta:<br/> Od poniedziałku do piątku w godz
                                        8.00–16.00
                                        <br/>infolinia: 22 518 29 29<br/>e-mail: <a href="mailto:cok@wip.pl"
                                                                                    title="mail cok">cok@wip.pl</a>
                                        <div className={"content-details"}>
                                            <h2>Dane do kuponu</h2>
                                            <h3>Dostęp do serwisu</h3>
                                            <ul className={"green-list"}>
                                                <li>Dostęp w ramach pakietu Agora</li>
                                            </ul>
                                            <img className={"mt-4"}  src={"./wyborcza-logo.png"} alt={"Wyborcza logo"}/>
                                        </div>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Row>
                </Container>
            </>
        )

    }

}

export default reduxForm({
    form: 'RegisterForm'
})(connect(null, mapDispatchToProps)(RegisterForm));