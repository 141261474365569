import React from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import LoginForm from "./LoginForm";
import PasswordReminderForm from "./PasswordReminderForm";
import NewPasswordForm from "./NewPasswordForm";

class Auth extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            type: 'LoginForm'
        }
    }

    componentDidMount() {
        if (this.props.match && this.props.match.params.USER_LOGIN) {
            this.setState({
                show: true,
                type: 'NewPasswordForm'
            })
        }
    }

    showModal() {
        this.setState({show: true});
    }

    hideModal() {
        if (this.state.type !== 'NewPasswordForm') {
            this.setState({show: false});
        } else {
            this.props.history.push('/')
        }
    }

    getContent() {

        switch (this.state.type) {
            case 'PasswordReminderForm':
                return (
                    <>
                        <PasswordReminderForm/>
                        <button
                            onClick={() => this.setState({type: 'LoginForm'})}
                            className="pass-reminder"
                        >Logowanie
                        </button>
                    </>
                );
            case 'NewPasswordForm':
                return (
                    <>
                        <NewPasswordForm params={this.props.match.params && this.props.match.params}/>
                    </>
                );
            default:
                return (
                    <>
                        <h3>Zaloguj</h3>
                        <LoginForm/>
                        <button
                            onClick={() => this.setState({type: 'PasswordReminderForm'})}
                            className="pass-reminder"
                        >Nie pamiętasz hasła?
                        </button>
                    </>
                );
        }

    }

    render() {
        const {params} = this.props;

        return (
            <>
                <Container className="modal-content-container">
                    <Row>
                        {params && params.link
                            ?
                            <Button className={'login-link'} onClick={this.showModal.bind(this)}>Zaloguj</Button>
                            :
                            <Button className="login-btn" onClick={this.showModal.bind(this)}>Zaloguj się
                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.8333 8.66669H3.16667C2.24619 8.66669 1.5 9.41288 1.5 10.3334V16.1667C1.5 17.0872 2.24619 17.8334 3.16667 17.8334H14.8333C15.7538 17.8334 16.5 17.0872 16.5 16.1667V10.3334C16.5 9.41288 15.7538 8.66669 14.8333 8.66669Z"
                                        fill="#232936"/>
                                    <path
                                        d="M4.83333 8.66669V5.33335C4.83333 4.22829 5.27232 3.16848 6.05372 2.38708C6.83512 1.60567 7.89493 1.16669 9 1.16669C10.1051 1.16669 11.1649 1.60567 11.9463 2.38708C12.7277 3.16848 13.1667 4.22829 13.1667 5.33335V8.66669"
                                        fill="#232936"/>
                                    <path
                                        d="M4.83333 8.66669V5.33335C4.83333 4.22829 5.27232 3.16848 6.05372 2.38708C6.83512 1.60567 7.89493 1.16669 9 1.16669C10.1051 1.16669 11.1649 1.60567 11.9463 2.38708C12.7277 3.16848 13.1667 4.22829 13.1667 5.33335V8.66669M3.16667 8.66669H14.8333C15.7538 8.66669 16.5 9.41288 16.5 10.3334V16.1667C16.5 17.0872 15.7538 17.8334 14.8333 17.8334H3.16667C2.24619 17.8334 1.5 17.0872 1.5 16.1667V10.3334C1.5 9.41288 2.24619 8.66669 3.16667 8.66669Z"
                                        stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M4.83333 8.66669V5.33335C4.83333 4.22829 5.27232 3.16848 6.05372 2.38708C6.83512 1.60567 7.89493 1.16669 9 1.16669C10.1051 1.16669 11.1649 1.60567 11.9463 2.38708C12.7277 3.16848 13.1667 4.22829 13.1667 5.33335V8.66669M3.16667 8.66669H14.8333C15.7538 8.66669 16.5 9.41288 16.5 10.3334V16.1667C16.5 17.0872 15.7538 17.8334 14.8333 17.8334H3.16667C2.24619 17.8334 1.5 17.0872 1.5 16.1667V10.3334C1.5 9.41288 2.24619 8.66669 3.16667 8.66669Z"
                                        stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>

                                </svg>
                            </Button>
                        }
                        <Modal className="login-modal" show={this.state.show} onHide={this.hideModal.bind(this)}>
                            <Container className="modal-content-container">
                                <Row>
                                    <Modal.Header closeButton>
                                        <img alt="logo" src="/logo.svg" onClick={this.hideModal.bind(this)}/>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Container>
                                            <Row>
                                                <Col md={6} lg={4} xl={3} className={"form-container-content"}>
                                                    {this.getContent()}
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Modal.Body>
                                </Row>
                            </Container>
                            <Container className={"mobile-login-offer"}>
                                <Button href="/abonament" className="login-btn btn-offer">Kup abonament</Button>
                            </Container>
                            <Container className={"modal-badge"} fluid>
                                <Row>
                                    <Container className={" modal-badge-container"}>
                                        <Col lg={6} className={"modal-badge-content"}>
                                            <h3>Nie masz konta?</h3><p>
                                            Wejdź do świata najlepszych szkoleń dla profesjonalistów i ciesz się
                                            nieograniczonym
                                            dostępem do wiedzy z różnych dziedzin. Od świata EDUCADO dzielą Cię tylko
                                            dwa kroki:
                                            rejestracja i wykupienie dostępu.</p>
                                        </Col>
                                        <Button href="/abonament" className="login-btn white">Dowiedz się
                                            więcej</Button>

                                    </Container>
                                </Row>
                            </Container>
                        </Modal>
                    </Row>
                </Container>
            </>
        )
    }

}

export default Auth;