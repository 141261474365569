import React, {useContext} from 'react';
import {
    Accordion,
    AccordionContext,
    Button,
    Card,
    Col,
    Container,
    Row,
    Spinner,
    useAccordionToggle
} from "react-bootstrap";
import {convertMetaDescription, convertMetaTitle} from "../../../common/utils";
import Helmet from "react-helmet";
import ExpertContainer from "../../Modules/Experts/Sliders/ExpertSliderContainer";
import AwardsSlider from "../../Modules/AwardsBanner/AwardsSlider";
import "react-multi-carousel/lib/styles.css";
import {deviceType} from "react-device-detect";
import Carousel from "react-multi-carousel";
import ClosedTrainingsForm from "./ClosedTrainingsForm";
import connect from "react-redux/es/connect/connect";
import {closedTrainingsCredentialsRequest, closedTrainingsTestimonialsRequest} from "../../../actions/closedTrainings";

const mapStateToProps = state => ({
    ...state.closedTrainings,

});

const mapDispatchToProps = {}

function ContextAwareToggle({children, eventKey, callback}) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button
            type="button"
            className={isCurrentEventKey ? 'active' : ''}
            onClick={decoratedOnClick}
        >
            {children}
        </button>
    );
}

class ClosedTraining extends React.Component {

    componentDidMount() {

    }

    render() {
        const {received} = this.props;

        return (
            <>
                <Helmet>
                    <title>{convertMetaTitle('Oferta szkoleń zamkniętych')}</title>
                    <meta name="description"
                          content={convertMetaDescription('Oferta szkoleń zamkniętych Educado.')}/>
                </Helmet>
                <div className={'ct-wrapper'}>
                    <Container className="home-slider-container alternative closed-training-slider" fluid>
                        {/*<svg className={"desktop"} xmlns="http://www.w3.org/2000/svg" preserveAspectRatio={"none"}*/}
                        {/*     viewBox="0 0 1366 676.673">*/}
                        {/*    <path id="wave"*/}
                        {/*          d="M-14120,1626.339s14.371-39.132,80.134-22.521,175.141,101.587,292.567,96.194,172.05-80.62,188.773-162.049-3.609-75.377-15.043-169.986,105.752-136.609,260.915-167.854,225.095,28.192,377.23,10.426,181.423-54.587,181.423-54.587V1023.584h-1366Z"*/}
                        {/*          transform="translate(14120 -1023.584)" fill="#232936"/>*/}
                        {/*</svg>*/}
                        <svg className={"desktop"} xmlns="http://www.w3.org/2000/svg" preserveAspectRatio={"none"} viewBox="0 0 1920 715" fill="none">
                            <path d="M-152 715C-152 715 -100.932 561.096 75.55 586.117C217.855 608.463 614.797 700.828 793.5 696C891.351 696 990.844 657.894 1016.29 585C1041.75 512.106 1039.9 499.192 1022.5 414.5C1005.1 329.808 970.869 154.47 1207 126.5C1443.13 98.5303 1551.97 212.31 1783.5 196.5C2015.03 180.69 2043 99.7978 2043 99.7978H2065L2017.23 -1L-152 -0.999999V715Z" fill="#232936"/>
                        </svg>
                        <svg className={"mobile"} xmlns="http://www.w3.org/2000/svg" preserveAspectRatio={"none"}
                             viewBox="0 0 375.018 716.2">
                            <g id="Group_2355" data-name="Group 2355" transform="translate(10876.018 -1026)">
                                <path id="Path_1229" data-name="Path 1229"
                                      d="M-10875.24,1588.267s21.48,111.6,91.883,118.76,129.611-57.816,190.4-54.983,92.716,23.077,92.716,23.077v-86.854Z"
                                      transform="translate(-0.777 34.621)" fill="#56e4a9"/>
                                <path id="Path_1228" data-name="Path 1228"
                                      d="M-10875.224,1652.044s37.052,80.52,83.8,78.242,137.674-81.076,198.464-78.242,92.716,23.077,92.716,23.077l.017-649.7h-375Z"
                                      transform="translate(-0.777 0.576)" fill="#232936"/>
                                <path id="Polygon_33" data-name="Polygon 33"
                                      d="M18.755,8.969a6,6,0,0,1,10.385,0l13.541,23.39a6,6,0,0,1-5.193,9.006H10.407a6,6,0,0,1-5.193-9.006Z"
                                      transform="translate(-10603.255 1663.921) rotate(90)" fill="#f3f150"/>
                                <path id="Polygon_34" data-name="Polygon 34"
                                      d="M28.371,8.969a6,6,0,0,1,10.385,0l23.157,40a6,6,0,0,1-5.193,9.006H10.407a6,6,0,0,1-5.193-9.006Z"
                                      transform="translate(-10533.37 1641.458) rotate(90)" fill="#f3f150"/>
                            </g>
                        </svg>
                        <Container>
                            <Row>
                                <Col className={"item-content-container item-category-content closed-training-content"}
                                     xs={12} md={6} lg={6}>
                                    <div className={"item-content"}>
                                        <h1 className="slider-header">Szkolenia zamknięte</h1>
                                        <h2 className={"slider-header-alt"}>Zamów szkolenie dla swojej firmy/ placówki/ instytucji!</h2>
                                        <ul>
                                            <li>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                    <path d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z" fill="#2EE3A9"/>
                                                    <path d="M5.74121 13.4434L11.2954 19.4562L20.2599 9.75537" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                                Ty decydujesz o <span>programie szkolenia!</span></li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                <path d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z" fill="#2EE3A9"/>
                                                <path d="M5.74121 13.4434L11.2954 19.4562L20.2599 9.75537" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg><span>Dogodna formuła:</span> online, stacjonarna lub hybrydowa</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                <path d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z" fill="#2EE3A9"/>
                                                <path d="M5.74121 13.4434L11.2954 19.4562L20.2599 9.75537" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg><span>Uznani eksperci</span> w swoich dziedzinach</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                <path d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z" fill="#2EE3A9"/>
                                                <path d="M5.74121 13.4434L11.2954 19.4562L20.2599 9.75537" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg><span>Materiały</span> dla każdego uczestnika</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                <path d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z" fill="#2EE3A9"/>
                                                <path d="M5.74121 13.4434L11.2954 19.4562L20.2599 9.75537" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg><span>Imienny certyfikat</span> dla każdego uczestnika</li>
                                        </ul>
                                        <a className={"educado-btn educado-btn-full"}
                                           href={"#ClosedTrainingsForm"}>Zapytaj o ofertę</a>
                                    </div>
                                </Col>
                                <Col md={6} lg={6}
                                     className={"item-image-container d-sm-hidden"}><img
                                    src={"./closed-trainings.png"} alt={"educado.pl"}/></Col></Row>
                        </Container>
                    </Container>
                    <Container fluid className={"closed-training-accordion"}>
                        <Container>
                            <h2 className={'closed-training-category-header'}>Nasi Klienci chętnie korzystają ze
                            szkoleń
                            zamkniętych dla oświaty:</h2>
                            <Container className={'closed-training-accordion-container'}>
                                <div className={"ct-accordion-content"}>
                                    <h2>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="67" height="50"
                                             viewBox="0 0 67 50"
                                             fill="none">
                                            <path
                                                d="M17.8772 49.9999C27.7505 49.9999 35.7543 41.9961 35.7543 32.1228C35.7543 22.2495 27.7505 14.2456 17.8772 14.2456C8.00388 14.2456 0 22.2495 0 32.1228C0 41.9961 8.00388 49.9999 17.8772 49.9999Z"
                                                fill="#15A6DD"/>
                                            <path
                                                d="M45.064 12.9051H40.7866C40.3601 12.113 40.0676 11.2356 39.9214 10.3217H43.0411C43.4676 11.3575 44.1744 12.2471 45.064 12.9051Z"
                                                fill="#15A6DD"/>
                                            <path
                                                d="M42.6635 9.10313H39.7998C39.7876 9.01783 39.7876 8.92034 39.7876 8.83503C39.7876 6.5684 40.6406 4.50893 42.0542 2.9491H45.0642C43.541 4.0946 42.5417 5.91034 42.5417 7.93325C42.5417 8.3354 42.5782 8.72536 42.6635 9.10313Z"
                                                fill="#15A6DD"/>
                                            <path
                                                d="M57.4821 8.835C57.4821 10.1999 57.1774 11.4916 56.6047 12.6493L55.8735 12.4056L55.0326 14.9281C54.923 15.0622 54.8011 15.184 54.6671 15.2937H42.6027C42.2127 14.9403 41.8593 14.5503 41.5547 14.1238H48.6349V12.9052C45.9539 12.8564 43.7604 10.6142 43.7604 7.93322C43.7604 5.25225 45.9539 3.00999 48.6471 2.94906V1.73044H43.3948C44.8572 0.633683 46.6729 0 48.6349 0C53.5215 0 57.4821 3.96052 57.4821 8.835Z"
                                                fill="#15A6DD"/>
                                            <path
                                                d="M51.2067 17.2922C51.4504 17.012 51.7429 16.7439 52.0597 16.5123H23.9827C21.2408 16.5123 19.0107 18.7424 19.0107 21.4843C19.0107 24.2262 21.2408 26.4685 23.9827 26.4685H51.1945L51.3895 25.8835C51.1092 25.6154 50.8655 25.3351 50.6583 25.0183C50.6583 25.0183 50.6583 24.9939 50.6461 24.9939C50.4024 24.6405 50.1952 24.2871 50.0246 23.885H32.4277V22.6664H49.6712C49.5737 22.2886 49.5372 21.8864 49.5372 21.4843C49.5372 19.8757 50.1587 18.4012 51.1945 17.2922H51.2067ZM42.9079 20.3144H25.3841V19.0958H42.9079V20.3144Z"
                                                fill="white"/>
                                            <path
                                                d="M43.7606 7.93325C43.7606 5.2401 45.9541 3.01002 48.6472 2.94909V1.73047H16.9875C13.5753 1.73047 10.7969 4.50892 10.7969 7.93325C10.7969 11.3576 13.5753 14.1238 16.9875 14.1238H48.635V12.9052C45.9541 12.8565 43.7606 10.6142 43.7606 7.93325ZM16.9875 12.9052C14.2456 12.9052 12.0155 10.6751 12.0155 7.93325C12.0155 5.19135 14.2456 2.94909 16.9875 2.94909H45.0645C43.5412 4.09459 42.5419 5.91034 42.5419 7.93325C42.5419 8.33539 42.5785 8.72535 42.6638 9.10312H25.4447V10.3217H43.0416C43.4681 11.3576 44.1749 12.2472 45.0645 12.9052H16.9875Z"
                                                fill="#232936"/>
                                            <path d="M35.9129 5.53259H18.4014V6.75121H35.9129V5.53259Z" fill="#232936"/>
                                            <path d="M42.9075 19.0958H25.3838V20.3144H42.9075V19.0958Z" fill="#232936"/>
                                            <path d="M35.9129 32.5981H18.4014V33.8168H35.9129V32.5981Z" fill="#232936"/>
                                            <path
                                                d="M54.3988 37.948L54.3501 38.0942L50.0483 42.4203L49.1953 36.376L49.244 36.2297L54.3988 37.948Z"
                                                fill="white"/>
                                            <path
                                                d="M63.161 15.4766L63.356 14.8916L56.6048 12.6494L55.8736 12.4056L55.0328 14.9282L54.9109 15.2938H23.9823C20.5702 15.2938 17.7918 18.0722 17.7918 21.4844C17.7918 24.8965 20.5702 27.6871 23.9823 27.6871H50.792L47.9404 36.2662L48.4644 39.9465C45.8566 39.8124 43.7606 37.6189 43.7606 34.9867C43.7606 32.3545 45.9541 30.0635 48.6472 30.0147V28.7961H16.9875C13.5753 28.7961 10.7969 31.5745 10.7969 34.9867C10.7969 38.3988 13.5753 41.1773 16.9875 41.1773H48.635L49.1834 45.0159L55.4228 38.7522L62.771 16.6343L64.733 17.2923L61.7596 26.2614L62.9173 26.6513L66.2807 16.5124L63.161 15.4766ZM16.9875 39.9587C14.2456 39.9587 12.0155 37.7286 12.0155 34.9867C12.0155 32.2448 14.2456 30.0147 16.9875 30.0147H45.0645C43.5412 31.148 42.5419 32.9638 42.5419 34.9867C42.5419 35.3888 42.5785 35.7788 42.6638 36.1566H25.4447V37.3752H43.0416C43.4681 38.411 44.1749 39.3006 45.0645 39.9587H16.9875ZM23.9823 26.4685C21.2405 26.4685 19.0104 24.2384 19.0104 21.4844C19.0104 18.7303 21.2405 16.5124 23.9823 16.5124H52.0594C51.7425 16.7439 51.4501 17.012 51.2063 17.2923C50.1705 18.4013 49.549 19.8758 49.549 21.4844C49.549 21.8865 49.5856 22.2887 49.6831 22.6664H32.4396V23.885H50.0365C50.2071 24.2872 50.4142 24.6406 50.658 24.994C50.6701 24.994 50.6702 25.0062 50.6702 25.0062C50.8773 25.3352 51.121 25.6155 51.4013 25.8836L51.2063 26.4685H23.9945H23.9823ZM54.3504 38.0942L50.0486 42.4203L49.1956 36.3759L49.2443 36.2297L54.3991 37.9479L54.3504 38.0942ZM51.8278 24.5675C51.1576 23.7144 50.7554 22.6299 50.7554 21.4844C50.7554 19.2177 52.3153 17.2679 54.4479 16.6952L51.8278 24.5675Z"
                                                fill="#232936"/>
                                        </svg>
                                        Oświata
                                    </h2>
                                    <Accordion className={""} defaultActiveKey="0">
                                        <Card>
                                            <Card.Header>
                                                <ContextAwareToggle as={Button} variant="link" eventKey="0">
                                                    <div className={"category-name"}><span>Kategoria:</span> Pomoc
                                                        psychologiczno-pedagogiczna
                                                    </div>
                                                    <div className={"expert-name"}><span>Prowadząca:</span> <img alt={"Perder"}
                                                        src={'ct-experts/perdek.png'}/>
                                                        <p>Angelika Perder-Chabinowska<br/>Pedagog szkolny</p>
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                        <path d="M12.5 18.75L23.5269 29.7769C24.2212 30.4713 24.5685 30.8185 25 30.8185C25.4315 30.8185 25.7787 30.4713 26.4731 29.7769L37.5 18.75" stroke="#232936" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </ContextAwareToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                    <table className="data-table">
                                                        <thead>
                                                        <tr>
                                                            <th>LP</th>
                                                            <th>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25"
                                                                     height="26" viewBox="0 0 25 26" fill="none">
                                                                    <path
                                                                        d="M2.0835 8.83333C2.0835 10.231 10.5275 14.0417 12.5002 14.0417C14.4729 14.0417 22.9168 10.231 22.9168 8.83333C22.9168 7.43565 14.4729 3.625 12.5002 3.625C10.5275 3.625 2.0835 7.43565 2.0835 8.83333Z"
                                                                        stroke="#232936" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path
                                                                        d="M6.25 11.9583L6.50568 17.8228C6.51079 17.9402 6.5235 18.0578 6.55758 18.1703C6.66291 18.518 6.85703 18.8339 7.1532 19.0462C9.47044 20.7067 15.5296 20.7067 17.8468 19.0462C18.143 18.8339 18.3371 18.518 18.4424 18.1703C18.4765 18.0578 18.4892 17.9402 18.4944 17.8228L18.75 11.9583"
                                                                        stroke="#232936" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path
                                                                        d="M22.1355 10.3958C22.1355 9.96434 21.7857 9.61456 21.3542 9.61456C20.9228 9.61456 20.573 9.96434 20.573 10.3958H22.1355ZM20.573 10.3958V17.695H22.1355V10.3958H20.573ZM20.6262 21.2675C20.7771 20.6323 21.0367 20.0518 21.3145 19.5081C21.569 19.01 21.9025 18.4337 22.0809 17.982L20.6276 17.408C20.485 17.7691 20.2463 18.1647 19.9231 18.7972C19.6232 19.3841 19.2992 20.0932 19.106 20.9063L20.6262 21.2675ZM23.6025 20.9063C23.4092 20.0932 23.0853 19.3841 22.7854 18.7972C22.4621 18.1647 22.2235 17.7691 22.0809 17.408L20.6276 17.982C20.806 18.4337 21.1394 19.01 21.3939 19.5081C21.6717 20.0518 21.9313 20.6323 22.0823 21.2675L23.6025 20.9063ZM21.6828 21.5937H21.0257V23.1562H21.6828V21.5937ZM19.106 20.9063C18.9776 21.4473 18.9004 22.1629 19.41 22.6739C19.6486 22.9133 19.9429 23.0257 20.202 23.0832C20.4604 23.1405 20.7438 23.1562 21.0257 23.1562V21.5937C20.7879 21.5937 20.6356 21.5789 20.5403 21.5578C20.4459 21.5369 20.4687 21.523 20.5164 21.5708C20.5765 21.631 20.5774 21.6873 20.5744 21.6557C20.5699 21.6069 20.5734 21.49 20.6262 21.2675L19.106 20.9063ZM22.0823 21.2675C22.1351 21.49 22.1386 21.6069 22.134 21.6557C22.1311 21.6873 22.1319 21.631 22.192 21.5708C22.2398 21.523 22.2626 21.5369 22.1682 21.5578C22.0729 21.5789 21.9206 21.5937 21.6828 21.5937V23.1562C21.9646 23.1562 22.2481 23.1405 22.5064 23.0832C22.7656 23.0257 23.0599 22.9133 23.2985 22.6739C23.8081 22.1629 23.7309 21.4473 23.6025 20.9063L22.0823 21.2675Z"
                                                                        fill="#232936"/>
                                                                </svg>
                                                                Temat szkoleń
                                                            </th>
                                                            <th>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                     height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path
                                                                        d="M12 6V12L16 14M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                                                        stroke="#090A0A" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                                </svg>
                                                                Czas<br/> Trwania
                                                            </th>
                                                            <th>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                     height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path
                                                                        d="M20.774 18C21.5233 18 22.1193 17.5285 22.6545 16.8691C23.7499 15.5194 21.9513 14.4408 21.2654 13.9126C20.568 13.3756 19.7894 13.0714 19 13M18 11C19.3807 11 20.5 9.88071 20.5 8.5C20.5 7.11929 19.3807 6 18 6"
                                                                        stroke="#141B34" strokeWidth="1.5"
                                                                        strokeLinecap="round"/>
                                                                    <path
                                                                        d="M3.22596 18C2.47666 18 1.88067 17.5285 1.34555 16.8691C0.250087 15.5194 2.04867 14.4408 2.73465 13.9126C3.43197 13.3756 4.21058 13.0714 5 13M5.5 11C4.11929 11 3 9.88071 3 8.5C3 7.11929 4.11929 6 5.5 6"
                                                                        stroke="#141B34" strokeWidth="1.5"
                                                                        strokeLinecap="round"/>
                                                                    <path
                                                                        d="M8.0838 15.1112C7.06203 15.743 4.38299 17.0331 6.0147 18.6474C6.81178 19.436 7.69952 20 8.81563 20H15.1844C16.3005 20 17.1882 19.436 17.9853 18.6474C19.617 17.0331 16.938 15.743 15.9162 15.1112C13.5201 13.6296 10.4799 13.6296 8.0838 15.1112Z"
                                                                        stroke="#141B34" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path
                                                                        d="M15.5 7.5C15.5 9.433 13.933 11 12 11C10.067 11 8.5 9.433 8.5 7.5C8.5 5.567 10.067 4 12 4C13.933 4 15.5 5.567 15.5 7.5Z"
                                                                        stroke="#141B34" strokeWidth="1.5"/>
                                                                </svg>
                                                                Liczba <br/> uczestników
                                                            </th>
                                                            <th>Cena <br/> netto</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Jak wysłać dziecko na diagnozę w PPP i jak ona wygląda w
                                                                praktyce?
                                                            </td>
                                                            <td>6h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>

                                                            </td>
                                                            <td>
                                                                <div className="price-group">3 500 zł</div>
                                                                <div className="price-group">4 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>Jak poprawnie interpretować treść opinii PPP i
                                                                orzeczenia?
                                                            </td>
                                                            <td>6h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">3 500 zł</div>
                                                                <div className="price-group">4 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>9 wskazówek, jak efektywnie prowadzić zajęcia
                                                                rewalidacyjne w szkole?
                                                            </td>
                                                            <td>6h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">3 500 zł</div>
                                                                <div className="price-group">4 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>Wsparcie ucznia z opinią PPP - dokumentacja i praktyczne
                                                                wskazówki
                                                            </td>
                                                            <td>6h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">3 500 zł</div>
                                                                <div className="price-group">4 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>Jakie innowacje pedagogiczne i metody kreatywne można
                                                                zastosować w szkole?
                                                            </td>
                                                            <td>6h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">3 500 zł</div>
                                                                <div className="price-group">4 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>

                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Card.Header>
                                                <ContextAwareToggle as={Button} variant="link" eventKey="1">
                                                    <div className={"category-name"}><span>Kategoria:</span> Prawo oświatowe, zmiany
                                                    </div>
                                                    <div className={"expert-name"}><span>Prowadzący:</span> <img alt={"Lorens"}
                                                        src={'ct-experts/lorens.png'}/>
                                                        <p>Roman Lorens<br/>Ekspert w zakresie prawa oświatowego</p>
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                        <path d="M12.5 18.75L23.5269 29.7769C24.2212 30.4713 24.5685 30.8185 25 30.8185C25.4315 30.8185 25.7787 30.4713 26.4731 29.7769L37.5 18.75" stroke="#232936" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </ContextAwareToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>
                                                    <table className="data-table">
                                                        <thead>
                                                        <tr>
                                                            <th>LP</th>
                                                            <th>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25"
                                                                     height="26" viewBox="0 0 25 26" fill="none">
                                                                    <path
                                                                        d="M2.0835 8.83333C2.0835 10.231 10.5275 14.0417 12.5002 14.0417C14.4729 14.0417 22.9168 10.231 22.9168 8.83333C22.9168 7.43565 14.4729 3.625 12.5002 3.625C10.5275 3.625 2.0835 7.43565 2.0835 8.83333Z"
                                                                        stroke="#232936" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path
                                                                        d="M6.25 11.9583L6.50568 17.8228C6.51079 17.9402 6.5235 18.0578 6.55758 18.1703C6.66291 18.518 6.85703 18.8339 7.1532 19.0462C9.47044 20.7067 15.5296 20.7067 17.8468 19.0462C18.143 18.8339 18.3371 18.518 18.4424 18.1703C18.4765 18.0578 18.4892 17.9402 18.4944 17.8228L18.75 11.9583"
                                                                        stroke="#232936" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path
                                                                        d="M22.1355 10.3958C22.1355 9.96434 21.7857 9.61456 21.3542 9.61456C20.9228 9.61456 20.573 9.96434 20.573 10.3958H22.1355ZM20.573 10.3958V17.695H22.1355V10.3958H20.573ZM20.6262 21.2675C20.7771 20.6323 21.0367 20.0518 21.3145 19.5081C21.569 19.01 21.9025 18.4337 22.0809 17.982L20.6276 17.408C20.485 17.7691 20.2463 18.1647 19.9231 18.7972C19.6232 19.3841 19.2992 20.0932 19.106 20.9063L20.6262 21.2675ZM23.6025 20.9063C23.4092 20.0932 23.0853 19.3841 22.7854 18.7972C22.4621 18.1647 22.2235 17.7691 22.0809 17.408L20.6276 17.982C20.806 18.4337 21.1394 19.01 21.3939 19.5081C21.6717 20.0518 21.9313 20.6323 22.0823 21.2675L23.6025 20.9063ZM21.6828 21.5937H21.0257V23.1562H21.6828V21.5937ZM19.106 20.9063C18.9776 21.4473 18.9004 22.1629 19.41 22.6739C19.6486 22.9133 19.9429 23.0257 20.202 23.0832C20.4604 23.1405 20.7438 23.1562 21.0257 23.1562V21.5937C20.7879 21.5937 20.6356 21.5789 20.5403 21.5578C20.4459 21.5369 20.4687 21.523 20.5164 21.5708C20.5765 21.631 20.5774 21.6873 20.5744 21.6557C20.5699 21.6069 20.5734 21.49 20.6262 21.2675L19.106 20.9063ZM22.0823 21.2675C22.1351 21.49 22.1386 21.6069 22.134 21.6557C22.1311 21.6873 22.1319 21.631 22.192 21.5708C22.2398 21.523 22.2626 21.5369 22.1682 21.5578C22.0729 21.5789 21.9206 21.5937 21.6828 21.5937V23.1562C21.9646 23.1562 22.2481 23.1405 22.5064 23.0832C22.7656 23.0257 23.0599 22.9133 23.2985 22.6739C23.8081 22.1629 23.7309 21.4473 23.6025 20.9063L22.0823 21.2675Z"
                                                                        fill="#232936"/>
                                                                </svg>
                                                                Temat szkoleń
                                                            </th>
                                                            <th>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                     height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path
                                                                        d="M12 6V12L16 14M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                                                        stroke="#090A0A" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                                </svg>
                                                                Czas<br/> Trwania
                                                            </th>
                                                            <th>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                     height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path
                                                                        d="M20.774 18C21.5233 18 22.1193 17.5285 22.6545 16.8691C23.7499 15.5194 21.9513 14.4408 21.2654 13.9126C20.568 13.3756 19.7894 13.0714 19 13M18 11C19.3807 11 20.5 9.88071 20.5 8.5C20.5 7.11929 19.3807 6 18 6"
                                                                        stroke="#141B34" strokeWidth="1.5"
                                                                        strokeLinecap="round"/>
                                                                    <path
                                                                        d="M3.22596 18C2.47666 18 1.88067 17.5285 1.34555 16.8691C0.250087 15.5194 2.04867 14.4408 2.73465 13.9126C3.43197 13.3756 4.21058 13.0714 5 13M5.5 11C4.11929 11 3 9.88071 3 8.5C3 7.11929 4.11929 6 5.5 6"
                                                                        stroke="#141B34" strokeWidth="1.5"
                                                                        strokeLinecap="round"/>
                                                                    <path
                                                                        d="M8.0838 15.1112C7.06203 15.743 4.38299 17.0331 6.0147 18.6474C6.81178 19.436 7.69952 20 8.81563 20H15.1844C16.3005 20 17.1882 19.436 17.9853 18.6474C19.617 17.0331 16.938 15.743 15.9162 15.1112C13.5201 13.6296 10.4799 13.6296 8.0838 15.1112Z"
                                                                        stroke="#141B34" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path
                                                                        d="M15.5 7.5C15.5 9.433 13.933 11 12 11C10.067 11 8.5 9.433 8.5 7.5C8.5 5.567 10.067 4 12 4C13.933 4 15.5 5.567 15.5 7.5Z"
                                                                        stroke="#141B34" strokeWidth="1.5"/>
                                                                </svg>
                                                                Liczba <br/> uczestników
                                                            </th>
                                                            <th>Cena <br/> netto</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Ocena pracy dyrektora szkoły w nowym roku szkolnym
                                                            </td>
                                                            <td>6h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>

                                                            </td>
                                                            <td>
                                                                <div className="price-group">4 500 zł</div>
                                                                <div className="price-group">5 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>Zmiany w Karcie Nauczyciela w nowym roku szkolnym
                                                            </td>
                                                            <td>6h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">4 500 zł</div>
                                                                <div className="price-group">5 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>Jak obsadzić funkcje kierownicze w szkole?
                                                            </td>
                                                            <td>6h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">4 500 zł</div>
                                                                <div className="price-group">5 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>Obowiązki dyrektora przed rozpoczęciem i zakończeniem roku szkolnego
                                                            </td>
                                                            <td>6h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">4 500 zł</div>
                                                                <div className="price-group">5 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>Z jakim stanem prawa oświatowego wchodzimy w nowy rok szkolny?
                                                            </td>
                                                            <td>6h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">4 500 zł</div>
                                                                <div className="price-group">5 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>

                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Card.Header>
                                                <ContextAwareToggle as={Button} variant="link" eventKey="2">
                                                    <div className={"category-name"}><span>Kategoria:</span> Awans zawodowy
                                                    </div>
                                                    <div className={"expert-name"}><span>Prowadzący:</span> <img alt={"kowalski"}
                                                        src={'ct-experts/kowalski.png'}/>
                                                        <p>Michał Kowalski<br/>Radca prawny,<br/>specjalista prawa oświatowego</p>
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                        <path d="M12.5 18.75L23.5269 29.7769C24.2212 30.4713 24.5685 30.8185 25 30.8185C25.4315 30.8185 25.7787 30.4713 26.4731 29.7769L37.5 18.75" stroke="#232936" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </ContextAwareToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="2">
                                                <Card.Body>
                                                    <table className="data-table">
                                                        <thead>
                                                        <tr>
                                                            <th>LP</th>
                                                            <th>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25"
                                                                     height="26" viewBox="0 0 25 26" fill="none">
                                                                    <path
                                                                        d="M2.0835 8.83333C2.0835 10.231 10.5275 14.0417 12.5002 14.0417C14.4729 14.0417 22.9168 10.231 22.9168 8.83333C22.9168 7.43565 14.4729 3.625 12.5002 3.625C10.5275 3.625 2.0835 7.43565 2.0835 8.83333Z"
                                                                        stroke="#232936" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path
                                                                        d="M6.25 11.9583L6.50568 17.8228C6.51079 17.9402 6.5235 18.0578 6.55758 18.1703C6.66291 18.518 6.85703 18.8339 7.1532 19.0462C9.47044 20.7067 15.5296 20.7067 17.8468 19.0462C18.143 18.8339 18.3371 18.518 18.4424 18.1703C18.4765 18.0578 18.4892 17.9402 18.4944 17.8228L18.75 11.9583"
                                                                        stroke="#232936" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path
                                                                        d="M22.1355 10.3958C22.1355 9.96434 21.7857 9.61456 21.3542 9.61456C20.9228 9.61456 20.573 9.96434 20.573 10.3958H22.1355ZM20.573 10.3958V17.695H22.1355V10.3958H20.573ZM20.6262 21.2675C20.7771 20.6323 21.0367 20.0518 21.3145 19.5081C21.569 19.01 21.9025 18.4337 22.0809 17.982L20.6276 17.408C20.485 17.7691 20.2463 18.1647 19.9231 18.7972C19.6232 19.3841 19.2992 20.0932 19.106 20.9063L20.6262 21.2675ZM23.6025 20.9063C23.4092 20.0932 23.0853 19.3841 22.7854 18.7972C22.4621 18.1647 22.2235 17.7691 22.0809 17.408L20.6276 17.982C20.806 18.4337 21.1394 19.01 21.3939 19.5081C21.6717 20.0518 21.9313 20.6323 22.0823 21.2675L23.6025 20.9063ZM21.6828 21.5937H21.0257V23.1562H21.6828V21.5937ZM19.106 20.9063C18.9776 21.4473 18.9004 22.1629 19.41 22.6739C19.6486 22.9133 19.9429 23.0257 20.202 23.0832C20.4604 23.1405 20.7438 23.1562 21.0257 23.1562V21.5937C20.7879 21.5937 20.6356 21.5789 20.5403 21.5578C20.4459 21.5369 20.4687 21.523 20.5164 21.5708C20.5765 21.631 20.5774 21.6873 20.5744 21.6557C20.5699 21.6069 20.5734 21.49 20.6262 21.2675L19.106 20.9063ZM22.0823 21.2675C22.1351 21.49 22.1386 21.6069 22.134 21.6557C22.1311 21.6873 22.1319 21.631 22.192 21.5708C22.2398 21.523 22.2626 21.5369 22.1682 21.5578C22.0729 21.5789 21.9206 21.5937 21.6828 21.5937V23.1562C21.9646 23.1562 22.2481 23.1405 22.5064 23.0832C22.7656 23.0257 23.0599 22.9133 23.2985 22.6739C23.8081 22.1629 23.7309 21.4473 23.6025 20.9063L22.0823 21.2675Z"
                                                                        fill="#232936"/>
                                                                </svg>
                                                                Temat szkoleń
                                                            </th>
                                                            <th>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                     height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path
                                                                        d="M12 6V12L16 14M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                                                        stroke="#090A0A" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                                </svg>
                                                                Czas<br/> Trwania
                                                            </th>
                                                            <th>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                     height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path
                                                                        d="M20.774 18C21.5233 18 22.1193 17.5285 22.6545 16.8691C23.7499 15.5194 21.9513 14.4408 21.2654 13.9126C20.568 13.3756 19.7894 13.0714 19 13M18 11C19.3807 11 20.5 9.88071 20.5 8.5C20.5 7.11929 19.3807 6 18 6"
                                                                        stroke="#141B34" strokeWidth="1.5"
                                                                        strokeLinecap="round"/>
                                                                    <path
                                                                        d="M3.22596 18C2.47666 18 1.88067 17.5285 1.34555 16.8691C0.250087 15.5194 2.04867 14.4408 2.73465 13.9126C3.43197 13.3756 4.21058 13.0714 5 13M5.5 11C4.11929 11 3 9.88071 3 8.5C3 7.11929 4.11929 6 5.5 6"
                                                                        stroke="#141B34" strokeWidth="1.5"
                                                                        strokeLinecap="round"/>
                                                                    <path
                                                                        d="M8.0838 15.1112C7.06203 15.743 4.38299 17.0331 6.0147 18.6474C6.81178 19.436 7.69952 20 8.81563 20H15.1844C16.3005 20 17.1882 19.436 17.9853 18.6474C19.617 17.0331 16.938 15.743 15.9162 15.1112C13.5201 13.6296 10.4799 13.6296 8.0838 15.1112Z"
                                                                        stroke="#141B34" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path
                                                                        d="M15.5 7.5C15.5 9.433 13.933 11 12 11C10.067 11 8.5 9.433 8.5 7.5C8.5 5.567 10.067 4 12 4C13.933 4 15.5 5.567 15.5 7.5Z"
                                                                        stroke="#141B34" strokeWidth="1.5"/>
                                                                </svg>
                                                                Liczba <br/> uczestników
                                                            </th>
                                                            <th>Cena <br/> netto</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Jesienne zadania dyrektora w ramach awansu zawodowego
                                                            </td>
                                                            <td>6h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>

                                                            </td>
                                                            <td>
                                                                <div className="price-group">4 500 zł</div>
                                                                <div className="price-group">5 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>Jak zaplanować stan zatrudnienia w arkuszu organizacyjnym?
                                                            </td>
                                                            <td>6h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">4 500 zł</div>
                                                                <div className="price-group">5 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>Awans na nauczyciela dyplomowanego. Nowa i stara ścieżka rozwoju.
                                                            </td>
                                                            <td>6h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">4 500 zł</div>
                                                                <div className="price-group">5 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>Awans zawodowy na stopień nauczyciela mianowanego.
                                                            </td>
                                                            <td>6h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">4 500 zł</div>
                                                                <div className="price-group">5 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>Jakie kwalifikacje muszą mieć nauczyciele do zajęć rewalidacyjnych dla uczniów o różnych orzeczeniach o niepełnosprawnościach?
                                                            </td>
                                                            <td>6h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">4 500 zł</div>
                                                                <div className="price-group">5 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>6</td>
                                                            <td>Jak sobie radzić z niedoborami kadrowymi w szkole?
                                                            </td>
                                                            <td>6h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">3 500 zł</div>
                                                                <div className="price-group">4 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>

                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Card.Header>
                                                <ContextAwareToggle as={Button} variant="link" eventKey="3">
                                                    <div className={"category-name"}><span>Kategoria:</span> Hejt, agresja w szkole
                                                    </div>
                                                    <div className={"expert-name"}><span>Prowadząca:</span><img alt={"Flis"}
                                                        src={'ct-experts/flis.png'}/>
                                                        <p>Małgorzata Flis<br/>Dyrektor szkoły, nauczyciel, coach</p>
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                        <path d="M12.5 18.75L23.5269 29.7769C24.2212 30.4713 24.5685 30.8185 25 30.8185C25.4315 30.8185 25.7787 30.4713 26.4731 29.7769L37.5 18.75" stroke="#232936" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </ContextAwareToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="3">
                                                <Card.Body>
                                                    <table className="data-table">
                                                        <thead>
                                                        <tr>
                                                            <th>LP</th>
                                                            <th>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25"
                                                                     height="26" viewBox="0 0 25 26" fill="none">
                                                                    <path
                                                                        d="M2.0835 8.83333C2.0835 10.231 10.5275 14.0417 12.5002 14.0417C14.4729 14.0417 22.9168 10.231 22.9168 8.83333C22.9168 7.43565 14.4729 3.625 12.5002 3.625C10.5275 3.625 2.0835 7.43565 2.0835 8.83333Z"
                                                                        stroke="#232936" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path
                                                                        d="M6.25 11.9583L6.50568 17.8228C6.51079 17.9402 6.5235 18.0578 6.55758 18.1703C6.66291 18.518 6.85703 18.8339 7.1532 19.0462C9.47044 20.7067 15.5296 20.7067 17.8468 19.0462C18.143 18.8339 18.3371 18.518 18.4424 18.1703C18.4765 18.0578 18.4892 17.9402 18.4944 17.8228L18.75 11.9583"
                                                                        stroke="#232936" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path
                                                                        d="M22.1355 10.3958C22.1355 9.96434 21.7857 9.61456 21.3542 9.61456C20.9228 9.61456 20.573 9.96434 20.573 10.3958H22.1355ZM20.573 10.3958V17.695H22.1355V10.3958H20.573ZM20.6262 21.2675C20.7771 20.6323 21.0367 20.0518 21.3145 19.5081C21.569 19.01 21.9025 18.4337 22.0809 17.982L20.6276 17.408C20.485 17.7691 20.2463 18.1647 19.9231 18.7972C19.6232 19.3841 19.2992 20.0932 19.106 20.9063L20.6262 21.2675ZM23.6025 20.9063C23.4092 20.0932 23.0853 19.3841 22.7854 18.7972C22.4621 18.1647 22.2235 17.7691 22.0809 17.408L20.6276 17.982C20.806 18.4337 21.1394 19.01 21.3939 19.5081C21.6717 20.0518 21.9313 20.6323 22.0823 21.2675L23.6025 20.9063ZM21.6828 21.5937H21.0257V23.1562H21.6828V21.5937ZM19.106 20.9063C18.9776 21.4473 18.9004 22.1629 19.41 22.6739C19.6486 22.9133 19.9429 23.0257 20.202 23.0832C20.4604 23.1405 20.7438 23.1562 21.0257 23.1562V21.5937C20.7879 21.5937 20.6356 21.5789 20.5403 21.5578C20.4459 21.5369 20.4687 21.523 20.5164 21.5708C20.5765 21.631 20.5774 21.6873 20.5744 21.6557C20.5699 21.6069 20.5734 21.49 20.6262 21.2675L19.106 20.9063ZM22.0823 21.2675C22.1351 21.49 22.1386 21.6069 22.134 21.6557C22.1311 21.6873 22.1319 21.631 22.192 21.5708C22.2398 21.523 22.2626 21.5369 22.1682 21.5578C22.0729 21.5789 21.9206 21.5937 21.6828 21.5937V23.1562C21.9646 23.1562 22.2481 23.1405 22.5064 23.0832C22.7656 23.0257 23.0599 22.9133 23.2985 22.6739C23.8081 22.1629 23.7309 21.4473 23.6025 20.9063L22.0823 21.2675Z"
                                                                        fill="#232936"/>
                                                                </svg>
                                                                Temat szkoleń
                                                            </th>
                                                            <th>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                     height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path
                                                                        d="M12 6V12L16 14M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                                                        stroke="#090A0A" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                                </svg>
                                                                Czas<br/> Trwania
                                                            </th>
                                                            <th>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                     height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path
                                                                        d="M20.774 18C21.5233 18 22.1193 17.5285 22.6545 16.8691C23.7499 15.5194 21.9513 14.4408 21.2654 13.9126C20.568 13.3756 19.7894 13.0714 19 13M18 11C19.3807 11 20.5 9.88071 20.5 8.5C20.5 7.11929 19.3807 6 18 6"
                                                                        stroke="#141B34" strokeWidth="1.5"
                                                                        strokeLinecap="round"/>
                                                                    <path
                                                                        d="M3.22596 18C2.47666 18 1.88067 17.5285 1.34555 16.8691C0.250087 15.5194 2.04867 14.4408 2.73465 13.9126C3.43197 13.3756 4.21058 13.0714 5 13M5.5 11C4.11929 11 3 9.88071 3 8.5C3 7.11929 4.11929 6 5.5 6"
                                                                        stroke="#141B34" strokeWidth="1.5"
                                                                        strokeLinecap="round"/>
                                                                    <path
                                                                        d="M8.0838 15.1112C7.06203 15.743 4.38299 17.0331 6.0147 18.6474C6.81178 19.436 7.69952 20 8.81563 20H15.1844C16.3005 20 17.1882 19.436 17.9853 18.6474C19.617 17.0331 16.938 15.743 15.9162 15.1112C13.5201 13.6296 10.4799 13.6296 8.0838 15.1112Z"
                                                                        stroke="#141B34" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path
                                                                        d="M15.5 7.5C15.5 9.433 13.933 11 12 11C10.067 11 8.5 9.433 8.5 7.5C8.5 5.567 10.067 4 12 4C13.933 4 15.5 5.567 15.5 7.5Z"
                                                                        stroke="#141B34" strokeWidth="1.5"/>
                                                                </svg>
                                                                Liczba <br/> uczestników
                                                            </th>
                                                            <th>Cena <br/> netto</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Hejt w szkole – jak przeciwdziałać przemocy werbalnej.
                                                            </td>
                                                            <td>4h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>

                                                            </td>
                                                            <td>
                                                                <div className="price-group">3 500 zł</div>
                                                                <div className="price-group">4 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>Agresja, przemoc fizyczna i psychiczna w szkole i przedszkolu.

                                                            </td>
                                                            <td>4h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">3 500 zł</div>
                                                                <div className="price-group">4 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>Przemoc wobec dziecka z niepełnosprawnościami – interwencja prawna.
                                                            </td>
                                                            <td>4h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">3 500 zł</div>
                                                                <div className="price-group">4 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>Przemoc w szkole – przyczyny, diagnozowanie, działanie.
                                                            </td>
                                                            <td>4h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">3 500 zł</div>
                                                                <div className="price-group">4 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>Interwencje kryzysowe w szkole.
                                                            </td>
                                                            <td>4h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">3 500 zł</div>
                                                                <div className="price-group">4 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>

                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Card.Header>
                                                <ContextAwareToggle as={Button} variant="link" eventKey="4">
                                                    <div className={"category-name"}><span>Kategoria:</span> Umiejętności miękkie (rozmowa z dziećmi, rodzicami)
                                                    </div>
                                                    <div className={"expert-name"}><span>Prowadząca:</span> <img alt={"Perek"}
                                                                                                                 src={'ct-experts/perek.png'}/>
                                                        <p>Natalia Perek<br/>Psycholog, psychoterapeutka,<br/>dzieci i młodzieży</p>
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                        <path d="M12.5 18.75L23.5269 29.7769C24.2212 30.4713 24.5685 30.8185 25 30.8185C25.4315 30.8185 25.7787 30.4713 26.4731 29.7769L37.5 18.75" stroke="#232936" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </ContextAwareToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="4">
                                                <Card.Body>
                                                    <table className="data-table">
                                                        <thead>
                                                        <tr>
                                                            <th>LP</th>
                                                            <th>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25"
                                                                     height="26" viewBox="0 0 25 26" fill="none">
                                                                    <path
                                                                        d="M2.0835 8.83333C2.0835 10.231 10.5275 14.0417 12.5002 14.0417C14.4729 14.0417 22.9168 10.231 22.9168 8.83333C22.9168 7.43565 14.4729 3.625 12.5002 3.625C10.5275 3.625 2.0835 7.43565 2.0835 8.83333Z"
                                                                        stroke="#232936" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path
                                                                        d="M6.25 11.9583L6.50568 17.8228C6.51079 17.9402 6.5235 18.0578 6.55758 18.1703C6.66291 18.518 6.85703 18.8339 7.1532 19.0462C9.47044 20.7067 15.5296 20.7067 17.8468 19.0462C18.143 18.8339 18.3371 18.518 18.4424 18.1703C18.4765 18.0578 18.4892 17.9402 18.4944 17.8228L18.75 11.9583"
                                                                        stroke="#232936" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path
                                                                        d="M22.1355 10.3958C22.1355 9.96434 21.7857 9.61456 21.3542 9.61456C20.9228 9.61456 20.573 9.96434 20.573 10.3958H22.1355ZM20.573 10.3958V17.695H22.1355V10.3958H20.573ZM20.6262 21.2675C20.7771 20.6323 21.0367 20.0518 21.3145 19.5081C21.569 19.01 21.9025 18.4337 22.0809 17.982L20.6276 17.408C20.485 17.7691 20.2463 18.1647 19.9231 18.7972C19.6232 19.3841 19.2992 20.0932 19.106 20.9063L20.6262 21.2675ZM23.6025 20.9063C23.4092 20.0932 23.0853 19.3841 22.7854 18.7972C22.4621 18.1647 22.2235 17.7691 22.0809 17.408L20.6276 17.982C20.806 18.4337 21.1394 19.01 21.3939 19.5081C21.6717 20.0518 21.9313 20.6323 22.0823 21.2675L23.6025 20.9063ZM21.6828 21.5937H21.0257V23.1562H21.6828V21.5937ZM19.106 20.9063C18.9776 21.4473 18.9004 22.1629 19.41 22.6739C19.6486 22.9133 19.9429 23.0257 20.202 23.0832C20.4604 23.1405 20.7438 23.1562 21.0257 23.1562V21.5937C20.7879 21.5937 20.6356 21.5789 20.5403 21.5578C20.4459 21.5369 20.4687 21.523 20.5164 21.5708C20.5765 21.631 20.5774 21.6873 20.5744 21.6557C20.5699 21.6069 20.5734 21.49 20.6262 21.2675L19.106 20.9063ZM22.0823 21.2675C22.1351 21.49 22.1386 21.6069 22.134 21.6557C22.1311 21.6873 22.1319 21.631 22.192 21.5708C22.2398 21.523 22.2626 21.5369 22.1682 21.5578C22.0729 21.5789 21.9206 21.5937 21.6828 21.5937V23.1562C21.9646 23.1562 22.2481 23.1405 22.5064 23.0832C22.7656 23.0257 23.0599 22.9133 23.2985 22.6739C23.8081 22.1629 23.7309 21.4473 23.6025 20.9063L22.0823 21.2675Z"
                                                                        fill="#232936"/>
                                                                </svg>
                                                                Temat szkoleń
                                                            </th>
                                                            <th>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                     height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path
                                                                        d="M12 6V12L16 14M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                                                        stroke="#090A0A" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                                </svg>
                                                                Czas<br/> Trwania
                                                            </th>
                                                            <th>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                     height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path
                                                                        d="M20.774 18C21.5233 18 22.1193 17.5285 22.6545 16.8691C23.7499 15.5194 21.9513 14.4408 21.2654 13.9126C20.568 13.3756 19.7894 13.0714 19 13M18 11C19.3807 11 20.5 9.88071 20.5 8.5C20.5 7.11929 19.3807 6 18 6"
                                                                        stroke="#141B34" strokeWidth="1.5"
                                                                        strokeLinecap="round"/>
                                                                    <path
                                                                        d="M3.22596 18C2.47666 18 1.88067 17.5285 1.34555 16.8691C0.250087 15.5194 2.04867 14.4408 2.73465 13.9126C3.43197 13.3756 4.21058 13.0714 5 13M5.5 11C4.11929 11 3 9.88071 3 8.5C3 7.11929 4.11929 6 5.5 6"
                                                                        stroke="#141B34" strokeWidth="1.5"
                                                                        strokeLinecap="round"/>
                                                                    <path
                                                                        d="M8.0838 15.1112C7.06203 15.743 4.38299 17.0331 6.0147 18.6474C6.81178 19.436 7.69952 20 8.81563 20H15.1844C16.3005 20 17.1882 19.436 17.9853 18.6474C19.617 17.0331 16.938 15.743 15.9162 15.1112C13.5201 13.6296 10.4799 13.6296 8.0838 15.1112Z"
                                                                        stroke="#141B34" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path
                                                                        d="M15.5 7.5C15.5 9.433 13.933 11 12 11C10.067 11 8.5 9.433 8.5 7.5C8.5 5.567 10.067 4 12 4C13.933 4 15.5 5.567 15.5 7.5Z"
                                                                        stroke="#141B34" strokeWidth="1.5"/>
                                                                </svg>
                                                                Liczba <br/> uczestników
                                                            </th>
                                                            <th>Cena <br/> netto</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Jak komunikować się z nastoletnim uczniem?
                                                            </td>
                                                            <td>4h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>

                                                            </td>
                                                            <td>
                                                                <div className="price-group">3 500 zł</div>
                                                                <div className="price-group">4 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>Jak dbać o psychikę uczniów?

                                                            </td>
                                                            <td>4h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">3 500 zł</div>
                                                                <div className="price-group">4 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>Jak wspierać zdolnego ucznia?
                                                            </td>
                                                            <td>4h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">3 500 zł</div>
                                                                <div className="price-group">4 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>Jak efektownie i efektywnie zorganizować spotkanie z rodzicami w szkole?
                                                            </td>
                                                            <td>4h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">3 500 zł</div>
                                                                <div className="price-group">4 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>Jak radzić sobie z roszczeniowym rodzicem?
                                                            </td>
                                                            <td>4h</td>
                                                            <td>
                                                                <div className="user-group">&lt; 20os</div>
                                                                <div className="user-group">&gt; 20os</div>
                                                            </td>
                                                            <td>
                                                                <div className="price-group">3 500 zł</div>
                                                                <div className="price-group">4 000 zł</div>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>

                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </div>
                            </Container>
                        </Container>
                    </Container>
                    <Container fluid className={"closed-training-category-container"}>
                        <Container>
                            <h2 className={'closed-training-category-header'}>Ponadto organizujemy szkolenia zamknięte z
                                kategorii:</h2>
                            <div className={'category-conteiner-items'}>
                                <div className={"ct-category-item category-1"}>
                                    <div className={"header"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="59" height="50"
                                             viewBox="0 0 59 50" fill="none">
                                            <path
                                                d="M41.3443 50C50.6207 50 58.1407 42.48 58.1407 33.2035C58.1407 23.9271 50.6207 16.4071 41.3443 16.4071C32.0678 16.4071 24.5479 23.9271 24.5479 33.2035C24.5479 42.48 32.0678 50 41.3443 50Z"
                                                fill="#A2BEEF"/>
                                            <path
                                                d="M20.117 16.6247C24.7078 16.6247 28.4294 12.9031 28.4294 8.31234C28.4294 3.72156 24.7078 0 20.117 0C15.5262 0 11.8047 3.72156 11.8047 8.31234C11.8047 12.9031 15.5262 16.6247 20.117 16.6247Z"
                                                fill="#A2BEEF"/>
                                            <path
                                                d="M26.4257 16.3957C28.4112 16.3957 30.0209 14.7861 30.0209 12.8005C30.0209 10.815 28.4112 9.20538 26.4257 9.20538C24.4401 9.20538 22.8306 10.815 22.8306 12.8005C22.8306 14.7861 24.4401 16.3957 26.4257 16.3957Z"
                                                fill="#232936"/>
                                            <path
                                                d="M29.5171 31.509C32.265 30.3068 34.1999 27.5704 34.1999 24.3874C34.1999 20.1053 30.7193 16.6132 26.4257 16.6132C22.1321 16.6132 18.6515 20.0939 18.6515 24.3874C18.6515 27.6047 20.6208 30.3755 23.4259 31.5548C19.0293 32.8944 15.812 36.9933 15.812 41.825V42.3975H37.2798V41.825C37.2798 36.9361 33.9938 32.8028 29.5056 31.5205L29.5171 31.509ZM20.3231 26.9636L27.7424 19.6588L33.0206 24.8798C32.7687 28.3032 29.9063 31.0167 26.4257 31.0167C23.6893 31.0167 21.3307 29.3451 20.3231 26.975V26.9636ZM16.9798 41.2411C17.2775 36.2148 21.4566 32.2303 26.5517 32.2303C31.6467 32.2303 35.8257 36.2262 36.1234 41.2411H16.9798Z"
                                                fill="#232936"/>
                                            <path
                                                d="M45.3517 16.6018C48.0996 15.3996 50.0346 12.6632 50.0346 9.48021C50.0346 5.19809 46.5539 1.70599 42.2604 1.70599C37.9668 1.70599 34.4861 5.18664 34.4861 9.48021C34.4861 12.6975 36.4554 15.4683 39.2606 16.6476C36.444 17.4949 34.074 19.4642 32.7344 22.2121L33.7648 22.7158C35.3907 19.3955 38.6881 17.3346 42.3863 17.3346C47.4813 17.3346 51.6604 21.3305 51.9581 26.3453H33.5358V27.4903H53.1145V26.9178C53.1145 22.0289 49.8285 17.8956 45.3403 16.6133L45.3517 16.6018ZM36.1692 12.0449L43.5885 4.74011L48.8667 9.9611C48.6148 13.3845 45.7524 16.098 42.2718 16.098C39.5354 16.098 37.1768 14.4264 36.1692 12.0563V12.0449Z"
                                                fill="#232936"/>
                                            <path
                                                d="M10.7053 17.2887C14.6325 17.2887 18.1131 19.6359 19.5786 23.2768L20.6435 22.8532C19.4069 19.7847 16.8766 17.5292 13.831 16.6132C16.6247 15.4339 18.5825 12.6746 18.5825 9.46873C18.5825 5.19806 15.1019 1.71741 10.8312 1.71741C6.56057 1.71741 3.07992 5.19806 3.07992 9.46873C3.07992 12.6517 5.00344 15.3881 7.75132 16.5789C3.28601 17.8727 0 21.983 0 26.8605V27.433H19.5786V26.288H1.15641C1.4541 21.2732 5.62171 17.2887 10.7053 17.2887ZM4.24777 9.9496L9.51455 4.74008L16.9224 12.0334C15.9148 14.4035 13.5677 16.0751 10.8312 16.0751C7.35059 16.0751 4.49966 13.373 4.24777 9.9496Z"
                                                fill="#232936"/>
                                        </svg>
                                        <h2>HR, KADRY I<br/> PRAWO PRACY</h2>
                                    </div>
                                    <ul>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#A2BEEF"/>
                                            </svg>
                                            Zatrudnianie i zwalnianie pracowników w praktyce
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#A2BEEF"/>
                                            </svg>
                                            Urlop od A do Z – urlop na żądanie, urlop zaległy, urlop osoby z
                                            niepełnosprawnością
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#A2BEEF"/>
                                            </svg>
                                            Pracownik wraca po urlopie – problemy z praktyki
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#A2BEEF"/>
                                            </svg>
                                            Jak efektywnie zbudować system wynagrodzeń w firmie?
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#A2BEEF"/>
                                            </svg>
                                            Zatrudnianie członków rodziny a koszty podatkowe
                                        </li>
                                    </ul>
                                </div>
                                <div className={"ct-category-item category-2"}>
                                    <div className={"header"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="59" height="50"
                                             viewBox="0 0 59 50" fill="none">
                                            <path
                                                d="M39.1867 35.3494C49.6547 35.3494 58.1406 27.4362 58.1406 17.6747C58.1406 7.91323 49.6547 0 39.1867 0C28.7188 0 20.2329 7.91323 20.2329 17.6747C20.2329 27.4362 28.7188 35.3494 39.1867 35.3494Z"
                                                fill="#FFCE66"/>
                                            <path
                                                d="M8.91489 50C13.8385 50 17.8298 46.278 17.8298 41.6867C17.8298 37.0954 13.8385 33.3735 8.91489 33.3735C3.99133 33.3735 0 37.0954 0 41.6867C0 46.278 3.99133 50 8.91489 50Z"
                                                fill="#FFCE66"/>
                                            <path
                                                d="M14.5741 4.53009L7.48096 11.1445V42.5421H30.3367V41.3373H8.77297V12H15.4785V5.73491H38.6831V21.747H39.9751V4.53009H14.5741ZM14.1865 10.7952H9.67738L14.1865 6.59033V10.7952Z"
                                                fill="#232936"/>
                                            <path d="M27.985 14.3011H11.8994V15.506H27.985V14.3011Z" fill="#232936"/>
                                            <path d="M35.5432 14.3011H30.3364V15.506H35.5432V14.3011Z" fill="#232936"/>
                                            <path d="M27.985 18.3976H11.8994V19.6024H27.985V18.3976Z" fill="#232936"/>
                                            <path d="M35.5432 18.3976H30.3364V19.6024H35.5432V18.3976Z" fill="#232936"/>
                                            <path d="M27.985 22.506H11.8994V23.7108H27.985V22.506Z" fill="#232936"/>
                                            <path d="M27.985 26.6024H11.8994V27.8072H27.985V26.6024Z" fill="#232936"/>
                                            <path d="M47.4556 25.3494H33.6182V29.0361H47.4556V25.3494Z" fill="white"/>
                                            <path
                                                d="M30.3369 21.7469V48.7349H50.7378V21.7469H30.3369ZM36.3319 44.1566H33.6186V41.6265H36.3319V44.1566ZM36.3319 39.1084H33.6186V36.5903H36.3319V39.1084ZM36.3319 34.0723H33.6186V31.5421H36.3319V34.0723ZM41.9004 44.1566H39.1872V41.6265H41.9004V44.1566ZM41.9004 39.1084H39.1872V36.5903H41.9004V39.1084ZM41.9004 34.0723H39.1872V31.5421H41.9004V34.0723ZM47.4561 44.1566H44.7429V41.6265H47.4561V44.1566ZM47.4561 39.1084H44.7429V36.5903H47.4561V39.1084ZM47.4561 34.0723H44.7429V31.5421H47.4561V34.0723ZM47.4561 29.0361H33.6186V25.3494H47.4561V29.0361Z"
                                                fill="#232936"/>
                                            <path d="M36.3319 36.5903H33.6187V39.1084H36.3319V36.5903Z" fill="#232936"/>
                                            <path d="M47.4559 36.5903H44.7427V39.1084H47.4559V36.5903Z" fill="#232936"/>
                                            <path d="M47.4559 41.6265H44.7427V44.1566H47.4559V41.6265Z" fill="#232936"/>
                                            <path d="M36.3314 31.5421H33.6182V34.0722H36.3314V31.5421Z" fill="white"/>
                                            <path d="M41.9002 31.5421H39.187V34.0722H41.9002V31.5421Z" fill="white"/>
                                            <path d="M47.4559 31.5421H44.7427V34.0722H47.4559V31.5421Z" fill="white"/>
                                            <path d="M36.3314 36.5903H33.6182V39.1084H36.3314V36.5903Z" fill="white"/>
                                            <path d="M41.9002 36.5903H39.187V39.1084H41.9002V36.5903Z" fill="white"/>
                                            <path d="M47.4559 36.5903H44.7427V39.1084H47.4559V36.5903Z" fill="white"/>
                                            <path d="M36.3314 41.6265H33.6182V44.1566H36.3314V41.6265Z" fill="white"/>
                                            <path d="M41.9002 41.6265H39.187V44.1566H41.9002V41.6265Z" fill="white"/>
                                            <path d="M47.4559 41.6265H44.7427V44.1566H47.4559V41.6265Z" fill="white"/>
                                        </svg>
                                        <h2>Księgowość</h2>
                                    </div>
                                    <ul>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#FDD26E"/>
                                            </svg>
                                            Odliczenia i zwroty VAT
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#FDD26E"/>
                                            </svg>
                                            Jak określić miejsce świadczenia usług w podatku VAT?
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#FDD26E"/>
                                            </svg>
                                            Dofinansowanie małych firm
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#FDD26E"/>
                                            </svg>
                                            Jak wprowadzić nowe przepisy dotyczące sygnalistów?
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#FDD26E"/>
                                            </svg>
                                            Kasy fiskalne – ewidencja sprzedaży ze zmianami
                                        </li>
                                    </ul>
                                </div>
                                <div className={"ct-category-item category-3"}>
                                    <div className={"header"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="59" height="50"
                                             viewBox="0 0 59 50" fill="none">
                                            <path
                                                d="M16.1932 33.8913C25.1365 33.8913 32.3865 26.3045 32.3865 16.9457C32.3865 7.58683 25.1365 0 16.1932 0C7.24994 0 0 7.58683 0 16.9457C0 26.3045 7.24994 33.8913 16.1932 33.8913Z"
                                                fill="#F5EE3E"/>
                                            <path
                                                d="M49.8225 50C54.4165 50 58.1406 46.1028 58.1406 41.2954C58.1406 36.4879 54.4165 32.5908 49.8225 32.5908C45.2285 32.5908 41.5044 36.4879 41.5044 41.2954C41.5044 46.1028 45.2285 50 49.8225 50Z"
                                                fill="#F5EE3E"/>
                                            <path
                                                d="M51.8039 27.1053V24.2853C51.8039 21.6971 49.7982 19.5853 47.3249 19.5853C44.8517 19.5853 42.8337 21.6971 42.8337 24.2853V27.1053H39.0068V44.5145H55.6307V27.1053H51.8039ZM44.6425 39.0162L46.0206 36.5052C45.5407 36.0932 45.2331 35.488 45.2331 34.7926V34.7411C45.2331 34.5866 45.2577 34.445 45.2823 34.3033C45.3561 33.9814 45.4915 33.6981 45.6884 33.4535C46.0575 32.9384 46.6482 32.6036 47.3249 32.6036C47.7556 32.6036 48.1494 32.7324 48.4816 32.9642C48.6785 33.1058 48.8384 33.2603 48.9615 33.4535C49.2445 33.814 49.4045 34.2518 49.4168 34.7411V34.7926C49.4168 35.488 49.0968 36.0932 48.617 36.5052L50.0074 39.0162H44.6425ZM50.5734 27.1053H44.0641V24.2853C44.0641 22.4053 45.5284 20.873 47.3249 20.873C49.1214 20.873 50.5734 22.4053 50.5734 24.2853V27.1053Z"
                                                fill="#232936"/>
                                            <path
                                                d="M26.074 30.376C25.9632 26.7576 23.7115 23.693 20.5983 22.5599C22.4933 21.4911 23.773 19.4051 23.773 17.01C23.773 13.6621 21.2628 10.9065 18.1004 10.752C19.4417 10.5846 20.4753 9.38705 20.4753 7.94486C20.4753 6.38679 19.2694 5.12488 17.7805 5.12488C16.2916 5.12488 15.0857 6.38679 15.0857 7.94486C15.0857 9.38705 16.1193 10.5846 17.4606 10.752C14.2982 10.9065 11.7757 13.6621 11.7757 17.01C11.7757 19.4437 13.1047 21.5555 15.0365 22.5985C11.8988 23.796 9.64697 26.9637 9.64697 30.6593V31.3031H26.0863V30.6593C26.0863 30.5691 26.0863 30.4661 26.074 30.376ZM13.3507 18.89L18.7649 13.3144L22.5302 17.2032C22.4441 19.8686 20.3522 22.0062 17.7805 22.0062C15.7871 22.0062 14.0644 20.7185 13.3507 18.89ZM10.9144 30.0154C11.222 26.2941 14.2244 23.3453 17.8666 23.3453C21.5089 23.3453 24.5236 26.2941 24.8312 30.0154H10.9144Z"
                                                fill="#232936"/>
                                            <path
                                                d="M55.6305 2.5238V27.1053H54.4001V3.81147H4.83596V33.4535H39.0066V34.7412H3.60547V2.5238H55.6305Z"
                                                fill="#232936"/>
                                            <path d="M47.6571 11.9109H28.3384V13.1986H47.6571V11.9109Z" fill="#232936"/>
                                            <path d="M42.4029 16.3662H28.3384V17.6539H42.4029V16.3662Z" fill="#232936"/>
                                            <path
                                                d="M44.6421 39.0162L46.0202 36.5053C45.5403 36.0932 45.2327 35.488 45.2327 34.7927V34.7412C45.2327 34.5866 45.2573 34.445 45.2819 34.3034C45.3558 33.9814 45.4911 33.6982 45.688 33.4535C46.0571 32.9384 46.6478 32.6036 47.3245 32.6036C47.7552 32.6036 48.149 32.7324 48.4812 32.9642C48.6781 33.1058 48.8381 33.2603 48.9611 33.4535C49.2441 33.814 49.4041 34.2518 49.4164 34.7412V34.7927C49.4164 35.488 49.0965 36.0932 48.6166 36.5053L50.007 39.0162H44.6421Z"
                                                fill="white"/>
                                        </svg>
                                        <h2>Ochrona danych osobowych</h2>
                                    </div>
                                    <ul>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#F5EE3E"/>
                                            </svg>
                                            RODO w małej firmie
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#F5EE3E"/>
                                            </svg>
                                            Ochrona danych osobowych dla działów Kadr i HR
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#F5EE3E"/>
                                            </svg>
                                            Otrzymałeś wezwanie z UODO. Jak postąpić?
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#F5EE3E"/>
                                            </svg>
                                            Kontrola przestrzegania przepisów o ochronie danych osobowych?
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#F5EE3E"/>
                                            </svg>
                                            RODO w placówkach oświatowych
                                        </li>
                                    </ul>
                                </div>
                                <div className={"ct-category-item category-4"}>
                                    <div className={"header"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="59" height="51"
                                             viewBox="0 0 59 51" fill="none">
                                            <path id="Vector"
                                                  d="M39.1868 35.875C49.6547 35.875 58.1406 27.956 58.1406 18.1875C58.1406 8.41896 49.6547 0.5 39.1868 0.5C28.7189 0.5 20.2329 8.41896 20.2329 18.1875C20.2329 27.956 28.7189 35.875 39.1868 35.875Z"
                                                  fill="#FBB2A3"/>
                                            <path id="Vector_2"
                                                  d="M8.9278 50.5C13.8585 50.5 17.8556 46.7699 17.8556 42.1687C17.8556 37.5674 13.8585 33.8373 8.9278 33.8373C3.9971 33.8373 0 37.5674 0 42.1687C0 46.7699 3.9971 50.5 8.9278 50.5Z"
                                                  fill="#FBB2A3"/>
                                            <path id="Vector_3" d="M53.3211 26.9529H23.0234V28.6649H53.3211V26.9529Z"
                                                  fill="white"/>
                                            <path id="Vector_4"
                                                  d="M9.28967 4.78021L2.18359 11.3995V42.8318H20.1942V41.6261H3.47561V12.2555H10.1941V5.9859H33.3986V16.9336H34.6906V4.78021H9.28967ZM8.90206 11.0498H4.39293L8.90206 6.84194V11.0498Z"
                                                  fill="#232936"/>
                                            <path id="Vector_5" d="M22.6879 14.5583H6.61523V15.764H22.6879V14.5583Z"
                                                  fill="#232936"/>
                                            <path id="Vector_6" d="M22.6879 18.6698H6.61523V19.8755H22.6879V18.6698Z"
                                                  fill="#232936"/>
                                            <path id="Vector_7" d="M15.879 22.7691H6.61523V23.9748H15.879V22.7691Z"
                                                  fill="#232936"/>
                                            <path id="Vector_8"
                                                  d="M56.8486 45.002V41.6261H52.8433V29.8706H54.6134V23.6613L38.2048 12.5328L21.7833 23.6613V29.8706H23.5534V41.6261H19.5481V45.002H18.437V46.2077H57.9597V45.002H56.8486ZM23.0754 28.665V26.9529H53.3214V28.665H23.0754ZM51.5513 29.8706V41.6261H45.8535V29.8706H51.5513ZM44.5615 29.8706V41.6261H38.8508V29.8706H44.5615ZM37.5588 29.8706V41.6261H31.8481V29.8706H37.5588ZM30.5561 29.8706V41.6261H24.8454V29.8706H30.5561ZM55.5566 45.002H20.8402V42.8318H55.5566V45.002Z"
                                                  fill="#232936"/>
                                        </svg>
                                        <h2>Zamówienia publiczne</h2>
                                    </div>
                                    <ul>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#FBB2A3"/>
                                            </svg>
                                            Jak poprawnie wdrożyć e-doręczenia?
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#FBB2A3"/>
                                            </svg>
                                            Negocjacje w zamówieniach publicznych
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#FBB2A3"/>
                                            </svg>
                                            Przetargi i rządzące nimi przepisy prawa
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#FBB2A3"/>
                                            </svg>
                                            Rejestr umów w zamówieniach publicznych
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#FBB2A3"/>
                                            </svg>
                                            PZP: Zamówienia na usługi szkoleniowe
                                        </li>
                                    </ul>
                                </div>
                                <div className={"ct-category-item category-5"}>
                                    <div className={"header"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="59" height="51"
                                             viewBox="0 0 59 51" fill="none">
                                            <path
                                                d="M19.1582 37.9904C29.739 37.9904 38.3164 29.5979 38.3164 19.2452C38.3164 8.89252 29.739 0.5 19.1582 0.5C8.57739 0.5 0 8.89252 0 19.2452C0 29.5979 8.57739 37.9904 19.1582 37.9904Z"
                                                fill="#00ABA8"/>
                                            <path
                                                d="M34.3722 6.33951C34.3722 6.33951 28.9525 11.144 28.2212 13.3674C28.2212 13.4568 34.3722 19.3985 34.3722 19.3985V6.33951Z"
                                                fill="white"/>
                                            <path
                                                d="M48.6593 41.1721C53.8956 41.1721 58.1405 37.0188 58.1405 31.8954C58.1405 26.7719 53.8956 22.6186 48.6593 22.6186C43.4231 22.6186 39.1782 26.7719 39.1782 31.8954C39.1782 37.0188 43.4231 41.1721 48.6593 41.1721Z"
                                                fill="#00ABA8"/>
                                            <path
                                                d="M34.8947 5.82839L34.5029 5.54727L34.1111 5.82839C33.7324 6.1095 24.9434 12.6646 24.9434 20.9447C24.9434 28.5347 32.322 34.6809 33.8499 35.8693V50.5H35.1559V35.8693C36.6577 34.6937 44.0493 28.5475 44.0493 20.9447C44.0493 12.6646 35.2604 6.1095 34.8947 5.82839ZM42.4169 18.3252L35.1559 25.4297V7.70674C37.1148 9.4062 41.2415 13.444 42.4169 18.3252ZM33.8499 34.1826C31.5514 32.2148 26.2493 26.9758 26.2493 20.9447C26.2493 18.5935 27.059 16.3702 28.1821 14.4024L33.8499 19.9608V34.1826ZM33.8499 18.1463L28.8743 13.2779C30.5459 10.7479 32.6223 8.75453 33.8499 7.70674V18.1463ZM35.1559 34.1826V27.2442L42.6781 19.8841C42.7303 20.2419 42.7434 20.5869 42.7434 20.9447C42.7434 26.9631 37.4543 32.1892 35.1559 34.1826Z"
                                                fill="#232936"/>
                                            <path
                                                d="M47.3533 31.5376L47.51 39.4599L37.5588 49.5544C37.2845 47.05 36.9188 39.396 41.346 34.9109C43.0176 33.2115 45.1724 32.1765 47.3533 31.5376Z"
                                                fill="#232936"/>
                                            <path
                                                d="M56.1688 30.6687L48.7903 38.1565L48.6597 31.2053C51.7939 30.5153 54.7845 30.592 56.1688 30.6687Z"
                                                fill="#232936"/>
                                            <path
                                                d="M43.6056 45.2483L54.2752 45.0566C53.9879 45.4655 53.6614 45.8488 53.3088 46.2066C48.8816 50.6917 41.046 50.6022 38.4863 50.4489L43.6056 45.2483Z"
                                                fill="#232936"/>
                                            <path
                                                d="M55.1109 43.766L44.8984 43.9449L57.096 31.5631C57.318 33.6459 57.6053 39.3449 55.1109 43.766Z"
                                                fill="#232936"/>
                                            <path
                                                d="M11.1396 40.5204L19.2365 40.3671L29.5534 50.1039C26.9938 50.3722 19.1712 50.73 14.5873 46.3983C12.8504 44.7627 11.7926 42.6543 11.1396 40.5204Z"
                                                fill="#232936"/>
                                            <path
                                                d="M10.2517 31.8953L17.9046 39.1149L10.8002 39.2426C10.095 36.1759 10.1733 33.2498 10.2517 31.8953Z"
                                                fill="#232936"/>
                                            <path
                                                d="M25.1524 44.1877L24.9565 33.7481C25.3744 34.0293 25.7662 34.3487 26.1319 34.6937C30.7157 39.0254 30.6243 46.6922 30.4676 49.1966L25.1524 44.1877Z"
                                                fill="#232936"/>
                                            <path
                                                d="M23.6378 32.9304L23.8206 42.9227L11.166 30.9881C13.2947 30.7709 19.1192 30.4898 23.6378 32.9304Z"
                                                fill="#232936"/>
                                        </svg>
                                        <h2>Ochrona środowiska</h2>
                                    </div>
                                    <ul>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#00ABA8"/>
                                            </svg>
                                            Jak identyfikować obowiązki środowiskowe?
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#00ABA8"/>
                                            </svg>
                                            ESG a środowisko
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#00ABA8"/>
                                            </svg>
                                            Sprawozdawczość emisyjna w praktyce
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#00ABA8"/>
                                            </svg>
                                            Gospodarka odpadami w praktyce
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <circle cx="7" cy="7" r="7" fill="#00ABA8"/>
                                            </svg>
                                            Transgraniczne przemieszczanie odpadów
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <h2 className={'closed-training-category-header'}>Potrzebujesz szkolenia z innej
                                dziedziny?<br/> Napisz do nas! Zorganizujemy szkolenie, o którym myślisz.</h2>
                            <Button href={"#ClosedTrainingsForm"} className={"educado-btn educado-btn-full "}>Zgłoś
                                swoje szkolenie</Button>

                        </Container>
                    </Container>
                    <Container fluid>
                        <Container className={"closed-traingins-benefit-container"}>
                            <h2>Odkryj korzyści szkoleń zamkniętych</h2>
                            <div className={'benefits-container-items'}>
                                <div className={"benefit-item"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40"
                                         fill="none">
                                        <path
                                            d="M3.8335 13.3333C3.8335 15.5696 17.3438 21.6667 20.5002 21.6667C23.6565 21.6667 37.1668 15.5696 37.1668 13.3333C37.1668 11.097 23.6565 5 20.5002 5C17.3438 5 3.8335 11.097 3.8335 13.3333Z"
                                            stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M10.5 18.3333L10.9091 27.7165C10.9173 27.9043 10.9376 28.0925 10.9921 28.2725C11.1606 28.8288 11.4713 29.3343 11.9451 29.674C15.6527 32.3308 25.3473 32.3308 29.0548 29.674C29.5288 29.3343 29.8393 28.8288 30.0078 28.2725C30.0623 28.0925 30.0827 27.9043 30.091 27.7165L30.5 18.3333"
                                            stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M35.9166 15.8333C35.9166 15.143 35.3569 14.5833 34.6666 14.5833C33.9762 14.5833 33.4166 15.143 33.4166 15.8333H35.9166ZM33.4166 15.8333V27.512H35.9166V15.8333H33.4166ZM33.5017 33.228C33.7432 32.2116 34.1586 31.2828 34.6031 30.413C35.0102 29.616 35.5437 28.694 35.8292 27.9711L33.5039 27.0528C33.2757 27.6306 32.8939 28.2635 32.3767 29.2755C31.8969 30.2146 31.3786 31.3491 31.0694 32.6501L33.5017 33.228ZM38.2637 32.6501C37.9546 31.3491 37.4362 30.2146 36.9564 29.2755C36.4392 28.2635 36.0574 27.6306 35.8292 27.0528L33.5039 27.9711C33.7894 28.694 34.3229 29.616 34.7301 30.413C35.1746 31.2828 35.5899 32.2116 35.8314 33.228L38.2637 32.6501ZM35.1922 33.75H34.1409V36.25H35.1922V33.75ZM31.0694 32.6501C30.8639 33.5156 30.7404 34.6606 31.5557 35.4783C31.9376 35.8613 32.4084 36.0411 32.8231 36.1331C33.2364 36.2248 33.6899 36.25 34.1409 36.25V33.75C33.7604 33.75 33.5167 33.7263 33.3642 33.6925C33.2132 33.659 33.2497 33.6368 33.3261 33.7133C33.4222 33.8096 33.4236 33.8996 33.4189 33.8491C33.4116 33.771 33.4172 33.584 33.5017 33.228L31.0694 32.6501ZM35.8314 33.228C35.9159 33.584 35.9216 33.771 35.9142 33.8491C35.9096 33.8996 35.9109 33.8096 36.0071 33.7133C36.0834 33.6368 36.1199 33.659 35.9689 33.6925C35.8164 33.7263 35.5727 33.75 35.1922 33.75V36.25C35.6432 36.25 36.0967 36.2248 36.5101 36.1331C36.9247 36.0411 37.3956 35.8613 37.7774 35.4783C38.5927 34.6606 38.4692 33.5156 38.2637 32.6501L35.8314 33.228Z"
                                            fill="#2EE3A9"/>
                                    </svg>
                                    <h3>Program szkolenia</h3>
                                    <p>dostosowany do potrzeb Twoich i Twoich współpracowników.</p>
                                </div>
                                <div className={"benefit-item"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40"
                                         fill="none">
                                        <path
                                            d="M21.3335 36.6667H11.4849C8.90931 36.6667 6.86068 35.4133 5.02126 33.661C1.25577 30.0735 7.43816 27.2067 9.79611 25.8027C13.5452 23.5703 17.9777 22.8515 22.1668 23.6463"
                                            stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M26.3335 10.8333C26.3335 14.9754 22.9757 18.3333 18.8335 18.3333C14.6914 18.3333 11.3335 14.9754 11.3335 10.8333C11.3335 6.69118 14.6914 3.33331 18.8335 3.33331C22.9757 3.33331 26.3335 6.69118 26.3335 10.8333Z"
                                            stroke="#2EE3A9" strokeWidth="2.5"/>
                                        <path
                                            d="M31.652 24.2962L32.8252 26.662C32.9852 26.9912 33.4117 27.3072 33.7717 27.3677L35.8982 27.7239C37.258 27.9524 37.578 28.947 36.598 29.9284L34.9448 31.5952C34.665 31.8775 34.5117 32.4219 34.5983 32.8117L35.0715 34.875C35.4448 36.5082 34.585 37.14 33.1518 36.2864L31.1587 35.0969C30.7987 34.8817 30.2055 34.8817 29.8388 35.0969L27.8457 36.2864C26.4192 37.14 25.5527 36.5015 25.9258 34.875L26.3992 32.8117C26.4858 32.4219 26.3325 31.8775 26.0525 31.5952L24.3993 29.9284C23.4262 28.947 23.7395 27.9524 25.0993 27.7239L27.2257 27.3677C27.579 27.3072 28.0057 26.9912 28.1657 26.662L29.3388 24.2962C29.9788 23.0124 31.0187 23.0124 31.652 24.2962Z"
                                            stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                    </svg>
                                    <h3>Eksperci - praktyce</h3>
                                    <p>w swoich dziedzinach</p>
                                </div>
                                <div className={"benefit-item"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40"
                                         fill="none">
                                        <path d="M37.1665 16.6666L29.6665 3.33331L22.1665 16.6666H37.1665Z"
                                              stroke="#2EE3A9" strokeWidth="2.5" strokeLinejoin="round"/>
                                        <path d="M17.1668 3.33331H3.8335V16.6666H17.1668V3.33331Z" stroke="#2EE3A9"
                                              strokeWidth="2.5" strokeLinejoin="round"/>
                                        <path
                                            d="M29.6667 36.6666C33.3486 36.6666 36.3333 33.6819 36.3333 30C36.3333 26.3181 33.3486 23.3333 29.6667 23.3333C25.9848 23.3333 23 26.3181 23 30C23 33.6819 25.9848 36.6666 29.6667 36.6666Z"
                                            stroke="#2EE3A9" strokeWidth="2.5" strokeLinejoin="round"/>
                                        <path d="M17.1667 23.3333L3.8335 36.6666M3.83361 23.3333L17.1668 36.6666"
                                              stroke="#2EE3A9" strokeWidth="2.5" strokeLinejoin="round"/>
                                    </svg>
                                    <h3>Elestyczność formuły!</h3>
                                    <p>Zorganizujemy szkolenie stacjonarnie, online lub hybrydowo.</p>
                                </div>
                                <div className={"benefit-item"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40"
                                         fill="none">
                                        <path
                                            d="M34.6668 28.2143V16.6666C34.6668 10.3812 34.6668 7.23856 32.7142 5.28593C30.7617 3.33331 27.6188 3.33331 21.3335 3.33331H19.6668C13.3814 3.33331 10.2387 3.33331 8.28611 5.28593C6.3335 7.23856 6.3335 10.3812 6.3335 16.6666V32.5"
                                            stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"/>
                                        <path
                                            d="M34.6668 28.3333H10.5002C8.19898 28.3333 6.3335 30.1988 6.3335 32.5C6.3335 34.8011 8.19898 36.6666 10.5002 36.6666H34.6668"
                                            stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"/>
                                        <path
                                            d="M34.6667 36.6666C32.3655 36.6666 30.5 34.8011 30.5 32.5C30.5 30.1988 32.3655 28.3333 34.6667 28.3333"
                                            stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"/>
                                        <path d="M25.5 11.6667H15.5" stroke="#2EE3A9" strokeWidth="2.5"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M20.5 18.3333H15.5" stroke="#2EE3A9" strokeWidth="2.5"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <h3>Materiały szkoleniowe</h3>
                                    <p>dla każdego uczestnika</p>
                                </div>
                                <div className={"benefit-item"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"
                                         fill="none">
                                        <path
                                            d="M30.8335 29.73C32.551 29.4778 33.7713 28.9901 34.7142 28.0473C36.6668 26.0948 36.6668 22.952 36.6668 16.6666C36.6668 10.3812 36.6668 7.23856 34.7142 5.28593C32.7617 3.33331 29.6188 3.33331 23.3335 3.33331H16.6668C10.3814 3.33331 7.23875 3.33331 5.28611 5.28593C3.3335 7.23856 3.3335 10.3812 3.3335 16.6666C3.3335 22.952 3.3335 26.0948 5.28611 28.0473C6.63308 29.3943 8.54633 29.8121 11.6668 29.9416"
                                            stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"/>
                                        <path d="M28.3332 11.6667H11.6665" stroke="#2EE3A9" strokeWidth="2.5"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                        <path
                                            d="M24.1668 24.1667C24.1668 26.4678 22.3013 28.3333 20.0002 28.3333C17.699 28.3333 15.8335 26.4678 15.8335 24.1667C15.8335 21.8655 17.699 20 20.0002 20C22.3013 20 24.1668 21.8655 24.1668 24.1667Z"
                                            stroke="#2EE3A9" strokeWidth="2.5"/>
                                        <path
                                            d="M15.8335 24.1667C15.8335 30.9432 18.7038 34.7844 20.0002 36.6667L22.5002 31.6667L25.4168 33.3334L28.3335 35C27.109 33.8147 25.8432 30.0785 25.8432 30.0785"
                                            stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                    </svg>
                                    <h3>Imienny certyfikat</h3>
                                    <p>dla każdego uczestnika</p>
                                </div>
                                <div className={"benefit-item"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"
                                         fill="none">
                                        <path
                                            d="M33.3335 28.6436C31.4052 30 28.6847 30 23.2438 30H16.607C11.1662 30 8.44573 30 6.51733 28.6436C5.80388 28.1418 5.18333 27.5185 4.68378 26.8018C3.3335 24.8646 3.3335 22.132 3.3335 16.6666C3.3335 11.2013 3.3335 8.46858 4.68378 6.5315C5.18333 5.81483 5.80388 5.19148 6.51733 4.68968C8.44573 3.33331 11.1662 3.33331 16.607 3.33331"
                                            stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"/>
                                        <path d="M20 30V36.6667" stroke="#2EE3A9" strokeWidth="2.5"/>
                                        <path d="M13.3335 36.6667H26.6668Z" fill="#2EE3A9"/>
                                        <path d="M13.3335 36.6667H26.6668" stroke="#2EE3A9" strokeWidth="2.5"
                                              strokeLinecap="round"/>
                                        <path
                                            d="M29.9998 3.33331C33.1425 3.33331 34.7138 3.33331 35.6902 4.30963C36.6665 5.28593 36.6665 6.85728 36.6665 9.99998V16.6666C36.6665 19.8093 36.6665 21.3806 35.6902 22.357C34.7138 23.3333 33.1425 23.3333 29.9998 23.3333H28.3332C25.1905 23.3333 23.6192 23.3333 22.6428 22.357C21.6665 21.3806 21.6665 19.8093 21.6665 16.6666V9.99998C21.6665 6.85728 21.6665 5.28593 22.6428 4.30963C23.6192 3.33331 25.1905 3.33331 28.3332 3.33331H29.9998Z"
                                            stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"/>
                                        <path d="M29.1665 19.1667H29.1815" stroke="#2EE3A9" strokeWidth="2.5"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <p>Szkolenia online dostępne na</p>
                                    <h3>dowolnym urządzeniu.</h3>
                                </div>
                            </div>
                            <Button href={"#ClosedTrainingsForm"} className={"educado-btn educado-btn-full "}>Zgłoś
                                swoje szkolenie</Button>
                        </Container>
                    </Container>
                    <ExpertContainer/>
                    <Container className={"closed-training-form-container"} fluid>
                        <Container>
                            <Row className={"ct-form-container mb-5"}>
                                <h2 className={'closed-training-header mt-3'}>Realizujemy szkolenia zamknięte w 3
                                    prostych krokach</h2>
                                <div className={"steps-container"}>
                                    <div className={"steps-item"}>
                                        <span>1</span>
                                        <h3>Napisz nam o szkoleniu, które Cię interesuje.</h3>
                                        <p>Przeanalizujemy Twoje zapytanie.</p>
                                    </div>
                                    <div className={"steps-item"}>
                                        <span>2</span>
                                        <h3>Znajdziemy eksperta, który przygotuje program szkolenia.</h3>
                                        <p>Wyślemy Ci jego propozycję do akceptacji.</p>
                                    </div>
                                    <div className={"steps-item"}>
                                        <span>3</span>
                                        <h3>Twoje szkolenie jest gotowe!</h3>
                                        <p>Skorzystaj online, stacjonarnie lub hybrydowo.</p>
                                    </div>
                                </div>
                                <Col md={6} lg={4} className={"manager-col"}>
                                    <p>Napisz nam, który temat Cię zainteresował.</p>
                                    <p>Nasza specjalistka skontaktuje się z Tobą z ciągu kilku dni i przedstawi ofertę
                                        stworzoną specjalnie dla Ciebie!</p>
                                    <div className="manager-section">
                                        <img src={'./sg.jpg'} alt={"Sylwia Gizler"}/>
                                        <div className="data">
                                            <h4>Sylwia Gizler<br/>Koordynator szkoleń zamkniętych</h4>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                     viewBox="0 0 30 30" fill="none">
                                                    <path
                                                        d="M6.25 11.25C6.25 7.12521 6.25 5.06281 7.53141 3.78141C8.81281 2.5 10.8752 2.5 15 2.5C19.1247 2.5 21.1871 2.5 22.4686 3.78141C23.75 5.06281 23.75 7.12521 23.75 11.25V18.75C23.75 22.8747 23.75 24.9371 22.4686 26.2186C21.1871 27.5 19.1247 27.5 15 27.5C10.8752 27.5 8.81281 27.5 7.53141 26.2186C6.25 24.9371 6.25 22.8747 6.25 18.75V11.25Z"
                                                        stroke="#2EE3A9" strokeWidth="1.5" strokeLinecap="round"/>
                                                    <path d="M13.75 23.75H16.25" stroke="#2EE3A9" strokeWidth="1.5"
                                                          strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path
                                                        d="M11.25 2.5L11.3612 3.16752C11.6023 4.61411 11.7229 5.33741 12.219 5.77755C12.7365 6.23667 13.4701 6.25 15 6.25C16.5299 6.25 17.2635 6.23667 17.781 5.77755C18.2771 5.33741 18.3976 4.61411 18.6388 3.16752L18.75 2.5"
                                                        stroke="#2EE3A9" strokeWidth="1.5" strokeLinejoin="round"/>
                                                </svg>
                                                +48 660 404 172
                                            </p>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28"
                                                     viewBox="0 0 28 28" fill="none">
                                                    <path
                                                        d="M8.1665 9.91675L11.5989 11.946C13.5999 13.1292 14.3998 13.1292 16.4008 11.946L19.8332 9.91675"
                                                        stroke="#2EE3A9" strokeWidth="1.5" strokeLinecap="round"
                                                        strokeLinejoin="round"/>
                                                    <path
                                                        d="M2.35189 15.7216C2.42816 19.2981 2.4663 21.0863 3.78595 22.411C5.10559 23.7356 6.94221 23.7817 10.6155 23.874C12.8793 23.9309 15.121 23.9309 17.3849 23.874C21.0581 23.7817 22.8947 23.7356 24.2144 22.411C25.5341 21.0863 25.5722 19.2981 25.6484 15.7216C25.673 14.5716 25.673 13.4285 25.6484 12.2785C25.5722 8.70204 25.5341 6.91381 24.2144 5.58915C22.8947 4.26448 21.0581 4.21834 17.3849 4.12604C15.121 4.06915 12.8793 4.06915 10.6155 4.12603C6.94221 4.21831 5.10559 4.26445 3.78594 5.58913C2.46629 6.9138 2.42816 8.70203 2.35188 12.2785C2.32736 13.4285 2.32737 14.5716 2.35189 15.7216Z"
                                                        stroke="#2EE3A9" strokeWidth="1.5" strokeLinejoin="round"/>
                                                </svg>
                                                <a href={"mailto:sgizler@wip.pl"}>sgizler@wip.pl</a></p>
                                        </div>

                                    </div>
                                </Col>
                                <Col md={6} lg={8} className={received && received ? "received" :"form-col"}>
                                    {received && received ?
                                        <h3 className={'text-center'}>Dziekujemy za zgłoszenie!</h3> :
                                        <><ClosedTrainingsForm/></>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                    <AwardsSlider/>
                </div>
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClosedTraining);