import React from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {convertMetaDescription, convertMetaTitle} from "../../common/utils";
import HomeExpertContainer from "../Modules/Experts/Sliders/ExpertSliderContainer";
import AwardsSlider from "../Modules/AwardsBanner/AwardsSlider";
import FreeWebinarListContainer from "./FreeWebinarListContainer";

class FreeWebinarIndex extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            buttonShow: true,
        }
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>{convertMetaTitle('Webinary bezpłatne')}</title>
                    <meta name="description"
                          content={convertMetaDescription('Lista webinarów bezpłatnych.')}/>
                </Helmet>
                <Container fluid className={"webinar-list-container"}>
                    <Container>
                        <Row>
                            <Container>
                                <Row>
                                    <h1 className={"webinar-list-headline"}>Webinary bezpłatne</h1>
                                </Row>
                            </Container>
                            <div className={"webinar-list-benefits"}>
                                <div className="benefit-item"><img src={"./webinar-benefit-blue-2.png"}
                                                                   alt={"3-dniowy dostęp do nagrania"}/><span>45-minutowe<br/>wystąpienie eksperta</span>
                                </div>
                                <div className="benefit-item"><img src={"./webinar-benefit-blue-2.png"}
                                                                   alt={"3-dniowy dostęp do nagrania"}/><span>48-godzinny dostęp<br/>do nagrania</span>
                                </div>
                                <div className="benefit-item"><img src={"./webinar-benefit-blue-3.png"}
                                                                   alt={"Dodatkowe materiały merytoryczne"}/><span>Prezentacje PDF<br/>ze szkoleń</span>
                                </div>
                                <div className="benefit-item"><img src={"./webinar-benefit-blue-1.png"}
                                                                   alt={"Imienny certyfikat"}/><span>Imienny
                                certyfiakt do<br/>wybranych szkoleń</span>
                                </div>
                            </div>

                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <FreeWebinarListContainer {...this.state}/>
                        </Row>
                    </Container>
                </Container>
                <Container className={"webinar-list-subscription-container"} fluid>
                    <Container>
                        <Row>
                            <Col className="webinar-list-subscription-image" lg={4} md={5}><img
                                src={"./webinar-list-subscription.svg"} alt={"Abonament"}/></Col>
                            <Col className="webinar-list-subscription-text" lg={6} md={7}>
                                <h3>Przegapiłeś termin? Nic straconego!</h3>
                                <p>Jeśli przegapiłeś termin webinaru live możesz obejrzeć szkolenie VOD. Wystarczy, że
                                    kupisz subskrypcje Educado i zyskasz nieograniczony dostęp do wszystkich szkoleń z
                                    wielu
                                    kategorii.</p>
                                <p> Wybierz najlepszy dla siebie abonament i wejdź do świata szkoleń online!</p>
                                <Button className="webinar-btn reverse" href={"/abonament"}>Sprawdź</Button>
                            </Col>

                        </Row>

                    </Container>
                </Container>
                <HomeExpertContainer/>
                <AwardsSlider/>
            </>
        );

    }
}


export default FreeWebinarIndex;