import React from "react";
import InputMask from "react-input-mask";
import classNames from "classnames";

export const validateEmail = (email) => {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (pattern.test(email)) {
        return true;
    }
    return false;
};
export const requiredNumber = (number) => {
    var pattern = new RegExp('^[0-9]+$');
    if (pattern.test(number)) {
        return true;
    }
    return false;
};

export const validateTextByPattern = (text, params) => {
    var pattern = new RegExp(params.pattern);
    if (pattern.test(text)) {
        return true;
    }
    return false;
}

export const validateIsDifferentFields = (field, params) => {

    if (params.notDifferent && params.field1 === params.field2) {
        return true;
    } else if (!params.notDifferent && params.field1 !== params.field2) {
        return true;
    }
    return false;
}

export const requiredField = (field = false) => {
    if (field || field.length > 0) {
        return true;
    }
    return false;
}
export const requiredFieldSelect = (field) => {
    if (field && field !== ('Wybierz termin'))  {
        return true

    }

    return false;

}
export const validateNip = (nip) => {

    if (typeof nip !== 'string') {
        return false;
    }
    const nipWithoutDashes = nip.replace(/-/g, '');
    const reg = /^[0-9]{10}$/;
    if (reg.test(nipWithoutDashes) === false) {
        return false;
    }

    const dig = ('' + nipWithoutDashes).split('');
    const control =
        (6 * parseInt(dig[0], 10) +
            5 * parseInt(dig[1], 10) +
            7 * parseInt(dig[2], 10) +
            2 * parseInt(dig[3], 10) +
            3 * parseInt(dig[4], 10) +
            4 * parseInt(dig[5], 10) +
            5 * parseInt(dig[6], 10) +
            6 * parseInt(dig[7], 10) +
            7 * parseInt(dig[8], 10)) %
        11;

    if (parseInt(dig[9], 10) === control) {
        return true;
    }
    return false;
}

export const minChar = (field, params) => {
    if (field && field.length >= params.limit) {
        return true;
    }
    return false;
}

/**
 *
 * @param mapFields array
 * @param fields object
 * @returns {boolean|*}
 * example
 * {
 *    field: 'email',
 *    text: 'Podany adres email nie jest prawidłowy!',
 *    method: validateEmail
 * },
 * {
 *    field: 'acceptTerms',
 *    text: 'Akceptacja regulaminu jest wymagana!',
 *    method: requiredField
 * },
 * {
 *    field: 'name',
 *    methods: [
 *        {
 *           method: requiredField,
 *           text: 'Pole imię nie może pozostać puste!'
 *        },
 *        {
 *           method: minChar,
 *           text: 'Imię powinno skladać się z minium 3 znaków.',
 *           params: {limit: 3}
 *        }
 *    ]
 * }
 */
export const formValidate = (mapFields, fields) => {
    let validate = [];
    mapFields.forEach((item) => {
        Object.entries(fields).map((field) => {
            if (field.find((fn) => fn === item.field)) {
                if (item.method) {
                    if (!item.method(field[1], item.params)) {
                        validate.push({[field[0]]: item.text});
                    }
                } else if (item.methods) {
                    item.methods.some((met) => {
                        if (!met.method(field[1], met.params)) {
                            validate.push({[field[0]]: met.text});
                            return !met.method(field[1], met.params);
                        }
                        return '';
                    })
                }
            }
            return '';
        })
        return '';
    })
    if (validate.length !== 0) {
        return validate.reduce((x, y) => Object.assign(x, y), {})
    }
    return false;

}

export const setInputMask = (pattern, value) => {
    let tmp = '';
    let pos = '';
    let patternMap = {
        d: /[^0-9]/g,
        s: /[^a-z]+/gi
    }

    pattern.split("").some((char, key) => {
        if (patternMap[char]) {
            pos = key;
        }
        return patternMap[char];
    });

    pos = pattern.slice(0, pos);

    if (pos && value.indexOf(pos) < 0) {
        value = pos + value;
    }

    let arr = value.split("");

    pattern.split("").some((char, key) => {
        if (arr[key] && patternMap[char]) {
            if (!arr[key].match(patternMap[char])) {
                tmp += arr[key];
            } else if (arr[key]) {
                tmp = {error: arr[key], value: tmp}
                return arr[key];
            }
        } else if (key < arr.length) {
            tmp += char;
        }
        return arr.length === key;
    })

    return tmp;
};


export const renderField = ({input, label, options, placeholder, mask, type, meta: {error}}) => {

    // if (mask && mask.pattern && input.value.length > 0) {
    //     let tmp = setInputMask(mask.pattern, input.value);
    //     if (tmp && tmp.error) {
    //         error = mask.text;
    //         input.value = tmp.value;
    //     } else {
    //         input.value = tmp;
    //     }
    // }

    const classes = classNames(
        'form-control',
        {'is-invalid': error}
    );

    return (
        <div className="form-group">
            {(type === 'text' || type === 'password') &&
            <>
                {(mask)
                    ? <InputMask {...input} placeholder={placeholder} type={type} className={classes}
                                 mask={mask.pattern}/>
                    : <input {...input} placeholder={placeholder} type={type} className={classes}/>
                }
                {error && <div className="invalid-feedback">{error}</div>}
            </>
            }
            {(type === 'textarea') &&
            <>
                <label>
                    <textarea {...input} type={type} placeholder={placeholder} className={classes}/>
                    {label}
                    {error && <div className="invalid-feedback">{error}</div>}
                </label>
            </>
            }
            {(type === 'checkbox') &&
            <label className={`checkbox`}>
                <input {...input} type={type} className={classes}/>
                {label}
                {error && <div className="invalid-feedback">{error}</div>}
            </label>}

            {/** select ściśle do formularza webinaru bezpłatnego. Przekazywanie dwóch wartości z obiektu do jednej opcji. DO REFAKTORYZACJI*/}
            {/** ./Components/Product/webinar/WebinarForm.js*/}

            {(type === 'select') &&

            <>

                <select {...input} options={options} className={classes}>
                    <option hidden>{placeholder}</option>
                    {options ? options.map((option, key) => (
                    <option value={option[1]} key={key}>{option[0]}</option>)) : ''}
                </select>
                {label}
                {error && <div className="invalid-feedback">{error}</div>}
            </>}
        </div>
    );
};

export const profileRenderField = ({input, label, placeholder, mask, type, meta: {error}}) => {

    // if (mask && mask.pattern && input.value.length > 0) {
    //     let tmp = setInputMask(mask.pattern, input.value);
    //     if (tmp && tmp.error) {
    //         error = mask.text;
    //         input.value = tmp.value;
    //     } else {
    //         input.value = tmp;
    //     }
    // }


    const classes = classNames(
        'form-control',
        {'is-invalid': error}
    );
    return (
        <div className="form-group">
            {(type === 'text' || type === 'password') &&
            <>
                <label>{placeholder}</label>
                <input {...input} placeholder={placeholder} type={type} className={classes} readOnly/>
                {error && <div className="invalid-feedback">{error}</div>}
            </>
            }
            {(type === 'checkbox') &&
            <label>
                <input {...input} type={type} className={classes}/>

                {label}
                {error && <div className="invalid-feedback">{error}</div>}
            </label>}
        </div>
    );
};

export const codeRenderField = ({input, label, placeholder, mask, type, meta: {error}}) => {

    const classes = classNames(
        'form-control',
        {'is-invalid': error}
    );

    return (
        <div className="form-group">
            {(type === 'text' || type === 'password') &&
            <>
                {(mask)
                    ?
                    <> <label>{placeholder}:*</label>
                        <InputMask {...input} placeholder={placeholder} type={type} className={classes}
                                   mask={mask.pattern}/>
                    </>
                    :
                    <> <label>{placeholder}:*</label>
                    <input {...input} placeholder={placeholder} type={type} className={classes}/>
                        </>
                }
                {error && <div className="invalid-feedback">{error}</div>}
            </>
            }
            {(type === 'checkbox') &&
            <label>
                <input {...input} type={type} className={classes}/>
                {label}
                {error && <div className="invalid-feedback">{error}</div>}
            </label>}
        </div>
    );
};