import React from 'react';
import {categoryDescendantsFetch} from "../../../actions/category";
import {connect} from "react-redux";
import {Button, Container, Modal, Row, Spinner} from "react-bootstrap";
import ListContainer from "../../Modules/Index/Lists/ListContainer";
import AdditionalListContainer from "../../Modules/Index/Lists/AdditionalListContainer";
import SliderContainer from "../../Modules/Index/Sliders/SliderContainer";
import Breadcrumb from "../../../common/breadcrumb";
import CategoryBanner from "../../Modules/Index/Banners/CategoryBanner";

const mapStateToProps = state => ({
    category_descendants: state.category.category_descendants,
});

const mapDispatchToProps = {
    categoryDescendantsFetch,
}

class CategoryIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCategory: [this.props.id],
        };

    }

    componentDidMount(prevProps) {
        const {category_descendants} = this.props;
        if (!category_descendants) {
            this.props.categoryDescendantsFetch(this.props.id);
        }

    }
    getCategory(id) {
        switch (id) {
            case 71:
                return 'oswiata/';
            case 13:
                return 'ksiegowosc-i-kadry/';
            default:
                return '';
        }
    }
    componentDidUpdate() {
        if (this.props.error) {
            if (!this.props.isFetching) {
                this.props.history.push(`/404`);

            } else {
                setInterval(() => {
                    this.props.history.push(`/404`);
                }, 15000); //lagi...
            }
        }
    }

    render() {
        const {auth, isFetching, id, category_descendants} = this.props;

        return (
            <>
                <Breadcrumb id={this.props.id}/>
                {window.innerWidth > 992 ?
                    <CategoryBanner id={this.props.id} auth={auth}/> : ''
                }
                <div className={"container-content"}>
                    <Container>
                        <SliderContainer id={id} type={['online']}/>
                    </Container>
                    {category_descendants && category_descendants.length > 0 ?
                        <Container className={"filter-container"}>
                        <span>Filtruj po kategorii</span>
                        {category_descendants && category_descendants.map((item) => (
                            <Button
                                key={item.id}
                                id={item.id}
                                label={item.name}
                                variant={""}
                                onClick={() => this.setState({currentCategory: [item.id]})}
                                className={`btn-filter cat-${this.props.id} ${this.state.currentCategory.includes(item.id) ? 'active' : 'non-active'}`}>
                                {item.name}
                            </Button>
                        ))}
                        <Button
                            label={'Pokaż wszystkie'}
                            variant={""}
                            onClick={() => this.setState({currentCategory: [id]})}
                            className={`btn-filter ${this.state.currentCategory.includes(id) ? 'active' : 'non-active'}`}>
                            Pokaż wszystkie
                        </Button>
                    </Container> : '' }
                    <Row>
                        <Container>
                            <h3 className={"headline-small text-center home-products-header"}>Najnowsze szkolenia</h3>
                        </Container>
                    </Row>
                    <ListContainer {...this.props} type={['online']} hideButton={true} currentCategory={this.state.currentCategory}/>
                    <Row>
                        <Container className={'text-center mt-5'}>
                            <Button href={`/${this.getCategory(id)}szkolenia`} className={'educado-btn educado-btn-black educado-btn-wide'}>Zobacz wszystkie szkolenia</Button>
                        </Container>
                    </Row>
                    <Row>
                        <Container>
                            <h3 className={"headline-small text-center home-products-header"}>Najnowsze webinary</h3>
                        </Container>
                    </Row>
                    <AdditionalListContainer {...this.props} hideButton={true} type={['webinar', 'webinarpaid']}
                                             currentCategory={this.state.currentCategory}/>
                    <Row>
                        <Container className={'text-center mt-5'}>
                            <Button href={`/${this.getCategory(id)}webinary`} className={'educado-btn educado-btn-black educado-btn-wide'}>Zobacz wszystkie webinary</Button>
                        </Container>
                    </Row>
                </div>
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryIndex);