import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import {parseTrainingMinutes, parseTrainingTime, strNormalize} from "../../../common/utils";
import ProductVimeoPlayer from "../video/ProductVimeoPlayer";
import {deviceType} from "react-device-detect";
import dateFormat from "dateformat";
import {Helmet} from "react-helmet";
import ProductContent from "../ProductContent";
import RightSection from "../RightSection";

class WebinarPaidVideo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            'paused': false,
            'error': false,
            'vimeoId': null,
            'videoId': null,
        };
        this.setDeviceType();

    }

    setDeviceType() {
        switch (deviceType) {
            case 'browser':
                this.width = '815px';
                break;
            case 'tablet':
                this.width = '690px';
                break;
            case 'phone':
                this.width = '340px';
                break;
            default:
        }
    }

    handlePlayerPause() {
        this.setState({paused: true});
    }

    handlePlayerPlay() {
        this.setState({paused: false});
    }


    render() {
        const {auth, product} = this.props.children;
        let tmp = false;
        if (product.timetable) {
            product.timetable.forEach((el) => {
                if (!el.expired) {
                    tmp = true
                }
            });
        }
        return (
            <>
                <Container fluid className={`webinar-wave-container webinar-video-container ${auth && auth.isAuthenticated ? strNormalize(product.categories[0].name) + ''  : 'not-logged'}`}>
                    <Container className={"webinar-wave-container-content"}>
                        {!product.accessExpired && !product.accessDenied && product.videos[0] ?
                            <><Row className={'webinar-data webinar-video-data'}>
                                {!product.accessExpired && !product.accessDenied && product.additionalFields.hasChat ?

                                    <Container>
                                        <h1 className="vimeo-access-header">{product.name}</h1>
                                    </Container>
                                    :
                                    <Col lg={3} md={12} className={'webinar-data'}><h1>{product.name}</h1>
                                        <h2>Ekspert:

                                            {product.experts ?
                                                <>{product && product.experts.map((expert, key) => (
                                                    <span key={key}>&nbsp;
                                                        {expert.name} {expert.surname}{product.experts.length > key + 1 && product.experts.length > 1 ? ', ' : ''}
                                            </span>
                                                ))}
                                                </>
                                                : ''
                                            }
                                        </h2>
                                        <span
                                            className={"webinar-duration white"}>{parseTrainingTime(product.timetable[0].duration)}</span>
                                        <div className={"webinar-dates-container"}>
                                            {product && product.timetable.map((date, index) => (
                                                <div key={index}
                                                     className={date.expired ? 'webinar-date-item expired' : 'webinar-date-item'}>
                                                    <span>{index === 0 ? <>1&nbsp;</> : index + 1} TERMIN</span><p>
                                            <span
                                                className={"time"}>{dateFormat(Date.parse(date.eventStartDate), 'HH:MM')}</span><span
                                                    className="date">
                                             &nbsp;| {dateFormat(Date.parse(date.eventStartDate), 'dd-mm-yyyy')}</span>
                                                </p>
                                                </div>
                                            ))}
                                        </div>
                                        {product.accessDenied ?
                                            <Button href={"/abonament"}
                                                    className={"webinar-btn webinar-btn-white"}>Kup w
                                                abonamencie</Button>
                                            : ''
                                        }
                                    </Col>
                                }

                                <Col lg={9} md={12}
                                     className={"webinar-image"}>
                                    <ProductVimeoPlayer
                                        className="video-box"
                                        width={this.width}
                                        video={product.videos[0].vimeoId}
                                        onPause={this.handlePlayerPause.bind(this)}
                                        onPlay={this.handlePlayerPlay.bind(this)}

                                    />

                                </Col>
                                {!product.accessExpired && !product.accessDenied && product.additionalFields.hasChat && product.videos[0].vimeoUrl ?
                                    <Col lg={3} md={12}>
                                        <div className={"chat-box"}>
                                            <iframe title="chat"
                                                    src={`${product.videos[0].vimeoUrl}/chat`}/>
                                        </div>
                                    </Col> :
                                    ''
                                }
                            </Row></>
                            :
                            <Row>
                                <Col lg={3} md={12} className={'webinar-data'}><h1>{product.name}</h1>
                                    <h2>Ekspert:

                                        {product.experts ?
                                            <>{product && product.experts.map((expert, key) => (
                                                <span key={key}>&nbsp;
                                                    {expert.name} {expert.surname}{product.experts.length > key + 1 && product.experts.length > 1 ? ', ' : ''}
                                            </span>
                                            ))}
                                            </>
                                            : ''
                                        }
                                    </h2>
                                    <span
                                        className={"webinar-duration white"}>{parseTrainingMinutes(product.videosLength)} min</span>
                                    <div className={"webinar-dates-container"}>
                                        {product && product.timetable.map((date, index) => (
                                            <div key={index}
                                                 className={date.expired ? 'webinar-date-item expired' : 'webinar-date-item'}>
                                                <span>{index === 0 ? <>1&nbsp;</> : index + 1} TERMIN</span><p>
                                            <span
                                                className={"time"}>{dateFormat(Date.parse(date.eventStartDate), 'HH:MM')}</span><span
                                                className="date">
                                             &nbsp;| {dateFormat(Date.parse(date.eventStartDate), 'dd-mm-yyyy')}</span>
                                            </p>
                                            </div>
                                        ))}
                                    </div>
                                    {product.accessExpired ?
                                        <Button href={"/abonament"}
                                                className={"webinar-btn webinar-btn-white"}>Kup w
                                            abonamencie</Button>
                                        : ''
                                    }
                                    {/*{product.accessDenied && !product.accessByKey && !product.accessExpired ?*/}
                                    {/*<Button href={`/rejestracja-webinar/${product.id}`}*/}
                                    {/*className={"webinar-btn reverse"}>Kup*/}
                                    {/*dostęp</Button>*/}
                                    {/*: ''*/}
                                    {/*}*/}
                                </Col>
                                <Col lg={9} md={12}
                                     className={"webinar-image webinar-image-denied"}>
                                    <div className={"webinar-image-badge"}>
                                        <div className={"webinar-image-badge-type"}>webinar płatny</div>
                                        <div
                                            className={`webinar-image-badge-category badge-${strNormalize(product.categories[0].name)}`}>{product.categories[0].name}</div>
                                    </div>
                                    <div className={"opacity-background"} style={{width: `${this.width}`}}>
                                    </div>
                                    <h3>{product.accessDeniedMessage}</h3>
                                    {product.imageUrl
                                        ? <img src={product.imageUrl} alt={product.name}/>
                                        : <img src={"/zaslepka.png"} alt={"webinar"}/>}

                                </Col>
                            </Row>}
                    </Container>
                </Container>
                <Container fluid className={"webinar-form-section webinar-video-details-section"}>
                    <Container>
                        <Row>
                            <Col className={"webinar-details-container"} lg={8} md={6}>
                                <Container className={"webinar-details-container-content"}>
                                    <ProductContent children={this.props.children}/>
                                </Container>
                            </Col>
                            <Col className={"webinar-form-container"} lg={4} md={6}>
                                {product.accessExpired ?
                                    <Container className={"webinar-offer-container mt-4"}>
                                        <h4 id={"WebinarForm"}>Kup Webinar</h4>
                                        <h2>{product.name}</h2>
                                        <p className={"offer-item"}>Autor:
                                            {product.experts ?
                                                <>{product && product.experts.map((expert, key) => (
                                                    <span key={key}>&nbsp;
                                                        {expert.name} {expert.surname}{product.experts.length > key + 1 && product.experts.length > 1 ? ', ' : ''}
                                            </span>
                                                ))}
                                                </>
                                                : ''
                                            }
                                        </p>
                                        <p className={"offer-item"}>Termin: {product && product.timetable[0] ? dateFormat(Date.parse(product.timetable[0].eventStartDate), 'dd-mm-yyyy') : ''}
                                        </p>
                                        <p className={"offer-item"}>Godzina
                                            rozpoczęcia: {product && product.timetable[0] ? dateFormat(Date.parse(product.timetable[0].eventStartDate), 'HH:MM') : ''}
                                        </p>
                                        <p className={"offer-item"}>Czas
                                            trwania: {parseTrainingMinutes(product.videosLength)} min</p>

                                        <h2 className={"mb-4 mt-4"}>Zapisy na ten webinar zostały zamknięte. Możesz
                                            jednak obejrzeć ten materiał
                                            kupując dostęp do platformy.</h2>
                                        <Button href="/abonament" className={"webinar-btn reverse"}>Kup
                                            abonament</Button>
                                    </Container>
                                    :
                                    ''

                                }
                                {/*{!product.accessExpired && product.accessDenied && product.accessByKey ?*/}
                                {/*<Container className={"webinar-offer-container mt-4"}>*/}
                                {/*<h4 id={"WebinarForm"}>Kup Webinar</h4>*/}
                                {/*<h2>{product.name}</h2>*/}
                                {/*<p className={"offer-item"}>Autor:*/}
                                {/*{product.experts ?*/}
                                {/*<>{product && product.experts.map((expert, key) => (*/}
                                {/*<span key={key}>&nbsp;*/}
                                {/*{expert.name} {expert.surname}{product.experts.length > key + 1 && product.experts.length > 1 ? ', ' : ''}*/}
                                {/*</span>*/}
                                {/*))}*/}
                                {/*</>*/}
                                {/*: ''*/}
                                {/*}*/}
                                {/*</p>*/}
                                {/*<p className={"offer-item"}>Termin: {product && product.timetable[0] ? dateFormat(Date.parse(product.timetable[0].eventStartDate), 'dd-mm-yyyy') : ''}*/}
                                {/*</p>*/}
                                {/*<p className={"offer-item"}>Godzina*/}
                                {/*rozpoczęcia: {product && product.timetable[0] ? dateFormat(Date.parse(product.timetable[0].eventStartDate), 'HH:MM') : ''}*/}
                                {/*</p>*/}
                                {/*<p className={"offer-item"}>Czas*/}
                                {/*trwania: {parseTrainingMinutes(product.videosLength)} min</p>*/}
                                {/*<div className={'price-container webinar-paid-price-container'}>*/}
                                {/*<h2 className={"mt-5"}>{product.additionalFields.priceGross.toFixed(2)} zł<span> brutto</span>*/}
                                {/*</h2>*/}
                                {/*<h3 className={"mb-4 mt-2 pb-3"}>{product.additionalFields.priceNet.toFixed(2)} zł<span> netto</span>*/}
                                {/*</h3>*/}
                                {/*</div>*/}
                                {/*<p>Cena dotyczy jednego dostępu, w dalszych krokach istnieje możliwość zakupu*/}
                                {/*produktu dla wielu stanowisk.</p>*/}
                                {/*<p>Posiadasz kod rabatowy? Wpisz go w kolejnym kroku.</p>*/}
                                {/*<Button href={`/rejestracja-webinar/${product.id}`}*/}
                                {/*className={"webinar-btn reverse"}>Kup*/}
                                {/*teraz</Button>*/}
                                {/*</Container>*/}
                                {/*:*/}
                                {/*''*/}
                                {/*}*/}


                                <RightSection product={product}/>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                {product.ads && product.ads.map((item, index) => {
                    if (item.zone === "leaderboard") {
                        return (
                            <Container className={"text-center mb-5"} key={index}>
                                <div className={"product-banner banner-front"}>
                                    {item.url ? <a target={'_blank'} href={item.url} rel='noreferrer'>
                                        <img alt={item.name} src={item.imageUrl}/>
                                    </a> : ''}

                                    <Helmet>
                                        <script>{item.js}</script>
                                    </Helmet>
                                </div>
                            </Container>
                        )
                    }
                    return '';
                })}
            </>
        )
    }
}

export default WebinarPaidVideo;
