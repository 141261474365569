import {APP_NAME, APP_URL} from "../constants";
import {Col, Row} from "react-bootstrap";
import React from "react";
import Container from "react-bootstrap/Container";

export const parseTrainingTime = (sec) => {
    let hours = Math.floor(sec / 3600);
    (hours >= 1) ? sec = sec - (hours * 3600) : hours = '00';

    let min = Math.floor(sec / 60);
    (min >= 1) ? sec = sec - (min * 60) : min = '00';

    (sec < 1) ? sec = '00' : void 0;

    (min.toString().length === 1) ? min = '0' + min : void 0;
    (sec.toString().length === 1) ? sec = '0' + sec : void 0;

    return hours + ':' + min + ':' + sec;
}
export const parseTrainingMinutes = (sec) => {


    let min = Math.ceil(sec / 60);
    (min >= 1) ? sec = sec - (min * 60) : min = '00';
    (min.toString().length === 1) ? min = '0' + min : void 0;

    return min;
}
export const convertMetaTitle = (title) => {
    if (typeof title === 'string') {
        return title.replace(/(.{150})..+/, "$1…") + ' - ' + APP_NAME;
    }
    return;
};

export const convertMetaDescription = (description) => {
    if (typeof description === 'string') {
        return description.replace(/(<([^>]+)>)/gi, "").replace(/(.{500})..+/, "$1…");
    }
    return;
};

export const cannonicalUrl = (url) => {
    let tmp = `${APP_URL}/${url}`;
    return tmp;
}

export const strNormalize = (name) => {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return name.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w-]+/g, '') // Remove all non-word characters
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
        .replace(/\?/g, '')
}
export const getPrice = (price) => {
    const tmp = price + "";
    const separator = '.';
    return tmp.substring(0, tmp.length - 2) + separator + tmp.substring(tmp.length - 2, tmp.length);
}

export const getBreadcrumb = (items, index, cssClass = 'breadcrumb-item') => {
    return (
        <Row className={"breadcrumb-container"}>
            <Container>
                {items.map((value) => (
                    <Col key={value.type} className={`${index === value.type ? 'active' : ''}`}>
                        <div className={`${cssClass} ${index === value.type ? 'active' : ''}`}>{value.name}</div>
                    </Col>
                ))
                }
            </Container>
        </Row>
    );
}
export const createUrl = (url) => {
    const myUrlWithParams = new URL(url);

    if (window.sessionStorage.getItem('cid')) {
        myUrlWithParams.searchParams.append("cid", window.sessionStorage.getItem('cid'));
    }
    if (window.sessionStorage.getItem('utm_source')) {
        myUrlWithParams.searchParams.append("utm_source", window.sessionStorage.getItem('utm_source'));
    }
    if (window.sessionStorage.getItem('utm_medium')) {
        myUrlWithParams.searchParams.append("utm_medium", window.sessionStorage.getItem('utm_medium'));
    }
    if (window.sessionStorage.getItem('utm_campaign')) {
        myUrlWithParams.searchParams.append("utm_campaign", window.sessionStorage.getItem('utm_campaign'));
    }
    if (window.sessionStorage.getItem('lin')) {
        myUrlWithParams.searchParams.append("lin", window.sessionStorage.getItem('lin'));
    }

    return myUrlWithParams.href;
}