import React from "react";
import {parseTrainingTime} from "../../common/utils";
import {Button, Container} from "react-bootstrap";
import dateFormat from "dateformat";

export const offerTypes = {
    'month': "Miesiąc",
    'annual': 'Rok',
    'quarter': 'Kwartał'

};

class ProductOffer extends React.Component {

    componentDidMount() {
        const {registerOfferListFetch} = this.props.children;
        registerOfferListFetch();
    }

    callToAction(id) {
        const {history} = this.props.children;
        history.push(`/rejestracja/${id}`);
    }

    render() {
        const {offers, product} = this.props.children;

        if (!offers) {
            return (<></>);
        }

        return (
            <React.Fragment>
                <Container className={"webinar-offer-container"}>
                    <h4>Kup szkolenie</h4>
                    <h2>{product.name}</h2>
                    <p className={"offer-item"}>Autor:
                        {product.experts ?
                            <>{product && product.experts.map((expert, key) => (
                                <span key={key}>&nbsp;
                                    {expert.name} {expert.surname}{product.experts.length > key + 1 && product.experts.length > 1 ? ', ' : ''}
                                            </span>
                            ))}
                            </>
                            : ''
                        }
                    </p>
                    <p className={"offer-item"}>Stan prawny: {dateFormat(product.legalStatusDate, 'dd-mm-yyyy')}</p>
                    <p className={"offer-item"} id={"product-offer"}>Czas
                        trwania: {parseTrainingTime(product.videosLength)}</p>
                </Container>
                <Container className={"webinar-offer-container product-offer"}>
                    <h4>Abonament Educado</h4>
                    <div className={"benefit-items"}>
                        <div className={"item"}><img src={"/green/clock.svg"} alt={"time"}/><span>Ponad 45-minutowe szkolenia specjalistyczne</span>
                        </div>
                        <div className={"item"}><img src={"/infinity-small-black.svg"} alt={"infinity"}/><span>Nieograniczony dostęp do bazy szkoleń z różnych dziedzin po przedłużeniu subskrypcji</span>
                        </div>
                        <div className={"item"}><img src={"/green/certificate.svg"} alt={"certyfikat"}/><span>Testy wiedzy i imienne certyfikaty</span>
                        </div>
                        <div className={"item"}><img src={"/green/materials.svg"} alt={"materiały"}/><span>Dodatkowe materiały szkoleniowe</span>
                        </div>
                    </div>
                    <div className={"price-container"}>JUŻ OD <span><strong>19zł</strong>/ miesięcznie</span></div>
                    <Button className="webinar-btn reverse"
                            href={product.categories[0].name === "Oświata" ? "/oswiata/abonament" : "/ksiegowosc-i-kadry/abonament"}>Kup w abonamencie</Button>
                    {/*<p className={"small-details"}>Cena dotyczy jednego dostępu, w dalszych krokach istnieje możliwość*/}
                        {/*zakupu produktu dla wielu stanowisk.</p>*/}
                    {/*<p className={"small-details"}>Posiadasz kod rabatowy? Wpisz go w kolejnym kroku.</p>*/}
                </Container>
            </React.Fragment>
        )
    }
}

export default ProductOffer;