import React from 'react';
import striptags from 'striptags';
import {Button, Carousel, Col, Container, Row} from "react-bootstrap";
import {parseTrainingTime, strNormalize} from "../../../../../common/utils";
import dateFormat from "dateformat";


const WebinarPaidSliderItem = props => {

    const {product, auth, id} = props;

    return (
        <Col lg={6} className={"slider-details-container"}>
            <Container className="sliders-details-container-content">
                <div className="wrapper">
                    <div className="slider-item-type">
                        <span>{auth && auth.isAuthenticated ? 'Webinar live' :'Webinar płatny'} </span>
                        <span className={"chat-badge"}>chat
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14"
                                 fill="none">
                                <path
                                    d="M12.125 6.68752C12.1272 7.51244 11.9344 8.3262 11.5625 9.06252C11.1215 9.94486 10.4436 10.687 9.60466 11.2058C8.76572 11.7246 7.7989 11.9996 6.8125 12C5.98758 12.0022 5.17382 11.8094 4.4375 11.4375L0.875 12.625L2.0625 9.06252C1.69058 8.3262 1.49785 7.51244 1.5 6.68752C1.50038 5.70112 1.77538 4.7343 2.2942 3.89536C2.81302 3.05642 3.55516 2.3785 4.4375 1.93752C5.17382 1.5656 5.98758 1.37287 6.8125 1.37502H7.125C8.42772 1.44689 9.65815 1.99674 10.5807 2.9193C11.5033 3.84187 12.0531 5.0723 12.125 6.37502V6.68752Z"
                                    stroke="#232936" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                    </div>
                    {product.categories ?
                        <div
                            className={`slider-category-item-container category${id}`}>{product.categories.map((category, key) => (
                            <span className="home-product-category-item" key={key}>{category.name}</span>

                        ))}</div>
                        : ''
                    }
                    <h2>{product.name}</h2>
                    {product.experts ?
                        <h3>Ekspert: {product.experts.map((expert, key) => (
                            <a className="d-inline-block"
                               href={`/ekspert/${strNormalize(expert.name)}-${strNormalize(expert.surname)}/${expert.id}`}
                               title={`${expert.name} ${expert.surname}`}
                               key={key}>&nbsp;{expert.name} {expert.surname}{product.experts.length > key + 1 && product.experts.length > 1 ? ', ' : ''}</a>

                        ))}</h3>
                        : ''
                    }
                    {product.experts ? <p className={'expert-desc'}>{product.experts[0].academicDegree}</p> : ''}
                    <div className={'slider-details-text'}>
                        <div className={"slider-text-info"}>
                            {id === 71 ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"
                                     fill="none">
                                    <path d="M22.5 2.5V5M7.5 2.5V5" stroke="#4AA5D8" strokeWidth="1.5"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M14.9944 16.25H15.0056M14.9944 21.25H15.0056M19.9888 16.25H20M10 16.25H10.0112M10 21.25H10.0112"
                                        stroke="#4AA5D8" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path d="M4.375 10H25.625" stroke="#4AA5D8" strokeWidth="1.5"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M3.125 15.304C3.125 9.85743 3.125 7.1341 4.69015 5.44205C6.2553 3.75 8.77436 3.75 13.8125 3.75H16.1875C21.2256 3.75 23.7448 3.75 25.3099 5.44205C26.875 7.1341 26.875 9.85743 26.875 15.304V15.946C26.875 21.3926 26.875 24.1159 25.3099 25.808C23.7448 27.5 21.2256 27.5 16.1875 27.5H13.8125C8.77436 27.5 6.2553 27.5 4.69015 25.808C3.125 24.1159 3.125 21.3926 3.125 15.946V15.304Z"
                                        stroke="#4AA5D8" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path d="M3.75 10H26.25" stroke="#4AA5D8" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg> :
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"
                                     fill="none">
                                    <path d="M22.5 2.5V5M7.5 2.5V5" stroke="#FDD26E" strokeWidth="1.5"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M14.9944 16.25H15.0056M14.9944 21.25H15.0056M19.9888 16.25H20M10 16.25H10.0112M10 21.25H10.0112"
                                        stroke="#FDD26E" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path d="M4.375 10H25.625" stroke="#FDD26E" strokeWidth="1.5"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M3.125 15.304C3.125 9.85743 3.125 7.1341 4.69015 5.44205C6.2553 3.75 8.77436 3.75 13.8125 3.75H16.1875C21.2256 3.75 23.7448 3.75 25.3099 5.44205C26.875 7.1341 26.875 9.85743 26.875 15.304V15.946C26.875 21.3926 26.875 24.1159 25.3099 25.808C23.7448 27.5 21.2256 27.5 16.1875 27.5H13.8125C8.77436 27.5 6.2553 27.5 4.69015 25.808C3.125 24.1159 3.125 21.3926 3.125 15.946V15.304Z"
                                        stroke="#FDD26E" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path d="M3.75 10H26.25" stroke="#FDD26E" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>
                            }
                            {product && product.timetable.map((data, index) => (
                                <div key={index}
                                     className={data.expired ? 'webinar-date-item expired' : 'webinar-date-item'}>
                                    <span>Termin: {dateFormat(data.eventStartDate, 'dd-mm-yyyy')} | Godzina: {dateFormat(data.eventStartDate, 'HH:MM')}</span>

                                </div>
                            ))}

                        </div>
                        <div className={"slider-text-info"}>
                            {id === 71 ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"
                                     fill="none">
                                    <rect x="0.575" y="0.575" width="28.85" height="28.85" rx="14.425" stroke="#4AA5D8"
                                          strokeWidth="1.15"/>
                                    <rect x="9.375" y="9.375" width="11.25" height="11.25" rx="5.625" fill="#4AA5D8"/>
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"
                                     fill="none">
                                    <rect x="0.575" y="0.575" width="28.85" height="28.85" rx="14.425" stroke="#FDD26E"
                                          strokeWidth="1.15"/>
                                    <rect x="9.375" y="9.375" width="11.25" height="11.25" rx="5.625" fill="#FDD26E"/>
                                </svg>
                            }
                            <p>LIVE z chatem</p>
                        </div>
                    </div>
                </div>
                <div className={"slider-button-wrapper"}>
                    {auth && auth.isAuthenticated ? '' : <Button
                        href={`/${strNormalize(product.type)}/${strNormalize(product.name)}/${product.id}`}
                        title={product.name} className={"educado-btn-text"}>
                        Więcej o webinarze
                    </Button>}
                    {auth && auth.isAuthenticated ?
                        <div className={'slider-button-container justify-content-end'}>
                            <Button
                                className="btn-watch educado-btn educado-btn-full"
                                href={`/${strNormalize(product.type)}/${strNormalize(product.name)}/${product.id}`}
                                title={product.name}>Oglądaj</Button>
                        </div> :
                        <div className={'slider-button-container'}>
                            <Button
                                href={`/rejestracja-webinar/${product.id}`}
                                title={product.name} className={"educado-btn-text"}>
                                <span>{product.additionalFields.priceGross} zł</span> dostęp jednorazowy
                            </Button>
                            <Button
                                className="educado-btn educado-btn-full"
                                href={id === 71 ? `/oswiata/abonament` : `/ksiegowosc-i-kadry/abonament`}
                                title={product.name}>Kup w abonamencie</Button>
                        </div>}
                </div>
            </Container>
        </Col>
    )
}

export default (WebinarPaidSliderItem);