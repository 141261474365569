import {
    PROFILE_VIDEO_REQUEST,
    PROFILE_VIDEO_SAVED_WATCH_SUCCESS,
    PROFILE_VIDEO_WATCH_LIST_RECEIVED,
    PROFILE_VIDEO_WATCH_RECEIVED,
    PROFILE_VIDEO_ERROR,
    PROFILE_VIDEO_SET_TIME_WATCHED
} from "../constants";

export const profileVideo = (state = {
    isFetching: false,
    vimeoId: false,
    videoId: false,
    sort: 1,
    update: false,
    watchedTime: false
}, action) => {
    switch (action.type) {
        case PROFILE_VIDEO_REQUEST:
            state = {
                ...state,
                isFetching: true
            };
            return state;
        case PROFILE_VIDEO_SET_TIME_WATCHED:
            return {
                ...state,
                isFetching: false,
                watchedTime: action.time,
                videoId: action.videoId,
                vimeoId: action.vimeoId,
                sort: action.sort,
                update: action.update,
            };
        case PROFILE_VIDEO_SAVED_WATCH_SUCCESS:
            return {
                ...state,
                isFetching: false
            };
        case PROFILE_VIDEO_WATCH_RECEIVED:
            return {
                ...state,
                watchedTime: action.data.watchedTime,
                isFetching: false
            };
        case PROFILE_VIDEO_WATCH_LIST_RECEIVED:
            return {
                ...state,
                watchVideos: action.data['hydra:member'],
                isFetching: false
            };
        case PROFILE_VIDEO_ERROR:
            state = {
                ...state,
                isFetching: false,
                watchVideos: null,
                watchStatusVideo: null
            };
            return state;
        default:
            return state;
    }
}