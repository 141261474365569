import React from 'react';
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {convertMetaDescription, convertMetaTitle} from "../../../common/utils";
import Helmet from "react-helmet";
import ExpertContainer from "../../Modules/Experts/Sliders/ExpertSliderContainer";
import AwardsSlider from "../../Modules/AwardsBanner/AwardsSlider";
import "react-multi-carousel/lib/styles.css";
import connect from "react-redux/es/connect/connect";
import SchoolMultiForm from "./SchoolMultiForm";
import Breadcrumb from "../../../common/breadcrumb";

const mapStateToProps = state => ({
    ...state.closedTrainings,

});

const mapDispatchToProps = {}


class SchoolMulti extends React.Component {

    componentDidMount() {
    }

    render() {
        const {received, brand, error} = this.props;

        return (
            <>
                <Helmet>
                    <title>{convertMetaTitle(brand === 1 ? 'Plan dla szkoły' : 'Plan dla firm')}</title>
                    <meta name="description"
                          content={convertMetaDescription(brand === 1 ? 'Oferta dla szkół Educado.' : 'Oferta dla firm')}/>
                </Helmet>
                <div className={'school-multi-container'}>
                    <Container>
                        <Breadcrumb id={brand === 1 ? 71 : 13} dark
                                    items={[{name: brand === 1 ? 'oferta dla szkół' : 'oferta dla firm'}]}/>
                        <h1 className={''}>Zapytaj o ofertę</h1>

                    </Container>
                    <Container className={"form-multi-container"}>
                        <Row>
                            <Col md={6} lg={8} className={""}>
                                <div className={"form-col"}>
                                    {received && received ?
                                        <div className={"thx-multi-form"}>
                                            <h2 className={'text-center'}>
                                                Już prawie gotowe!<br/><br/>Potwierdź jeszcze swój adres e-mail</h2>
                                            <p className={'text-center'}>Kliknij w link wysłany na podany przez Ciebie
                                                adres e-mail,<br/> a nasz konsultant skontaktuje się z Tobą w ciągu 1 dnia
                                                roboczego.</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="296" height="233" viewBox="0 0 296 233" fill="none">
                                                <g clipPath="url(#clip0_3100_38052)">
                                                    <path d="M112.916 203.715L99.4768 195.366L95.0592 193.982C95.0592 193.982 86.9272 193.604 82.184 189.864L80.3072 190.938L52.6384 192.814L20.4616 174.646L18.1464 171.992L2.05603 113.685L102.191 106.9L115.406 155.308C115.406 155.308 127.222 149.244 133.22 149.947C139.218 150.65 158.529 165.33 158.529 165.33L149.356 203.402L112.916 203.716V203.715Z" fill="white"/>
                                                    <path d="M293.573 230.027H210.512C210.512 230.027 205.436 166.248 213.512 157.644C221.587 149.041 270.819 147.56 270.819 147.56L283.421 144.871C283.421 144.871 293.919 143.04 293.919 157.231L293.573 230.027Z" fill="white"/>
                                                    <path d="M193.402 110.044L198.891 106.424L191.831 88.4884C191.831 88.4884 183.964 96.602 177.079 96.6892C170.195 96.7764 164.679 76.5692 164.679 76.5692L155.335 72.2052C155.335 72.2052 161.554 65.362 161.202 53.2956C161.202 53.2956 158.299 56.0188 154.988 53.3828C154.988 53.3828 139.943 28.0804 158.53 20.6388L160.699 18.7756C160.699 18.7756 169.999 7.61564 174.855 3.91724C179.711 0.218042 187.884 0.650042 195.825 5.62524L199.978 4.58684C199.978 4.58684 210.467 1.72444 221.479 9.36364C232.49 17.0028 233.737 38.3684 235.276 51.306L236.315 57.1652C236.315 57.1652 240.625 71.71 247.735 79.9908C254.846 88.2708 271.887 101.448 269.579 122.213C269.579 122.213 263.638 131.11 250.659 135.4C237.681 139.691 221.242 137.484 216.051 129.156C210.859 120.828 196.191 121.408 193.403 110.044H193.402Z" fill="white"/>
                                                    <path d="M192.46 73.5699C192.46 74.9755 193.6 76.1171 195.008 76.1171C196.416 76.1171 197.556 74.9763 197.556 73.5699C197.556 72.1635 196.415 71.0219 195.008 71.0219C193.601 71.0219 192.46 72.1619 192.46 73.5699Z" fill="#2EE3A9"/>
                                                    <path d="M195.007 77.8466C192.648 77.8466 190.729 75.9274 190.729 73.569C190.729 71.2106 192.648 69.2906 195.007 69.2906C197.366 69.2906 199.286 71.2098 199.286 73.569C199.286 75.9282 197.366 77.8466 195.007 77.8466ZM195.007 72.7514C194.557 72.7514 194.19 73.1178 194.19 73.5682C194.19 74.0186 194.557 74.385 195.007 74.385C195.458 74.385 195.824 74.0186 195.824 73.5682C195.824 73.1178 195.457 72.7514 195.007 72.7514Z" fill="#2EE3A9"/>
                                                    <path d="M273.378 145.649L271.302 122.065C271.232 121.277 270.637 120.636 269.855 120.509C269.072 120.38 268.306 120.801 267.991 121.526C267.94 121.645 261.848 133.442 226.979 134.354C226.155 134.314 215.105 133.402 212.211 113.479C212.094 112.673 211.431 112.058 210.618 112.001L189.339 110.523C189.076 110.505 188.81 110.547 188.566 110.647C188.038 110.862 175.579 116.041 169.563 126.56C164.274 135.806 149.799 181.649 146.673 191.643L130.07 184.355C129.662 184.176 129.201 184.161 128.782 184.314C128.598 184.381 110.486 191.261 114.173 213.557C114.268 214.132 114.646 214.62 115.178 214.857C116.294 215.352 142.646 227.023 154.916 230.057C156.606 230.474 158.262 230.686 159.84 230.686C170.709 230.686 175.312 221.075 176.464 218.129C177.41 215.712 179.753 210.217 181.566 205.997L185.383 230.637C185.514 231.48 186.24 232.106 187.094 232.106H213.626C214.582 232.106 215.354 231.324 215.354 230.368V176.495C215.354 176.449 215.354 176.401 215.35 176.354C215.302 175.777 214.354 162.189 227.721 158.885C240.006 155.85 271.771 147.558 272.09 147.475C272.907 147.261 273.451 146.49 273.378 145.649L273.378 145.649Z" fill="#2EE3A9"/>
                                                    <path d="M100.768 54.9198C100.768 40.387 88.9876 28.6062 74.4548 28.6062C59.922 28.6062 48.142 40.387 48.142 54.9198C48.142 67.9502 57.6156 78.7614 70.0492 80.8582L74.4548 88.4902L78.8612 80.8582C91.2948 78.7614 100.768 67.9502 100.768 54.9198Z" fill="#2EE3A9"/>
                                                    <path d="M164.297 55.5822C164.489 56.6342 165.498 57.3286 166.548 57.1382C167.6 56.947 168.297 55.939 168.104 54.8862C167.912 53.8358 166.905 53.1398 165.854 53.3318C164.803 53.5238 164.106 54.531 164.297 55.583V55.5822Z" fill="#232936"/>
                                                    <path d="M72.3092 66.6675H72.2948C71.7188 66.6675 71.17 66.2603 70.7796 65.8347L61.6077 55.7635C60.8317 54.9187 60.8885 53.5651 61.7333 52.7899C62.5789 52.0139 63.8917 52.0523 64.6669 52.8955L72.33 61.2323L84.7309 48.0891C85.5181 47.2563 86.8325 47.2155 87.6661 48.0027C88.5005 48.7899 88.5381 50.1027 87.7509 50.9371L73.8188 65.8563C73.4268 66.2723 72.8797 66.6667 72.3085 66.6667L72.3092 66.6675Z" fill="white"/>
                                                    <path d="M163.214 48.8447C162.509 48.8447 161.846 48.4103 161.589 47.7087C161.26 46.8119 161.721 45.8175 162.618 45.4887C162.724 45.4503 165.254 44.5319 167.85 44.6327C168.805 44.6687 169.55 45.4719 169.514 46.4271C169.478 47.3815 168.683 48.0975 167.719 48.0903C165.828 48.0175 163.829 48.7303 163.81 48.7375C163.612 48.8095 163.411 48.8447 163.214 48.8447Z" fill="#232936"/>
                                                    <path d="M174.292 90.7824C174.166 90.7824 174.038 90.768 173.911 90.74C172.979 90.5304 172.393 89.6048 172.602 88.672C173.316 85.488 173.162 82.1224 172.93 79.9784C170.9 81.0968 169.728 82.0984 169.713 82.112C168.99 82.7328 167.898 82.6561 167.273 81.9361C166.648 81.2145 166.724 80.1248 167.443 79.4984C167.538 79.416 169.806 77.46 173.601 75.7904C174.078 75.58 174.626 75.5968 175.089 75.8352C175.553 76.0736 175.885 76.5088 175.992 77.0192C176.046 77.28 177.315 83.4784 175.979 89.4304C175.798 90.2352 175.084 90.7824 174.293 90.7824H174.292Z" fill="#232936"/>
                                                    <path d="M293.954 231.754C292.998 231.754 292.234 230.98 292.234 230.024V157.229C292.234 153.001 291.134 149.995 289.01 148.295C285.983 145.872 281.582 146.769 281.539 146.78L264.456 150.863C263.526 151.087 262.59 150.512 262.368 149.582C262.146 148.652 262.718 147.719 263.647 147.496L280.747 143.408C281.014 143.347 286.805 142.104 291.163 145.594C294.148 147.984 295.674 151.9 295.674 157.231V230.025C295.674 230.981 294.91 231.756 293.954 231.756V231.754Z" fill="#232936"/>
                                                    <path d="M18.1448 173.73C17.3856 173.73 16.6896 173.227 16.4776 172.459L0.387982 114.151C0.249582 113.651 0.343982 113.114 0.644782 112.69C0.945582 112.266 1.42078 111.999 1.93918 111.964L102.074 105.179C102.894 105.121 103.641 105.653 103.859 106.446L116.226 151.303C116.48 152.225 115.939 153.177 115.018 153.431C114.097 153.687 113.144 153.145 112.89 152.223L100.898 108.727L4.28798 115.275L19.8136 171.539C20.068 172.46 19.5272 173.413 18.6056 173.667C18.4512 173.71 18.2968 173.731 18.144 173.731L18.1448 173.73Z" fill="#232936"/>
                                                    <path d="M23.8691 165.834C23.0939 165.834 22.4067 165.315 22.2003 164.559L10.1707 120.415C10.0347 119.915 10.1299 119.38 10.4307 118.958C10.7315 118.535 11.2059 118.27 11.7235 118.235L96.6659 112.479C97.4859 112.421 98.2355 112.955 98.4522 113.751L109.219 153.283C109.471 154.204 108.927 155.155 108.004 155.407C107.083 155.66 106.131 155.115 105.88 154.193L95.4859 116.028L14.0659 121.545L25.1675 162.283L67.7107 159.416C68.6619 159.349 69.4891 160.072 69.5531 161.027C69.6171 161.98 68.8963 162.805 67.9427 162.869L23.9859 165.831C23.9467 165.833 23.9075 165.835 23.8683 165.835L23.8691 165.834Z" fill="#232936"/>
                                                    <path d="M23.4669 172.935C22.5645 172.935 21.8045 172.235 21.7421 171.321C21.6773 170.367 22.3981 169.542 23.3517 169.478L58.5717 167.091C59.5301 167.028 60.3501 167.747 60.4149 168.701C60.4797 169.655 59.7589 170.48 58.8053 170.544L23.5853 172.931C23.5453 172.933 23.5061 172.935 23.4669 172.935Z" fill="#232936"/>
                                                    <path d="M52.6377 194.548C52.3401 194.548 52.0465 194.471 51.7873 194.324L19.6105 176.156C18.7777 175.686 18.4841 174.63 18.9545 173.798C19.4241 172.966 20.4801 172.673 21.3121 173.142L53.0377 191.056L80.1905 189.216C81.1473 189.158 81.9689 189.872 82.0337 190.826C82.0985 191.779 81.3777 192.605 80.4241 192.669L52.7545 194.544C52.7161 194.546 52.6761 194.548 52.6377 194.548Z" fill="#232936"/>
                                                    <path d="M48.6544 150.487C47.8696 150.487 47.176 149.956 46.9792 149.187L42.2632 130.775C42.136 130.277 42.236 129.748 42.5376 129.332C42.8392 128.915 43.3096 128.655 43.8232 128.619L72.828 126.654C73.66 126.599 74.4144 127.143 74.6216 127.951L79.3384 146.362C79.4656 146.859 79.3656 147.388 79.064 147.805C78.7624 148.222 78.292 148.483 77.7784 148.518L48.7736 150.483C48.7344 150.486 48.6952 150.487 48.656 150.487H48.6544ZM46.132 131.931L49.9744 146.933L75.468 145.205L71.6248 130.203L46.132 131.931V131.931Z" fill="#232936"/>
                                                    <path d="M61.492 140.995C61.2448 140.995 60.9952 140.943 60.7616 140.834L44.5936 133.303C43.7272 132.899 43.352 131.87 43.756 131.003C44.1592 130.136 45.1888 129.76 46.0552 130.164L61.1592 137.2L70.3728 128.509C71.068 127.853 72.1632 127.886 72.8184 128.58C73.4744 129.276 73.4424 130.371 72.7472 131.027L62.68 140.523C62.352 140.833 61.9248 140.995 61.4928 140.995H61.492Z" fill="#232936"/>
                                                    <path d="M95.0628 195.716C95.022 195.716 94.9804 195.714 94.9388 195.712C78.5188 194.551 71.4532 182.084 68.4356 176.761C68.1756 176.301 67.9476 175.899 67.7484 175.568C66.1468 172.905 65.0068 172.453 64.838 172.4C63.9628 172.328 63.2684 171.601 63.2532 170.705C63.2372 169.749 63.998 168.961 64.954 168.945C65.6188 168.916 67.9876 169.25 70.714 173.784C70.9268 174.137 71.17 174.566 71.4468 175.054C74.19 179.895 80.614 191.231 95.1828 192.26C96.1364 192.327 96.854 193.155 96.7868 194.108C96.7228 195.02 95.9628 195.717 95.0628 195.717V195.716Z" fill="#232936"/>
                                                    <path d="M124.187 187.662C123.928 187.662 123.666 187.604 123.419 187.481L106.322 178.994C106.139 178.903 105.973 178.78 105.833 178.632C101.216 173.737 97.5468 169.017 94.5996 165.225C92.2156 162.158 90.1564 159.508 88.2708 157.608C87.3708 156.701 86.5332 156.289 85.774 156.375C84.5988 156.513 83.5732 157.829 83.3628 158.174C82.9092 158.919 81.974 159.213 81.1764 158.852C80.0628 158.352 79.5268 158.596 79.3508 158.675C78.5308 159.048 78.0308 160.177 77.9076 160.581C77.7636 161.051 77.426 161.444 76.9796 161.652C76.534 161.859 76.0196 161.873 75.5668 161.68C74.3148 161.143 73.346 161.064 72.6876 161.441C71.7252 161.993 71.3396 163.514 71.2724 164.052C71.2028 164.608 70.8676 165.102 70.374 165.368C69.8804 165.632 69.2884 165.648 68.786 165.4C67.5052 164.765 66.3332 164.656 65.2036 165.068C61.6468 166.364 59.034 172.34 57.6308 175.551C57.2756 176.364 56.9948 177.005 56.7428 177.49C56.414 178.123 56.398 178.697 56.702 178.953C57.026 179.228 57.7796 179.212 58.6724 178.533C61.6236 176.293 65.194 172.532 65.23 172.493C65.886 171.799 66.982 171.768 67.6764 172.426C68.37 173.083 68.4004 174.178 67.7436 174.872C67.59 175.035 63.9404 178.88 60.766 181.289C58.3452 183.128 55.8604 182.769 54.4716 181.599C52.8812 180.258 52.5676 178.018 53.6732 175.892C53.8708 175.511 54.1444 174.887 54.4604 174.164C56.1844 170.22 59.0708 163.618 64.0204 161.816C65.4412 161.299 66.902 161.22 68.382 161.58C68.8188 160.495 69.59 159.239 70.9388 158.455C72.2012 157.72 73.6716 157.553 75.3268 157.956C75.8412 157.094 76.6572 156.108 77.8876 155.539C78.6516 155.185 79.7988 154.897 81.27 155.241C82.098 154.322 83.4932 153.157 85.37 152.936C87.2396 152.72 89.0404 153.469 90.7284 155.17C92.762 157.22 94.8804 159.945 97.3332 163.101C100.339 166.968 103.739 171.342 108.14 176.032L124.959 184.381C125.815 184.806 126.164 185.844 125.739 186.7C125.436 187.309 124.824 187.662 124.188 187.662H124.187Z" fill="#232936"/>
                                                    <path d="M101.713 167.063C100.354 167.063 99.244 166.933 98.34 166.68C93.3208 165.275 92.9528 159.732 92.94 159.496C92.8872 158.551 93.604 157.738 94.548 157.673C107.65 156.767 119.098 152.016 123.987 149.986L124.336 149.841C129.178 147.834 133.575 147.681 137.406 149.386C140.472 150.751 154.227 159.926 155.786 160.968C156.579 161.499 156.793 162.573 156.262 163.368C155.731 164.161 154.657 164.376 153.862 163.845C149.695 161.06 138.365 153.601 135.998 152.548C133.068 151.245 129.59 151.409 125.66 153.038L125.314 153.182C120.518 155.173 109.603 159.702 96.796 160.957C97.172 161.897 97.8944 162.961 99.272 163.347C103.591 164.554 116.106 161.382 126.526 158.484C127.445 158.225 128.402 158.765 128.658 159.687C128.914 160.608 128.375 161.561 127.454 161.817C114.527 165.415 106.55 167.062 101.712 167.063H101.713Z" fill="#232936"/>
                                                    <path d="M144.396 180.142C140.809 180.142 137.706 178.071 135.351 176.499C134.151 175.699 133.018 174.943 132.213 174.715C129.543 173.955 127.664 174.264 123.545 174.937C122.222 175.153 120.723 175.399 118.93 175.649C116.386 176.004 114.431 175.468 113.121 174.051C110.962 171.718 111.686 168.078 111.775 167.671C111.929 166.973 112.495 166.441 113.201 166.332C118.854 165.459 123.973 163.383 126.815 162.063L125.167 160.745C124.421 160.148 124.3 159.059 124.897 158.313C125.494 157.566 126.582 157.444 127.329 158.043L131.088 161.05C131.538 161.41 131.778 161.968 131.732 162.542C131.686 163.115 131.357 163.628 130.855 163.91C130.55 164.081 123.622 167.935 115.051 169.528C115.047 170.28 115.174 171.182 115.665 171.707C116.283 172.369 117.52 172.354 118.45 172.223C120.204 171.977 121.682 171.736 122.986 171.523C127.201 170.834 129.746 170.417 133.158 171.386C134.486 171.763 135.839 172.666 137.271 173.622C141.57 176.491 144.942 178.243 149.186 174.619C149.914 173.999 151.006 174.086 151.626 174.813C152.246 175.54 152.16 176.633 151.433 177.253C148.946 179.374 146.581 180.143 144.394 180.143L144.396 180.142Z" fill="#232936"/>
                                                    <path d="M194.613 71.527C194.133 71.527 193.656 71.4478 193.187 71.287C192.282 70.9782 191.8 69.9942 192.109 69.0902C192.417 68.1862 193.4 67.7014 194.306 68.0126C194.498 68.0758 194.812 68.131 195.312 67.8398C196.305 67.2582 197.677 65.4326 198.274 62.203C198.803 59.3414 198.572 55.3606 197.11 53.291C196.576 52.5358 195.928 52.107 195.127 51.979C192.184 51.5078 191.409 54.2398 191.284 54.7926C191.073 55.7206 190.154 56.3102 189.224 56.107C188.295 55.9038 187.703 54.9958 187.9 54.0654C188.377 51.8094 190.616 47.7494 195.671 48.5622C197.392 48.8366 198.867 49.7814 199.936 51.2942C202.056 54.295 202.337 59.251 201.677 62.8318C200.991 66.5478 199.265 69.5358 197.06 70.827C196.264 71.2926 195.436 71.527 194.612 71.527H194.613Z" fill="#232936"/>
                                                    <path d="M189.48 55.113C189.386 55.113 189.293 55.1058 189.198 55.0898C185.416 54.4698 181.269 49.1042 178.455 44.7122C177.549 43.2978 176.661 41.749 175.802 40.2506C173.605 36.4186 171.334 32.4562 169.017 31.2018C168.364 30.8482 167.829 30.7458 167.427 30.8946C166.823 31.1194 166.401 31.917 166.301 32.1794C165.962 33.0658 164.969 33.5234 164.081 33.1946C163.19 32.865 162.728 31.8906 163.048 30.9978C163.138 30.745 163.994 28.5026 166.179 27.6666C167.134 27.3002 168.688 27.089 170.663 28.1578C173.846 29.881 176.254 34.0818 178.804 38.5298C179.642 39.9922 180.509 41.5034 181.369 42.8458C185.308 48.9938 188.35 51.4442 189.758 51.6754C190.701 51.8298 191.34 52.7194 191.186 53.6626C191.047 54.5106 190.313 55.113 189.48 55.113Z" fill="#232936"/>
                                                    <path d="M177.767 98.4613C177.427 98.4613 177.092 98.4421 176.763 98.4045C173.998 98.0861 171.705 96.4461 169.755 93.3917C166.771 88.7189 164.09 80.5205 163.271 77.8885C159.02 76.2221 154.69 73.8301 154.496 73.7229C154.026 73.4621 153.703 72.9973 153.624 72.4653C153.544 71.9341 153.716 71.3949 154.089 71.0077C158.96 65.9541 160.3 55.5485 159.082 50.6053C157.18 42.8869 156.816 37.1205 156.802 36.8789C156.744 35.9253 157.472 35.1061 158.424 35.0477C159.369 34.9829 160.198 35.7165 160.256 36.6701C160.26 36.7269 160.62 42.3845 162.442 49.7781C163.728 54.9957 162.773 65.2117 158.032 71.6781C159.832 72.6021 162.665 73.9837 165.279 74.9493C165.791 75.1389 166.184 75.5581 166.338 76.0813C166.368 76.1837 169.423 86.4405 172.673 91.5285C174.046 93.6797 175.472 94.7717 177.16 94.9661C180.467 95.3509 185.292 92.4669 191.502 86.4077C192.186 85.7405 193.281 85.7533 193.949 86.4373C194.617 87.1213 194.604 88.2173 193.92 88.8845C187.257 95.3869 182.086 98.4605 177.768 98.4605L177.767 98.4613Z" fill="#232936"/>
                                                    <path d="M192.708 51.2402C192.003 51.2402 191.34 50.8057 191.083 50.1049C188.941 44.2562 185.645 38.7602 185.611 38.7058C185.118 37.8874 185.381 36.8242 186.199 36.3306C187.018 35.8362 188.08 36.1002 188.575 36.9186C188.716 37.153 192.073 42.7441 194.333 48.9137C194.662 49.8113 194.2 50.8049 193.303 51.1346C193.107 51.2065 192.905 51.241 192.708 51.241V51.2402Z" fill="#232936"/>
                                                    <path d="M154.99 55.1189C154.368 55.1189 153.767 54.7822 153.457 54.1926C148.124 44.0469 146.466 35.9149 148.387 29.3326C151.071 20.1349 157.975 18.9773 158.268 18.9325C159.211 18.7885 160.096 19.4358 160.24 20.3822C160.384 21.3214 159.743 22.2005 158.806 22.3517C158.568 22.3933 153.743 23.3333 151.708 30.3021C150.074 35.9021 151.693 43.399 156.519 52.583C156.964 53.4286 156.639 54.475 155.794 54.9198C155.537 55.055 155.261 55.1189 154.99 55.1189Z" fill="#232936"/>
                                                    <path d="M226.972 137.129C226.874 137.129 226.774 137.121 226.674 137.103C209.749 134.163 207.794 105.774 206.744 90.5218C206.549 87.689 206.381 85.2426 206.158 83.745C205.016 76.0914 200.794 71.1818 200.752 71.133C200.125 70.4146 200.196 69.3218 200.914 68.693C201.63 68.0634 202.72 68.1322 203.352 68.8482C203.552 69.0762 208.282 74.5266 209.581 83.2338C209.825 84.8682 209.989 87.2586 210.198 90.2842C211.182 104.59 213.015 131.219 227.267 133.695C228.208 133.858 228.839 134.754 228.676 135.695C228.53 136.537 227.798 137.13 226.973 137.13L226.972 137.129Z" fill="#232936"/>
                                                    <path d="M160.699 20.5085C160.256 20.5085 159.815 20.3405 159.476 20.0029C158.8 19.3277 158.8 18.2317 159.474 17.5557C159.509 17.5197 163.066 13.9445 168.14 7.86532C175.641 -1.12428 186.066 -2.47468 196.738 4.15812C197.551 4.66212 197.799 5.72932 197.295 6.54052C196.791 7.35252 195.724 7.60292 194.912 7.09652C185.73 1.39252 177.166 2.45172 170.796 10.0821C165.616 16.2909 162.072 19.8517 161.924 20.0005C161.586 20.3389 161.142 20.5085 160.699 20.5085Z" fill="#232936"/>
                                                    <path d="M113.112 205.134C112.814 205.134 112.511 205.057 112.235 204.895L98.5992 196.859C97.7752 196.373 97.5016 195.312 97.9864 194.489C98.4712 193.667 99.532 193.392 100.355 193.876L113.991 201.912C114.815 202.398 115.089 203.459 114.604 204.282C114.281 204.829 113.704 205.134 113.111 205.134H113.112Z" fill="#232936"/>
                                                    <path d="M235.275 53.0404C234.45 53.0404 233.72 52.4492 233.573 51.6092C233.152 49.2108 232.815 46.4348 232.458 43.4948C231.059 31.954 229.317 17.59 220.981 11.15C210.669 3.18358 200.597 6.20998 200.496 6.24118C199.582 6.52838 198.613 6.01958 198.326 5.10678C198.041 4.19478 198.549 3.22438 199.461 2.93798C199.942 2.78598 211.373 -0.647622 223.096 8.41078C232.577 15.7356 234.417 30.8972 235.894 43.078C236.245 45.9708 236.576 48.7028 236.981 51.0092C237.146 51.9508 236.517 52.8476 235.576 53.0124C235.475 53.0308 235.373 53.0388 235.275 53.0388V53.0404Z" fill="#232936"/>
                                                    <path d="M269.846 123.948C269.803 123.948 269.76 123.946 269.716 123.943C268.764 123.873 268.048 123.043 268.119 122.089C269.353 105.412 260.56 96.5175 252.803 88.6703C249.914 85.7479 247.184 82.9879 245.086 79.9871C237.916 69.7351 234.771 58.1039 234.64 57.6135C234.394 56.6903 234.944 55.7431 235.867 55.4975C236.789 55.2479 237.738 55.8007 237.984 56.7239C238.015 56.8383 241.113 68.2687 247.921 78.0039C249.852 80.7639 252.48 83.4223 255.262 86.2367C263.128 94.1943 272.919 104.097 271.568 122.345C271.501 123.254 270.742 123.947 269.845 123.947L269.846 123.948Z" fill="#232936"/>
                                                    <path d="M260.575 130.108C259.654 130.108 258.887 129.381 258.848 128.45C258.134 111.624 251.875 103.882 251.813 103.806C251.204 103.071 251.303 101.982 252.034 101.371C252.766 100.762 253.851 100.854 254.465 101.583C254.753 101.926 261.537 110.188 262.305 128.305C262.345 129.259 261.605 130.066 260.65 130.107C260.625 130.107 260.601 130.109 260.575 130.109V130.108Z" fill="#232936"/>
                                                    <path d="M239.427 137.134C239.15 137.134 238.869 137.068 238.607 136.927C223.262 128.65 220.521 95.2599 220.411 93.8431C220.338 92.8903 221.05 92.0583 222.004 91.9847C222.962 91.9255 223.789 92.6239 223.863 93.5767C223.888 93.9055 226.562 126.498 240.251 133.882C241.092 134.335 241.406 135.385 240.953 136.226C240.64 136.806 240.044 137.135 239.428 137.135L239.427 137.134Z" fill="#232936"/>
                                                    <path d="M247.737 132.982C247.238 132.982 246.745 132.768 246.402 132.355C239.527 124.054 234.885 111.613 234.692 111.086C234.361 110.19 234.819 109.195 235.715 108.864C236.612 108.533 237.607 108.991 237.938 109.886C237.984 110.01 242.574 122.307 249.069 130.147C249.678 130.883 249.576 131.974 248.84 132.584C248.517 132.851 248.125 132.982 247.737 132.982H247.737Z" fill="#232936"/>
                                                    <path d="M196.863 92.4893C196.301 92.4893 195.75 92.2165 195.418 91.7133C194.891 90.9157 195.11 89.8421 195.908 89.3149C201.597 85.5549 206.642 83.4621 206.854 83.3749C207.738 83.0101 208.749 83.4325 209.113 84.3157C209.477 85.1989 209.056 86.2101 208.173 86.5749C208.124 86.5949 203.211 88.6373 197.817 92.2029C197.523 92.3965 197.191 92.4893 196.864 92.4893H196.863Z" fill="#232936"/>
                                                    <path d="M197.901 105.989C197.194 105.989 196.53 105.552 196.274 104.849L192.122 93.429C191.795 92.5306 192.258 91.5379 193.157 91.2115C194.055 90.8835 195.047 91.3482 195.374 92.2466L199.527 103.667C199.853 104.565 199.39 105.558 198.491 105.884C198.297 105.955 198.097 105.989 197.901 105.989Z" fill="#232936"/>
                                                    <path d="M193.404 111.779C192.842 111.779 192.291 111.506 191.958 111.002C191.432 110.204 191.652 109.13 192.449 108.604L198.602 104.546C198.835 104.402 203.305 101.661 208.003 100.215C208.914 99.932 209.884 100.447 210.165 101.36C210.446 102.274 209.933 103.242 209.02 103.523C204.754 104.835 200.504 107.437 200.462 107.462L194.356 111.493C194.062 111.686 193.731 111.778 193.404 111.778V111.779Z" fill="#232936"/>
                                                    <path d="M195.821 157.895C194.987 157.895 194.253 157.291 194.115 156.441C193.184 150.681 189.44 134.86 183.376 130.881C178.059 127.391 174.741 128.669 174.709 128.682C173.833 129.065 172.813 128.66 172.433 127.782C172.053 126.905 172.457 125.886 173.333 125.506C173.534 125.42 178.328 123.429 185.276 127.987C193.853 133.614 197.386 154.981 197.533 155.888C197.685 156.831 197.043 157.72 196.101 157.872C196.007 157.887 195.914 157.894 195.822 157.894L195.821 157.895Z" fill="#232936"/>
                                                    <path d="M180.98 206.696C180.777 206.696 180.572 206.66 180.371 206.585C179.476 206.248 179.025 205.25 179.361 204.355L194.205 164.903C194.541 164.008 195.539 163.555 196.434 163.893C197.329 164.23 197.781 165.228 197.445 166.123L182.601 205.575C182.34 206.269 181.68 206.697 180.981 206.697L180.98 206.696Z" fill="#232936"/>
                                                    <path d="M144.987 197.873C144.773 197.873 144.557 197.833 144.346 197.749C143.458 197.394 143.026 196.388 143.379 195.501L145.243 190.829C145.597 189.941 146.602 189.505 147.491 189.862C148.379 190.217 148.812 191.223 148.458 192.11L146.595 196.782C146.324 197.46 145.674 197.873 144.987 197.873Z" fill="#232936"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_3100_38052">
                                                        <rect width="295.349" height="232.106" fill="white" transform="translate(0.325562)"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        :
                                        <><SchoolMultiForm brand={brand}/></>
                                    }</div>
                            </Col>
                            <Col md={6} lg={4} className={""}>
                                <div className={"multi-benefits-col"}>
                                    <div className={"head-section"}>
                                        <h2>{brand === 1 ? 'Plan dla szkoły' : 'Plan dla firm'}</h2>
                                        <h4>Pełny dostęp już od</h4>
                                        <h3><strong>{brand === 1 ? '3' : '29,90'} </strong> zł brutto</h3>
                                        <p>za osobę przy zakupie dostępu dla całej {brand === 1 ? 'szkoły' : 'firmy'} na
                                            12 miesięcy </p>
                                    </div>
                                    <ul className={`form-benefit-list ${brand === 1 ? 'oswiata' : 'kik'}`}>
                                        <span>Pakiet zawiera</span>

                                        <li>
                                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                                                    fill={brand === 1 ? "#4AA5D8" : '#FDD26E'}/>
                                                <path d="M3.5332 8.77246L6.95117 12.4727L12.4678 6.50293"
                                                      stroke="white"
                                                      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <span><strong>12 miesięczny</strong> abonament</span></li>
                                        <li>
                                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                                                    fill={brand === 1 ? "#4AA5D8" : '#FDD26E'}/>
                                                <path d="M3.5332 8.77246L6.95117 12.4727L12.4678 6.50293"
                                                      stroke="white"
                                                      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <span><strong>Oszczędzasz nawet do 2000 zł</strong></span></li>
                                        <li>
                                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                                                    fill={brand === 1 ? "#4AA5D8" : '#FDD26E'}/>
                                                <path d="M3.5332 8.77246L6.95117 12.4727L12.4678 6.50293"
                                                      stroke="white"
                                                      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <span>Jeden wygodny zakup dla wszystkich pracowników {brand === 1 ? 'merytorycznych szkoły' : 'firmy'}</span>
                                        </li>
                                        <li>
                                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                                                    fill={brand === 1 ? "#4AA5D8" : '#FDD26E'}/>
                                                <path d="M3.5332 8.77246L6.95117 12.4727L12.4678 6.50293"
                                                      stroke="white"
                                                      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <span>Nieograniczony dostęp <strong>do wszystkich webinarów LIVE Z CHATEM</strong> do eksperta</span>
                                        </li>
                                        <li>
                                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                                                    fill={brand === 1 ? "#4AA5D8" : '#FDD26E'}/>
                                                <path d="M3.5332 8.77246L6.95117 12.4727L12.4678 6.50293"
                                                      stroke="white"
                                                      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <span>Nieograniczony dostęp <strong>do wszystkich szkoleń VOD</strong></span>
                                        </li>
                                        <li>
                                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                                                    fill={brand === 1 ? "#4AA5D8" : '#FDD26E'}/>
                                                <path d="M3.5332 8.77246L6.95117 12.4727L12.4678 6.50293"
                                                      stroke="white"
                                                      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <span><strong>Imienne certyfikaty </strong>oraz testy wiedzy z każdego szkolenia</span>
                                        </li>
                                        <li>
                                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                                                    fill={brand === 1 ? "#4AA5D8" : '#FDD26E'}/>
                                                <path d="M3.5332 8.77246L6.95117 12.4727L12.4678 6.50293"
                                                      stroke="white"
                                                      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <span>W każdym miesiącu <strong>nowe szkolenia</strong></span></li>
                                        <li>
                                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                                                    fill={brand === 1 ? "#4AA5D8" : '#FDD26E'}/>
                                                <path d="M3.5332 8.77246L6.95117 12.4727L12.4678 6.50293"
                                                      stroke="white"
                                                      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <span><strong>Materiały dodatkowe </strong>(prezentacje, wzory dokumentów, artykuły tematyczne)</span>
                                        </li>
                                    </ul>
                                    <p>
                                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12.8327 7.50033C12.8327 4.27866 10.221 1.66699 6.99935 1.66699C3.77769 1.66699 1.16602 4.27866 1.16602 7.50033C1.16602 10.722 3.77769 13.3337 6.99935 13.3337C10.221 13.3337 12.8327 10.722 12.8327 7.50033Z"
                                                stroke="#232936" strokeOpacity="0.6"/>
                                            <path
                                                d="M7.14193 10.417V7.50033C7.14193 7.22534 7.14193 7.08785 7.05647 7.00239C6.97107 6.91699 6.83358 6.91699 6.55859 6.91699"
                                                stroke="#232936" strokeOpacity="0.6" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                            <path d="M6.99609 5.16699H7.00134" stroke="#232936" strokeOpacity="0.6"
                                                  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        Subskrypcja autoodnawialna co 12 miesiący. Możesz zrezygnować w każdej chwili
                                        przed
                                        końcem okresu rozliczeniowego kontaktując się z Centrum Obsługi Klienta
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {brand===1 ? '' : <ExpertContainer/>}
                <AwardsSlider/>
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolMulti);