import React from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import {convertMetaDescription, convertMetaTitle} from "../../../common/utils";
import Helmet from "react-helmet";

const MultiThx = () => {

    return (
        <>
            <Helmet>
                <title>{convertMetaTitle('potwierdzenie złożenia oferty wielostanowiskowości')}</title>
                <meta name={"potwierdzenie złożenia oferty wielostanowiskowości"}
                      content={convertMetaDescription("potwierdzenie złożenia oferty wielostanowiskowości")}/>
            </Helmet>
            <Container className={""} fluid>
                <Row className={""}>
                    <Container>
                        <div className={"subuser-thx-container"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="68" height="67" viewBox="0 0 68 67"
                                 fill="none">
                                <rect x="0.5" width="67" height="67" rx="33.5" fill="#2EE3A9"/>
                                <path
                                    d="M31.6201 43.115H31.6057C31.0297 43.115 30.4809 42.7078 30.0905 42.2822L20.9185 32.211C20.1425 31.3662 20.1993 30.0126 21.0441 29.2374C21.8897 28.4614 23.2025 28.4998 23.9777 29.343L31.6409 37.6798L44.0417 24.5366C44.8289 23.7038 46.1433 23.663 46.9769 24.4502C47.8113 25.2374 47.8489 26.5502 47.0617 27.3846L33.1297 42.3038C32.7377 42.7198 32.1905 43.1142 31.6193 43.1142L31.6201 43.115Z"
                                    fill="white"/>
                            </svg>
                            <h1>Twój adres e-mail został potwierdzony.</h1>
                            <p>Nasz konsultant skontaktuje się z Tobą w ciągu<br/> 1 dnia
                                roboczego w celu przedstawienia oferty.</p>
                            <p>Dziękujemy za przesłanie kontaktu.<br/> Zespół EDUCADO</p>
                            {/*<Button className={"educado-btn educado-btn-full"} href={'/'}>Strona główna</Button>*/}
                        </div>
                    </Container>
                </Row>
            </Container>
        </>
    );

}

export default MultiThx;