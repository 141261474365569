import React from "react";
import {Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {reduxForm} from "redux-form";
import connect from "react-redux/es/connect/connect";
import {getBreadcrumb} from "../../../common/utils";
import ListItems from "./userList";
import dateFormat from "dateformat";

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'users',
            breadcrumb: [
                {'type': 'buyer', 'class': 'one', 'name': 'Krok 1 / 3 - DANE'},
                {'type': 'users', 'class': 'one', 'name': 'Krok 1 / 3 - DANE'},
                {'type': 'payment', 'class': 'two', 'name': 'Krok 2 / 3 - PODSUMOWANIE I PŁATNOŚĆ'},
                {'type': 'summary', 'class': 'three', 'name': 'KROK 3 / 3 -  POTWIERDZENIE I DODATKOWE INFORMACJE'}
            ],
            itemsList: [],
            discount: '',
            quantity: 1,
            currentItem: {
                name: '',
                surname: '',
                email: '',
                key: ''
            },
            nameError: false,
            surnameError: false,
            emailError: false
        };

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeSurname = this.handleChangeSurname.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.handlePaymentProceed = this.handlePaymentProceed.bind(this);
        this.editInvoiceData = this.editInvoiceData.bind(this);

    }

    componentDidMount() {
        const {product, registerWebinarGetUser, registerWebinarPrice, registerToken} = this.props.children;
        if (registerToken) {
            registerWebinarGetUser(registerToken)
        }
        if (window.sessionStorage.getItem('discount') !== null) {
            let tmp = JSON.parse(window.sessionStorage.getItem('discount'));
            this.setState({discount: null, quantity: tmp.quantity});
            registerWebinarPrice(product.id, null, this.state.quantity)
        }


    }

    componentDidUpdate(prevProps, prevState) {
        const {product, registerWebinarPrice, getUsersList} = this.props.children;

        if (getUsersList && prevProps.children.getUsersList !== getUsersList) {

            return (registerWebinarPrice(product.id, this.state.discount, getUsersList.length + 1));
        }
    }

    handleChangeName(e) {
        this.setState({
            nameError: false,
            currentItem: {
                name: e.target.value,
                surname: this.state.currentItem.surname,
                email: this.state.currentItem.email,
            }
        });
    }

    handleChangeSurname(e) {
        this.setState({
            surnameError: false,
            currentItem: {
                name: this.state.currentItem.name,
                surname: e.target.value,
                email: this.state.currentItem.email,
            }
        });
    }

    handleChangeEmail(e) {
        this.setState({
            emailError: false,
            currentItem: {
                name: this.state.currentItem.name,
                surname: this.state.currentItem.surname,
                email: e.target.value,
            }
        });
    }

    handleSubmit(e) {
        const {registerToken, registerWebinarAddUser} = this.props.children;
        const tmp = this.state.itemsList.filter(e => e.email === this.state.currentItem.email);
        const validateEmail = (email) => {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        };
        if (this.state.currentItem.name.length === 0 || this.state.currentItem.surname.length === 0 || this.state.currentItem.email.length === 0 || !this.state.currentItem.email.includes("@") || tmp.length > 0) {
            if (this.state.currentItem.name.length === 0) {
                this.setState({nameError: true});
            }
            if (this.state.currentItem.surname.length === 0) {
                this.setState({surnameError: true});
            }
            if (this.state.currentItem.email.length === 0 || !this.state.currentItem.email.includes("@") || tmp.length > 0) {
                this.setState({emailError: true});
            }
            if (!validateEmail(this.state.currentItem.email)) {
                this.setState({emailError: true});
            }
        } else {
            registerWebinarAddUser(registerToken, this.state.currentItem);
            this.setState({
                currentItem: {
                    name: '',
                    surname: '',
                    email: '',

                }
            });
        }
        e.preventDefault();
    }

    deleteItem(key) {
        const {registerWebinarDeleteUser, registerToken, getUsersList} = this.props.children

        const itemToDel = getUsersList.filter(item =>
            item.key === key);
        const tmp = itemToDel[0].id;
        registerWebinarDeleteUser(tmp, registerToken)
    }

    getPrice() {
        const {priceData, isFetching} = this.props.children;
        if (priceData && !isFetching) {
            return (
                <div className={"price-container"}>
                    <h4>{priceData && priceData.discountApply ? priceData.discountPriceGross.toFixed(2) : priceData.totalPriceGross.toFixed(2)}
                        <small> zł</small>
                        <span> brutto</span>
                    </h4>
                    <h5>{priceData && priceData.discountApply ? priceData.discountPriceNet.toFixed(2) : priceData.totalPriceNet.toFixed(2)}
                        <small> zł netto</small>
                    </h5>
                </div>
            )
        } else {
            return (<Spinner animation="grow"/>);

        }
    }

    editInvoiceData() {
        window.sessionStorage.setItem('regStep', 'PROGRESS');
        //window.sessionStorage.removeItem('discount');
        window.location.reload(false)
    }

    handlePaymentProceed() {
        const {registerUsersList, product, getUsersList, registerToken} = this.props.children;
        //const tmp = this.state.itemsList.filter(item => delete item.key);

        let tmp = JSON.parse(window.sessionStorage.getItem('discount'));
        if (!tmp) {
            let body = {
                discountCode: '',
                quantity: getUsersList.length + 1,
            };
            window.sessionStorage.setItem('discount', JSON.stringify(body))

            registerUsersList(registerToken, product, getUsersList.length + 1)
        } else {

            let body = {
                discountCode: tmp.discountCode,
                quantity: getUsersList.length + 1,
            };

            window.sessionStorage.setItem('discount', JSON.stringify(body))
            registerUsersList(registerToken, product, getUsersList.length + 1)
        }
    }

    render() {
        const {product, registerToken, priceData, isFetching, getUsersList} = this.props.children;

        if (!product || !registerToken) {
            return (<Spinner animation="grow"/>);
        }
        return (
            <>
                <Container>
                    <Row className={"register-product-buyer-container register-product-users-container"}>
                        <Col xs={{span: 12}} lg={{span: 8}} className={"rpa-login-form-container"}>
                            <div className={"background-wrapper"}>
                                <Container className={"buyer-form-container"}>
                                    <Row>
                                        {getBreadcrumb(this.state.breadcrumb, this.state.type, 'reg-breadcrumb')}
                                    </Row>
                                    {isFetching ? <Spinner animation="grow"/> :
                                        <Row className={"order-data-container users-list-container"}>
                                            <h2><span>*</span>PODAJ DANE DODATKOWYCH UCZESTNIKÓW WEBINARU:</h2>
                                            {getUsersList ?
                                                <ListItems items={getUsersList} deleteItem={this.deleteItem}/> : ''}
                                            <Form className="user-form" onSubmit={this.handleSubmit}>
                                                <Row className={"form-wrapper"}>
                                                    <Col className={'list-item-wrapper'} lg={3}>
                                                        <div
                                                            className={"add-number"}>{getUsersList ? getUsersList.length + 1 + '.' : 1}</div>
                                                        <Form.Group controlId="validationCustom01">
                                                            <Form.Control placeholder={"imię"} type="text"
                                                                          value={this.state.currentItem.name}
                                                                          onChange={this.handleChangeName}
                                                                          className={this.state.nameError ? 'is-invalid' : ''}

                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Wprowardź imię
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Group controlId="validationCustom02">
                                                            <Form.Control placeholder={"nazwisko"} type="text"
                                                                          value={this.state.currentItem.surname}
                                                                          onChange={this.handleChangeSurname}
                                                                          className={this.state.surnameError ? 'is-invalid' : ''}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Wprowadź nazwisko
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Form.Group controlId="validationCustom03">
                                                            <Form.Control placeholder={"e-mail"} type="email"
                                                                          value={this.state.currentItem.email}
                                                                          onChange={this.handleChangeEmail}
                                                                          className={this.state.emailError ? 'is-invalid' : ''}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Wprowadź poprawny mail
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Button className={"addUser"} type="submit"
                                                        value="Submit"><span>+</span> Dodaj użytkownika</Button>
                                            </Form>
                                        </Row>}
                                </Container>
                            </div>
                        </Col>
                        <Col md lg={{span: 4, offset: 0, order: 2}} className={"rpa-offer"}>
                            <Container>
                                <div className={"register-offer-wrapper"}>
                                    <div className="register-info-block mb-5">
                                        <h2>{product ? product.name : ''}</h2>
                                        {product && product.experts ?
                                            <p>Eksperci:
                                                {product && product.experts.map((expert, key) => (
                                                    <span key={key}>&nbsp;
                                                        {expert.name} {expert.surname}{product.experts.length > key + 1 && product.experts.length > 1 ? ', ' : ''}
                                            </span>
                                                ))}
                                            </p>
                                            : ''
                                        }
                                        <p>Termin: {product && product.timetable[0].eventStartDate ?
                                            <><span
                                                className="date">{dateFormat(Date.parse(product.timetable[0].eventStartDate), 'dd-mm-yyyy')}</span></> : ''


                                        }
                                        </p>
                                        <p>Godzina: {product && product.timetable[0].eventStartDate ?
                                            <span
                                                className={"time"}>{dateFormat(Date.parse(product.timetable[0].eventStartDate), 'HH:MM')}</span>
                                            : ''}
                                        </p>
                                        {priceData && priceData.valid && priceData.discountApply && priceData.discountCode ?
                                            <>
                                                <h4 className={"green-dot"}>dodano kod
                                                    rabatowy {priceData.discountCode}</h4>
                                                <h4 className={"green-dot"}>kupujesz dostęp
                                                    dla {priceData.quantity === 1 ? "uczestnika" : 'uczestników'} </h4></> : ''}
                                    </div>
                                    <div className="register-info-block mb-5">
                                        <h3 className={"payu"}>Do zapłaty
                                            {priceData && priceData.valid && priceData.discountApply && priceData.discountCode ?
                                                <span>rabaty łącznie: {priceData.totalPriceGross.toFixed(2) - priceData.discountPriceGross.toFixed(2)} zł brutto</span>
                                                : ''
                                            }

                                        </h3>
                                        {isFetching ?
                                            <Spinner animation="grow"/> :
                                            <>
                                                {this.getPrice()}
                                                <div className={"btn-wrapper"}>
                                                    <Button className={'btn btn-reverse webinar-btn'}
                                                            onClick={this.editInvoiceData}>Wstecz</Button>
                                                    <Button className={"btn webinar-btn"}
                                                            onClick={this.handlePaymentProceed}> Kolejny krok</Button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default reduxForm({
    form: 'Users',
    enableReinitialize: false,
    destroyOnUnmount: true,

})(connect(null, null)(Users));