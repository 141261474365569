import React from 'react';
import {Container, Row} from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import {deviceType} from "react-device-detect";

const AwardsSlider = props => {


    const responsive = {
        browser: {
            breakpoint: {max: 3000, min: 992},
            items: 4,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: {max: 991, min: 768},
            items: 3,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: {max: 767, min: 0},
            items: 1,
            slidesToSlide: 1
        }
    };

    return (
        <Container className={"carousel-main-container awards-carousel mt-5 mb-5"} fluid>
            <Row>
                <Container className={"carousel-wrapper"}>
                    <h3>Nasze nagrody i wyróżnienia</h3>
                    <Carousel className=""
                              swipeable={true}
                              draggable={false}
                              showDots={false}
                              responsive={responsive}
                              ssr={true}
                              infinite={true}
                              autoPlay={true}
                              autoPlaySpeed={3000}
                              keyBoardControl={true}
                              customTransition="all 1s"
                              transitionDuration={900}
                              containerClass="carousel-container"
                              removeArrowOnDeviceType={false}
                              deviceType={deviceType}
                              dotListClass="custom-dot-list-style"
                              itemClass="carousel-item-padding-40-px text-center"
                    >
                            <div className={"carousel-image"}><img src={"/logotypes/item1.png"} loading={"lazy"} alt={"Award 1"}/></div>
                            <div className={"carousel-image"}><img src={"/logotypes/item2.png"} loading={"lazy"} alt={"Award 2"}/></div>
                            <div className={"carousel-image"}><img src={"/logotypes/item3.png"} loading={"lazy"} alt={"Award 3"}/></div>
                            <div className={"carousel-image"}><img src={"/logotypes/item4.png"} loading={"lazy"} alt={"Award 4"}/></div>
                            <div className={"carousel-image"}><img src={"/logotypes/item6.png"} loading={"lazy"} alt={"Award 6"}/></div>
                            <div className={"carousel-image"}><img src={"/logotypes/item7.png"} loading={"lazy"} alt={"Award 7"}/></div>
                            <div className={"carousel-image"}><img src={"/logotypes/item8.png"} loading={"lazy"} alt={"Award 8"}/></div>

                    </Carousel>
                </Container>
            </Row>
        </Container>
    )

}

export default AwardsSlider;





