import {requests} from "../agent";

import {
    PROFILE_COMPLETE_PRODUCTS_LIST_RECEIVED,
    PROFILE_ERROR,
    PROFILE_NEW_PASSWORD_RECEIVED,
    PROFILE_PASSWORD_REMINDER_RECEIVED,
    PROFILE_PROGRESS_PRODUCTS_LIST_RECEIVED,
    PROFILE_REQUEST,
    PROFILE_SAVED_PRODUCTS_DELETE,
    PROFILE_SAVED_PRODUCTS_LIST_RECEIVED,
    PROFILE_SAVED_PRODUCTS_SUCCESS,
    PROFILE_VIDEO_ERROR,
    PROFILE_VIDEO_REQUEST,
    PROFILE_VIDEO_SAVED_WATCH_SUCCESS,
    PROFILE_VIDEO_SET_TIME_WATCHED,
    PROFILE_VIDEO_WATCH_LIST_RECEIVED,
    PROFILE_VIDEO_WATCH_RECEIVED,
    PROFILE_WEBINAR_ACTIVE_RECEIVED,
    PROFILE_WEBINAR_ACTIVE_REQUEST,
    PROFILE_WEBINAR_CERTIFICATE_RECEIVED,
    PROFILE_WEBINAR_CERTIFICATE_REQUEST,
    PROFILE_WEBINAR_ERROR,
    PROFILE_WEBINAR_RECEIVED,
    PROFILE_WEBINAR_REQUEST
} from "../constants";
import {SubmissionError} from "redux-form";
import {formValidate, minChar, requiredField, validateEmail, validateIsDifferentFields} from "../common/form";

/**
 * Set Profile default state
 * @returns {{type: *}}
 */
export const profileRequest = () => ({
    type: PROFILE_REQUEST,
});

/**
 * Set Profile state after saved product action
 * @param data
 * @returns {{data: *, type: *}}
 */
export const profileSavedProductsSuccess = (data) => {
    return {
        type: PROFILE_SAVED_PRODUCTS_SUCCESS,
        data
    }
};

/**
 * Set Profile saved trainings list
 * @param data
 * @returns {{data: *, type: *}}
 */
export const profileSavedProductsListReceived = (data) => {
    return {
        type: PROFILE_SAVED_PRODUCTS_LIST_RECEIVED,
        data
    }
};

/**
 * Set Profile state after delete action
 * @param data
 * @returns {{data: *, type: *}}
 */
export const profileDeleteProductReceived = (data) => {
    return {
        type: PROFILE_SAVED_PRODUCTS_DELETE,
        data
    }
}

/**
 * Set Profile error
 * @param error
 * @returns {{type: *, error: *}}
 */
export const profileError = (error) => ({
    type: PROFILE_ERROR,
    error
});

/**
 * Saved product to my favourite list
 * @param productId
 * @returns {function(*): *}
 */
export const profileSavedProduct = (productId) => {
    let body = {
        "training": `/api/trainings/${productId}`,
        "ipAddress": "1.1.1.1"
    }

    return (dispatch) => {
        return requests.post('/saved_trainings', body, true)
            .then(response => dispatch(profileSavedProductsSuccess(response)))
            .catch(error => dispatch(profileError(error)))
    }
};

/**
 * Delete product from my favourite list
 * @param productId
 * @returns {function(*): *}
 */
export const profileDeleteProduct = (productId) => {
    return (dispatch) => {
        dispatch(profileRequest());
        return requests.del(`/saved_trainings/${productId}`, true)
            .then(dispatch(profileDeleteProductReceived(productId)))
            .catch(error => dispatch(profileError(error)))
    }
};

/**
 * get List my favourite products
 * @param productId
 * @returns {function(*): *}
 */
export const profileSavedProductsFetch = (productId = false) => {
    let url = `/saved_trainings`;
    if (productId) {
        url = `/saved_trainings/${productId}`;
    }
    return (dispatch) => {
        dispatch(profileRequest());
        return requests.get(url, true)
            .then(response => dispatch(profileSavedProductsListReceived(response)))
            .catch(error => dispatch(profileError(error)))
    }
};

/**
 * Set Profile progress trainings list
 * @param data
 * @returns {{data: *, type: *}}
 */
export const profileProgressProductsListReceived = (data) => {
    return {
        type: PROFILE_PROGRESS_PRODUCTS_LIST_RECEIVED,
        data
    }
};

/**
 * get List my progress products
 * @param productId
 * @returns {function(*): *}
 */
export const profileProgressProductsFetch = () => {
    return (dispatch) => {
        dispatch(profileRequest());
        return requests.get('/trainings/progress', true)
            .then(response => dispatch(profileProgressProductsListReceived(response)))
            .catch(error => dispatch(profileError(error)))
    }
};

/**
 * Set Profile complete trainings list
 * @param data
 * @returns {{data: *, type: *}}
 */
export const profileCompleteProductsListReceived = (data) => {
    return {
        type: PROFILE_COMPLETE_PRODUCTS_LIST_RECEIVED,
        data
    }
};

/**
 * get List my complete products
 * @param productId
 * @returns {function(*): *}
 */
export const profileCompleteProductsFetch = () => {
    return (dispatch) => {
        dispatch(profileRequest());
        return requests.get('/trainings/complete', true)
            .then(response => dispatch(profileCompleteProductsListReceived(response)))
            .catch(error => dispatch(profileError(error)))
    }
};

/**
 * set ProfileVideo default state
 * @returns {{type: *}}
 */
export const profileVideoRequest = () => ({
    type: PROFILE_VIDEO_REQUEST,
});

/**
 * set ProfileVideo state watchedTime
 * @param data
 * @returns {{data: *, type: *}}
 */
export const profileVideoWatchReceived = (data) => {
    return {
        type: PROFILE_VIDEO_WATCH_RECEIVED,
        data
    }
};

/**
 * set nothing
 * @param time
 * @returns {{time: *, type: *}}
 */
export const profileVideoSavedWatchSuccess = (time) => ({
    type: PROFILE_VIDEO_SAVED_WATCH_SUCCESS,
    time
});

/**
 * Set ProfileVideo state multiple params
 * @param vimeoId
 * @param videoId
 * @param time
 * @param sort
 * @param update
 * @returns {{vimeoId: *, update: *, videoId: *, time: *, sort: *, type: *}}
 */
export const profileVideoSetTimeWatched = (vimeoId, videoId, time, sort, update = false) => ({
    type: PROFILE_VIDEO_SET_TIME_WATCHED,
    vimeoId,
    videoId,
    time,
    sort,
    update
});

/**
 * Set ProfileVideo state watchVideos
 * @param data
 * @returns {{data: *, type: *}}
 */
export const profileVideoWatchListReceived = (data) => ({
    type: PROFILE_VIDEO_WATCH_LIST_RECEIVED,
    data
});

/**
 * Set ProfileVideo error
 * @param error
 * @returns {{type: *, error: *}}
 */
export const profileVideoError = (error) => ({
    type: PROFILE_VIDEO_ERROR,
    error
});

/**
 * saved/update video watch time
 * @param productId
 * @param vimeoId
 * @param videoId
 * @param time
 * @param sort
 * @param method
 * @returns {Function}
 */
export const profileVideoSavedWatch = (productId, vimeoId, videoId, time, sort, method = 'POST') => {
    let body = {
        "watchedTime": time,
        "video": `/api/trainings_videos/${videoId}`,
        "training": `/api/trainings/${productId}`,
        "ip": "1.1.1.1"
    };

    return (dispatch) => {
        dispatch(profileVideoRequest());
        if (method === 'POST') {
            return requests.post('/watch_status_videos_trainings', body, true)
                .then(response => dispatch(profileVideoSavedWatchSuccess(time)))
                .catch(error => dispatch(profileVideoError(error)))
        } else {
            return requests.put(`/watch_status_videos_trainings/training=${productId};video=${videoId}`, body, true)
                .then(response => dispatch(profileVideoSavedWatchSuccess(time)))
                .catch(error => dispatch(profileVideoError(error)))
        }
    }
};

export const profileVideoWatchFetch = (productId, videoId) => {
    return (dispatch) => {
        dispatch(profileVideoRequest());
        return requests.get(`/watch_status_videos_trainings/training=${productId};video=${videoId}`, true)
            .then(response => dispatch(profileVideoWatchReceived(response)))
            .catch(error => dispatch(profileVideoError(error)))
    }
};

export const profileVideoWatchListFetch = () => {
    return (dispatch) => {
        dispatch(profileVideoRequest());
        return requests.get(`/watch_status_videos_trainings`, true)
            .then(response => dispatch(profileVideoWatchListReceived(response)))
            .catch((error => dispatch(profileError(error))));
    }
};

export const profileUserUpdate = (...data) => {

    if (data) {
        throw new SubmissionError({
            _error: 'Wystąpił błąd podczas edycji danych!'
        })
    }
    return data;
}

export const profileChangePassword = (oldPassword, newPassword, reNewPassword) => {

    let mapFields = [
        {
            field: 'oldPassword',
            method: requiredField,
            text: 'Wypełnij wszystkie pola'
        },
        {
            field: 'newPassword',
            methods: [
                {
                    method: requiredField,
                    text: 'Wypełnij wszystkie pola'
                },
                {
                    method: minChar,
                    text: 'Hasło powinno składać się z minimum 8 znaków!',
                    params: {limit: 8}
                },
                {
                    method: validateIsDifferentFields,
                    text: 'Nowe hasło musi się różnić od starego hasła!',
                    params: {
                        notDifferent: false,
                        field1: oldPassword,
                        field2: newPassword
                    }
                }
            ]
        },
        {
            field: 'reNewPassword',
            methods: [
                {
                    method: requiredField,
                    text: 'Wypełnij wszystkie pola'
                },
                {
                    method: minChar,
                    text: 'Hasło powinno składać się z minimum 8 znaków!',
                    params: {limit: 8}
                },
                {
                    method: validateIsDifferentFields,
                    text: 'Nowe hasła nie są takie same!',
                    params: {
                        notDifferent: true,
                        field1: newPassword,
                        field2: reNewPassword
                    }
                }
            ]
        }
    ];

    let validate = formValidate(mapFields, {
        oldPassword: oldPassword,
        newPassword: newPassword,
        reNewPassword: reNewPassword
    })

    if (validate)
        throw new SubmissionError(validate)

    let body = {
        currentPassword: oldPassword,
        newPassword: newPassword,
        confirmNewPassword: reNewPassword
    }

    return (dispatch) => {
        dispatch(profileRequest());
        return requests.post(`/users/change-password`, body, true)
            .then(response => dispatch(profilePasswordReminderReceived(response)))
            .catch(() => {
                throw new SubmissionError({
                    _error: 'Wystąpił problem z zmianą hasła!'
                })
            })
    }

}

export const profilePasswordReminderReceived = (data) => ({
    type: PROFILE_PASSWORD_REMINDER_RECEIVED,
    data
});

export const profileNewPasswordReceived = (data) => ({
    type: PROFILE_NEW_PASSWORD_RECEIVED,
    data
})

export const profilePasswordReminder = (email) => {

    let mapFields = [
        {
            field: 'email',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole email nie może pozostać puste!'
                },
                {
                    method: validateEmail,
                    text: 'Podany adres email nie jest prawidłowy!'
                }
            ]
        }
    ];

    let validate = formValidate(mapFields, {email: email})

    if (validate)
        throw new SubmissionError(validate)

    let body = {
        login: email
    }
    return (dispatch) => {
        return requests.post(`/users/password-reset`, body)
            .then(response => dispatch(profilePasswordReminderReceived(response)))
            .catch(() => {
                throw new SubmissionError({
                    _error: 'Brak przypisanego konta do adresu email!'
                })
            })
    }
}

export const profileNewPassword = (login, token, newPassword, reNewPassword) => {

    if (!newPassword || !reNewPassword) {
        throw new SubmissionError({
            _error: 'Wypełnij wszystkie pola'
        })
    } else if (newPassword.length < 7) {
        throw new SubmissionError({
            _error: 'Hasło powinno składać się z minimum 8 znaków!'
        })
    } else if (newPassword !== reNewPassword) {
        throw new SubmissionError({
            _error: 'Hasła muszą być takie same!'
        })
    }

    let body = {
        login: login,
        token: token,
        newPassword: newPassword
    }
    return (dispatch) => {
        dispatch(profileRequest());
        return requests.post(`/users/set-new-password`, body)
            .then(response => dispatch(profileNewPasswordReceived(response)))
            .catch(() => {
                throw new SubmissionError({
                    _error: 'Nie udało się ustawić nowego hasła!'
                })
            })
    }
}


export const profileWebinarRequest = () => ({
    type: PROFILE_WEBINAR_REQUEST,
});

export const profileWebinarActiveRequest = () => ({
    type: PROFILE_WEBINAR_ACTIVE_REQUEST,
});

export const profileWebinarError = (error) => ({
    type: PROFILE_WEBINAR_ERROR,
    error
});

export const profileWebinarFetch = () => {
    return (dispatch) => {
        dispatch(profileWebinarRequest());
        return requests.get('/users/webinars/list', true)
            .then(response => dispatch(profileWebinarReceived(response)))
            .catch(error => dispatch(profileWebinarError(error)))
    }
};
export const profileWebinarActiveFetch = () => {
    return (dispatch) => {
        dispatch(profileWebinarActiveRequest());
        return requests.get('/users/webinars/list?type=active', true)
            .then(response => dispatch(profileWebinarActiveReceived(response)))
            .catch(error => dispatch(profileWebinarError(error)))
    }
};

export const profileWebinarReceived = (data) => {
    return {
        type: PROFILE_WEBINAR_RECEIVED,
        data
    }
};
export const profileWebinarActiveReceived = (data) => {
    return {
        type: PROFILE_WEBINAR_ACTIVE_RECEIVED,
        data
    }
};

export const profileWebinarCefrtificateRequest = () => ({
    type: PROFILE_WEBINAR_CERTIFICATE_REQUEST,
});

export const profileWebinarCertificateFetch = () => {
    return (dispatch) => {
        dispatch(profileWebinarCefrtificateRequest());
        return requests.get('/users/webinars/certificates', true)
            .then(response => dispatch(profileWebinarCertificateReceived(response)))
            .catch(error => dispatch(profileWebinarError(error)))
    }
};

export const profileWebinarCertificateReceived = (data) => {
    return {
        type: PROFILE_WEBINAR_CERTIFICATE_RECEIVED,
        data
    }
};

