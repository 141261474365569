import React from 'react';
import {Container, Row} from "react-bootstrap";
import {convertMetaDescription, convertMetaTitle} from "../../common/utils";
import Helmet from "react-helmet";
import Regulamin from "../../assets/files/regulamin_platformy_szkoleniowej_educado.pdf";


class Terms extends React.Component {
    componentWillMount() {
        window.location.replace('https://www.wip.pl/regulamin/0');
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{convertMetaTitle('Regulamin')}</title>
                    <meta name={"description"} content={convertMetaDescription("Regulamin")}/>
                </Helmet>
                <Container className={"contact-container"} fluid>
                    <Row className={"static-main-badge"}>
                        <Container>
                            <h1>Regulamin</h1>
                        </Container>
                    </Row>
                    <Row className={"contact-content-section"}>
                        <Container className={"contact-content-container"}>
                            <h4>Og&oacute;lny regulamin świadczenia usług oraz sprzedaży towar&oacute;w przez Wiedza i
                                Praktyka sp. z o.o.</h4>
                            <p><strong>&nbsp;</strong></p>
                            <h5>Definicje</h5>
                            <table width="630">
                                <tbody>
                                <tr>
                                    <td width="124">
                                        <p>WIP</p>
                                    </td>
                                    <td width="506">
                                        <p>Wiedza i Praktyka sp. z o. o. z siedzibą w Warszawie ul. Łotewska 9a, 03-918
                                            Warszawa, NIP: 526-19-92-256, REGON 012725225, wpisana do rejestru
                                            przedsiębiorc&oacute;w Krajowego Rejestru Sądowego prowadzonego przez Sąd
                                            Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego
                                            Rejestru Sądowego pod numerem KRS 0000098264; kapitał zakładowy 200 000 zł,
                                            Nr
                                            rejestrowy BDO: 000008579;&nbsp;e-mail:cok@wip.pl; nr tel.:&nbsp; 22 518 29
                                            29.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="124">
                                        <p>Klient</p>
                                    </td>
                                    <td width="506">
                                        <p>osoba fizyczna, osoba prawna lub jednostka organizacyjna niebędąca osobą
                                            prawną,
                                            kt&oacute;rej ustawa przyznaje zdolność prawną, kt&oacute;ra zawarła umowę
                                            na
                                            świadczenie lub sprzedaż przez WIP Produktu.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="124">
                                        <p>Produkt</p>
                                    </td>
                                    <td width="506">
                                        <p>towar lub usługa świadczona lub sprzedawana przez WIP.</p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <h5>Rozdział I</h5>
                            <p><strong>Postanowienia og&oacute;lne</strong></p>
                            <ol>
                                <li>Wyłącznym źr&oacute;dłem zobowiązań WIP wobec Klient&oacute;w jest niniejszy
                                    regulamin
                                    wraz z załącznikami, oferta WIP przyjęta przez Klient&oacute;w oraz bezwzględnie
                                    obowiązujące przepisy prawa.
                                </li>
                                <li>Do um&oacute;w zawartych pomiędzy WIP a Klientami zastosowanie mają tylko te
                                    szczeg&oacute;łowe regulaminy produktowe stanowiące załączniki do niniejszego
                                    regulaminu
                                    (wskazane w Rozdziale II poniżej), w zakresie kt&oacute;rych Klient zawarł z WIP
                                    umowę
                                    na związany z nimi Produkt.
                                </li>
                                <li>W przypadku sprzeczności postanowień szczeg&oacute;łowych regulamin&oacute;w
                                    produktowych z niniejszym regulaminem, zastosowanie mają postanowienia niniejszego
                                    regulaminu.
                                </li>
                                <li>W zakresie w jakim postanowienia oferty WIP przyjętej przez Klienta byłyby sprzeczne
                                    z
                                    niniejszym og&oacute;lnym regulaminem lub szczeg&oacute;łowym regulaminem
                                    produktowym,
                                    zastosowanie mają postanowienia tej oferty.
                                </li>
                            </ol>
                            <h5>Rozdział II</h5>
                            <p><strong>Załączniki &nbsp;do regulaminu &ndash; szczeg&oacute;łowe regulaminy
                                produktowe</strong></p>
                            <ol start="5">
                                <li>Integralną cześć niniejszego regulaminu stanowią wskazane poniżej szczeg&oacute;łowe
                                    regulaminy produktowe, tj.:
                                </li>
                                <br/>
                                <ol>
                                    <li><a href="https://www.wip.pl/regulamin/1" target={"_blank"}
                                           rel={"noopener noreferrer"}> Regulamin odpłatnych Serwis&oacute;w
                                        sp&oacute;łki Wiedza i Praktyka sp. z o.o.,
                                    </a></li>
                                    <li><a href="https://www.wip.pl/regulamin/2" target={"_blank"}
                                           rel={"noopener noreferrer"}> Regulamin odpłatnych Czasopism sp&oacute;łki
                                        Wiedza i Praktyka sp. z o.o.,</a></li>
                                    <li><a href="https://www.wip.pl/regulamin/3" target={"_blank"}
                                           rel={"noopener noreferrer"}> Regulamin odpłatnych książek, ebook&oacute;w,
                                        kalendarzy sp&oacute;łki Wiedza i
                                        Praktyka
                                        sp. z o.o.,
                                    </a></li>
                                    <li><a href="https://www.wip.pl/regulamin/4" target={"_blank"}
                                           rel={"noopener noreferrer"}> Regulamin odpłatnych szkoleń internetowych
                                        sp&oacute;łki Wiedza i Praktyka sp. z
                                        o.o.,
                                    </a></li>
                                    <li><a href="https://www.wip.pl/regulamin/5" target={"_blank"}
                                           rel={"noopener noreferrer"}> Regulamin bezpłatnych e-letter&oacute;w
                                        sp&oacute;łki Wiedza i Praktyka sp. z
                                        o.o.,
                                    </a></li>
                                    <li><a href="https://www.wip.pl/regulamin/6" target={"_blank"}
                                           rel={"noopener noreferrer"}> Regulamin bezpłatnych ebook&oacute;w i broszur
                                        sp&oacute;łki Wiedza i Praktyka sp. z
                                        o.o.,
                                    </a></li>
                                    <li><a href="https://www.wip.pl/regulamin/7" target={"_blank"}
                                           rel={"noopener noreferrer"}> Regulamin bezpłatnych szkoleń internetowych
                                        sp&oacute;łki Wiedza i Praktyka sp. z
                                        o.o.,
                                    </a></li>
                                    <li><a href="https://www.wip.pl/regulamin/8" target={"_blank"}
                                           rel={"noopener noreferrer"}> Regulamin odpłatnych e-letter&oacute;w
                                        sp&oacute;łki Wiedza i Praktyka sp. z o.o.,
                                    </a></li>
                                    <li><a href="https://www.wip.pl/regulamin/9" target={"_blank"}
                                           rel={"noopener noreferrer"}> Regulamin internetowych for&oacute;w
                                        dyskusyjnych sp&oacute;łki Wiedza i Praktyka
                                        sp. z
                                        o.o,
                                    </a></li>
                                    <li><a href="https://www.wip.pl/regulamin/10" target={"_blank"}
                                           rel={"noopener noreferrer"}> Regulamin programu lojalnościowego Klub
                                        Profesjonalnych Księgowych sp&oacute;łki
                                        Wiedza
                                        i Praktyka sp. z o.o.,
                                    </a></li>
                                    <li><a href={Regulamin} target="_blank" className={"btn-contact"}
                                           rel="noopener noreferrer">Regulamin platformy
                                        szkoleniowej Educado Wiedza i Praktyka sp. z o.o.</a>
                                    </li>
                                </ol>
                            </ol>

                            <p>&nbsp;</p>
                            <h5>Rozdział III</h5>
                            <p><strong>Zmiany regulaminu</strong></p>
                            <ol start="6">
                                <li>WIP zastrzega sobie prawo uzupełniania regulaminu o nowe szczeg&oacute;łowe
                                    regulaminy
                                    produktowe dotyczące nowych produkt&oacute;w jakie będzie świadczyła lub
                                    sprzedawała.
                                    Zmiany takie nie stanowią podstawy do rozwiązania lub wypowiedzenia um&oacute;w
                                    zawartych z WIP.
                                </li>
                                <li>Nie stanowią podstawy do rozwiązania lub wypowiedzenia um&oacute;w zawartych z WIP
                                    zmiany regulaminu i jego załącznik&oacute;w, kt&oacute;re nie wpływają na zakres
                                    praw
                                    lub obowiązk&oacute;w Klient&oacute;w.
                                </li>
                                <li>W przypadku zmian w danym szczeg&oacute;łowym regulaminie produktowym, odbywać się
                                    to
                                    będzie na zasadach określonych w takim szczeg&oacute;łowym regulaminie produktowym,
                                    przy
                                    czym do zmian takich ma zastosowanie pkt 6 powyżej.
                                </li>
                            </ol>
                            <h5>Rozdział IV</h5>
                            <p><strong>Postanowienia końcowe</strong></p>
                            <ol start="9">
                                <li>Niniejszy regulamin obowiązuje w zakresie um&oacute;w zawartych pomiędzy Klientami a
                                    WIP
                                    od dnia 01.01.2021 r.
                                </li>
                                <li>Treść niniejszego regulaminu może zostać utrwalona przez Klienta poprzez
                                    wydrukowanie,
                                    zapisanie na nośniku lub pobranie w każdej chwili m.in. ze strony
                                    www.educado.pl/regulamin Regulamin można także uzyskać kontaktując się z WIP pod
                                    danymi
                                    teleadresowymi wskazanymi w definicjach do niniejszego regulaminu.
                                </li>
                            </ol>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                        </Container>
                    </Row>
                </Container>
            </>
        );

    }
}

export default Terms;