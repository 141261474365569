import React from 'react';
import {connect} from "react-redux";
import {Container, Spinner} from "react-bootstrap";
import {productListQuerySlider} from "../../../../actions/product";
import SliderItem from "./SliderItem";

const mapStateToProps = state => ({
    ...state.product,
    auth: state.auth,

});

const mapDispatchToProps = {
    productListQuerySlider
};

class SliderContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            typeFilter: {
                type: [this.props.type]
            },
            filterCategory: {category: [this.props.id]},

        };
    }



    componentDidMount() {
        this.props.productListQuerySlider(this.state.typeFilter, this.state.filterCategory, 3);
    }

    render() {
        const {auth, id, productQuerySList, isFetching, type} = this.props;
        if (isFetching) {
            return <Container className={"spinner-container"}><Spinner animation="border"/></Container>
        }
        return (
            <SliderItem products={productQuerySList && productQuerySList} auth={auth}
                              type={type} id={id} isFetching={isFetching}/>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SliderContainer);