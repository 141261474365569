import React from "react";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import RegisterForm from "./RegisterForm";
import LoginForm from "../../Auth/LoginForm";
import PasswordReminderForm from "../../Auth/PasswordReminderForm";
import {Helmet} from "react-helmet";
import {convertMetaDescription, convertMetaTitle} from "../../../common/utils";
import dateFormat from "dateformat";
import striptags from "striptags";


class Account extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            type: 'LoginForm'
        }
    }

    render() {
        const {children} = this.props;

        if (!children.product) {
            return (<Spinner animation="grow"/>);
        }

        return (
            <>
                <Helmet>
                    <title>{convertMetaTitle('rejestracja krok pierwszy')}</title>
                    <meta name="description"
                          content={convertMetaDescription(children.product && children.product.shortDescription ? striptags(children.product.shortDescription) : children.product.title)}/>
                </Helmet>
                <Row className={"register-product-account-container register-product-users-container"}>
                    <Col xs={{span: 12}} lg={{span: 8}} className={"rpa-login-form-container"}>
                        <Container>
                            <div className={"background-wrapper"}>
                                <Row>
                                    <Col lg={{span: 6}} className={"rpa-login-col rpa-login-form"}>
                                        <div className={"register-login-form-wrapper"}>
                                            {children.auth.isAuthenticated && !children.auth.decreaseLevel ?
                                                '' :
                                                <>
                                                    {this.state.type === 'LoginForm'
                                                        ? <>
                                                            <h3>Masz już konto ?<span>Zaloguj się</span></h3>
                                                            <LoginForm/>
                                                            <button className="pass-reminder"
                                                                    onClick={() => this.setState({type: 'PasswordReminderForm'})}>Nie
                                                                pamiętasz hasła?
                                                            </button>

                                                        </>
                                                        : <>
                                                            <PasswordReminderForm/>
                                                            <button className="pass-reminder"
                                                                    onClick={() => this.setState({type: 'LoginForm'})}>Logowanie
                                                            </button>
                                                        </>
                                                    }
                                                </>
                                            }
                                            <div className={"webinar-help-container"}>
                                                <h2>Potrzebujesz pomocy?</h2>
                                                <p>Nasi konsultanci są dostępni od poniedziałku do piątku w godzinach
                                                    8:00 -
                                                    16:00</p>
                                                <h3>22 518 29 29</h3>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="rpa-register-form rpa-login-col" xs={{span: 12}} lg={{span: 6}}>
                                        <RegisterForm children={children}/>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </Col>
                    <Col md lg={{span: 4, offset: 0, order: 3}} className={"rpa-offer"}>
                        <Container>
                            <div className={"register-offer-wrapper"}>
                                <div className="register-info-block mb-5">
                                    <h2>{children.product.name}</h2>
                                    {children.product.experts ?
                                        <p>Eksperci:
                                            {children.product && children.product.experts.map((expert, key) => (
                                                <span key={key}>&nbsp;
                                                    {expert.name} {expert.surname}{children.product.experts.length > key + 1 && children.product.experts.length > 1 ? ', ' : ''}
                                            </span>
                                            ))}
                                        </p>
                                        : ''
                                    }

                                    <p>Termin: {children.product.timetable[0].eventStartDate ?
                                        <><span
                                            className="date">{dateFormat(Date.parse(children.product.timetable[0].eventStartDate), 'dd-mm-yyyy')}</span></> : ''


                                    }

                                    </p>
                                    <p>Godzina: {children.product.timetable[0].eventStartDate ?
                                        <span
                                            className={"time"}>{dateFormat(Date.parse(children.product.timetable[0].eventStartDate), 'HH:MM')}</span>
                                        : ''}
                                    </p>
                                </div>
                                <div className="register-info-block mb-5">
                                    <h3 className={"payu"}>Do zapłaty</h3>
                                    <div className={"price-container"}>
                                        <h4>{children.product.additionalFields.priceGross.toFixed(2)}<span> brutto</span>
                                        </h4>
                                        <h5>{children.product.additionalFields.priceNet.toFixed(2)} netto</h5>
                                    </div>

                                </div>
                            </div>
                        </Container>
                    </Col>
                </Row>
            </>
        )

    }

}

export default Account;