import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {Button, Form} from "react-bootstrap";
import {connect} from 'react-redux';
import {userLoginAttempt} from "../../actions/auth";
import {renderField} from "../../common/form";


const mapDispatchToProps = {
    userLoginAttempt
};

class LoginForm extends React.Component {

    onSubmit(values) {
        const {userLoginAttempt} = this.props;
        return userLoginAttempt(
            values.email,
            values.password
        );
    }

    render() {
        const {form, handleSubmit, error} = this.props;

        return (
            <>
                {error && <div className="alert alert-danger">{error}</div>}
                <Form id={form}
                      onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Field name="email" type="text"
                           component={renderField} placeholder="E-mail"/>
                    <Field name="password" placeholder="hasło" type="password"
                           component={renderField}/>
                    {/*<div className={"recaptcha-container"}>RECAPTCHA</div>*/}
                    <Button className="login-btn" variant="primary" type="submit">Zaloguj
                        się</Button>
                </Form>
            </>
        )
    }
}

export default reduxForm({
    form: 'LoginForm'
})(connect(null, mapDispatchToProps)(LoginForm));