import React from "react";
import {Tabs, Tab} from "react-bootstrap";
import {
    profileDeleteProduct,
    profileVideoWatchListFetch,
    profileSavedProductsFetch,
    profileProgressProductsFetch,
    profileCompleteProductsFetch
} from "../../actions/profile";
import {connect} from "react-redux";
import ProductFavouriteList from "./product/ProductFavouriteList";
import ProductStartedList from "./product/ProductStartedList";
import ProductCompletedList from "./product/ProductCompletedList";

const mapStateToProps = state => ({
    ...state.profile,
    profileVideoWatch: state.profileVideo,
    userData: state.auth.userData,
    progressProducts: state.profile.progressProducts,
    completeProducts: state.profile.completeProducts
});

const mapDispatchToProps = {
    profileSavedProductsFetch,
    profileVideoWatchListFetch,
    profileDeleteProduct,
    profileProgressProductsFetch,
    profileCompleteProductsFetch
}

class ProfileProductContainer extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            activeAction: 'favourite'
        }
    }




    switchAction = (e) => {
        this.setState({
            activeAction: e
        })
    }

    render() {
        return (
            <>
                <div className="profile-product-container">
                <Tabs
                    id="uncontrolled-tab-example"
                    activeKey={this.state.activeAction}
                    onSelect={this.switchAction.bind(this)}
                >
                    <Tab eventKey="favourite" title="Zapisane">
                        <ProductFavouriteList children={this.props} />
                    </Tab>
                    <Tab eventKey="started" title="Rozpoczęte">
                        <ProductStartedList children={this.props} />
                    </Tab>
                    <Tab eventKey="finished" title="Ukończone">
                        <ProductCompletedList children={this.props} />
                    </Tab>
                </Tabs>
                </div>
            </>
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileProductContainer);