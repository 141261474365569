import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {Button, Container, Form} from "react-bootstrap";
import {connect} from "react-redux";
import {renderField} from "../../../common/form";
import {webinarRegisterAttempt} from "../../../actions/webinar";
import dateFormat from "dateformat";

const mapStateToProps = state => ({
    ...state.product,
    error: state.webinar.error
});

const mapDispatchToProps = {
    webinarRegisterAttempt
};
export const acceptTerms = <div>Znam i akceptuję warunki <a href="/regulamin" target={"_blank"}
                                                            rel={"noopener noreferrer"}>Regulaminu</a> i <a
    href="https://www.wip.pl/polityka-prywatnosci" target={"_blank"} rel={"noopener noreferrer"}>Polityki
    Prywatności </a><span> *</span></div>;
export const marketingAgreement = <div>Chcę otrzymywać informacje drogą elektroniczną i telefoniczną o dodatkowych
    promocjach, rabatach dotyczących produktów własnych WIP i partnerów WIP. Wiem, że moja zgoda może być wycofana w
    dowolnym momencie.<span> *</span></div>;

class WebinarForm extends React.Component {

    getDates() {
        const {dates} = this.props;
        let tmp = [];
        dates.map((data) => {
            if (!data.expired) {
                tmp.push([dateFormat(Date.parse(data.eventStartDate), 'dd-mm-yy HH:MM'), parseInt(data.id)])
            }
        })
        return (tmp)
    }

    onSubmit(values) {
        const {webinarRegisterAttempt, product} = this.props;
        let tmp = {
            name: values.name,
            surname: values.surname,
            email: values.email,
            phone: values.phone,
            position: values.position,
            timetableId: (parseInt(values.timetableId)),
            trainingId: product.id,
            acceptTerms: values.acceptTerms,
            marketingAgreement: values.marketingAgreement,
            cid: localStorage.cid,
            requestUrl: window.location.href
        };
        if (product) {
            product.timetable.forEach((item) => {
                if (item.id === tmp.timetableId) {
                    sessionStorage.setItem('webinarDate', dateFormat(Date.parse(item.eventStartDate), 'HH:MM | dd-mm-yy '));
                }
            })
        }
        return webinarRegisterAttempt(tmp)

    }

    getPositions() {
        const {product} = this.props;
        const tmp = [];
        const positions = [['dyrektor/wicedyrektor szkoły'],
            ['dyrektor/wicedyrektor przedszkola'],
            ['pedagog szkolny/psycholog szkolny'],
            ['logopeda'],
            ['nauczyciel wspomagający'],
            ['nauczyciel przedszkola'],
            ['nauczyciel wychowawca'],
            ['nauczyciel (zaznacz, jeśli nie jesteś wychowawcą)'],
            ['katecheta'],
            ['specjalista ds. kadr i/lub księgowości'],
            ['doradca zawodowy w SP'],
            ['doradca zawodowy w szkole ponadpodstawowej'],
            ['terapeuta SI'],
            ['przedstawiciel organu prowadzącego'],
            ['inne']];
        product.categories.map((category) => {
            if (category.name === "Oświata" || category.name === "Dyrektorzy" || category.name === "Oświata" || category.name === "Oświata") {
                return (tmp.push(category.name))
            }
        });
        if (tmp.length > 0) {
            return (
                <Field name="position" type="select" options={positions} component={renderField}
                       placeholder="Wybierz stanowisko"/>
            )
        } else {
            return (
                <Field name="position" type="text"
                       component={renderField} placeholder="Stanowisko"/>
            )
        }
    }


    render() {
        const {form, handleSubmit, error} = this.props;
        const options = this.getDates();

        return (

            <Container className={"webinar-form-container-content"}>
                {error &&
                <div className="alert alert-danger">Wystąpił błąd! Odśwież stronę lub spróbuj inny e-mail.</div>}
                <h2>WYPEŁNIJ FORMULARZ I ZAPISZ
                    SIĘ NA <span>BEZPŁATNY</span> WEBINAR</h2>
                <Form className="" id={form} onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Field name="name" type="text"
                           component={renderField} placeholder="Imie"/>
                    <Field name="surname" type="text"
                           component={renderField} placeholder="Nazwisko"/>
                    <Field name="email" type="text"
                           component={renderField} placeholder="E-mail"/>
                    <Field name="phone"
                           type="text"
                           component={renderField}
                           placeholder="Telefon"
                           mask={{
                               pattern: "+48 999 999 999",
                               text: 'Prawidłowy format +48 999 999 999'
                           }}
                    />
                    {this.getPositions()}
                    <Field name="timetableId" type="select" options={options}
                           component={renderField} placeholder="Wybierz termin"/>
                    <Field name="acceptTerms" type="checkbox"
                           component={renderField} label={acceptTerms}/>
                    <Field name="marketingAgreement" type="checkbox"
                           component={renderField} label={marketingAgreement}/>
                    <Button className="webinar-btn webinar-btn-white" type="submit">Zapisz się bezpłatnie</Button>
                </Form>
            </Container>
        )
    }
}

export default reduxForm({
    form: 'WebinarForm'
})(connect(mapStateToProps, mapDispatchToProps)(WebinarForm));