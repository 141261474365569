import React from 'react';
import {Alert, Card, Col, Row, Spinner} from "react-bootstrap";
import dateFormat from "dateformat";
import {strNormalize} from "../../common/utils";
import striptags from "striptags";
import WebinarPaidListItems from "../Modules/Index/Lists/webinarPaid/WebinarPaidListItem";
import WebinarFreeListItems from "../Modules/Index/Lists/webinarFree/WebinarFreeListItem";
import TrainingListItems from "../Modules/Index/Lists/training/TrainingListItem";



export const searchTypes = {
    'webinar': 'webinar bezpłatny',
    'online': 'szkolenie',
    'webinarPaid': 'webinar płatny'
};


const ProductSearchList = props => {

    const {products, auth} = props;


    function getContent(type, product, auth, id) {
        switch (type) {
            case 'online':
                return (<TrainingListItems product={product} auth={auth} id={id} />);
            case 'webinar':
                return (<WebinarFreeListItems product={product} auth={auth} id={id} />);
            case 'webinarPaid':
                return (<WebinarPaidListItems product={product} auth={auth} id={id} />);
            default:
                return (<TrainingListItems product={product} auth={auth} id={id} />);
        }
    }

    if (!products)
        return (<Spinner animation="grow"/>);

    if (products.length === 0)
        return (<Alert variant="info">Brak produktów!</Alert>);

    return (
        <Row>
            {products && products.map((product, index) => (

                <Col key={index} lg={6} xl={4} md={6} className={'mt-4 list-item-container'}>
                    {getContent(product.type, product, auth, product.categories[0].id)}

                </Col>
            ))}
        </Row>
    );

}

export default ProductSearchList;