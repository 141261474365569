import {requests} from "../agent";

import {
    MULTI_TABLE_REQUEST,
    MULTI_TABLE_LIST_RECEIVED,
    MULTI_TABLE_ERROR,
    MULTI_TABLE_DELETE_USER_RECEIVED,
    MULTI_TABLE_REPEAT_INVITE_RECEIVED,
    MULTI_TABLE_REGISTER_USER_RECEIVED,
    MULTI_TABLE_GET_VOUCHER_RESPONSE,
    MULTI_TABLE_GET_SECOND_VOUCHER_RESPONSE,
    MULTI_TABLE_DELETE_USER_REQUEST,
    MULTI_TABLE_REGISTER_USER_REQUEST,
    MULTI_SECOND_TABLE_LIST_RECEIVED,
    MULTI_TABLE_GIVE_ADMIN_REQUEST,
    MULTI_TABLE_REMOVE_ADMIN_REQUEST,
    MULTI_TABLE_GIVE_ADMIN_RECEIVED,
    MULTI_TABLE_REMOVE_ADMIN_RECEIVED,
    MULTI_TABLE_REINVITE_REQUEST,
    MULTI_TABLE_ADD_USER_ERROR,
    MULTI_TABLE_INVITE_CONFIRMATION_RESPONSE,
    MULTI_TABLE_INVITE_CONFIRMATION_REQUEST,
    MULTI_TABLE_IMPORT_FILE_ERROR,
    MULTI_TABLE_IMPORT_FILE_RESPONSE,
    MULTI_TABLE_IMPORT_FILE_REQUEST
} from "../constants";
import {SubmissionError} from "redux-form";
import {
    formValidate,
    minChar,
    requiredField,
    validateEmail,
    validateTextByPattern
} from "../common/form";


export const multiTableRequest = () => ({
    type: MULTI_TABLE_REQUEST,
});
export const multiTableDeleteRequest = () => ({
    type: MULTI_TABLE_DELETE_USER_REQUEST,
});

export const multiTableAddUserRequest = () => ({
    type: MULTI_TABLE_REGISTER_USER_REQUEST,
});

export const multiTableGiveAdminRequest = () => ({
    type: MULTI_TABLE_GIVE_ADMIN_REQUEST,
});

export const multiTableRemoveAdminRequest = () => ({
    type: MULTI_TABLE_REMOVE_ADMIN_REQUEST,
});

export const multiTableError = (error) => ({
    type: MULTI_TABLE_ERROR,
    error
});
export const multiTableAddUserError = (error) => ({
    type: MULTI_TABLE_ADD_USER_ERROR,
    error
});


/*
* POBIERANIE LISTY UŻYTKOWNIKÓW W PANELU SUPMARKI. Dwie listy do jednoczesnego pobierania przy pierwszym renderowaniu mojej strefy
*
* */
export const multiTableList = () => {
    let body = {brand: 1}
    return (dispatch) => {
        dispatch(multiTableRequest());
        return requests.post(`/subusers/accounts`, body, true)
            .then(response => dispatch(multiTableListResponse(response)))
            .catch(error => dispatch(multiTableError(error)))
    }
};

export const multiTableListResponse = (data) => {
    return {
        type: MULTI_TABLE_LIST_RECEIVED,
        data
    }
};
export const multiSecondTableList = () => {
    let body = {brand: 2}
    return (dispatch) => {
        dispatch(multiTableRequest());
        return requests.post(`/subusers/accounts`, body, true)
            .then(response => dispatch(multiSecondTableListResponse(response)))
            .catch(error => dispatch(multiTableError(error)))
    }
};

export const multiSecondTableListResponse = (data) => {
    return {
        type: MULTI_SECOND_TABLE_LIST_RECEIVED,
        data
    }
};

/*
* KASOWANIE POJEDYNCZEGO UŻYTKOWNIKA Z LIST
*
* */


export const multiTableDeleteUser = (userId, brand) => {
    let body = {
        brand: brand,
        userId: userId,
    }
    return (dispatch) => {
        dispatch(multiTableDeleteRequest());
        return requests.post(`/subusers/remove`,  body, true)
            .then(response => dispatch(multiTableDeleteUserResponse(response)))
            .catch(error => dispatch(multiTableError(error)))
    }
};

export const multiTableDeleteUserResponse = (data) => {
    return {
        type: MULTI_TABLE_DELETE_USER_RECEIVED,
        data
    }
}


/*
* NADAWANIE STATUSU ADMINISTRATORA POJEDYNCZEMU UŻYTKOWNIKOWI Z LISTY
*
* */
export const multiTableGiveAdmin = (userId, brand) => {
    let body = {
        userId: userId,
        brand: brand
    }

    return (dispatch) => {
        dispatch(multiTableGiveAdminRequest());
        return requests.post(`/subusers/toggle_admin`, body, true)
            .then(response => dispatch(multiTableGiveAdminResponse(response)))
            .catch(error => dispatch(multiTableError(error)))
    }
};

export const multiTableGiveAdminResponse = (data) => {
    return {
        type: MULTI_TABLE_GIVE_ADMIN_RECEIVED,
        data
    }
}

/*
* ODBIERANIE STATUSU ADMINISTRATORA POJEDYNCZEMU UŻYTKOWNIKOWI Z LISTY
*
* */
export const multiTableRemoveAdmin = (userId, brand) => {
    let body = {
        userId: userId,
        brand: brand
    }

    return (dispatch) => {
        dispatch(multiTableRemoveAdminRequest());
        return requests.post(`/subusers/toggle_admin`, body, true)
            .then(response => dispatch(multiTableRemoveAdminResponse(response)))
            .catch(error => dispatch(multiTableError(error)))
    }
};

export const multiTableRemoveAdminResponse = (data) => {
    return {
        type: MULTI_TABLE_REMOVE_ADMIN_RECEIVED,
        data
    }
}
/*
* WYSYŁANIE PONOWNIE ZAPROSZENIA POJEDYNCZEMU UŻYTKOWNIKOWI Z LISTY
*
* */

export const multiTableRepeatInvite = (userId, brand) => {
    let body = {
        userId: userId,
        brand: brand
    }


    return (dispatch) => {
        dispatch(multiTableReinviteRequest());
        return requests.post(`/subusers/reinvite`, body, true)
            .then(response => dispatch(multiTableRepeatInviteResponse(response)))
            .catch(error => dispatch(multiTableError(error)))
    }
};

export const multiTableRepeatInviteResponse = (data) => {
    return {
        type: MULTI_TABLE_REPEAT_INVITE_RECEIVED,
        data
    }
}

export const multiTableReinviteRequest = () => ({
    type: MULTI_TABLE_REINVITE_REQUEST,
});

/*
* DODAWANIE POJEDYNCZEGO UŻYTKOWNIKA DO LISTY
*
* */
export const multiTableRegisterUser = (fields, brand) => {

    let mapFields = [
        {
            field: 'name',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole imię nie może pozostać puste!'
                },
                {
                    method: minChar,
                    text: 'Imię powinno zawierać minimum 3 znaki.',
                    params: {limit: 3}
                },
                {
                    method: validateTextByPattern,
                    text: 'Tylko litery, myślnik oraz spacja.',
                    params: {pattern: /^[a-zęćółśążźń /-]*$/iu}
                }
            ]
        },
        {
            field: 'surname',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole nazwisko nie może pozostać puste!'
                },
                {
                    method: minChar,
                    text: 'Nazwisko powinno zawierać minimum 3 znaki.',
                    params: {limit: 3}
                },
                {
                    method: validateTextByPattern,
                    text: 'Tylko litery, myślnik oraz spacja.',
                    params: {pattern: /^[a-zęółćśążźń /-]*$/iu}
                }
            ]
        },
        {
            field: 'email',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole email nie może pozostać puste!'
                },
                {
                    method: validateEmail,
                    text: 'Podany adres email nie jest prawidłowy!',
                }
            ]
        },
        {
            field: 'isAdmin',
        }
    ];
    let validate = formValidate(mapFields, fields)

    if (validate) {
        throw new SubmissionError(validate)
    } else {
        let body = {name: fields.name, surname: fields.surname, email: fields.email, isAdmin: fields.isAdmin, brand: brand}
        return (dispatch) => {
            dispatch(multiTableAddUserRequest());
            return requests.post(`/subusers/add`, body, true)
                .then(response => dispatch(multiTableRegisterUserReceived(response)))
                .catch(error => dispatch(multiTableAddUserError(error)))

        }

    }
};


export const multiTableRegisterUserReceived = (data) => {
    return {
        type: MULTI_TABLE_REGISTER_USER_RECEIVED,
        data
    }
}


export const multiTableGetVoucher = () => {
    let body = {
        brand: 1,
    }

    return (dispatch) => {
        dispatch(multiTableRequest());
        return requests.post(`/subusers/get_user_voucher`, body, true)
            .then(response => dispatch(multiTableGetVoucherResponse(response)))
            .catch(error => dispatch(multiTableError(error)))
    }
};


export const multiTableGetVoucherResponse = (data) => {
    return {
        type: MULTI_TABLE_GET_VOUCHER_RESPONSE,
        data
    }
}

export const multiTableGetSecondVoucher = () => {
    let body = {
        brand: 2,
    }

    return (dispatch) => {
        dispatch(multiTableRequest());
        return requests.post(`/subusers/get_user_voucher`, body, true)
            .then(response => dispatch(multiTableGetSecondVoucherResponse(response)))
            .catch(error => dispatch(multiTableError(error)))
    }
};


export const multiTableGetSecondVoucherResponse = (data) => {
    return {
        type: MULTI_TABLE_GET_SECOND_VOUCHER_RESPONSE,
        data
    }
}


// logowanie klienta po kliknięcie w mailu z powtórnym zaproszeniem

export const multiTableInviteConfirmationRequest = () => ({
    type: MULTI_TABLE_INVITE_CONFIRMATION_REQUEST,
});
export const multiTableInviteConfirmation = (brand, userId, invitationKey ) => {
    let body = {
        brand: brand ? brand : null ,
        invitationKey: invitationKey ? invitationKey : null,
        userId: userId ? userId : null

    }

    return (dispatch) => {
        dispatch(multiTableInviteConfirmationRequest());
        return requests.post(`/subusers/invitation`, body, false)
            .then(response => dispatch(multiTableInviteConfirmationResponse(response)))
            .catch(error => dispatch(multiTableError(error)))
    }
};
export const multiTableInviteConfirmationResponse = (data) => {
    return {
        type: MULTI_TABLE_INVITE_CONFIRMATION_RESPONSE,
        data
    }
}
// importowanie pliku csv

export const multiTableImportFileRequest = () => ({
    type: MULTI_TABLE_IMPORT_FILE_REQUEST,
});
export const  multiTableImportFile = (brand, file) => {
    let body = {
        brand: brand ? brand : null ,
        file: file ? file : null,
    }

    return (dispatch) => {
        dispatch(multiTableImportFileRequest());
        return requests.post(`/subusers/add_from_file`, body)
            .then(response => dispatch(multiTableImportFileResponse(response)))
            .catch(error => dispatch(multiTableImportFileError(error)))
    }
};
export const multiTableImportFileResponse = (data) => {
    return {
        type: MULTI_TABLE_IMPORT_FILE_RESPONSE,
        data
    }
}
export const multiTableImportFileError = (error) => {
    return {
        type: MULTI_TABLE_IMPORT_FILE_ERROR,
        error
    }
}