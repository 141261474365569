import React from "react";
import {Spinner} from "react-bootstrap";

const ProductVideoList = (props) => {

    const {product, auth, setVideo, profileVideo} = props;

    if (!product)
        return (<Spinner animation="grow"/>);

    return (
        <div className="video-list">
            <h3>Treść kursu</h3>
            <ul  className="btn-video-list">
            <p>Kliknij na rozdział, aby odtworzyć</p>

            {product && product.videos.map((item, key) => {
                const eventClick = () => {
                    setVideo(item);
                };
                return (
                    <>
                        {((auth.isAuthenticated && !auth.decreaseLevel) || item.paid !== true) ?
                            <li><button key={key} className={`video-list-item  ${item.vimeoId === profileVideo.vimeoId ? 'active' : 'no-active'}`}
                                        onClick={eventClick}><span>{item.title}</span></button></li>
                            :
                            <p>{item.title}</p>
                        }</>
                )
            })}
            </ul>

        </div>
    )
}

export default ProductVideoList;