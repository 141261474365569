import {SubmissionError} from "redux-form";
import {
    NEWSLETTER_ERROR,
    NEWSLETTER_RECEIVED,
    WEBINAR_ERROR,
    WEBINAR_MAIL_CONFIRMATION_RECEIVED,
    WEBINAR_REGISTER_RECEIVED
} from "../constants";
import {
    formValidate,
    minChar,
    requiredField,
    requiredFieldSelect,
    validateEmail,
    validateTextByPattern
} from "../common/form";
import {requests} from "../agent";

export const webinarRegisterAttempt = (fields) => {

    let mapFields = [
        {
            field: 'name',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole imię nie może pozostać puste!'
                },
                {
                    method: minChar,
                    text: 'Imię powinno zawierać minimum 3 znaki.',
                    params: {limit: 3}
                },
                {
                    method: validateTextByPattern,
                    text: 'Tylko litery, myślnik oraz spacja.',
                    params: {pattern: /^[a-zćęółśążźń /-]*$/iu}
                }
            ]
        },
        {
            field: 'surname',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole nazwisko nie może pozostać puste!'
                },
                {
                    method: minChar,
                    text: 'Nazwisko powinno zawierać minimum 3 znaki.',
                    params: {limit: 3}
                },
                {
                    method: validateTextByPattern,
                    text: 'Tylko litery, myślnik oraz spacja.',
                    params: {pattern: /^[a-zćęółśążźń /-]*$/iu}
                }
            ]
        },
        {
            field: 'email',
            text: 'Podany adres email nie jest prawidłowy!',
            method: validateEmail
        },
        {
            field: 'phone',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole telefon nie może pozostać puste!'
                },
                {
                    method: minChar,
                    text: 'Poprawny format telefonu +48 999 999 999',
                    params: {limit: 15}
                },
                {
                    method: validateTextByPattern,
                    text: 'Poprawny format telefonu +48 999 999 999',
                    params: {pattern: /^\+48 [0-9 ]{11}$/iu}
                }
            ]
        },
        {
            field: 'position',
            method: requiredField,
            text: 'Pole stanowisko nie może pozostać puste!'
        },
        {
            field: 'timetableId',
            method: requiredFieldSelect,
            text: 'Proszę wybrać datę!'
        },
        {
            field: 'acceptTerms',
            text: 'Akceptacja regulaminu jest wymagana!',
            method: requiredField
        },
        {
            field: 'marketingAgreement',
            text: 'Akceptacja pola jest wymagana!',
            method: requiredField
        }
    ];

    let validate = formValidate(mapFields, fields)

    if (validate) {
        sessionStorage.removeItem('webinarDate')
        throw new SubmissionError(validate)
    } else {
        let body = fields

        return (dispatch) => {
            return requests.post(`/event_subscriber_lists`, body)
                .then(response => dispatch(webinarRegisterReceived(response)))
                .catch(error => dispatch(webinarError(error)))

        }

    }
};


export const webinarMailConfirmation = (id, hash) => {
    let url = window.location.href;
    let body = {
        hash: hash,
        requestUrl: url
    }
    return (dispatch) => {
        return requests.post(`/subscriber/doi/${id}/confirm`, body)
            .then(response => dispatch(webinarMailConfirmationReceived(response)))
            .catch(error => dispatch(webinarError(error)))
    }
};


export const webinarMailConfirmationReceived = (data) => {
    return {
        type: WEBINAR_MAIL_CONFIRMATION_RECEIVED,
        data,

    }
};

export const webinarRegisterReceived = (data) => {
    return {
        type: WEBINAR_REGISTER_RECEIVED,
        data,

    }
};

export const webinarError = (error) => ({
    type: WEBINAR_ERROR,
    error
});


export const newsletterAttempt = (fields) => {

    let mapFields = [

        {
            field: 'email',
            text: 'Podany adres email nie jest prawidłowy!',
            method: validateEmail
        },
        {
            field: 'acceptTerms',
            text: 'Akceptacja regulaminu jest wymagana!',
            method: requiredField
        },
        {
            field: 'marketingAgreement',
            text: 'Akceptacja pola jest wymagana!',
            method: requiredField
        }
    ];

    let validate = formValidate(mapFields, fields);

    if (validate) {
        throw new SubmissionError(validate)
    } else {
        let body = fields;

        return (dispatch) => {
            return requests.post(`/orders`, body)
                .then(response => dispatch(newsletterReceived(response)))
                .catch((error) => {
                    dispatch(newsletterError(error));
                    throw new SubmissionError({
                        _error: 'Wystąpił problem z pobraniem danych klienta! Proszę odświeżyć stronę.'
                    })
                })
        }

    }
};

export const newsletterReceived = (data) => {
    return {
        type: NEWSLETTER_RECEIVED,
        data,

    }
};

export const newsletterError = (error) => ({
    type: NEWSLETTER_ERROR,
    error
});