import React from "react";
import {Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {getBreadcrumb, getPrice} from "../../../common/utils";


class Payment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            paymentType: 'payu',
            error: false,
            type: 'payment',
            quantity: 1,
            discountCode: '',
            breadcrumb: [
                {'type': 'buyer', 'class': 'one', 'name': 'Krok 1 / 3 - DANE'},
                {'type': 'users', 'class': 'one', 'name': 'Krok 1 / 3 - DANE'},
                {'type': 'payment', 'class': 'two', 'name': 'Krok 2 / 3 - PODSUMOWANIE I PŁATNOŚĆ'},
                {'type': 'summary', 'class': 'three', 'name': 'KROK 3 / 3 -  POTWIERDZENIE I DODATKOWE INFORMACJE'}
            ]
        }
    }

    componentDidMount() {
        const {registerGetOrder, registerToken} = this.props.children;
        if (registerToken)
            registerGetOrder(registerToken);
        if (!window.sessionStorage.getItem('reguser')) {
            window.sessionStorage.setItem('reguser', true);
            window.location.reload();

        }
    }
    componentWillUnmount() {
        window.sessionStorage.removeItem('reguser');
    }

    processPayment() {
        if (this.state.paymentType) {
            const {registerPaymentComplete, registerToken, product} = this.props.children;
            registerPaymentComplete(product, this.state.paymentType, registerToken);
        } else {
            this.setState({error: 'Musisz wybrać sposób płatności!'})
        }
    }

    choosePaymentType(paymentType) {
        this.setState({paymentType: paymentType})
    }

    invoiceData() {
        const {user, buyer} = this.props.children;
        if (user && buyer) {
            return (
                <>
                    <div className={'payment-data-head-container'}>
                        <h5>Twoje dane do faktury</h5>
                        <button className={'btn btn-primary'} onClick={this.editInvoiceData.bind(this)}>zmień
                        </button>
                    </div>
                    <p>
                        <small>imię:</small>
                        {user.name}</p>
                    <p>
                        <small>nazwisko:</small>
                        {user.surname}</p>
                    <p>
                        <small>email:</small>
                        {user.email}</p>
                    {
                        buyer.tin && user.userType === "company" ?
                            <>
                                <hr/>
                                <p><strong>Dane płatnika:</strong></p>
                                <p>
                                    <small>adres:</small>
                                    {buyer.city}, {buyer.postalCode}, {buyer.street} {buyer.streetNumber} {buyer.apartmentNumber}
                                </p>
                                {buyer.tin ? <p>
                                    <small>NIP:</small>
                                    {buyer.tin}</p> : ''}
                            </>
                            :
                            <>
                                <hr/>
                                <p>
                                    <small>adres:</small>
                                    {user.city}, {user.postalCode}, {user.street} {user.streetNumber} {user.apartmentNumber}
                                </p>
                                {user.tin && user.userType === "company" ? <p>
                                    <small>NIP:</small>
                                    {user.tin}</p> : ''}
                            </>
                    }
                </>
            );
        }
        return;
    }


    editInvoiceData() {
        window.sessionStorage.setItem('regStep', 'PROGRESS');
        window.location.reload(false)
    }

    render() {
        const {product, user, buyer, isFetching} = this.props.children;

        if (isFetching || !user) {
            return (<Spinner animation="grow"/>);
        }

        return (
            <Container>
                <Row className={"register-product-buyer-container"}>
                    <Form className="row">
                        <Col xs={{span: 12}} lg={{span: 8}} className={"rpa-login-form-container payment-container"}>
                            <div className={"background-wrapper"}>

                                <Container className={"buyer-form-container"}>
                                    {getBreadcrumb(this.state.breadcrumb, this.state.type, 'reg-breadcrumb payment')}
                                    <Row className={"payment-data-container"}>

                                        <Col md={5} className={"payment-item"}>
                                            <h2>Dane nabywcy:</h2>
                                            <p>{user.name} {user.surname}</p>
                                            {user.userType === "company" ? <p>{user.companyName}</p> : ''}
                                            {user.userType === "company" && user.tin ? <p>NIP: {user.tin}</p> : ''}
                                            <p>{user.street} {user.streetNumber}{user.apartmentNumber ? '/' + user.apartmentNumber : ''}</p>
                                            <p>{user.postalCode} {user.city}</p>
                                            <p>{user.phoneNumber}</p>
                                            <p>{user.email}</p>
                                        </Col>

                                        {window.sessionStorage.vatRelease && window.sessionStorage.vatRelease === 'true' ?
                                            <Col md={{span: 5, offset: 2}} className={"payment-item vat-release"}>
                                                <p>Zaznaczyłeś opcję <strong>„Zwolnienie z VAT”.</strong>
                                                    Pobierz oświadczenie i prześlij podpisany skan dokumentu na adres
                                                    cok@wip.pl, a płatność zostanie skorygowana.</p>
                                                <Button href={"/oswiadczenie.doc"} target={'_blank'}
                                                        rel='noreferrer' className={'btn webinar-btn'}>Pobierz
                                                    oświadczenie</Button>
                                            </Col> : ''}
                                        {user.userType === "company" && user.diffBuyer ?
                                            <Col md={(window.sessionStorage.vatRelease === 'true' ? {span: 5} : {span: 5, offset: 2})} className={"payment-item"}>
                                                <h2>Dane odbiorcy:</h2>
                                                <p>{buyer.companyName}</p>
                                                {buyer.tin ? <p>NIP: {buyer.tin}</p> : ''}
                                                <p>{buyer.street} {buyer.streetNumber}{buyer.apartmentNumber ? '/' + buyer.apartmentNumber : ''}</p>
                                                <p>{buyer.postalCode} {buyer.city}</p>
                                                <p>{buyer.phoneNumber}</p>
                                                <p>{buyer.email}</p>
                                            </Col> : ''
                                        }
                                        <Col
                                            md={(user.userType === "company" && user.diffBuyer && window.sessionStorage.vatRelease==='true') || (!user.diffBuyer && window.sessionStorage.vatRelease !== 'true') || (user.userType !== "company")? {
                                                span: 5,
                                                offset: 2
                                            } : {span: 5}} className={"payment-item payment-radio"}>
                                            <h2>Sposób płatności:</h2>
                                            <Form.Group className={"form-group-container payment-method-container payu"}>
                                                <Form.Check
                                                    key={1}
                                                    type="radio"
                                                    label={'Payu'}
                                                    name="reg-payment-radio"
                                                    id={`radio-1`}
                                                    onClick={this.choosePaymentType.bind(this, 'payu')}
                                                    defaultChecked
                                                />
                                                <Form.Check
                                                    className={"invoice"}
                                                    key={2}
                                                    type="radio"
                                                    label={'Przelew tradycyjny'}
                                                    name="reg-payment-radio"
                                                    id={`radio-2`}
                                                    onClick={this.choosePaymentType.bind(this, 'invoice')}
                                                />
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                </Container>
                            </div>
                        </Col>
                        <Col md lg={{span: 4, offset: 0, order: 2}} className={"rpa-offer"}>
                            <Container>
                                <div className={"register-offer-wrapper"}>
                                    <div className="register-info-block mb-5">
                                        <h2 className={"mb-5"}>Abonament {product.name}</h2>
                                        {product.benefits ?
                                            <>
                                                {product && product.benefits.map((benefit, key) => (
                                                    <h4 className={'green-dot'} key={key}>
                                                        {benefit}
                                                    </h4>
                                                ))}
                                            </>
                                            : ''
                                        }
                                        <h4 className={'green-dot'}>
                                            Abonament odnawialny <a href="/pomoc">dowiedz się więcej</a>
                                        </h4>

                                    </div>
                                    <div className="register-info-block mb-4">
                                        <h3 className={"payu"}>Do zapłaty</h3>
                                        <div className={"price-container"}>
                                            <h4>{getPrice(product.priceGross)}<span> brutto</span>
                                            </h4>
                                            <h5>{getPrice(product.priceNet)} netto</h5>
                                        </div>
                                        <div className={"btn-wrapper"}>
                                            <Button className={'btn btn-reverse webinar-btn'}
                                                    onClick={this.editInvoiceData}>Wstecz</Button>
                                            <Button className={"btn webinar-btn"}
                                                    onClick={this.processPayment.bind(this)}>Zapłać</Button>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </Col>
                    </Form>
                </Row>
            </Container>
        )
    }

}

export default Payment;