import React from 'react';
import {Button, Container, Form, FormControl, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import ProductSearchList from "./ProductSearchList";
import {searchFetch, searchSetType, searchSetPage} from "../../actions/search";
import {connect} from "react-redux";
import queryString from 'query-string';
import {Helmet} from "react-helmet";
import {convertMetaDescription, convertMetaTitle} from "../../common/utils";

const mapStateToProps = state => ({
    ...state.search,
    auth: state.auth
});

const mapDispatchToProps = {
    searchFetch,
    searchSetType,
    searchSetPage
}

// const productTags = [
//     {
//         "type": "online",
//         "name": "Szkolenia online"
//     },
//     {
//         "type": "videoinstruction",
//         "name": "Wideoinstrukcje"
//     },
//     {
//         "type": "videotip",
//         "name": "Wideoporady"
//     },
//     // {
//     //     "type": "podcast",
//     //     "name": "Podcast"
//     // }
// ];

class Search extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            phraze: '',
            tagFilters: {
                type: [
                    'online',
                    'webinar',
                    'videotip',
                   // 'podcast'
                ]
            }
        }
    }

    componentDidMount() {
        if (this.props.location.search) {
            this.setState({
                phraze: queryString.parse(this.props.location.search).phraze
            });
        }
        this.props.searchSetType(this.getQueryParams());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {searchFetch, searchSetType, tagFilters} = this.props;
        if (prevProps.tagFilters !== this.getQueryParams()) {
            searchSetType(this.getQueryParams());
        }
        if (tagFilters !== prevProps.tagFilters) {
            searchFetch(this.state.phraze, tagFilters);
        }
    }

    getQueryParams() {
        return this.state.tagFilters;
    }

    setTagFilter(tag, clear) {
        let tmp = this.state.tagFilters.type.filter(item => item !== tag);
        if (!clear)
            tmp.push(tag);
        this.setState({tagFilters: {type: tmp}});
    }

    showModal() {
        this.setState({show: true});
    };

    hideModal() {
        this.setState({show: false});
    };
    resetTagFilter() {
        this.setState({
            tagFilters: {
                type: [
                    'online',
                    'videoinstruction',
                    'videotip',
                 //   'podcast'
                ]
            }
        });
    }

    onChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }

    render() {

        const {productsList, tagFilters, isFetching, auth} = this.props;

        if (tagFilters === null || isFetching) {
            return false;
        }

        return (
            <Container className={"search-container"} fluid>
                <Helmet>
                    <title>{convertMetaTitle('wyszukiwarka')}</title>
                    <meta name="description" content={convertMetaDescription('Wpisz czego szukasz?')} />
                </Helmet>
                <Row className={"search-main-badge"}>
                    <Container>
                        <h4>wyniki wyszukiwania</h4>
                        <div className="mr-auto search-form">
                            <Form className="mr-auto" inline action="/szukaj" method="get">
                                <FormControl onChange={this.onChange} name="phraze" type="text"
                                             value={this.state.phraze ? this.state.phraze : ""}
                                             placeholder={'Czego szukasz?'}
                                             className=""/>
                                <Button type="submit" variant="outline-light"><FontAwesomeIcon icon={faSearch}/></Button>
                            </Form>
                        </div>
                    </Container>
                </Row>
                <Row className="search-main-content">
                    {/*<Container className="box-filters-container">*/}
                        {/*<div className="box-filters">*/}
                            {/*{tagFilters && productTags.map((tag) => (*/}
                                {/*<Col key={tag.type}>*/}
                                    {/*<Form.Check*/}
                                        {/*custom*/}
                                        {/*type="checkbox"*/}
                                        {/*id={tag.type}*/}
                                        {/*label={tag.name}*/}
                                        {/*onChange={this.setTagFilter.bind(this, tag.type, tagFilters.type && tagFilters.type.some(item => item === tag.type))}*/}
                                        {/*className={`checkbox-input ${tagFilters.type && tagFilters.type.some(item => item === tag.type) ? 'active' : 'no-active'}`}*/}
                                        {/*checked={tagFilters.type && tagFilters.type.some(item => item === tag.type)}*/}
                                    {/*/>*/}
                                {/*</Col>*/}
                            {/*))}*/}
                            {/*<button className="btn" onClick={this.resetTagFilter.bind(this)}> - Resetuj filtry</button>*/}
                        {/*</div>*/}
                        {/*<Button className="filter-modal-btn" onClick={this.showModal.bind(this)}>FILTRUJ</Button>*/}
                        {/*<Modal className="login-modal" id={"filters-modal"} show={this.state.show}*/}
                               {/*onHide={this.hideModal.bind(this)}>*/}
                            {/*<Container className="modal-content-container">*/}
                                {/*<Row>*/}
                                    {/*<Modal.Header closeButton>*/}
                                        {/*<div className={"menu-mobile-category-badge"}>*/}
                                            {/*<img alt="logo" src="/logo.svg"/>*/}
                                        {/*</div>*/}
                                    {/*</Modal.Header>*/}
                                    {/*<Modal.Body>*/}
                                        {/*<Container>*/}
                                            {/*<Row>*/}
                                                {/*<div className="box-filters">*/}
                                                    {/*{tagFilters && productTags.map((tag) => (*/}
                                                        {/*<Col key={tag.type}>*/}
                                                            {/*<Form.Check*/}
                                                                {/*custom*/}
                                                                {/*type="checkbox"*/}
                                                                {/*id={tag.type}*/}
                                                                {/*label={tag.name}*/}
                                                                {/*onChange={this.setTagFilter.bind(this, tag.type, tagFilters.type && tagFilters.type.some(item => item === tag.type))}*/}
                                                                {/*className={`checkbox-input ${tagFilters.type && tagFilters.type.some(item => item === tag.type) ? 'active' : 'no-active'}`}*/}
                                                                {/*checked={tagFilters.type && tagFilters.type.some(item => item === tag.type)}*/}
                                                            {/*/>*/}
                                                        {/*</Col>*/}
                                                    {/*))}*/}
                                                    {/*<button className="login-btn" variant="primary" onClick={this.resetTagFilter.bind(this)}>Resetuj filtry</button>*/}
                                                {/*</div>*/}
                                            {/*</Row>*/}
                                        {/*</Container>*/}
                                    {/*</Modal.Body>*/}
                                {/*</Row>*/}
                            {/*</Container>*/}
                        {/*</Modal>*/}
                    {/*</Container>*/}
                    <Container className="search-results-container">
                        {
                            productsList
                                ? <ProductSearchList products={productsList} auth={auth} isFetching={this.props.isFetching}/>
                                : <h3>Zmień kryteria wyszukiwania!</h3>
                        }
                    </Container>
                </Row>
            </Container>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);