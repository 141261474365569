import React from 'react';
import {Container, Row, Spinner} from "react-bootstrap";
import {convertMetaDescription, convertMetaTitle} from "../../common/utils";
import Helmet from "react-helmet";
import {multiTableInviteConfirmation} from "../../actions/multiTable";
import {connect} from "react-redux";

const mapStateToProps = state => ({
    ...state.multiTable
});

const mapDispatchToProps = {
    multiTableInviteConfirmation
}

class InvitationContainer extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        const {match, multiTableInviteConfirmation} = this.props

        multiTableInviteConfirmation(match.params.brand, match.params.id, match.params.invitationKey);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {token} = this.props
        if(token && prevProps.token !== token) {
            localStorage.setItem("jwt", token);
            window.location.replace('/')

        }
    }

    render() {
        const{isFetching, error} = this.props;

        if (isFetching) {
            return (
                <Container className="home-main-container spinner-container">
                    <Row>
                        <Container className="home-products-container">
                            <br/><br/><Spinner animation="grow"/>
                        </Container>
                    </Row>
                </Container>
            );
        }

        return (
            <>
                <Helmet>
                    <title>{convertMetaTitle('Zaloguj się do educado')}</title>
                    <meta name={"potwierdzenie powtórnego zaproszenia"}
                          content={convertMetaDescription("")}/>
                </Helmet>
                <Container className={""} fluid>
                    <Row className={""}>
                        <Container>
                            {error ?
                            <div className={"subuser-thx-container"}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     fill="red" height="50" width="50" version="1.1" id="Capa_1"
                                     viewBox="0 0 460.775 460.775" >
                                    <path
                                        d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55  c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55  c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505  c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55  l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719  c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"/>
                                </svg>
                                <h1>Logowanie nie powiodło się</h1>
                                <p>Spróbuj jeszcze raz,<br/>lub skontaktuj się z COK.</p>
                            </div> :
                                <div className={"subuser-thx-container"}>
                                    <Spinner animation="grow"/>
                                    <h1>Trwa sprawdzanie...</h1>
                                </div>
                                }
                        </Container>
                    </Row>
                </Container>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (InvitationContainer);