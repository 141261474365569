import React from "react";
import {Col, Row, Spinner} from "react-bootstrap";
import {iriFormatId} from "../../common/apiUtils";
import ProductQuiz from "./quiz/ProductQuiz";
import Auth from "../Auth";
import {parseTrainingMinutes, parseTrainingTime, strNormalize} from "../../common/utils";
import ProductDocuments from "./ProductDocuments";
import ProductSocialMedia from "./ProductSocialMedia";
import dateFormat from "dateformat";
import ProductFavorite from "./ProductFavorite";
import striptags from "striptags";

export const contentType = {
    forWhichUsers: 'Dla kogo',
    problems: 'O szkoleniu',
    program: 'Program',
    legalBasis: 'Podstawa prawna'
};

class ProductContent extends React.Component {

    getTimetable(product) {
        if (product.subType !== 'online') {
            return <Col lg={7} md={12} className={"webinar-dates-container"}>
                {product && product.timetable.map((date, index) => (
                    <div key={index} className={date.expired ? 'webinar-date-item expired' : 'webinar-date-item'}>
                        {product.subType !== "webinar" ? <>
                                <span>LIVE z czatem</span><p><span
                                className={"time"}>{dateFormat(Date.parse(date.eventStartDate), 'HH:MM')}</span><span
                                className="date">&nbsp;| {dateFormat(Date.parse(date.eventStartDate), 'dd-mm-yyyy')}</span>
                            </p></> :
                            <>
                                <span>{index === 0 ? <>1&nbsp;</> : index + 1} TERMIN</span><p><span
                                className={"time"}>{dateFormat(Date.parse(date.eventStartDate), 'HH:MM')}</span><span
                                className="date">&nbsp;| {dateFormat(Date.parse(date.eventStartDate), 'dd-mm-yyyy')}</span>
                            </p></>}
                    </div>
                ))}
            </Col>

        }
    }

    static getBenefits(product) {
        switch (product.subType) {
            case 'online':
                return (
                    <>
                        <h3>Co otrzymasz w ramach abonamentu?</h3>
                        <ul className={"product-benefits-container mt-5"}>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                                     fill="none">
                                    <path
                                        d="M12.4997 22.9163C18.2526 22.9163 22.9163 18.2526 22.9163 12.4997C22.9163 6.74671 18.2526 2.08301 12.4997 2.08301C6.74671 2.08301 2.08301 6.74671 2.08301 12.4997C2.08301 18.2526 6.74671 22.9163 12.4997 22.9163Z"
                                        stroke="#232936" strokeWidth="1.5"/>
                                    <path d="M12.5 8.33301V12.4997L14.5833 14.583" stroke="#232936" strokeWidth="1.5"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <p>Ponad 40-minutowe szkolenia specjalistyczne</p></li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                                     fill="none">
                                    <path
                                        d="M19.2705 18.5809C20.3439 18.4233 21.1067 18.1185 21.6959 17.5293C22.9163 16.3089 22.9163 14.3447 22.9163 10.4163C22.9163 6.48797 22.9163 4.52379 21.6959 3.30339C20.4756 2.08301 18.5113 2.08301 14.583 2.08301H10.4163C6.48797 2.08301 4.52379 2.08301 3.30339 3.30339C2.08301 4.52379 2.08301 6.48797 2.08301 10.4163C2.08301 14.3447 2.08301 16.3089 3.30339 17.5293C4.14525 18.3711 5.34103 18.6323 7.29134 18.7132"
                                        stroke="#232936" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M17.7087 7.29199H7.29199" stroke="#232936" strokeWidth="1.5"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M15.1038 15.1042C15.1038 16.5424 13.9379 17.7083 12.4997 17.7083C11.0614 17.7083 9.89551 16.5424 9.89551 15.1042C9.89551 13.6659 11.0614 12.5 12.4997 12.5C13.9379 12.5 15.1038 13.6659 15.1038 15.1042Z"
                                        stroke="#232936" strokeWidth="1.5"/>
                                    <path
                                        d="M9.89551 15.1045C9.89551 19.3398 11.6895 21.7405 12.4997 22.917L14.0622 19.792L15.8851 20.8337L17.708 21.8753C16.9427 21.1345 16.1515 18.7994 16.1515 18.7994"
                                        stroke="#232936" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                </svg>
                                <p>Imienny certyfikat</p></li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                                     fill="none">
                                    <path
                                        d="M21.3538 17.6336V10.4163C21.3538 6.48797 21.3538 4.52379 20.1334 3.30339C18.9131 2.08301 16.9488 2.08301 13.0205 2.08301H11.9788C8.05047 2.08301 6.08629 2.08301 4.86589 3.30339C3.64551 4.52379 3.64551 6.48797 3.64551 10.4163V20.3122"
                                        stroke="#141B34" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path
                                        d="M21.3538 17.708H6.24967C4.81143 17.708 3.64551 18.8739 3.64551 20.3122C3.64551 21.7504 4.81143 22.9163 6.24967 22.9163H21.3538"
                                        stroke="#141B34" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path
                                        d="M21.3542 22.9163C19.9159 22.9163 18.75 21.7504 18.75 20.3122C18.75 18.8739 19.9159 17.708 21.3542 17.708"
                                        stroke="#141B34" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M15.625 7.29199H9.375" stroke="#141B34" strokeWidth="1.5"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12.5 11.458H9.375" stroke="#141B34" strokeWidth="1.5"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <p>Dodatkowe materiały szkoleniowe</p></li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                                     fill="none">
                                    <path
                                        d="M7.29199 18.7503V16.1462M7.29199 16.1462V14.5837C7.29199 14.0926 7.29199 13.8471 7.45217 13.6945C7.61235 13.542 7.87015 13.542 8.38574 13.542H9.11491C9.86998 13.542 10.4821 14.1249 10.4821 14.8441C10.4821 15.5632 9.86998 16.1462 9.11491 16.1462H7.29199ZM21.8753 13.542H20.5081C19.6488 13.542 19.2192 13.542 18.9522 13.7963C18.6852 14.0505 18.6852 14.4597 18.6852 15.2781V16.1462M18.6852 16.1462V18.7503M18.6852 16.1462H20.9639M16.4066 16.1462C16.4066 17.5844 15.1823 18.7503 13.6722 18.7503C13.3315 18.7503 13.1611 18.7503 13.0342 18.6805C12.7303 18.5135 12.7607 18.1753 12.7607 17.8823V14.41C12.7607 14.117 12.7303 13.7789 13.0342 13.6118C13.1611 13.542 13.3315 13.542 13.6722 13.542C15.1823 13.542 16.4066 14.7079 16.4066 16.1462Z"
                                        stroke="#232936" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path
                                        d="M15.625 22.9163H11.1743C7.77716 22.9163 6.0786 22.9163 4.89903 22.0853C4.56106 21.8472 4.26102 21.5648 4.00802 21.2467C3.125 20.1364 3.125 18.5378 3.125 15.3406V12.689C3.125 9.60243 3.125 8.05911 3.61348 6.8265C4.39876 4.8449 6.05952 3.28184 8.16496 2.54275C9.47461 2.08301 11.1144 2.08301 14.394 2.08301C16.268 2.08301 17.205 2.08301 17.9533 2.34572C19.1565 2.76806 20.1054 3.66124 20.5542 4.79357C20.8333 5.49792 20.8333 6.37982 20.8333 8.14361V10.4163"
                                        stroke="#232936" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path
                                        d="M3.125 12.4997C3.125 10.5821 4.67956 9.02746 6.59722 9.02746C7.29075 9.02746 8.10837 9.14898 8.78268 8.9683C9.38179 8.80776 9.84975 8.3398 10.0103 7.74067C10.191 7.06638 10.0694 6.24876 10.0694 5.55523C10.0694 3.63757 11.6241 2.08301 13.5417 2.08301"
                                        stroke="#232936" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                </svg>
                                <p>Prezentacje PDF ze szkolenia</p></li>

                        </ul>
                    </>);
            default:
                return '';
        }
    }

    getDescription(product) {
        return <>
            {this.getTrainingsContents(product)}
        </>
    }

    getTrainingsContents(product) {
        return product.trainingsContents.map((item, key) => (
            <div key={key} className={item.type === 'program' ? 'program' : ''}>
                <h3 className={"mt-5"}>{contentType[item.type]}</h3>
                {/*{item.content}*/}
                <p dangerouslySetInnerHTML={{__html: striptags(item.content, ['ul', 'strong', 'p', 'ol', 'br', 'li'], `\n`)}}/>
            </div>
        ));
    }

    static getPatronage(product) {
        return (
            <>
                <h3 className="webinar-headline-small">partnerzy</h3>
                <div className={"webinar-patronage"}>
                    {product.patronage.map((patronage, key) => (
                        <Col sm={12} md={6} lg={6} xl={4} key={key} className={"product-patronage-col"}>
                            {patronage.url ?
                                <a href={patronage.url} rel='noreferrer' target={"_blank"}>
                                    <div className={"product-patronage-content"}>
                                        <img
                                            src={patronage.imageUrl}
                                            alt={patronage.alt}
                                        />
                                    </div>
                                </a> : ''
                            }
                        </Col>
                    ))}
                </div>
            </>);
    }

    getExpert(product) {
        return product.experts.map((expert, key) => (
            <>
                {key === 0 ? <h3 className={'mt-5'}>Ekspert</h3> : ''}
                <Row key={key} className={'mt-5 product-expert-container'}>
                    <Col sm={4} md={3}>
                        {expert.imageUrl ?
                            <img
                                className="product-expert-image"
                                src={expert.imageUrl}
                                alt={`${expert.name} ${expert.surname}`}
                            /> : <img
                                className="product-expert-image"
                                src={"/expert-default.png"}
                                alt={`${expert.name} ${expert.surname}`}
                            />
                        }
                    </Col>
                    <Col sm={8} md={9} className={'product-expert-description'}>
                        <h3><a
                            href={`/ekspert/${strNormalize(expert.name)}-${strNormalize(expert.surname)}/${iriFormatId(expert)}`}
                            className="d-inline-block">{expert.name} {expert.surname}</a></h3>
                        <h5>{expert.academicDegree}</h5>
                        <div dangerouslySetInnerHTML={{__html: expert.shortDescription}}/>
                        <a href={`/ekspert/${strNormalize(expert.name)}-${strNormalize(expert.surname)}/${iriFormatId(expert)}`}
                           className="d-inline-block">więcej szkoleń autora</a>
                    </Col>
                </Row>
            </>
        ));
    }

    getQuiz() {
        const {auth, product} = this.props.children;

        if (auth.isAuthenticated && !auth.decreaseLevel && product.quiz !== null) {
            if (product.quiz && product.quiz.id) {
                return (<div id={"quiz"}>
                        <ProductQuiz auth={auth} product={product}/></div>
                );
            }
            return (<h3 id={"quiz"} className={"mt-5"}>Test wiedzy nie jest dostępny dla tego szkolenia!</h3>);
        }
        if (auth.decreaseLevel) {
            return (<h3 id={"quiz"} className={"mt-5"}>Twój abonament wygasł!</h3>);
        }
        return (<><h3 id={"quiz"} className={"mt-5"}>Zaloguj się aby rozpocząć test</h3>
            <div className={"quiz-login-btn"}><Auth params={{link: true}}/></div>
        </>);
    }

    getTeachingMaterials() {
        const {auth, product} = this.props.children;

        if (auth.isAuthenticated) {
            if (product.accessDenied) {
                return (<>
                        <div className="product-content-sheets" id={"sheets"}>
                            <h3 className={"mt-5"}>Materiały dodatkowe</h3>
                            Nie masz dostępu do materiałów! Odnów abonament.
                        </div>
                    </>
                );

            } else {
                return (<>
                        <div className="product-content-sheets" id={"sheets"}>
                            <h3 className={"mt-5"}>Materiały dodatkowe</h3>
                            <ProductDocuments children={this.props.children}/>
                        </div>
                    </>
                );
            }
        } else {
            return (
                <div className="product-content-sheets" id={"sheets"}>
                    <h3 className={"mt-5"}>Materiały dodatkowe</h3>
                    <div className={"quiz-login-btn"}><Auth params={{link: true}}/></div>
                </div>)
        }
    }

    getTeachingMaterialsWebinar() {
        const {auth, product} = this.props.children;

        if (!product.accessDenied) {
            return (<>
                    <div className="product-content-sheets" id={"sheets"}>
                        <h3 className={"mt-5"}>Materiały dodatkowe</h3>
                        <ProductDocuments children={this.props.children}/>
                    </div>
                </>
            );
        } else {
            return (
                <div className="product-content-sheets" id={"sheets"}>
                    <h3 className={"mt-5"}>Materiały dodatkowe</h3>
                    <p>Nie masz dostepu do materiałów dodatkowych. Zakup szkolenie lub skontaktuj się z biurem obsługi
                        klienta.</p>
                </div>)
        }
    }

    render() {
        const {auth, product, isFetching} = this.props.children;
        if (isFetching)
            return (<Spinner animation="grow"/>);
        return (
            <>
                <h2>{product.name}
                    <div className={"media-box"}><ProductSocialMedia title={product.name}
                                                                     url={window.location.pathname}
                    />{product.subType === 'online' && auth && auth.isAuthenticated ?
                        <ProductFavorite children={this.props.children}/> : ''}</div>
                </h2>
                <h4>Ekspert: {product.experts ?
                    <>{product && product.experts.map((expert, key) => (
                        <a key={key}
                           href={`/ekspert/${strNormalize(expert.name)}-${strNormalize(expert.surname)}/${iriFormatId(expert)}`}>&nbsp;
                            {expert.name} {expert.surname}{product.experts.length > key + 1 && product.experts.length > 1 ? ', ' : ''}
                        </a>
                    ))}
                    </>
                    : ''
                }
                    {product.subType !== 'online' ? <span>{parseTrainingTime(product.timetable[0].duration)}</span> :
                        <span>{parseTrainingMinutes(product.videosLength)} min</span>}
                </h4>
                {this.getTimetable(product)}
                {product.patronage.length > 0 ? ProductContent.getPatronage(product) : ''}
                {this.getTrainingsContents(product)}
                {this.getExpert(product)}
                {product.subType === 'online' ? this.getTeachingMaterials() : ''}
                {product.subType === 'webinarPaid' ? this.getTeachingMaterialsWebinar() : ''}
                {product.subType === 'online' ? this.getQuiz() : ''}
                {auth && auth.isAuthenticated ? '' : ProductContent.getBenefits(product)}
            </>

        );

    }

}

export default ProductContent;