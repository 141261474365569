import {
    SEARCH_REQUEST,
    SEARCH_RECEIVED,
    SEARCH_ERROR,
    SEARCH_LIST_SET_PAGE,
    SEARCH_TYPES
} from "../constants";

export const search = (state = {
    isFetching: false,
    tagFilters: false,
    page: 1
}, action) => {
    switch (action.type) {
        case SEARCH_REQUEST:
            state = {
                ...state,
                isFetching: true
            };
            return state;
        case SEARCH_RECEIVED:
            state = {
                ...state,
                productsList: action.data['result'],
                isFetching: false
            };
            return state;
        case SEARCH_LIST_SET_PAGE:
            state = {
                ...state,
                page: action.page,
                isFetching: false
            }
            return state;
        case SEARCH_TYPES:
            state = {
                ...state,
                tagFilters: action.filtr,
                isFetching: false
            }
            return state;
        case SEARCH_ERROR:
            state = {
                ...state,
                isFetching: false,
                products: null,
                error: action.error,
                page: false,
                type: false
            };
            return  state;
        default:
            return state;
    }
}