import React from 'react';
import {connect} from "react-redux";
import {expertListFetch, expertListSetPage} from "../../actions/expert";
import {Col, Container, Jumbotron, Row, Spinner} from "react-bootstrap";
import ExpertListContainer from "./ExpertListContainer";
import ExpertListPagination from "./ExpertListPagination";
import queryString from "query-string";
import Helmet from "react-helmet";
import {convertMetaDescription, convertMetaTitle} from "../../common/utils";

const mapStateToProps = state => ({
    ...state.expert
});

const mapDispatchToProps = {
    expertListFetch,
    expertListSetPage
}

class List extends React.Component {

    componentDidMount() {
        this.props.expertListFetch(this.getQueryParamPage())
    }

    componentDidUpdate(prevProps) {
        const {currentPage, expertListFetch, expertListSetPage} = this.props;

        if (queryString.parse(prevProps.location.search).page !== this.getQueryParamPage()) {
            expertListSetPage(this.getQueryParamPage());
        }

        if (prevProps.currentPage !== currentPage) {
            expertListFetch(currentPage);
        }

    }

    getQueryParamPage() {
        return Number(queryString.parse(this.props.location.search).page) || 1;
    }

    changePage(page) {
        const {history, expertListSetPage} = this.props;
        expertListSetPage(page);
        history.push(`/ekspert-list?page=${page}`);
    }

    onNextPageClick(e) {
        const {currentPage, pageCount} = this.props;
        const newPage = Math.min(currentPage + 1, pageCount);
        this.changePage(newPage);
    }

    onPrevPageClick(e) {
        const {currentPage} = this.props;
        const newPage = Math.max(currentPage - 1, 1);
        this.changePage(newPage);
    }

    render() {
        const {experts, isFetching, currentPage, pageCount} = this.props;

        if (isFetching)
            return (<Spinner animation="grow"/>);

        return (
            <>
                <Helmet>
                    <title>{convertMetaTitle('Lista ekpsertów')}</title>
                    <meta name={"description"}
                          content={convertMetaDescription("Eksperci Educado to najlepsi specjaliści w swoich dziedzinach oraz wybitni szkoleniowcy")}/>
                </Helmet>
                <div>
                    <Jumbotron fluid className="expert-list-banner">
                        <Container className="">
                            <Row>
                                <Col md={4} className={"head-title"}><h1>Eksperci Educado</h1></Col>
                                <Col md={8} className={"head-description"}>
                                    Eksperci Educado to najlepsi specjaliści w swoich dziedzinach oraz wybitni
                                    szkoleniowcy.
                                    Za każdym razem profesjonalni i dobrze przygotowani. Zawsze są na bieżąco z
                                    najnowszymi
                                    rozporządzeniami i wytycznymi rządu. Z pasją analizują branżowe studia przypadków i
                                    dzielą się wnioskami z przeprowadzonych analiz. Często opracowują dla Educado
                                    również
                                    narzędzia, które bardzo pomagają w codziennej pracy. Dzięki zaangażowaniu naszych
                                    Ekspertów, każda osoba korzystająca na co dzień z Educado może czuć się przygotowana
                                    nawet na najtrudniejsze wdrożenia i wyzwania.
                                </Col>
                            </Row>
                        </Container>
                    </Jumbotron>
                    <ExpertListContainer experts={experts} isFetching={this.props.isFetching}/>
                    <Container className={`d-flex justify-content-center`}>
                        <ExpertListPagination currentPage={currentPage} pageCount={pageCount}
                                              setPage={this.changePage.bind(this)}
                                              nextPage={this.onNextPageClick.bind(this)}
                                              prevPage={this.onPrevPageClick.bind(this)}/>
                    </Container>
                </div>
            </>
        )
    }

}


export default connect(mapStateToProps, mapDispatchToProps)(List);