import React from 'react';
import {profilerFetchCategories} from "../../../../actions/profiler";
import HomeProfilerModal from "./HomeProfilerModal";
import connect from "react-redux/es/connect/connect";


const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    profilerData: state.profiler.profilerData,
    ...state.auth

});

const mapDispatchToProps = {
    profilerFetchCategories
}

class HomeProfilerContainer extends React.Component {

    componentDidMount() {

        if (this.props.isAuthenticated) {
            this.props.profilerFetchCategories();
        }
    }


    render() {
        const {isFetching, isAuthenticated, profilerData, role} = this.props;

        if (isFetching) {
            return (<i className="fas fa-spinner fa-spin"/>);
        }
        return (
            <>
                {isAuthenticated && role !== "ROLE_TEST_USER" && profilerData !== undefined && profilerData.categories === undefined ?
                    <HomeProfilerModal children={this.props}/> : ''}

            </>
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(HomeProfilerContainer);
