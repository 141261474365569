import React from 'react';

const ColumnsConfig = ({openModal, deleteUser, giveAdminRights, removeAdminRights, sendRepeatedInvite}) => {
    return [{
        Header: () => <div className={'header-wrapper'}>
            <div className={"triangles"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path
                        d="M9.84861 8.48438H2.15173C1.92087 8.48438 1.79196 8.24063 1.93493 8.07422L5.78337 3.61172C5.89353 3.48398 6.10564 3.48398 6.21696 3.61172L10.0654 8.07422C10.2084 8.24063 10.0795 8.48438 9.84861 8.48438Z"
                        fill="#808080"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path
                        d="M2.67267 4.01562H9.32767L6.00017 7.87407L2.67267 4.01562ZM6.16201 8.06174L6.16181 8.0615C6.16188 8.06158 6.16195 8.06166 6.16202 8.06174L6.00131 8.20033L6.16201 8.06174Z"
                        fill="#808080" stroke="#808080"/>
                </svg>
            </div>
            LP
        </div>,
        accessor: 'index', // String-based value accessors!
        Cell: props => <span>{props.value + 1}</span>

    }, {
        Header: () => <div className={'header-wrapper'}>
            <div className={"triangles"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path
                        d="M9.84861 8.48438H2.15173C1.92087 8.48438 1.79196 8.24063 1.93493 8.07422L5.78337 3.61172C5.89353 3.48398 6.10564 3.48398 6.21696 3.61172L10.0654 8.07422C10.2084 8.24063 10.0795 8.48438 9.84861 8.48438Z"
                        fill="#808080"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path
                        d="M2.67267 4.01562H9.32767L6.00017 7.87407L2.67267 4.01562ZM6.16201 8.06174L6.16181 8.0615C6.16188 8.06158 6.16195 8.06166 6.16202 8.06174L6.00131 8.20033L6.16201 8.06174Z"
                        fill="#808080" stroke="#808080"/>
                </svg>
            </div>
            Nazwisko i imię
        </div>,
        accessor: 'surname',
        Cell: ({value, row}) => (
            <div>
                <span>{value}</span>
                <span> {row._original.name}</span> {/* Accessing name property */}
            </div>
        )
    }, {
        Header: () => <div className={'header-wrapper'}>
            <div className={"triangles"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path
                        d="M9.84861 8.48438H2.15173C1.92087 8.48438 1.79196 8.24063 1.93493 8.07422L5.78337 3.61172C5.89353 3.48398 6.10564 3.48398 6.21696 3.61172L10.0654 8.07422C10.2084 8.24063 10.0795 8.48438 9.84861 8.48438Z"
                        fill="#808080"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path
                        d="M2.67267 4.01562H9.32767L6.00017 7.87407L2.67267 4.01562ZM6.16201 8.06174L6.16181 8.0615C6.16188 8.06158 6.16195 8.06166 6.16202 8.06174L6.00131 8.20033L6.16201 8.06174Z"
                        fill="#808080" stroke="#808080"/>
                </svg>
            </div>
            E-mail
        </div>,
        accessor: 'email',
        Cell: props => <span>{props.value}</span>
    },
    //     {
    //     Header: () => <div className={'header-wrapper'}>
    //         <div className={"triangles"}>
    //             <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    //                 <path
    //                     d="M9.84861 8.48438H2.15173C1.92087 8.48438 1.79196 8.24063 1.93493 8.07422L5.78337 3.61172C5.89353 3.48398 6.10564 3.48398 6.21696 3.61172L10.0654 8.07422C10.2084 8.24063 10.0795 8.48438 9.84861 8.48438Z"
    //                     fill="#808080"/>
    //             </svg>
    //             <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    //                 <path
    //                     d="M2.67267 4.01562H9.32767L6.00017 7.87407L2.67267 4.01562ZM6.16201 8.06174L6.16181 8.0615C6.16188 8.06158 6.16195 8.06166 6.16202 8.06174L6.00131 8.20033L6.16201 8.06174Z"
    //                     fill="#808080" stroke="#808080"/>
    //             </svg>
    //         </div>
    //         Wyświetlone szkolenia
    //     </div>,
    //     accessor: 'numberOfTrainings',
    //     Cell: ({value, row}) => (
    //         <div className={"table-button-wrapper"}>{}
    //             {row.lastLog === null ? 0 : <span>{value}</span>}
    //         </div>
    //     )
    // },
        {
        Header: () => <div className={'header-wrapper'}>
            <div className={"triangles"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path
                        d="M9.84861 8.48438H2.15173C1.92087 8.48438 1.79196 8.24063 1.93493 8.07422L5.78337 3.61172C5.89353 3.48398 6.10564 3.48398 6.21696 3.61172L10.0654 8.07422C10.2084 8.24063 10.0795 8.48438 9.84861 8.48438Z"
                        fill="#808080"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path
                        d="M2.67267 4.01562H9.32767L6.00017 7.87407L2.67267 4.01562ZM6.16201 8.06174L6.16181 8.0615C6.16188 8.06158 6.16195 8.06166 6.16202 8.06174L6.00131 8.20033L6.16201 8.06174Z"
                        fill="#808080" stroke="#808080"/>
                </svg>
            </div>
            Ostatnie logowanie
        </div>,
        accessor: 'lastSeen',
        Cell: props => <span className={"d-block text-center"}>{props.value === null ? '-' : props.value}</span>

    }, {
        Header: 'Zaproś', // Custom header components!
        accessor: "id",
        sortable: false,
        className: 'tooltip-col',
        Cell: ({value, row}) => (
            <div className={"table-button-wrapper"}>{row.lastSeen === null ? <button onClick={() => {
                sendRepeatedInvite(value)

            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <path
                        d="M14.6668 1.83398L7.3335 9.16732M14.6668 1.83398L10.0002 15.1673L7.3335 9.16732M14.6668 1.83398L1.3335 6.50065L7.3335 9.16732"
                        stroke="#090A0A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span>Ponów zaproszenie</span>
            </button> : ''}</div>)
    }, {
        Header: 'Admin', // Custom header components!
        accessor: "id",
        sortable: false,
        className: 'tooltip-col',
        Cell: ({value, row}) => (
            <div className={"table-button-wrapper"}>{row._original.isOperator ? <button onClick={() => {
                openModal(<>{removeAdminRights(value, row._original.isOperator)}</>)

            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M17.8751 12.375C18.4274 12.375 18.8751 12.8227 18.8751 13.375V13.7736C19.6155 13.9438 20.2849 14.2991 20.8263 14.789L21.3343 14.4624C21.7989 14.1637 22.4176 14.2982 22.7163 14.7628C23.015 15.2274 22.8805 15.8461 22.4159 16.1447L21.9517 16.4432C22.1176 16.89 22.2085 17.3723 22.2085 17.875C22.2085 18.3778 22.1176 18.8602 21.9516 19.307L22.4155 19.6053C22.88 19.9039 23.0145 20.5226 22.7159 20.9872C22.4172 21.4518 21.7985 21.5863 21.3339 21.2876L20.8262 20.9612C20.2847 21.451 19.6154 21.8062 18.8751 21.9764V22.375C18.8751 22.9273 18.4274 23.375 17.8751 23.375C17.3228 23.375 16.8751 22.9273 16.8751 22.375V21.9764C16.1348 21.8062 15.4655 21.451 14.9241 20.9612L14.4163 21.2876C13.9518 21.5863 13.333 21.4518 13.0344 20.9872C12.7357 20.5226 12.8702 19.9039 13.3348 19.6053L13.7986 19.307C13.6327 18.8602 13.5418 18.3778 13.5418 17.875C13.5418 17.3723 13.6326 16.89 13.7986 16.4432L13.3343 16.1447C12.8698 15.8461 12.7353 15.2274 13.034 14.7628C13.3326 14.2982 13.9513 14.1637 14.4159 14.4624L14.9239 14.789C15.4654 14.2991 16.1347 13.9438 16.8751 13.7736V13.375C16.8751 12.8227 17.3228 12.375 17.8751 12.375ZM17.8751 15.6607C17.0503 15.6607 16.3365 16.0648 15.9233 16.6613C15.6807 17.0116 15.5418 17.4276 15.5418 17.875C15.5418 18.3225 15.6807 18.7385 15.9234 19.0888C16.3366 19.6852 17.0503 20.0893 17.8751 20.0893C18.6999 20.0893 19.4136 19.6852 19.8268 19.0888C20.0695 18.7385 20.2085 18.3225 20.2085 17.875C20.2085 17.4276 20.0695 17.0116 19.8269 16.6613C19.4137 16.0648 18.7 15.6607 17.8751 15.6607Z"
                          fill="#2EE3A9"/>
                    <path
                        d="M5.62549 6.875C5.62549 3.9755 7.976 1.625 10.8755 1.625C13.7749 1.625 16.1255 3.9755 16.1255 6.875C16.1255 9.77449 13.7749 12.125 10.8755 12.125C7.976 12.125 5.62549 9.77449 5.62549 6.875Z"
                        fill="#2EE3A9"/>
                    <path
                        d="M11.6355 13.6502C10.847 13.5979 10.0539 13.627 9.27043 13.7375C7.80596 13.9441 6.37389 14.4352 5.06904 15.2122C4.95276 15.2814 4.80915 15.3624 4.64655 15.4542C3.93373 15.8563 2.85606 16.4643 2.1179 17.1868C1.65623 17.6387 1.21757 18.2342 1.13783 18.9638C1.05302 19.7396 1.39149 20.4677 2.0705 21.1146C3.24195 22.2306 4.64774 23.125 6.46604 23.125H11.5838C12.2979 23.125 12.6549 23.125 12.6822 23.0687C12.7096 23.0124 12.454 22.6873 11.9428 22.037C11.8824 21.9602 11.8256 21.8805 11.7728 21.7984C11.1833 20.8814 11.2688 19.7243 11.9024 18.9103C12.0029 18.7813 12.0532 18.7167 12.0692 18.6619C12.0853 18.6071 12.0786 18.534 12.0653 18.3877C12.0499 18.2187 12.042 18.0477 12.042 17.875C12.042 17.7025 12.0498 17.5316 12.0652 17.3627C12.0786 17.2164 12.0852 17.1432 12.0692 17.0884C12.0531 17.0336 12.0028 16.9691 11.9023 16.84C11.2684 16.0261 11.1828 14.8687 11.7724 13.9516C11.8501 13.8307 11.779 13.6597 11.6355 13.6502Z"
                        fill="#2EE3A9"/>
                </svg>
                <span>Usuń administratora</span>
            </button> : <button onClick={() => {
                openModal(<>{giveAdminRights(value, row._original.isOperator)}</>
                )
            }
            }>
                <span>Zrób administratorem</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <path
                        d="M11 20.5H2C2 16.634 5.58172 13.5 10 13.5C10.3387 13.5 10.6724 13.5184 11 13.5542M14 6.5C14 8.70914 12.2091 10.5 10 10.5C7.79086 10.5 6 8.70914 6 6.5C6 4.29086 7.79086 2.5 10 2.5C12.2091 2.5 14 4.29086 14 6.5Z"
                        stroke="#898D96" strokeWidth="1.5"/>
                    <path
                        d="M17.5 20.7143V22.5M17.5 20.7143C16.3432 20.7143 15.3241 20.1461 14.7263 19.2833M17.5 20.7143C18.6568 20.7143 19.6759 20.1461 20.2737 19.2833M14.7263 19.2833L13 20.5M14.7263 19.2833C14.3728 18.773 14.1667 18.1597 14.1667 17.5C14.1667 16.8403 14.3727 16.2271 14.7262 15.7169M20.2737 19.2833L22 20.5M20.2737 19.2833C20.6272 18.773 20.8333 18.1597 20.8333 17.5C20.8333 16.8403 20.6273 16.2271 20.2738 15.7169M17.5 14.2857C18.6569 14.2857 19.6761 14.854 20.2738 15.7169M17.5 14.2857C16.3431 14.2857 15.3239 14.854 14.7262 15.7169M17.5 14.2857V12.5M20.2738 15.7169L22 14.5M14.7262 15.7169L13 14.5"
                        stroke="#898D96" strokeWidth="1.5" strokeLinejoin="round"/>
                </svg>
            </button>}</div>)
    }, {
        Header: 'Usuń', // Custom header components!
        accessor: "id",
        sortable: false,
        className: 'tooltip-col',
        Cell: ({value, row}) => (<div className={"table-button-wrapper"}>
            <button onClick={() => {
                openModal(<>{deleteUser(value)}</>)

            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <path
                        d="M2 4.50065H3.33333M3.33333 4.50065H14M3.33333 4.50065V13.834C3.33333 14.1876 3.47381 14.5267 3.72386 14.7768C3.97391 15.0268 4.31304 15.1673 4.66667 15.1673H11.3333C11.687 15.1673 12.0261 15.0268 12.2761 14.7768C12.5262 14.5267 12.6667 14.1876 12.6667 13.834V4.50065H3.33333ZM5.33333 4.50065V3.16732C5.33333 2.8137 5.47381 2.47456 5.72386 2.22451C5.97391 1.97446 6.31304 1.83398 6.66667 1.83398H9.33333C9.68696 1.83398 10.0261 1.97446 10.2761 2.22451C10.5262 2.47456 10.6667 2.8137 10.6667 3.16732V4.50065M6.66667 7.83398V11.834M9.33333 7.83398V11.834"
                        stroke="#090A0A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span>Usuń użytkownika</span>
            </button>
        </div>)
    }]
};

export default ColumnsConfig;