import React from "react";
import {reduxForm} from "redux-form";
import connect from "react-redux/es/connect/connect";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {profilerProfileUpdateSettings} from "../../actions/profiler";

const mapStateToProps = state => ({
    category_all: state.category.category_all,

});

const mapDispatchToProps = {
    profilerProfileUpdateSettings,

};

class Profiler extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedItems: [],
            showInfo: false
        };

        this.handleCheck = this.handleCheck.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }


    handleClick() {

        const {profilerProfileUpdateSettings} = this.props;
        this.setState({showInfo: true});
        return (profilerProfileUpdateSettings(
            this.state.checkedItems
        ));
    }

    /*pobieranie checkboxów z profilera */

    getDefaultChecked(i) {
        const {profilerData, isFetching} = this.props.children;

        if (profilerData === undefined || isFetching) {
            return null
        } else {

            if (profilerData.categories) {
                this.state.checkedItems = profilerData.categories;

                if (profilerData.categories.includes(i)) {
                    return true
                }
            }

        }
    }

    /* funkcja po klikaniu w checkbox*/

    handleCheck(event) {
        let item = parseInt(event.target.value);
        this.setState({showInfo: false})

        if (this.state.checkedItems.includes(item)) {
            this.state.checkedItems.splice(this.state.checkedItems.indexOf(item), 1);

        } else {
            this.state.checkedItems.push(item);
        }
    }


    render() {
        const {category_all, isFetching} = this.props;

        if (isFetching)
            return (<Spinner animation="grow"/>);
        return (
            <Container className={"profiler-container"}>
                <Row>

                    {category_all && category_all.map((item, key) => <Col key={key}>

                            <input
                                name={item.id}
                                type="checkbox"
                                id={item.id}
                                value={item.id}
                                defaultChecked={this.getDefaultChecked(item.id)}
                                onClick={this.handleCheck}

                            />
                            <label>{item.name}</label>

                        </Col>
                    )}
                </Row>
                <Button className="login-btn btn register-btn" variant="primary"
                        onClick={this.handleClick}>Zapisz</Button>
                {this.state.showInfo ? <p className={"success-info"}>Zmiany zostały zapisane</p> : ''}
            </Container>
        )
    }

}


export default reduxForm({
    form: 'profilerUserSettings',
})(connect(mapStateToProps, mapDispatchToProps)(Profiler));