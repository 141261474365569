import React from 'react';
import {expertListPromotedFetch} from "../../../../actions/expert";
import {connect} from "react-redux";
import ExpertSlider from "./ExpertSlider";
import {Container} from "react-bootstrap";
import {deviceType} from "react-device-detect";

const mapStateToProps = state => ({
    ...state.expert
});

const mapDispatchToProps = {
    expertListPromotedFetch
}

class ExpertSliderContainer extends React.Component {

    componentDidMount() {
        this.props.expertListPromotedFetch(deviceType === 'browser' ? 24 : 24);
    }

    render() {
        const {experts} = this.props;

        return (
            <Container className="home-expert-slider carousel-main-container" fluid>
                <h3>Nasi eksperci</h3>
                <ExpertSlider experts={experts} isFetching={this.props.isFetching} />
            </Container>
        )
    }

}

export default connect(mapStateToProps, mapDispatchToProps) (ExpertSliderContainer);