import React from 'react';
import {Container, Row} from "react-bootstrap";
import Modal from 'react-modal';
import browser from "browser-detect";

let BrowserTimeout;

class BrowserInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    componentDidMount() {
        const result = browser();
        const tmp = window.sessionStorage.getItem('browserInfo');

        if (!tmp) {
            switch (result.name) {
                case 'chrome':
                    if (result.versionNumber < 54) {
                        BrowserTimeout = setTimeout(() => this.setState({showModal: true}), 3000);
                    }
                    break;
                case 'firefox':
                    if (result.versionNumber < 40) {
                        BrowserTimeout = setTimeout(() => this.setState({showModal: true}), 3000);
                    }
                    break;
                case 'ie':
                    BrowserTimeout = setTimeout(() => this.setState({showModal: true}), 3000);
                    break;
                case 'edge':
                    if (result.versionNumber < 12) {
                        BrowserTimeout = setTimeout(() => this.setState({showModal: true}), 3000);
                    }
                    break;
                case 'opera':
                    if (result.versionNumber < 64) {
                        BrowserTimeout = setTimeout(() => this.setState({showModal: true}), 3000);
                    }
                    break;
                default:


            }
        }
    }

    componentWillUnmount() {
        clearTimeout(BrowserTimeout);
    }

    toggleModal(showModal) {
        window.sessionStorage.setItem('browserInfo', '1');
        this.setState({showModal: showModal});
    }

    browserContent() {
        return (<>

            <h5>Witaj na Educado,<br/>
                platformie szkoleń dla profesjonalistów.</h5>
            <p>Zanim zaczniesz mamy dla Ciebie ważną informację.</p><p>Zauważyliśmy, że korzystasz z nieaktualnej wersji
                lub nieobsługiwanej przeglądarki. Z tego powodu możesz napotkać na ograniczenia w trakcie korzystania z
                portalu np. pewne elementy mogą nie wyświetlać się poprawnie lub wybrane funkcje mogą być niedostępne.
            </p><p>Aby zapewnić maksimum możliwości oraz wygodę użytkowania rekomendujemy zainstalowanie najnowszej
                wersji przeglądarki lub jej zmianę na inną.

            </p></>)
    }

    render() {
        return (
            <Modal className="browser-modal" id={"browser-modal"} ariaHideApp={false} isOpen={this.state.showModal}
                   onHide={() => this.setState({showModal: false})}>
                <Container className="modal-content-container">
                    <Row>


                        {this.browserContent()}
                        <button onClick={this.toggleModal.bind(this, false)}
                                className={'btn-contact btn btn-primary'}>Mimo wszystko chcę korzystać z obecnej wersji
                            przeglądarki
                        </button>
                    </Row>
                </Container>
            </Modal>
        );
    }

}

export default BrowserInfo;