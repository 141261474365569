import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {Button, Col, Form} from "react-bootstrap";
import {connect} from "react-redux";
import {renderField} from "../../../common/form";

export const acceptTerms = <div className={"form-terms"}>Znam i akceptuję warunki <a href="/regulamin" target={"_blank"}
                                                                                     rel={"noopener noreferrer"}>Regulaminu</a> i <a
    href="https://www.wip.pl/polityka-prywatnosci" target={"_blank"} rel={"noopener noreferrer"}>Polityki
    Prywatności </a><span> *</span></div>;
export const marketingAgreement = <div className={"form-terms"}>Chcę otrzymywać informacje drogą elektroniczną i
    telefoniczną o dodatkowych promocjach,
    rabatach dotyczących produktów własnych WIP i partnerów WIP. Wiem, że moja zgoda może być wycofana w dowolnym
    momencie.</div>;

class RegisterForm extends React.Component {

    componentDidMount() {
        const {auth} = this.props.children;

        if (auth && auth.userData && auth.isAuthenticated) {
            this.props.initialize({
                name: auth.userData.name,
                surname: auth.userData.surname,
                email: auth.userData.email,
                phone: auth.userData.phoneNumber,
                position: auth.userData.position,
            });
        }
    }

    onSubmit(values) {
        const {registerAccount, product} = this.props.children;
        let tmp = {
            name: values.name,
            surname: values.surname,
            email: values.email,
            phone: values.phone,
            position: values.position,
            acceptTerms: values.acceptTerms,
            marketingAgreement: values.marketingAgreement === undefined ? false : values.marketingAgreement
        };
        return registerAccount(product, tmp);
    }

    render() {
        const {form, handleSubmit, error} = this.props;
        const {auth} = this.props.children;

        return (
            <>
                {error && <div className="alert alert-danger">{error}</div>}
                <Form className="row" id={form} onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Col md lg={{span: 12, offset: 0}} className={"register-form-content"}>
                        {auth && auth.isAuthenticated ?
                            <h3> Twoje konto </h3> :
                            <h3> Zarejestruj się</h3>
                        }
                        <Field name="name" type="text"
                               component={renderField} placeholder="Imie"/>
                        <Field name="surname" type="text"
                               component={renderField} placeholder="Nazwisko"/>
                        <Field name="email" type="text"
                               component={renderField} placeholder="E-mail"/>
                        <Field name="phone"
                               type="text"
                               component={renderField}
                               placeholder="Telefon"
                               mask={{
                                   pattern: "+48 999 999 999",
                                   text: 'Prawidłowy format +48 999 999 999'
                               }}
                        />
                        <Field name="position" type="text"
                               component={renderField} placeholder="Stanowisko"/>
                        <Field name="acceptTerms" type="checkbox"
                               component={renderField} label={acceptTerms}/>
                        <Field name="marketingAgreement" type="checkbox"
                               component={renderField} label={marketingAgreement}/>
                        {auth && auth.isAuthenticated ?
                            <Button className="register-btn login-btn" type="submit">Dalej</Button> :
                            <Button className="register-btn login-btn" type="submit">Zarejestruj się</Button>
                        }
                    </Col>
                </Form>
            </>
        )
    }
}

export default reduxForm({
    form: 'RegisterForm'
})(connect(false, false)(RegisterForm));