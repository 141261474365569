import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {Button, Container, Form} from "react-bootstrap";
import {connect} from "react-redux";
import {renderField} from "../../common/form";
import {newsletterAttempt} from "../../actions/webinar";

const mapStateToProps = state => ({
    ...state.webinar
});

const mapDispatchToProps = {
    newsletterAttempt
};
export const acceptTerms = <div>Znam i akceptuję warunki <a href="/regulamin" target={"_blank"}
                                                            rel={"noopener noreferrer"}>Regulaminu</a> i <a
    href="https://www.wip.pl/polityka-prywatnosci" target={"_blank"} rel={"noopener noreferrer"}>Polityki
    Prywatności </a><span> *</span></div>;
export const marketingAgreement = <div>Chcę otrzymywać informacje drogą elektroniczną i telefoniczną o dodatkowych
    promocjach, rabatach dotyczących produktów własnych WIP i partnerów WIP. Wiem, że moja zgoda może być wycofana w
    dowolnym momencie.<span> *</span></div>;

class Newsletter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false
        }
    }


    onSubmit(values) {
        const {newsletterAttempt} = this.props;
        let tmp = {
            email: values.email,
            acceptTerms: values.acceptTerms,
            marketingAgreement: values.marketingAgreement
        };
        return (
            newsletterAttempt(tmp),
                this.setState({submitted: true})
        )


    }

    render() {
        const {form, handleSubmit, error} = this.props;
        return (
            <Container className={"newsletter-container"}>
                <h2>Zapisz się na newsletter!</h2>
                <p>Otrzymuj regularnie na swoją skrzynkę e-mailową aktualności branżowe i porady ekspertów.</p>
                <div className={"img-picture"}><img src={"https://cdn.upmenu.com/static/product-images/cb674fdc-1eb5-11eb-8682-525400080521/dde35953-2677-11eb-8682-525400080521/2/large/modro-moja-w-tortilli-lawaszu-kopia.jpg"} alt={"Ebook"}/>
                    <div className={"ebook-data"}>
                        <h3>Bezpłatny ebook w prezencie</h3>
                        <h4>"Tytuł ebooka"</h4>
                    </div>
                </div>
                {this.state.submitted ?
                    <div className={"newsletter-form-container-content"}><h2>Dziękujemy za zapis!</h2></div>
                    :
                    <div className={"newsletter-form-container-content"}>
                        {error && <div className="alert alert-danger">{error}</div>}

                        <Form className="" id={form} onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                            <div className={"field-wrapper"}>
                                <Field name="email" type="text"
                                       component={renderField} placeholder="Wpisz adres e-mail"/>
                                <Button className="newsletter-btn" type="submit">Zapisz się</Button>
                            </div>
                            <Field name="acceptTerms" type="checkbox"
                                   component={renderField} label={acceptTerms}/>
                            <Field name="marketingAgreement" type="checkbox"
                                   component={renderField} label={marketingAgreement}/>

                        </Form>
                    </div>}
            </Container>
        )
    }
}

export default reduxForm({
    form: 'Newsletter'
})(connect(mapStateToProps, mapDispatchToProps)(Newsletter));