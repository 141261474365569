import React from 'react';
import {Alert, Card, Col, Container, Row} from "react-bootstrap";
import {strNormalize} from "./utils";
import striptags from "striptags";

const Breadcrumb = props => {

    const {items, id, dark} = props;

    function getCategory(id) {
        switch (id) {
            case 71:
                return 'oświata'
            case 13:
            case 14:
                return 'księgowość i kadry'
            default:
                return ''
        }
    }

    return (
        <Container>
            <Row className={`breadcrumb-container-content ${dark ? 'dark' : '' }`}>
                <div className={"home-unit"}><a href={"/"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <g clipPath="url(#clip0_786_34771)">
                            <path d="M5.83301 10.5H8.16634" stroke={dark ? '#232936' : 'white' } strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M5.83301 10.5H8.16634" stroke={dark ? '#232936' : 'white' } strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path
                                d="M1.37197 7.7082C1.16604 6.36811 1.06308 5.69811 1.31642 5.10413C1.56977 4.51015 2.13184 4.10374 3.25599 3.29094L4.0959 2.68366C5.49433 1.67255 6.19352 1.16699 7.00033 1.16699C7.80713 1.16699 8.50632 1.67255 9.90474 2.68366L10.7447 3.29094C11.8688 4.10374 12.4309 4.51015 12.6842 5.10413C12.9375 5.69811 12.8346 6.36811 12.6287 7.7082L12.4531 8.85089C12.1611 10.7505 12.0152 11.7004 11.3339 12.267C10.6526 12.8337 9.65665 12.8337 7.66463 12.8337H6.33603C4.34402 12.8337 3.34802 12.8337 2.66674 12.267C1.98546 11.7004 1.8395 10.7505 1.54758 8.85089L1.37197 7.7082Z"
                                stroke={dark ? '#232936' : 'white' } strokeLinejoin="round"/>
                            <path
                                d="M1.37197 7.7082C1.16604 6.36811 1.06308 5.69811 1.31642 5.10413C1.56977 4.51015 2.13184 4.10374 3.25599 3.29094L4.0959 2.68366C5.49433 1.67255 6.19352 1.16699 7.00033 1.16699C7.80713 1.16699 8.50632 1.67255 9.90474 2.68366L10.7447 3.29094C11.8688 4.10374 12.4309 4.51015 12.6842 5.10413C12.9375 5.69811 12.8346 6.36811 12.6287 7.7082L12.4531 8.85089C12.1611 10.7505 12.0152 11.7004 11.3339 12.267C10.6526 12.8337 9.65665 12.8337 7.66463 12.8337H6.33603C4.34402 12.8337 3.34802 12.8337 2.66674 12.267C1.98546 11.7004 1.8395 10.7505 1.54758 8.85089L1.37197 7.7082Z"
                                stroke={dark ? '#232936' : 'white' } strokeLinejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_786_34771">
                                <rect width="14" height="14" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>

                </a>

                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path
                        d="M4.5 9L7.14645 6.35355C7.3131 6.1869 7.39645 6.10355 7.39645 6C7.39645 5.89645 7.3131 5.8131 7.14645 5.64645L4.5 3"
                        stroke={dark ? '#232936' : 'white' } strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M4.5 9L7.14645 6.35355C7.3131 6.1869 7.39645 6.10355 7.39645 6C7.39645 5.89645 7.3131 5.8131 7.14645 5.64645L4.5 3"
                        stroke={dark ? '#232936' : 'white' } strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <div className={"breadcrumb-unit home-item"}>
                    {items ?
                        <a href={`/${strNormalize(getCategory(id))}`}>{getCategory(id)}</a> :
                        <span>{getCategory(id)}</span>

                    }
                </div>
                {items && items.map((item, index) => (
                    <div key={index} className={'d-flex align-items-center breadcrumb-item-wrapper'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path
                                d="M4.5 9L7.14645 6.35355C7.3131 6.1869 7.39645 6.10355 7.39645 6C7.39645 5.89645 7.3131 5.8131 7.14645 5.64645L4.5 3"
                                stroke={dark ? '#232936' : 'white' } strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M4.5 9L7.14645 6.35355C7.3131 6.1869 7.39645 6.10355 7.39645 6C7.39645 5.89645 7.3131 5.8131 7.14645 5.64645L4.5 3"
                                stroke={dark ? '#232936' : 'white' } strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <div key={index} className={'breadcrumb-unit'}>
                            {index + 1 === items.length ?
                                <span> {item.name}</span> :
                                <a href={item.url ? `/${strNormalize(getCategory(id))}/${item.url}` : `/${strNormalize(getCategory(id))}/${strNormalize(item.name)}`}>{item.name}</a>}
                        </div>
                    </div>
                ))}
            </Row>
        </Container>
    );

}


export default Breadcrumb;