import React from "react";
import {Container} from "react-bootstrap";
import {Helmet} from "react-helmet";


const RightSection = props => {
    const {product} = props;

    return (
        <React.Fragment>
            <Container className={"webinar-help-container"}>
                <h2>Potrzebujesz pomocy?</h2>
                <p>Nasi konsultanci są dostępni od poniedziałku do piątku w godzinach 8:00 -
                    16:00</p>
                <h3>22 518 29 29</h3>
            </Container>
            {product.ads && product.ads.map((item, key) => {
                if (item.zone === "rightRectangle") {
                    return (
                        <Container className={"mt-5"}>
                            <div key={key} className={"product-banner rectangle-first"}>
                                {item.url ?
                                    <a target={'_blank'} href={item.url} rel="noreferrer">
                                        <img alt={item.name} src={item.imageUrl}/>
                                    </a> : ''}
                                <Helmet>
                                    <script>{item.js}</script>
                                </Helmet>
                            </div>
                        </Container>
                    )
                }
                return '';
            })}
            {product.ads && product.ads.map((item, index) => {
                if (item.zone === "rightRectangle2") {
                    return (
                        <Container key={index} className={"mt-5"}>
                            <div className={"product-banner rectangle-first"}>
                                {item.url ?
                                    <a target={'_blank'} href={item.url} rel="noreferrer">
                                        <img alt={item.name} src={item.imageUrl}/>
                                    </a> : ''}
                                <Helmet>
                                    <script>{item.js}</script>
                                </Helmet>
                            </div>
                        </Container>
                    )
                }
                return '';
            })}
        </React.Fragment>
    )
}

export default RightSection;