import React from 'react';
import {connect} from "react-redux";
import {quizQuestionDelete, quizQuestionListFetch, quizQuestionSend, quizQuestionSummary} from "../../../actions/quiz";
import ProductQuizQuestion from "./ProductQuizQuestion";
import {APP_URL} from "../../../constants";
import {Container, Modal, Row} from "react-bootstrap";

const mapStateToProps = state => ({
    ...state.quiz
});

const mapDispatchToProps = {
    quizQuestionListFetch,
    quizQuestionSend,
    quizQuestionSummary,
    quizQuestionDelete
}

class ProductQuiz extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            quizActive: false,
            showSummary: false
        }
    }

    componentDidMount() {
        const {product} = this.props;
        this.props.quizQuestionSummary(product.id, product.quiz.id);
    }

    quizActive() {
        this.setState({
            quizActive: true
        });
    }

    hideModalSummary() {
        this.setState({
            show: false,
            userAnswersContent: false
        });
    }

    getUserSummary() {
        const {summary} = this.props;

        let tmp = React.createElement("div", {className: 'quiz-summary-content'},
            React.createElement("h4", {dangerouslySetInnerHTML: {__html: summary.summary}}),
            React.createElement("p", {}, `Twój wynik: ${summary.userPoints} / ${summary.questionMaxPoints}`),
            React.createElement("div", {className: 'item-question-list-container'}, summary.userAnswers.map((item, key) => {
                return (
                    React.createElement("div", {
                            key: key,
                            className: `item-question ${item.isCorrect ? 'correct' : 'failed'}`,
                        },
                        React.createElement("div", {className: "item-question-title"}, item.question),
                        React.createElement("div", {className: "item-question-answer"},
                            React.createElement('span', {className: 'item-question-answer-marker'}, item.answer)
                        )
                    )
                )
            })));

        this.setState({
            show: true,
            userAnswersContent: tmp
        });
    }

    getCertificate() {
        const {product} = this.props;
        let tmp = APP_URL + product.certificatesUsers[0];
        window.open(tmp, '_blank');
    }

    productSummaryQuiz() {
        const {product, summary} = this.props;

        return (
            <>
                <div className="product-quiz-blue-section">
                    <h3 className={"mt-5"} dangerouslySetInnerHTML={{__html: summary.summary}}/>
                    <p>Twój wynik to {`${summary.userPoints} / ${summary.questionMaxPoints}`}</p>
                </div>
                {
                    this.state.userAnswersContent
                        ? <Modal className="quiz-modal" show={this.state.show} onHide={this.hideModalSummary.bind(this)}>
                            <Container className="modal-content-container">
                                <Row>
                                    <Modal.Header closeButton>
                                        <img alt="logo" src="/logo.svg"/>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Container className={"col-sm-9 quiz-content"}>
                                            <Row>
                                                {this.state.userAnswersContent}
                                            </Row>
                                        </Container>
                                    </Modal.Body>
                                </Row>
                            </Container>
                        </Modal>
                        : <button className="webinar-btn btn" onClick={this.getUserSummary.bind(this)}>Sprawdź odpowiedzi</button>
                }
                {
                    product.certificatesUsers && product.certificatesUsers.length > 0
                        ? <button onClick={this.getCertificate.bind(this)} className="webinar-btn btn">Pobierz certyfikat</button>
                        : <button onClick={this.quizActive.bind(this)} className="webinar-btn btn">Rozpocznij test</button>
                }
            </>
        )

    }

    render() {
        const {product, summary} = this.props;

        if (!product) {
            return '';
        }

        return (
            <>
                {
                    (summary && !this.state.quizActive)
                        ? this.productSummaryQuiz()
                        : <div>
                            <div>
                                <div className="product-quiz-blue-section">
                                    <h3 id={"quiz"} className={"mt-5"}>Test wiedzy</h3>
                                    {/*<h4 className={"mt-5"}>{product.quiz && product.quiz.shortDescription}</h4>*/}
                                    <p>Imienny certyfikat jest dostępny po zaznaczeniu co najmniej 70% poprawnych odpowiedzi w teście wiedzy. Kliknij w "Rozpocznij test", udziel odpowiedzi na pytania, a następnie odbierz certyfikat z panelu klienta w zakładce szkolenia ukończone.</p>
                                </div>
                                <button onClick={this.quizActive.bind(this)} className="webinar-btn btn">Rozpocznij test</button>
                            </div>
                        </div>
                }
                {
                    this.state.quizActive
                        ? <ProductQuizQuestion children={this.props}/>
                        : ''
                }
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductQuiz);