import React from "react";
import {Spinner} from "react-bootstrap";
import BuyerForm from "./BuyerForm";
import Payment from "./Payment";
import {Helmet} from "react-helmet";
import {convertMetaTitle} from "../../../common/utils";

class Buyer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            buyerStatus: 'PROGRESS'
        }
    }

    componentDidMount() {
        const tmp = window.sessionStorage.getItem('regStep');
        if (tmp) {
            this.setState({buyerStatus: tmp})
        }
    }

    componentDidUpdate(prevProps) {
        const {buyerStatus, registerToken, registerSetOrder, user, buyer} = this.props.children;
        if (buyerStatus && prevProps.children.buyerStatus !== buyerStatus) {
            this.setState({buyerStatus: buyerStatus});
            registerSetOrder(registerToken, user, buyer);
        }
    }

    getStepTwoContent() {
        switch (this.state.buyerStatus) {
            case 'PROGRESS':
                return <BuyerForm children={this.props.children}/>;
            case 'PAYMENT':
                return <Payment children={this.props.children}/>;
            default:
                return (<>
                    <h2>Coś poszło nie tak </h2>
                </>);
        }
    }

    render() {
        const {product} = this.props.children;

        if (!product) {
            return (<Spinner animation="grow"/>);
        }

        return (
            <>
                <Helmet>
                    <title>{convertMetaTitle('rejestracja krok drugi')}</title>
                    <meta name="description" content={product.name}/>
                </Helmet>
                {this.getStepTwoContent()}
            </>
        )

    }

}

export default Buyer;