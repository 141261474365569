import React from 'react';
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {convertMetaDescription, convertMetaTitle} from "../../../common/utils";
import Helmet from "react-helmet";
import "react-multi-carousel/lib/styles.css";
import connect from "react-redux/es/connect/connect";
import MultiFirstLogForm from "./MultiFirstLogForm";
const mapStateToProps = state => ({
    multiFirstLogReceived: state.register.multiFirstLogReceived
});

const mapDispatchToProps = {

}


class MultiFirstLogContainer extends React.Component {

    componentDidUpdate() {
        const {multiFirstLogReceived} = this.props
        multiFirstLogReceived && window.location.reload();

    }

    render() {
        const {userData} = this.props;

        return (
            <>
                <Helmet>
                    <title>{convertMetaTitle('Rejestracja nowego użytkownika')}</title>
                    <meta name="description"
                          content={convertMetaDescription('Rejestracja nowego użytkownika')}/>
                </Helmet>
                <div className={'school-multi-container bg-multi'}>
                    <Container className={"multi-first-log-container"}>
                        <Row>
                            <Col md={6} lg={5}  className={"multi-first-log-text"}>
                                <h1 className={''}><span>Witaj {userData.name},</span>już tylko jeden krok dzieli Cię,<br/> od wejścia do świata szkoleń Educado!</h1>
                            </Col>
                            <Col md={6} lg={{span: 6, offset: 1}}  className={""}>
                                <div className={"form-col"}>
                                    <MultiFirstLogForm userData={userData}/>
                                </div>
                            </Col>

                        </Row>
                    </Container>

                </div>
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiFirstLogContainer);