import React from "react";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import RegisterForm from "./RegisterForm";
import LoginForm from "../../Auth/LoginForm";
import PasswordReminderForm from "../../Auth/PasswordReminderForm";
import {Helmet} from "react-helmet";
import {convertMetaTitle, getPrice} from "../../../common/utils";

class Account extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            type: 'LoginForm'
        }
    }

    render() {
        const {children} = this.props;

        if (!children.product) {
            return (<Spinner animation="grow"/>);
        }

        return (
            <>
                <Helmet>
                    <title>{convertMetaTitle('rejestracja krok pierwszy')}</title>
                    <meta name="description"
                          content={`Abonament' + ${children.product ? children.product.name : '' }`}/>
                </Helmet>
                <Row className={"register-product-account-container register-product-users-container"}>
                    <Col xs={{span: 12}} lg={{span: 8}} className={"rpa-login-form-container"}>
                        <Container>
                            <div className={"background-wrapper"}>
                                <Row>
                                    <Col lg={{span: 6}} className={"rpa-login-col rpa-login-form"}>
                                        <div className={"register-login-form-wrapper"}>
                                            {children.auth.isAuthenticated && !children.auth.decreaseLevel ?
                                                '' :
                                                <>
                                                    {this.state.type === 'LoginForm'
                                                        ? <>
                                                            <h3>Masz już konto ?<span>Zaloguj się</span></h3>
                                                            <LoginForm/>
                                                            <button className="pass-reminder"
                                                                    onClick={() => this.setState({type: 'PasswordReminderForm'})}>Nie
                                                                pamiętasz hasła?
                                                            </button>

                                                        </>
                                                        : <>
                                                            <PasswordReminderForm/>
                                                            <button className="pass-reminder"
                                                                    onClick={() => this.setState({type: 'LoginForm'})}>Logowanie
                                                            </button>
                                                        </>
                                                    }
                                                </>
                                            }
                                            <div className={"webinar-help-container"}>
                                                <h2>Potrzebujesz pomocy?</h2>
                                                <p>Nasi konsultanci są dostępni od poniedziałku do piątku w godzinach
                                                    8:00 -
                                                    16:00</p>
                                                <h3>22 518 29 29</h3>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="rpa-register-form rpa-login-col" xs={{span: 12}} lg={{span: 6}}>
                                        <RegisterForm children={children}/>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </Col>
                    <Col md lg={{span: 4, offset: 0, order: 3}} className={"rpa-offer"}>
                        <Container>
                            <div className={"register-offer-wrapper"}>
                                <div className="register-info-block mb-5">
                                    <h2 className={"mb-5"}>Abonament {children.product.name}</h2>
                                    {children.product.benefits ?
                                        <>
                                            {children.product && children.product.benefits.map((benefit, key) => (
                                                <h4 className={'green-dot'} key={key}>
                                                    {benefit}
                                                </h4>
                                            ))}
                                        </>
                                        : ''
                                    }
                                    <h4 className={'green-dot'}>
                                        Abonament odnawialny <a href="/pomoc">dowiedz się więcej</a>
                                    </h4>

                                </div>
                                <div className="register-info-block mb-4">
                                    <h3 className={"payu"}>Do zapłaty</h3>
                                    <div className={"price-container"}>
                                        <h4>{getPrice(children.product.priceGross)}<span> brutto</span>
                                        </h4>
                                        <h5>{getPrice(children.product.priceNet)} netto</h5>
                                    </div>

                                </div>
                            </div>
                        </Container>
                    </Col>
                </Row>
            </>
        )
    }
}

export default Account;