import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {Button, Container, Form} from "react-bootstrap";
import {connect} from "react-redux";
import {renderField} from "../../../common/form";
import {multiFirstLogFormRequest} from "../../../actions/register";

const mapStateToProps = state => ({
    error: state.register.error
});

const mapDispatchToProps = {
    multiFirstLogFormRequest

};
export const acceptTerms = <div><span>*</span> Znam i akceptuję warunki <a href="/regulamin" target={"_blank"}
                                                            rel={"noopener noreferrer"}>Regulaminu</a> i <a
    href="https://www.wip.pl/polityka-prywatnosci" target={"_blank"} rel={"noopener noreferrer"}>Polityki
    Prywatności </a></div>;
export const marketingAgreement = <div><span>*</span> Chcę otrzymywać informacje drogą elektroniczną i telefoniczną o dodatkowych
    promocjach, rabatach dotyczących produktów własnych WIP i partnerów WIP. Wiem, że moja zgoda może być wycofana w
    dowolnym momencie.</div>;

class MultiFirstLogForm extends React.Component {


    onSubmit(values) {
        const {multiFirstLogFormRequest} = this.props;
        let tmp = {
            phone: values.phone,
            position: values.position,
            termsAndConditions: values.termsAndConditions,
            marketingAgreement: values.marketingAgreement,
        };

        return multiFirstLogFormRequest(tmp)

    }

    render() {
        const {form, handleSubmit, error, userData} = this.props;

        return (

            <Container className={"school-form-container-content"}>
                {error &&
                <div className="alert alert-danger">Wystąpił błąd! Odśwież stronę lub skontaktuj się z Centrum Obsługi Klienta.</div>}
                <h2 id={"formularz-zgloszeniowy"}>
                   Zarejestruj się
                </h2>
                <Form className="" id={form}  onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Field name="name" type="text" input={{ disabled: true, defaultValue: userData.name}}
                          component={renderField} placeholder="Imie"/>
                    <Field name="surname" type="text" input={{ disabled: true, defaultValue: userData.surname}}
                           component={renderField} placeholder="Nazwisko"/>
                    <Field name="email" type="text"
                           component={renderField} placeholder="E-mail" input={{ disabled: true, defaultValue: userData.email}}/>
                    <Field name="phone"
                           type="text"
                           component={renderField}
                           placeholder="Telefon"
                           mask={{
                               pattern: "+48 999 999 999",
                               text: 'Prawidłowy format +48 999 999 999'
                           }}
                    />
                    <Field name="position" type="text"
                           component={renderField} placeholder="Stanowisko"/>
                    <h4><span>*</span> Pola oznaczone gwiazdką są obowiązkowe</h4>
                    <Field name="termsAndConditions" type="checkbox"
                           component={renderField} label={acceptTerms} className={"acceptTerms"}/>
                    <Field name="marketingAgreement" type="checkbox"
                           component={renderField} label={marketingAgreement}/>
                    <div className={"button-wrapper"}><Button className="educado-btn educado-btn-full" type="submit">Wyślij</Button></div>
                </Form>
            </Container>
        )
    }
}

export default reduxForm({
    form: 'MultiFirstLogForm'
})(connect(mapStateToProps, mapDispatchToProps)(MultiFirstLogForm));