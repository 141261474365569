import {
    PROFILER_ERROR,
    PROFILER_FETCH_CATEGORIES_RECEIVED, PROFILER_PRODUCTS_RECEIVED, PROFILER_PROFILE_UPDATE_SETTINGS_RECEIVED,
    PROFILER_RECEIVED,
    PROFILER_REQUEST,
    PROFILER_UPDATE_SETTINGS_RECEIVED,
} from "../constants";

export const profiler = (state = {
    isFetching: false,
    profiler: []
}, action) => {
    switch (action.type) {
        case PROFILER_REQUEST:
            state = {
                ...state,
                isFetching: true
            };
            return state;
        case PROFILER_RECEIVED:
            state = {
                ...state,
                isFetching: false
            };
            return state;
        case PROFILER_UPDATE_SETTINGS_RECEIVED:
            return {
                ...state,
                success: action.data.success,
                isFetching: false
            };
        case PROFILER_PROFILE_UPDATE_SETTINGS_RECEIVED:
            return {
                ...state,
                success: action.data.success,
                isFetching: false
            };
        case PROFILER_FETCH_CATEGORIES_RECEIVED:
            return {
                ...state,
                profilerData: action.data,
                isFetching: false
            };
        case PROFILER_PRODUCTS_RECEIVED:
            state = {
                ...state,
                productsList: action.data['result'],
                isFetching: false
            };
            return state;
        case PROFILER_ERROR:
            state = {
                ...state,
                isFetching: false,
                error: action.error
            };
            return state;
        default:
            return state;
    }
}