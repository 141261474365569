import React from 'react';
import striptags from 'striptags';
import {Button, Carousel, Col, Container, Row} from "react-bootstrap";
import {parseTrainingTime, strNormalize} from "../../../../common/utils";
import TrainingSliderItem from "./training/TrainingSliderItem";
import WebinarFreeSliderItem from "./webinarFree/WebinarFreeSliderItem";
import WebinarPaidSliderItem from "./webinarPaid/WebinarPaidSliderItem";


const SliderItem = props => {

    const {products, auth, id} = props;


    function getContent(type, product) {
        switch (type) {
            case 'online':
                return (<TrainingSliderItem auth={auth} id={id} product={product}/>)
            case 'webinar':
                return (<WebinarFreeSliderItem auth={auth} id={id} product={product}/>)
            case 'webinarPaid':
                return (<WebinarPaidSliderItem auth={auth} id={id} product={product}/>)
            default:
                return '';
        }
    }

    return (
        <Container>
            <Row className={"slider-container-wrapper "}>

                <Container className="slider-container-content">
                    <Carousel interval={3000}>
                        {products && products.map((product, index) => (

                            <Carousel.Item key={index}>
                                <Row>
                                    <Col lg={6} className={"slider-image-container"}>
                                        <div className={`image-frame ${id === 71 ? 'oswiata' : 'kik'}`}>
                                        <img className={"img-responsive"}
                                             src={`${product.imageUrl}`}
                                             alt={product.name}/></div>
                                    </Col>
                                    {getContent(product.type, product)}
                                </Row>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Container>
            </Row>
        </Container>

    )
}

export default (SliderItem);