import React from 'react';
import {Button} from "react-bootstrap";

function ListItems(props) {
    const items = props.items;
    const listItems = items.map((item, index) => {
        return <div className="list-item" key={item.id}>
            <div className={'list-item-data'}>
                <p>{index + 1 + '. '}</p><p> {item.name}</p><p> {item.surname}</p>
                <p> {item.email}</p>
            </div>
            <Button className="delete-btn" onClick={() => {
                props.deleteItem(item.key)
            }} aria-label="Close">
                <span aria-hidden="true">&times;</span></Button>
        </div>
    });
    return <div className={"list-container"}>
        {listItems}
    </div>;
}

export default ListItems;