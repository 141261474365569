import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {Button, Container, Form} from "react-bootstrap";
import {connect} from "react-redux";
import {renderField} from "../../../common/form";
import {closedTrainingFormRequest} from "../../../actions/closedTrainings";

const mapStateToProps = state => ({
    ...state.closedTrainings,
    error: state.closedTrainings.error
});

const mapDispatchToProps = {
    closedTrainingFormRequest
};
export const acceptTerms = <div>Znam i akceptuję warunki <a href="/regulamin" target={"_blank"}
                                                            rel={"noopener noreferrer"}>Regulaminu</a> i <a
    href="https://www.wip.pl/polityka-prywatnosci" target={"_blank"} rel={"noopener noreferrer"}>Polityki
    Prywatności </a><span> *</span></div>;
export const marketingAgreement = <div>Chcę otrzymywać informacje drogą elektroniczną i telefoniczną o dodatkowych
    promocjach, rabatach dotyczących produktów własnych WIP i partnerów WIP. Wiem, że moja zgoda może być wycofana w
    dowolnym momencie.<span> *</span></div>;

class ClosedTrainingsForm extends React.Component {


    onSubmit(values) {
        const {closedTrainingFormRequest} = this.props;
        let tmp = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            company: values.company,
            participants: values.participants ? parseInt(values.participants) : 0,
            additionalInfo: values.additionalInfo,
            termsAgreement: values.termsAgreement,
            marketingAgreement: values.marketingAgreement,
            cid: localStorage.cid,
        };

        return closedTrainingFormRequest(tmp)

    }

    render() {
        const {form, handleSubmit, error} = this.props;

        return (

            <>
                {error &&
                <div className="alert alert-danger">Wystąpił błąd! Odśwież stronę lub skontaktuj się z Centrum Obsługi Klienta.</div>}
                <Form className="" id={form} onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <h2>Zapytaj o ofertę</h2>
                    <Field name="firstName" type="text"
                           component={renderField} placeholder="Imie"/>
                    <Field name="lastName" type="text"
                           component={renderField} placeholder="Nazwisko"/>
                    <Field name="phone"
                           type="text"
                           component={renderField}
                           placeholder="Telefon"
                           mask={{
                               pattern: "+48 999 999 999",
                               text: 'Prawidłowy format +48 999 999 999'
                           }}
                    />
                    <Field name="email" type="text"
                           component={renderField} placeholder="E-mail"/>
                    <Field name="company" type="text"
                           component={renderField} placeholder="Nazwa firmy lub instytucji"/>
                    <Field name="participants" type="text"
                           component={renderField} placeholder="Planowana liczba użytkowników"/>
                    <Field name="additionalInfo" type="textarea"
                           component={renderField} placeholder="Dodatkowe informacje"/>
                    <Field name="termsAgreement" type="checkbox"
                           component={renderField} label={acceptTerms} className={"acceptTerms"}/>
                    <Field name="marketingAgreement" type="checkbox"
                           component={renderField} label={marketingAgreement}/>
                    <Button className="webinar-btn webinar-btn-white" type="submit">Wyślij zapytanie</Button>
                </Form>
            </>
        )
    }
}

export default reduxForm({
    form: 'ClosedTrainingsForm'
})(connect(mapStateToProps, mapDispatchToProps)(ClosedTrainingsForm));