import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {Form, Spinner} from "react-bootstrap";
import {profileNewPassword} from "../../actions/profile";
import {renderField} from "../../common/form";
import LoginForm from "./LoginForm";


const mapStateToProps = state => ({
    ...state.profile,
    auth: state.auth
});

const mapDispatchToProps = {
    profileNewPassword
};

class NewPasswordForm extends React.Component {

    onSubmit(values) {
        const {profileNewPassword, params} = this.props;

        return profileNewPassword(
            params.USER_LOGIN,
            params.USER_TOKEN,
            values.newPassword,
            values.reNewPassword
        );
    }

    render() {
        const {form, handleSubmit, error, isFetching, submitSucceeded} = this.props;

        if (isFetching && !error) {
            return (<Spinner animation="border" variant="primary" />);
        }

        if (submitSucceeded) {
            return (
                <>
                    <div className="text-center">
                        <p>Hasło zostało zmienione. Możesz się teraz zalogować.</p>
                        <h3>Zaloguj</h3>
                        <LoginForm/>
                    </div>
                </>
            )
        }

        return (
            <>
                <h3>Ustaw nowe hasło</h3>
                {error && <div className="alert alert-danger text-center">{error}</div>}
                    <Form id={form}
                          onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <Field name="newPassword" placeholder="Nowe hasło" type="password"
                               component={renderField}/>
                        <Field name="reNewPassword" placeholder="Powtórz nowe hasło" type="password"
                               component={renderField}/>
                        <button className="login-btn btn" variant="primary" type="submit">Zapisz hasło</button>
                    </Form>
            </>

        )
    }
}

export default reduxForm({
    form: 'profileNewPassword'
})(connect(mapStateToProps, mapDispatchToProps)(NewPasswordForm));