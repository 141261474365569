import {requests} from "../agent";
import {
    APP_LOAD,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_SET_ID,
    USER_SET_DATA,
    USER_ERROR,
    USER_REQUEST
} from "../constants";
import {SubmissionError} from "redux-form";
import {formValidate, requiredField, validateEmail} from "../common/form";

/**
 * user auth.wip.pl request
 * @returns {{type: string}}
 */
export const userRequest = () => ({
    type: USER_REQUEST,
});

/**
 *
 * @returns {{payload: *, type: string, skipTracking: boolean, token: *}}
 */
export const onLoadApp = () => {
    return {
        type: APP_LOAD
    }
};

/**
 * set JWT
 * @param token
 * @returns {{type: string, token: *}}
 */
export const userLoginSuccess = (token) => {
    return {
        type: USER_LOGIN_SUCCESS,
        token
    }
};

/**
 * set user id
 * @param userId
 * @returns {{type: string, userId: *}}
 */
export const userSetId = (userId) => {
    return {
        type: USER_SET_ID,
        userId
    }
};

/**
 * set data
 * @param data
 * @returns {{data: *, type: string}}
 */
export const userSetData = (data) => {
    return {
        type: USER_SET_DATA,
        data
    }
}

/**
 * Redux action logout
 * @returns {{type: string}}
 */
export const userLogout = () => {
    return {
        type: USER_LOGOUT
    }
};

/**
 *
 * @param error
 * @returns {{type: string, error: *}}
 */
export const userError = (error) => {
    return {
        type: USER_ERROR,
        error
    }
};

/**
 * Login check
 * @param email
 * @param password
 * @returns {function(*): Q.Promise<any>}
 */
export const userLoginAttempt = (email, password) => {

    let mapFields = [
        {
            field: 'email',
            text: 'Podany adres email nie jest prawidłowy!',
            method: validateEmail
        },
        {
            field: 'password',
            text: 'Pole hasło nie może pozostać puste!',
            method: requiredField
        }
    ];

    let validate = formValidate(mapFields, {email, password})

    if (validate) {
        throw new SubmissionError(validate)
    } else {
        return (dispatch) => {
            return requests.post('/auth_login_check', {email, password})
                .then(response => dispatch(userLoginSuccess(response.token)))
                .catch(() => {
                    throw new SubmissionError({
                        _error: 'Podane dane do logowania nie są prawidłowe!'
                    })
                });
        }
    }
};

/**
 * loading user data from auth.wip.pl
 * @returns {function(*): Q.Promise<any>}
 */
export const userLoad = () => {
    return (dispatch) => {
        dispatch(userRequest());
        return requests.get('/users/info', true)
            .then( response => dispatch(userSetData(response)) )
            .catch( error => dispatch(userError(error)));
    }
}
