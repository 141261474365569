import {SubmissionError} from "redux-form";
import {
    formValidate,
    minChar,
    requiredField,
    requiredNumber,
    validateEmail,
    validateTextByPattern
} from "../common/form";
import {requests} from "../agent";
import {
    CLOSED_TRAININGS_CREDENTIALS_RECEIVED,
    CLOSED_TRAININGS_ERROR,
    CLOSED_TRAININGS_FORM_RECEIVED,
    CLOSED_TRAININGS_REQUEST,
    CLOSED_TRAININGS_TESTIMONIALS_RECEIVED, SCHOOL_MULTI_ERROR, SCHOOL_MULTI_FORM_RECEIVED
} from "../constants";

export const closedTrainingRequest = () => ({
    type: CLOSED_TRAININGS_REQUEST,
});


export const closedTrainingFormRequest = (fields) => {

    let mapFields = [
        {
            field: 'firstName',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole imię nie może pozostać puste!'
                },
                {
                    method: minChar,
                    text: 'Imię powinno zawierać minimum 3 znaki.',
                    params: {limit: 3}
                },
                {
                    method: validateTextByPattern,
                    text: 'Tylko litery, myślnik oraz spacja.',
                    params: {pattern: /^[a-zćęółśążźń /-]*$/iu}
                }
            ]
        },
        {
            field: 'lastName',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole nazwisko nie może pozostać puste!'
                },
                {
                    method: minChar,
                    text: 'Nazwisko powinno zawierać minimum 3 znaki.',
                    params: {limit: 3}
                },
                {
                    method: validateTextByPattern,
                    text: 'Tylko litery, myślnik oraz spacja.',
                    params: {pattern: /^[a-zćęółśążźń /-]*$/iu}
                }
            ]
        },
        {
            field: 'email',
            text: 'Podany adres email nie jest prawidłowy!',
            method: validateEmail
        },
        {
            field: 'participants',
            text: 'Ilość uczestników musi być liczbą',
            method: requiredNumber
        },
        {
            field: 'phone',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole telefon nie może pozostać puste!'
                },
                {
                    method: minChar,
                    text: 'Poprawny format telefonu +48 999 999 999',
                    params: {limit: 15}
                },
                {
                    method: validateTextByPattern,
                    text: 'Poprawny format telefonu +48 999 999 999',
                    params: {pattern: /^\+48 [0-9 ]{11}$/iu}
                }
            ]
        },
        {
            field: 'termsAgreement',
            text: 'Akceptacja regulaminu jest wymagana!',
            method: requiredField
        },
        {
            field: 'marketingAgreement',
            text: 'Akceptacja pola jest wymagana!',
            method: requiredField
        }
    ];

    let validate = formValidate(mapFields, fields);

    if (validate) {
        throw new SubmissionError(validate)
    } else {
        let body = fields;
        return (dispatch) => {
            return requests.post(`/contact/form`, body)
                .then(response => dispatch(closedTrainingsFormReceived(response)))
                .catch(error => dispatch(closedTrainingsError(error)))

        }

    }
};


export const closedTrainingsFormReceived = (data) => {
    return {
        type: CLOSED_TRAININGS_FORM_RECEIVED,
        data,

    }
};

export const closedTrainingsTestimonialsRequest = () => {
    return (dispatch) => {
        dispatch(closedTrainingRequest());
        return requests.get('/testimonials?page=1&itemsPerPage=3', true)
            .then(response => dispatch(closedTrainingsTestimonialsReceived(response)))
            .catch(error => dispatch(closedTrainingsError(error)));
    }
}


export const closedTrainingsTestimonialsReceived = (data) => {
    return {
        type: CLOSED_TRAININGS_TESTIMONIALS_RECEIVED,
        data,

    }
};

export const closedTrainingsCredentialsRequest = () => {
    return (dispatch) => {
        dispatch(closedTrainingRequest());
        return requests.get('/trusted_uses?page=1&itemsPerPage=10', true)
            .then(response => dispatch(closedTrainingsCredentialsReceived(response)))
            .catch(error => dispatch(closedTrainingsError(error)));
    }
}


export const closedTrainingsCredentialsReceived = (data) => {
    return {
        type: CLOSED_TRAININGS_CREDENTIALS_RECEIVED,
        data,

    }
};

export const closedTrainingsError = (error) => ({
    type: CLOSED_TRAININGS_ERROR,
    error
});


/*formularz multistanowisk dla oświaty
 */


export const schoolMultiFormRequest = (fields, brand) => {

    let mapFields = [
        {
            field: 'name',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole imię nie może pozostać puste!'
                },
                {
                    method: minChar,
                    text: 'Imię powinno zawierać minimum 3 znaki.',
                    params: {limit: 3}
                },
                {
                    method: validateTextByPattern,
                    text: 'Tylko litery, myślnik oraz spacja.',
                    params: {pattern: /^[a-zćęółśążźń /-]*$/iu}
                }
            ]
        },
        {
            field: 'surname',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole nazwisko nie może pozostać puste!'
                },
                {
                    method: minChar,
                    text: 'Nazwisko powinno zawierać minimum 3 znaki.',
                    params: {limit: 3}
                },
                {
                    method: validateTextByPattern,
                    text: 'Tylko litery, myślnik oraz spacja.',
                    params: {pattern: /^[a-zćęółśążźń /-]*$/iu}
                }
            ]
        },
        {
            field: 'email',
            text: 'Podany adres email nie jest prawidłowy!',
            method: validateEmail
        },
        {
            field: 'position',
            text: 'Pole stanowisko nie może pozostać puste',
            method: requiredField
        },
        {
            field: 'phone',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole telefon nie może pozostać puste!'
                },
                {
                    method: minChar,
                    text: 'Poprawny format telefonu +48 999 999 999',
                    params: {limit: 15}
                },
                {
                    method: validateTextByPattern,
                    text: 'Poprawny format telefonu +48 999 999 999',
                    params: {pattern: /^\+48 [0-9 ]{11}$/iu}
                }
            ]
        },
        {
            field: 'termsAgreement',
            text: 'Akceptacja regulaminu jest wymagana!',
            method: requiredField
        },
        {
            field: 'marketingAgreement',
            text: 'Akceptacja pola jest wymagana!',
            method: requiredField
        }
    ];
    let validate = formValidate(mapFields, fields);

    if (validate) {
        throw new SubmissionError(validate)
    } else {
        let body = fields;
        return (dispatch) => {
            return requests.post(`/offer/contact/form`, body)
                .then(response => dispatch(schoolMultiFormReceived(response)))
                .catch(error => dispatch(schoolMultiError(error)))

        }

    }
};

export const schoolMultiFormReceived = (data) => {
    return {
        type: SCHOOL_MULTI_FORM_RECEIVED,
        data,

    }
};

export const schoolMultiError = (error) => ({
    type: SCHOOL_MULTI_ERROR,
    error
});
