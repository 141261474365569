import React from 'react';
import {connect} from "react-redux";
import {Button, Container, Modal, Row} from "react-bootstrap";


const mapStateToProps = state => ({
    userData: state.auth.userData,
});

const mapDispatchToProps = {};

class RestrictedModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
        };
        this.hideModal = this.hideModal.bind(this);
    }

    hideModal() {
        sessionStorage.setItem('restrictedInfo', true);
        this.setState({show: false});
    }

    render() {
        const {userData, isFetching} = this.props;

        if (null === userData || isFetching) {
            return (<i className="fas fa-spinner fa-spin"/>);
        }
        return (
            <Container className="restricted-modal-main-container" fluid>
                <Modal id={"restricted-modal"} show={this.state.show}
                       onHide={this.hideModal.bind(this, false)}>
                    <Container className="modal-content-container">
                        <Row>
                            <Modal.Header>
                                <h2>{userData.name}, </h2>
                                <button type="button" onClick={this.hideModal} className="close">×</button>
                            </Modal.Header>
                            <Modal.Body>
                                <p>zapraszamy Cię do świata profesjonalnych szkoleń z różnych kategorii
                                    tematycznych.</p>
                                <p>Przypominamy, że w Twoim pakiecie <strong>jest dostęp do
                                    2 dowolnie wybranych szkoleń wideo,</strong> natomiast dostęp do materiałów
                                    dodatkowych jest aktywny w każdym szkoleniu.</p>
                                <h3>Jak to działa?</h3>
                                <p>Wybierz interesujące Cię szkolenie, następnie kliknij w przycisk “play”,
                                    który rozpoczyna nagranie.
                                    Po kliknięciu w “play” w 2 szkoleniach, Twój limit będzie osiągnięty.</p>
                                <h3>Porada na start od Educado:</h3>
                                <p>{userData.name}, przemyśl, które szkolenie Cię interesuje i dopiero wtedy
                                    rozpocznij odtworzenie szkolenia.</p>
                                <Button className="btn webinar-btn reverse" variant="primary"
                                        onClick={this.hideModal}>Przejdź do platformy</Button>

                            </Modal.Body>
                        </Row>
                    </Container>
                </Modal>
            </Container>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RestrictedModal);
