import {requests} from "../agent";

import {
    QUIZ_ERROR,
    QUIZ_RECEIVED,
    QUIZ_REQUEST,
    QUIZ_QUESTION_LIST_RECEIVED,
    QUIZ_QUESTION_SEND_RECEIVED,
    QUIZ_QUESTION_SUMMARY_RECEIVED,
    QUIZ_NO_SUMMARY
} from "../constants";

export const quizRequest = () => ({
    type: QUIZ_REQUEST,
});

export const quizReceived = (data) => {
    return {
        type: QUIZ_RECEIVED,
        data
    }
};

export const quizQuestionListReceived = (data) => {
    return {
        type: QUIZ_QUESTION_LIST_RECEIVED,
        data
    }
};

export const quizError = (error) => ({
    type: QUIZ_ERROR,
    error
});

export const quizNoSummary = (error) => ({
    type: QUIZ_NO_SUMMARY,
    error
});

export const quizQuestionSendReceived = (data) => {
    return {
        type: QUIZ_QUESTION_SEND_RECEIVED,
        data
    }
}

export const quizQuestionSummaryReceived = (data) => {
    return {
        type: QUIZ_QUESTION_SUMMARY_RECEIVED,
        data
    }
}

export const quizFetch = (id) => {
    return (dispatch) => {
        dispatch(quizRequest());
        return requests.get(`/quizzes/${id}`, true)
            .then(response => dispatch(quizReceived(response)))
            .catch(error => dispatch(quizError(error)))
    }
};

export const quizQuestionListFetch = (id) => {
    return (dispatch) => {
        dispatch(quizRequest());
        return requests.get(`/quizzes/${id}/questions`, true)
            .then(response => dispatch(quizQuestionListReceived(response)))
            .catch(error => dispatch(quizError(error)))
    }
};

export const quizQuestionSend = (productId, quizId, data) => {
    let body = {
        training: `/api/trainings/${productId}`,
        quiz: `/api/quizzes/${quizId}`,
        answers: data
    };
    return (dispatch) => {
        dispatch(quizRequest());
        return requests.post(`/quizzes_users_complete`, body, true)
            .then(response => dispatch(quizQuestionSendReceived(response)))
            .catch(error => dispatch(quizError(error)))
    }
};

export const quizQuestionDelete = (productId, quizId) => {
    return (dispatch) => {
        dispatch(quizRequest());
        return requests.del(`/quizzes_users/training=${productId};quiz=${quizId}`, true)
            .then()
            .catch(error => dispatch(quizError(error)))
    }
};

export const quizQuestionUpdateSend = (productId, quizId, data) => {
    let body = {
        training: `/api/trainings/${productId}`,
        quiz: `/api/quizzes/${quizId}`,
        answers: data
    };
    return (dispatch) => {
        dispatch(quizRequest());
        return requests.put(`/quizzes_users_complete/training=${productId};quiz=${quizId}`, body, true)
            .then(response => dispatch(quizQuestionSendReceived(response)))
            .catch(error => dispatch(quizError(error)))
    }
};

export const quizQuestionSummary = (productId, quizId) => {
    return (dispatch) => {
        dispatch(quizRequest());
        return requests.get(`/quizzes_users_summary/training=${productId};quiz=${quizId}`, true)
            .then(response => dispatch(quizQuestionSummaryReceived(response)))
            .catch(error => dispatch(quizNoSummary(error)))
    }
};