import React from 'react';
import striptags from 'striptags';
import {Button, Carousel, Col, Container, Row} from "react-bootstrap";
import {parseTrainingTime, strNormalize} from "../../../../../common/utils";
import dateFormat from "dateformat";


const WebinarFreeSliderItem = props => {

    const {product, auth, id} = props;

    return (
        <Col lg={6} className={"slider-details-container"}>
            <Container className="sliders-details-container-content">
                <div className="wrapper">
                    <div className="slider-item-type">
                        <span>Webinar bezpłatny</span>

                    </div>
                    {product.categories ?
                        <div
                            className={`slider-category-item-container category${id}`}>{product.categories.map((category, key) => (
                            <span className="home-product-category-item" key={key}>{category.name}</span>

                        ))}</div>
                        : ''
                    }
                    <h2>{product.name}</h2>
                    {product.experts ?
                        <h3>Ekspert: {product.experts.map((expert, key) => (
                            <a className="d-inline-block"
                               href={`/ekspert/${strNormalize(expert.name)}-${strNormalize(expert.surname)}/${expert.id}`}
                               title={`${expert.name} ${expert.surname}`}
                               key={key}>&nbsp;{expert.name} {expert.surname}{product.experts.length > key + 1 && product.experts.length > 1 ? ', ' : ''}</a>

                        ))}</h3>
                        : ''
                    }
                    {product.experts ? <p className={'expert-desc'}>{product.experts[0].academicDegree}</p> : ''}
                    <div className={'slider-details-text'}>
                        <div className={"slider-text-info webinar-free-timetable"}>

                            {product && product.timetable.map((data, index) => (
                                <div key={index}
                                     className={data.expired ? 'webinar-date-item expired' : 'webinar-date-item'}>
                                    {id === 71 ?
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"
                                             fill="none">
                                            <path d="M22.5 2.5V5M7.5 2.5V5" stroke="#4AA5D8" strokeWidth="1.5"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                            <path
                                                d="M14.9944 16.25H15.0056M14.9944 21.25H15.0056M19.9888 16.25H20M10 16.25H10.0112M10 21.25H10.0112"
                                                stroke="#4AA5D8" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                            <path d="M4.375 10H25.625" stroke="#4AA5D8" strokeWidth="1.5"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                            <path
                                                d="M3.125 15.304C3.125 9.85743 3.125 7.1341 4.69015 5.44205C6.2553 3.75 8.77436 3.75 13.8125 3.75H16.1875C21.2256 3.75 23.7448 3.75 25.3099 5.44205C26.875 7.1341 26.875 9.85743 26.875 15.304V15.946C26.875 21.3926 26.875 24.1159 25.3099 25.808C23.7448 27.5 21.2256 27.5 16.1875 27.5H13.8125C8.77436 27.5 6.2553 27.5 4.69015 25.808C3.125 24.1159 3.125 21.3926 3.125 15.946V15.304Z"
                                                stroke="#4AA5D8" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                            <path d="M3.75 10H26.25" stroke="#4AA5D8" strokeWidth="1.5" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                        </svg> :
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"
                                             fill="none">
                                            <path d="M22.5 2.5V5M7.5 2.5V5" stroke="#FDD26E" strokeWidth="1.5"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                            <path
                                                d="M14.9944 16.25H15.0056M14.9944 21.25H15.0056M19.9888 16.25H20M10 16.25H10.0112M10 21.25H10.0112"
                                                stroke="#FDD26E" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                            <path d="M4.375 10H25.625" stroke="#FDD26E" strokeWidth="1.5"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                            <path
                                                d="M3.125 15.304C3.125 9.85743 3.125 7.1341 4.69015 5.44205C6.2553 3.75 8.77436 3.75 13.8125 3.75H16.1875C21.2256 3.75 23.7448 3.75 25.3099 5.44205C26.875 7.1341 26.875 9.85743 26.875 15.304V15.946C26.875 21.3926 26.875 24.1159 25.3099 25.808C23.7448 27.5 21.2256 27.5 16.1875 27.5H13.8125C8.77436 27.5 6.2553 27.5 4.69015 25.808C3.125 24.1159 3.125 21.3926 3.125 15.946V15.304Z"
                                                stroke="#FDD26E" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                            <path d="M3.75 10H26.25" stroke="#FDD26E" strokeWidth="1.5" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                        </svg>
                                    }
                                    <span>
                                        <span className={"date-index"}>{index === 0 ? <>1&nbsp;</> : index + 1} TERMIN</span>
                                        <span className={"time"}>{dateFormat(data.eventStartDate, 'HH:MM')}</span>
                                        <span className={"date"}>{dateFormat(data.eventStartDate, 'dd-mm-yyyy')}</span>
                                    </span>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
                <div className={"slider-button-wrapper"}>
                    <div className={'slider-button-container'}>
                        <Button
                            href={`/${strNormalize(product.type)}/${strNormalize(product.name)}/${product.id}`}
                            title={product.name} className={"educado-btn-text"}>
                            <span></span> Więcej o webinarze
                        </Button>
                        {auth && auth.isAuthenticated ?
                            <Button
                                className="educado-btn educado-btn-full"
                                href={`/${strNormalize(product.type)}/${strNormalize(product.name)}/${product.id}`}
                                title={product.name}>Oglądaj bezpłatnie</Button> :
                            <Button
                                className="btn-buy educado-btn educado-btn-full"
                                href={`/${strNormalize(product.type)}/${strNormalize(product.name)}/${product.id}`}
                                title={product.name}>Zapisz się</Button>}
                    </div>
                </div>
            </Container>
        </Col>
    )
}

export default (WebinarFreeSliderItem);