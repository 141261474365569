import React from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";

const PromotionContainer = props => {
    const {id} = props;

    function getIcons() {
        return (
            <Col lg={7} className="promotion-info-container">
                <div className={"item"}>
                    <div className={"circle"}>
                        {window.innerWidth > 767 ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46"
                                 fill="none">
                                <path
                                    d="M22.6699 23C22.6699 23 17.5351 32.375 12.3574 32.375C7.17976 32.375 3.91992 28.1776 3.91992 23C3.91992 17.8223 7.17976 13.625 12.3574 13.625C17.5351 13.625 22.6699 23 22.6699 23ZM22.6699 23C22.6699 23 27.8048 32.375 32.9824 32.375C38.16 32.375 41.4199 28.1776 41.4199 23C41.4199 17.8223 38.16 13.625 32.9824 13.625C27.8048 13.625 22.6699 23 22.6699 23Z"
                                    stroke="#2EE3A9" strokeWidth="2.5" strokeLinejoin="round"/>
                            </svg> :
                            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="46" viewBox="0 0 45 46"
                                 fill="none">
                                <rect x="0.625" y="1.59473" width="43.75" height="43.75" rx="21.875" stroke="#2EE3A9"
                                      strokeWidth="1.25"/>
                                <path
                                    d="M22.5 23.4697C22.5 23.4697 19.9326 28.1572 17.3438 28.1572C14.7549 28.1572 13.125 26.0585 13.125 23.4697C13.125 20.8809 14.7549 18.7822 17.3438 18.7822C19.9326 18.7822 22.5 23.4697 22.5 23.4697ZM22.5 23.4697C22.5 23.4697 25.0674 28.1572 27.6562 28.1572C30.2451 28.1572 31.875 26.0585 31.875 23.4697C31.875 20.8809 30.2451 18.7822 27.6562 18.7822C25.0674 18.7822 22.5 23.4697 22.5 23.4697Z"
                                    stroke="#2EE3A9" strokeWidth="1.25" strokeLinejoin="round"/>
                            </svg>

                        }
                    </div>
                    <p>Nieograniczony dostęp do wszystkich szkoleń i webinarów w abonamencie</p>
                </div>
                <div className={"item"}>
                    <div className={"circle "}>
                        {window.innerWidth > 767 ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46"
                                 fill="none">
                                <path
                                    d="M4.25977 15.5C4.25977 18.0158 19.4589 24.875 23.0098 24.875C26.5606 24.875 41.7598 18.0158 41.7598 15.5C41.7598 12.9842 26.5606 6.125 23.0098 6.125C19.4589 6.125 4.25977 12.9842 4.25977 15.5Z"
                                    stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M11.7598 21.125L14.1088 31.9987C14.2697 32.7436 14.6412 33.4394 15.2605 33.8832C19.4315 36.8722 26.588 36.8722 30.759 33.8832C31.3785 33.4394 31.7499 32.7436 31.9108 31.9987L34.2598 21.125"
                                    stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M40.353 18.3125C40.353 17.5359 39.7234 16.9062 38.9468 16.9062C38.1702 16.9062 37.5405 17.5359 37.5405 18.3125H40.353ZM37.5405 18.3125V31.451H40.353V18.3125H37.5405ZM37.6364 37.8815C37.908 36.7381 38.3753 35.6932 38.8754 34.7146C39.3334 33.818 39.9336 32.7807 40.2548 31.9676L37.6388 30.9344C37.3821 31.5845 36.9525 32.2964 36.3707 33.4349C35.8309 34.4915 35.2478 35.7678 34.9 37.2314L37.6364 37.8815ZM42.9936 37.2314C42.6458 35.7678 42.0627 34.4915 41.5229 33.4349C40.941 32.2964 40.5115 31.5845 40.2548 30.9344L37.6388 31.9676C37.96 32.7807 38.5602 33.818 39.0182 34.7146C39.5183 35.6932 39.9855 36.7381 40.2572 37.8815L42.9936 37.2314ZM39.5382 38.4688H38.3554V41.2812H39.5382V38.4688ZM34.9 37.2314C34.6688 38.2051 34.5299 39.4933 35.4471 40.4131C35.8767 40.844 36.4064 41.0463 36.8729 41.1498C37.3379 41.2529 37.848 41.2812 38.3554 41.2812V38.4688C37.9274 38.4688 37.6532 38.4421 37.4817 38.4041C37.3118 38.3664 37.3529 38.3414 37.4387 38.4275C37.5469 38.5359 37.5484 38.6371 37.5432 38.5803C37.5349 38.4924 37.5413 38.282 37.6364 37.8815L34.9 37.2314ZM40.2572 37.8815C40.3523 38.282 40.3587 38.4924 40.3504 38.5803C40.3452 38.6371 40.3467 38.5359 40.4549 38.4275C40.5407 38.3414 40.5818 38.3664 40.4119 38.4041C40.2404 38.4421 39.9662 38.4688 39.5382 38.4688V41.2812C40.0455 41.2812 40.5557 41.2529 41.0207 41.1498C41.4872 41.0463 42.0169 40.844 42.4465 40.4131C43.3637 39.4933 43.2248 38.2051 42.9936 37.2314L40.2572 37.8815Z"
                                    fill="#2EE3A9"/>
                            </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="45" height="46" viewBox="0 0 45 46"
                                          fill="none">
                                <rect x="0.625" y="1.59473" width="43.75" height="43.75" rx="21.875" stroke="#2EE3A9"
                                      strokeWidth="1.25"/>
                                <path
                                    d="M13.125 19.7197C13.125 20.9776 20.7246 24.4072 22.5 24.4072C24.2754 24.4072 31.875 20.9776 31.875 19.7197C31.875 18.4618 24.2754 15.0322 22.5 15.0322C20.7246 15.0322 13.125 18.4618 13.125 19.7197Z"
                                    stroke="#2EE3A9" strokeWidth="1.25" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                                <path
                                    d="M16.875 22.5322L18.0495 27.9691C18.13 28.3415 18.3157 28.6894 18.6254 28.9114C20.7109 30.4058 24.2891 30.4058 26.3746 28.9114C26.6843 28.6894 26.8701 28.3415 26.9505 27.9691L28.125 22.5322"
                                    stroke="#2EE3A9" strokeWidth="1.25" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                                <path
                                    d="M31.1716 21.126C31.1716 20.7377 30.8568 20.4229 30.4685 20.4229C30.0802 20.4229 29.7654 20.7377 29.7654 21.126H31.1716ZM29.7654 21.126V27.6952H31.1716V21.126H29.7654ZM29.8133 30.9105C29.9491 30.3388 30.1828 29.8163 30.4328 29.327C30.6618 28.8787 30.9619 28.3601 31.1225 27.9535L29.8145 27.4369C29.6862 27.762 29.4714 28.1179 29.1805 28.6872C28.9106 29.2155 28.619 29.8536 28.4451 30.5854L29.8133 30.9105ZM32.4919 30.5854C32.318 29.8536 32.0265 29.2155 31.7565 28.6872C31.4656 28.1179 31.2509 27.762 31.1225 27.4369L29.8145 27.9535C29.9751 28.3601 30.2752 28.8787 30.5042 29.327C30.7543 29.8163 30.9879 30.3388 31.1237 30.9105L32.4919 30.5854ZM30.7642 31.2041H30.1728V32.6104H30.7642V31.2041ZM28.4451 30.5854C28.3295 31.0723 28.26 31.7164 28.7187 32.1763C28.9335 32.3917 29.1983 32.4929 29.4315 32.5446C29.664 32.5962 29.9191 32.6104 30.1728 32.6104V31.2041C29.9588 31.2041 29.8217 31.1908 29.736 31.1718C29.651 31.1529 29.6715 31.1404 29.7145 31.1835C29.7686 31.2377 29.7693 31.2883 29.7667 31.2599C29.7626 31.2159 29.7658 31.1107 29.8133 30.9105L28.4451 30.5854ZM31.1237 30.9105C31.1713 31.1107 31.1745 31.2159 31.1703 31.2599C31.1677 31.2883 31.1685 31.2377 31.2225 31.1835C31.2655 31.1404 31.286 31.1529 31.2011 31.1718C31.1153 31.1908 30.9782 31.2041 30.7642 31.2041V32.6104C31.0179 32.6104 31.273 32.5962 31.5055 32.5446C31.7387 32.4929 32.0036 32.3917 32.2184 32.1763C32.677 31.7164 32.6075 31.0723 32.4919 30.5854L31.1237 30.9105Z"
                                    fill="#2EE3A9"/>
                            </svg>}
                    </div>
                    <p>Praktyczna wiedza prosto{window.innerWidth > 1199 ? <br/> : ''} od ekspertów</p></div>
                <div className={"item"}>
                    <div className={"circle"}>
                        {window.innerWidth > 767 ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46"
                                 fill="none">
                                <path
                                    d="M27.0996 4.25H19.5996C13.4511 4.25 10.3768 4.25 8.19757 5.77591C7.39132 6.34044 6.69005 7.04171 6.12552 7.84796C4.59961 10.0272 4.59961 13.1014 4.59961 19.25C4.59961 25.3985 4.59961 28.4728 6.12552 30.6521C6.69005 31.4583 7.39132 32.1596 8.19757 32.7241C10.3768 34.25 13.4511 34.25 19.5996 34.25H27.0996C33.2481 34.25 36.3224 34.25 38.5017 32.7241C39.3079 32.1596 40.0092 31.4583 40.5737 30.6521C42.0996 28.4728 42.0996 25.3985 42.0996 19.25C42.0996 13.1014 42.0996 10.0272 40.5737 7.84796C40.0092 7.04171 39.3079 6.34044 38.5017 5.77591C36.3224 4.25 33.2481 4.25 27.0996 4.25Z"
                                    stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"/>
                                <path
                                    d="M28.0371 41.75L27.4455 40.9646C26.112 39.1942 25.7813 36.4895 26.6249 34.25M18.6621 41.75L19.2537 40.9646C20.5872 39.1942 20.9179 36.4895 20.0744 34.25"
                                    stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"/>
                                <path d="M13.9746 41.75H32.7246" stroke="#2EE3A9" strokeWidth="2.5"
                                      strokeLinecap="round"/>
                                <path
                                    d="M15 26C18.4471 21.1336 26.4728 20.8681 30 26M26.25 14.75C26.25 16.8211 24.5711 18.5 22.5 18.5C20.4289 18.5 18.75 16.8211 18.75 14.75C18.75 12.6789 20.4289 11 22.5 11C24.5711 11 26.25 12.6789 26.25 14.75Z"
                                    stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"/>
                            </svg> :
                            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="46" viewBox="0 0 45 46"
                                 fill="none">
                                <rect x="0.625" y="1.09473" width="43.75" height="43.75" rx="21.875" stroke="#2EE3A9"
                                      strokeWidth="1.25"/>
                                <path
                                    d="M24.375 13.5947H20.625C17.5507 13.5947 16.0136 13.5947 14.924 14.3577C14.5209 14.6399 14.1702 14.9906 13.888 15.3937C13.125 16.4833 13.125 18.0204 13.125 21.0947C13.125 24.169 13.125 25.7061 13.888 26.7958C14.1702 27.1989 14.5209 27.5495 14.924 27.8318C16.0136 28.5947 17.5507 28.5947 20.625 28.5947H24.375C27.4493 28.5947 28.9864 28.5947 30.076 27.8318C30.4792 27.5495 30.8298 27.1989 31.1121 26.7958C31.875 25.7061 31.875 24.169 31.875 21.0947C31.875 18.0204 31.875 16.4833 31.1121 15.3937C30.8298 14.9906 30.4792 14.6399 30.076 14.3577C28.9864 13.5947 27.4493 13.5947 24.375 13.5947Z"
                                    stroke="#2EE3A9" strokeWidth="1.25" strokeLinecap="round"/>
                                <path
                                    d="M24.8438 32.3447L24.548 31.952C23.8812 31.0668 23.7158 29.7145 24.1376 28.5947M20.1562 32.3447L20.452 31.952C21.1188 31.0668 21.2842 29.7145 20.8624 28.5947"
                                    stroke="#2EE3A9" strokeWidth="1.25" strokeLinecap="round"/>
                                <path d="M17.8125 32.3447H27.1875" stroke="#2EE3A9" strokeWidth="1.25"
                                      strokeLinecap="round"/>
                                <path
                                    d="M18.75 24.8447C20.4736 22.4115 24.4864 22.2788 26.25 24.8447M24.375 19.2197C24.375 20.2553 23.5356 21.0947 22.5 21.0947C21.4644 21.0947 20.625 20.2553 20.625 19.2197C20.625 18.1842 21.4644 17.3447 22.5 17.3447C23.5356 17.3447 24.375 18.1842 24.375 19.2197Z"
                                    stroke="#2EE3A9" strokeWidth="1.25" strokeLinecap="round"/>
                            </svg>
                        }
                    </div>
                    <p>Wygodny dostęp<br/>do wiedzy</p></div>
            </Col>

        )

    }

    function getContent(id) {
        switch (id) {
            case 71:
                return (
                    <Container fluid className={"promotion-banner-wrapper promotion-banner-oswiata"}>
                        <Container className={"promotion-banner-container"}>
                            <div className={"container-content"}>
                                {getIcons()}
                                <Col lg={5} className="promotion-banner">
                                    <h3>Wejdź do świata szkoleń dla pracowników oświaty!</h3>
                                    <p>Wybierz najlepszy dla siebie abonament i już dziś zacznij korzystać z
                                        platformy Educado Oświata. Dzięki szkoleniom możesz rozwijać swoją wiedzę i
                                        być na bieżąco ze zmianami w prawie, nowościami z branży, zdobywać miękkie
                                        kompetencje.</p>
                                </Col>
                            </div>
                            <Container>
                                <div className={"button-wrapper"}>
                                    <Button className="educado-btn educado-btn-white" title="oferta"
                                            href={id === 71 ? "/oswiata/abonament" : '/ksiegowosc-i-kadry/abonament'}>Poznaj
                                        ofertę</Button>
                                </div>
                            </Container>
                        </Container>
                    </Container>
                )
            case 13:
                return (
                    <Container fluid className={"promotion-banner-wrapper promotion-banner-kadry"}>
                        <Container className={"promotion-banner-container"}>
                            <div className={"container-content"}>
                                {getIcons()}
                                <Col lg={5} className="promotion-banner">
                                    <h3>Wejdź do świata szkoleń dla pracowników księgowości i kadr!</h3>
                                    <p>Wybierz najlepszy dla siebie abonament i już dziś zacznij korzystać z
                                        platformy Educado Księgowość i Kadry. Dzięki szkoleniom możesz rozwijać swoją
                                        wiedzę i
                                        być na bieżąco ze zmianami w prawie, nowościami z branży, zdobywać miękkie
                                        kompetencje.</p>
                                </Col>
                            </div>
                            <Container>
                                <div className={"button-wrapper"}>
                                    <Button className="educado-btn educado-btn-white" title="oferta"
                                            href={"https://educado.pl/ksiegowosc-i-kadry/abonament"}>Poznaj
                                        ofertę</Button>
                                </div>
                            </Container>
                        </Container>
                    </Container>
                )
            default:
                return (
                    <Container fluid className={"promotion-banner-wrapper promotion-banner-default"}>
                        <Container className={"promotion-banner-container"}>
                            <Container>
                                <h3>Wejdź do świata szkoleń on-line dla specjalistów w swojej dziedzinie!</h3>
                            </Container>
                            <div className={"container-content"}>
                                <Col lg={6} className="promotion-banner">
                                    <div className={"default-banner"}>
                                        <div className={"item item-kik"}>
                                            <div className={"logo-container"}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="53" height="51"
                                                     viewBox="0 0 53 51" fill="none">
                                                    <path
                                                        d="M43.6746 30.0823C44.5552 27.7953 45.0835 25.1565 45.0835 22.5177C45.0835 10.2033 35.0454 0 22.5417 0C10.0381 0 0 10.0274 0 22.5177C0 35.008 10.2142 45.0355 22.5417 45.0355C26.24 45.0355 29.7622 44.1559 32.756 42.5726C33.8126 46.7947 37.687 50.1371 42.4419 50.1371C47.9012 50.1371 52.48 45.7391 52.48 40.1097C52.48 34.8321 48.6056 30.61 43.6746 30.0823ZM29.7622 24.4528L20.0762 30.0823C18.3152 31.1378 16.9063 30.2582 16.9063 28.1472V16.8883C16.9063 14.7773 18.3152 14.0736 20.0762 14.9532L29.7622 20.5826C31.5232 21.6381 31.5232 23.3973 29.7622 24.4528Z"
                                                        fill="#FDD26E"/>
                                                </svg>
                                                <span>księgowość i kadry</span>
                                            </div>
                                            <Button className={'educado-btn educado-btn-white'}
                                                    href={"/ksiegowosc-i-kadry"}>Zobacz szkolenia</Button>

                                        </div>
                                        <div className={"item item-oswiata"}>
                                            <div className={"logo-container"}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="53" height="51"
                                                     viewBox="0 0 53 51" fill="none">
                                                    <path
                                                        d="M43.6746 30.0823C44.5552 27.7953 45.0835 25.1565 45.0835 22.5177C45.0835 10.2033 35.0454 0 22.5417 0C10.0381 0 0 10.0274 0 22.5177C0 35.008 10.2142 45.0355 22.5417 45.0355C26.24 45.0355 29.7622 44.1559 32.756 42.5726C33.8126 46.7947 37.687 50.1371 42.4419 50.1371C47.9012 50.1371 52.48 45.7391 52.48 40.1097C52.48 34.8321 48.6056 30.61 43.6746 30.0823ZM29.7622 24.4528L20.0762 30.0823C18.3152 31.1378 16.9063 30.2582 16.9063 28.1472V16.8883C16.9063 14.7773 18.3152 14.0736 20.0762 14.9532L29.7622 20.5826C31.5232 21.6381 31.5232 23.3973 29.7622 24.4528Z"
                                                        fill="#4AA5D8"/>
                                                </svg>
                                                <span>oświata</span>
                                            </div>
                                            <Button className={'educado-btn educado-btn-white'} href={"/oswiata"}>Zobacz
                                                szkolenia</Button>
                                        </div>
                                    </div>
                                </Col>
                                {getIcons()}

                            </div>
                        </Container>
                    </Container>

                )
        }
    }

    return (
        <>
            {getContent(id)}
        </>
    )
}

export default PromotionContainer;