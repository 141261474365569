import {
    CLOSED_TRAININGS_CREDENTIALS_RECEIVED,
    CLOSED_TRAININGS_ERROR,
    CLOSED_TRAININGS_FORM_RECEIVED,
    CLOSED_TRAININGS_REQUEST,
    CLOSED_TRAININGS_TESTIMONIALS_RECEIVED, SCHOOL_MULTI_ERROR, SCHOOL_MULTI_FORM_RECEIVED
} from "../constants";

export const closedTrainings = (state = {
    isFetching: false,
    testimonials: null,
    credentials: null,
    received: false,
    error: false
}, action) => {
    switch (action.type) {
        case CLOSED_TRAININGS_REQUEST:
            state = {
                isFetching: true
            };
            return state;
        case CLOSED_TRAININGS_FORM_RECEIVED:
            state = {
                ...state,
                received: true,
                isFetching: false,
                error: false
            };
            return state;
        case SCHOOL_MULTI_FORM_RECEIVED:
            state = {
                ...state,
                received: true,
                isFetching: false,
                error: false
            };
            return state;
        case SCHOOL_MULTI_ERROR:
            state = {
                ...state,
                isFetching: false,
                error: true
            };
            return state;
        case CLOSED_TRAININGS_CREDENTIALS_RECEIVED:
            state = {
                ...state,
                credentials: action.data['hydra:member'],
                isFetching: false
            };
            return state;
        case CLOSED_TRAININGS_TESTIMONIALS_RECEIVED:
            state = {
                ...state,
                testimonials: action.data['hydra:member'],
                isFetching: false
            };
            return state;
        case CLOSED_TRAININGS_ERROR:
            state = {
                ...state,
                error: true,
            };
            return state;
        default:
            return state;
    }
};