import React from "react";
import {Button, Col, Container, Form, Row, Spinner, Tab, Tabs} from "react-bootstrap";
import {Field, reduxForm} from "redux-form";
import {renderField} from "../../../common/form";
import connect from "react-redux/es/connect/connect";
import {getBreadcrumb, getPrice} from "../../../common/utils";

class BuyerForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            eInvoice: true,
            diffBuyer: false,
            nip: false,
            nipLoad: false,
            userType: 'company',
            type: 'buyer',
            multiSlot: false,
            vatRelease: false,
            quantity: 1,
            code: '',
            breadcrumb: [
                {'type': 'buyer', 'class': 'one', 'name': 'Krok 1 / 3 - DANE'},
                {'type': 'users', 'class': 'one', 'name': 'Krok 1 / 3 - DANE'},
                {'type': 'payment', 'class': 'two', 'name': 'Krok 2 / 3 - PODSUMOWANIE I PŁATNOŚĆ'},
                {'type': 'summary', 'class': 'three', 'name': 'KROK 3 / 3 -  POTWIERDZENIE I DODATKOWE INFORMACJE'}
            ],
            gusTin: false
        };
    }

    componentDidMount() {
        const {registerGetOrder, registerToken, product, registerWebinarPrice} = this.props.children;
        window.sessionStorage.removeItem('reguser');
        if (registerToken)
            registerGetOrder(registerToken);
        if (window.sessionStorage.getItem('vatRelease')) {
            const vatR = JSON.parse(window.sessionStorage.getItem('vatRelease'))
            if (vatR === true) {
                this.setState({vatRelease: true})
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {gusData, buyer, user, auth, registerToken} = this.props.children;

        if (gusData && prevProps.children.gusData !== gusData) {
            this.loadDataForm(gusData);
        } else if (user && prevProps.children.user !== user) {
            this.setState({
                userType: user.userType,
                tin: user.tin ?? buyer.tin,
                diffBuyer: true
            })
            if (registerToken) {
                this.loadDataForm(user);
            } else {
                if (auth.isAuthenticated) {
                    this.loadDataForm(auth.userData);
                } else {
                    this.loadDataForm(user);
                }
            }
            if (buyer.tin) {
                this.loadDataForm(buyer, true);
            }

        }
    }

    loadDataForm(data, buyer = false) {
        Object.entries(data).map((v) => {
            if ((this.state.diffBuyer || buyer) && typeof v[0] === 'string' && v[0]) {
                v[0] = 'b_' + v[0];
            }
            if (v[1] === null) {
                return v[1] = ''
            }
            return this.props.change(v[0], v[1].toString());
        })
    }

    handleDiffBuyerClick = () => {
        this.setState({diffBuyer: !this.state.diffBuyer});
        this.props.change('tin', '');
    }

    handleInvoiceClick = () => {
        this.setState({eInvoice: !this.state.eInvoice});
    }

    handleVatClick = () => {
        this.setState({vatRelease: !this.state.vatRelease});
    }


    onSubmit(values) {
        const {registerOrder, registerToken, product} = this.props.children;
        window.sessionStorage.setItem('vatRelease', this.state.vatRelease);
        window.sessionStorage.setItem('multiSlot', this.state.multiSlot);

        if (registerToken) {
            let tmp = {
                userType: this.state.userType,
                companyName: values.companyName,
                street: values.street,
                streetNumber: values.streetNumber,
                apartmentNumber: values.apartmentNumber,
                city: values.city,
                postalCode: values.postalCode,
                eInvoice: this.state.eInvoice,
                vatRelease: this.state.vatRelease,
                discountCode: null

            };
            let buyerData;

            if (this.state.diffBuyer) {
                buyerData = this.setDataDiffBuyer(values);
            }

            if (tmp && this.state.userType === 'company') {
                tmp = Object.assign({
                    tin: values.tin,
                }, tmp);
            }

            return registerOrder(registerToken, product, buyerData ? Object.assign(buyerData, tmp) : tmp, this.state.multiSlot);

        }
        return;
    }

    setDataDiffBuyer(values) {
        return {
            diffBuyer: this.state.diffBuyer,
            b_companyName: values.b_companyName,
            b_street: values.b_street,
            b_streetNumber: values.b_streetNumber,
            b_city: values.b_city,
            b_postalCode: values.b_postalCode
        }
    }

    handleChange = (e) => {
        this.setState({tin: e.target.value});
        this.setState({gusTin: false});
    }

    getGusTin() {
        const {registerTinFetch} = this.props.children;
        registerTinFetch(this.state.tin);
        this.setState({gusTin: true});
    }

    setUserType = (e) => {
        if (typeof e === 'string') {
            this.setState({userType: e});
        }
    }

    render() {
        const {product, tinError, registerToken, nipFetching, priceData} = this.props.children;
        const {form, handleSubmit, error, submitFailed} = this.props;

        if (!registerToken) {
            return (<Spinner animation="grow"/>);
        }

        return (
            <>
                <Container>
                    <Row className={"register-product-buyer-container"}>
                        <Form className="row" id={form}
                              onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                            <Col xs={{span: 12}} lg={{span: 8}} className={"rpa-login-form-container"}>
                                <div className={"background-wrapper"}>

                                    <Container className={"buyer-form-container"}>
                                        {getBreadcrumb(this.state.breadcrumb, this.state.type, 'reg-breadcrumb')}
                                        {error && <div className="alert alert-danger">{error}</div>}
                                        <Row className={"order-data-container"}>
                                            <Col lg={12}>
                                                <Col lg={12} className={"form-col-wrapper"}>
                                                    <Tabs activeKey={this.state.userType}
                                                          onSelect={this.setUserType.bind(this)}
                                                          transition={false} id="noanim-tab-example">
                                                        <Tab className="tab-company" eventKey="company"
                                                             title="firma lub instytucja">
                                                            <Col className={"register-form-content register-primar"}>
                                                                <h3>Dane płatnika</h3>

                                                                {this.state.diffBuyer ? '' :
                                                                    <div className={"address-wrapper"}>
                                                                        <Col className={"tin-wrapper"} sm={4} md={4}
                                                                             lg={8} xs={7}>
                                                                            <Field name="tin"
                                                                                   type="text"
                                                                                   component={renderField}
                                                                                   placeholder="NIP"
                                                                                   onChange={this.handleChange}
                                                                                   mask={{
                                                                                       pattern: "9999999999",
                                                                                       text: 'Poprawny format: 9999999999'
                                                                                   }}
                                                                            />
                                                                            {tinError && this.state.gusTin && !submitFailed
                                                                                ?
                                                                                <div
                                                                                    className="form-group tin-error">
                                                                                    <div
                                                                                        className={'is-invalid'}></div>
                                                                                    <div
                                                                                        className="invalid-feedback">{tinError.tin}</div>
                                                                                </div>
                                                                                : ''
                                                                            }
                                                                        </Col>
                                                                        {this.state.nipLoad
                                                                            ? <Spinner animation="grow"/>
                                                                            : <button type={'button'}
                                                                                      onClick={this.getGusTin.bind(this)}
                                                                                      className={"btn"}>pobierz dane
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                }
                                                                <Field name="companyName" type="text"
                                                                       readOnly={true}
                                                                       component={renderField}
                                                                       placeholder="Nazwa firmy"/>
                                                                <Field name="street" type="text"
                                                                       component={renderField} placeholder="Ulica"/>
                                                                <div className={'address-wrapper'}>
                                                                    <Col xs={6}>
                                                                        <Field name="streetNumber" type="text"
                                                                               component={renderField}
                                                                               placeholder="Nr domu"/></Col>
                                                                    <Col xs={6}>
                                                                        <Field name="apartmentNumber" type="text"
                                                                               component={renderField}
                                                                               placeholder="Nr lokalu"/></Col>
                                                                </div>
                                                                <Field name="city" type="text"
                                                                       component={renderField}
                                                                       placeholder="miejscowość"/>
                                                                <Field name="postalCode" type="text"
                                                                       component={renderField}
                                                                       placeholder="Kod Pocztowy"
                                                                       mask={{
                                                                           pattern: "99-999",
                                                                           text: 'Poprawny format: 99-999'
                                                                       }}
                                                                />
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    className="mb-2 mr-sm-2"
                                                                    id="inlineInviceCheck"
                                                                    label="faktura elektroniczna"
                                                                    checked={this.state.eInvoice}
                                                                    onChange={this.handleInvoiceClick}
                                                                />
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    className="mb-2 mr-sm-2"
                                                                    id="inlineFormCheck"
                                                                    label="inne dane płatnika"
                                                                    checked={this.state.diffBuyer}
                                                                    value={this.state.diffBuyer || ''}
                                                                    onChange={this.handleDiffBuyerClick}
                                                                />
                                                                <div className={"vat-release"}>
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        className="mb-2 mr-sm-2"
                                                                        id="inlineVatCheck"
                                                                        label="Zwolnienie z VAT"
                                                                        checked={this.state.vatRelease}
                                                                        onChange={this.handleVatClick}
                                                                    />
                                                                    <span className={"vat-release-info"}
                                                                          title={"tekst vat"}>?</span>
                                                                </div>
                                                            </Col>
                                                            {this.state.diffBuyer ?
                                                                <Col
                                                                    className={"register-form-content register-form-invoice-content"}>
                                                                    <p>Zaznaczyłeś opcję „Inne dane płatnika”. Uzupełnij
                                                                        poniższe pola.</p>
                                                                    <h3>Dane do faktury/Nabywca</h3>
                                                                    <div className={"address-wrapper"}>
                                                                        <Col className={"tin-wrapper"} sm={4} md={4}
                                                                             lg={8} xs={7}>
                                                                            <Field name="tin"
                                                                                   type="text"
                                                                                   component={renderField}
                                                                                   placeholder="NIP"
                                                                                   onChange={this.handleChange}
                                                                                   mask={{
                                                                                       pattern: "9999999999",
                                                                                       text: 'Poprawny format: 9999999999'
                                                                                   }}
                                                                            />
                                                                            {tinError && this.state.gusTin
                                                                                ?
                                                                                <div
                                                                                    className="form-group tin-error">
                                                                                    <div
                                                                                        className={'is-invalid'}></div>
                                                                                    <div
                                                                                        className="invalid-feedback">{tinError.tin}</div>
                                                                                </div>
                                                                                : ''
                                                                            }
                                                                        </Col>
                                                                        {this.state.nipLoad
                                                                            ? <div></div>
                                                                            : <button type={'button'}
                                                                                      onClick={this.getGusTin.bind(this)}
                                                                                      className={"btn"}>pobierz dane
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                    <Field name="b_companyName" type="text"
                                                                           component={renderField}
                                                                           placeholder="Nazwa firmy"/>
                                                                    <Field name="b_street" type="text"
                                                                           component={renderField}
                                                                           placeholder="Ulica"/>
                                                                    <div className={'address-wrapper'}>
                                                                        <Col xs={6}>
                                                                            <Field name="b_streetNumber" type="text"
                                                                                   component={renderField}
                                                                                   placeholder="Nr domu"/></Col>
                                                                        <Col xs={6}>
                                                                            <Field name="b_apartmentNumber"
                                                                                   type="text"
                                                                                   component={renderField}
                                                                                   placeholder="Nr lokalu"/></Col>
                                                                    </div>
                                                                    <Field name="b_city" type="text"
                                                                           component={renderField}
                                                                           placeholder="miejscowość"/>
                                                                    <Field name="b_postalCode" type="text"
                                                                           component={renderField}
                                                                           placeholder="Kod Pocztowy"
                                                                           mask={{
                                                                               pattern: "99-999",
                                                                               text: 'Poprawny format: 99-999'
                                                                           }}
                                                                    />
                                                                </Col>
                                                                : ''
                                                            }
                                                        </Tab>
                                                        <Tab className="tab-person" eventKey="private"
                                                             title="Osoba fizyczna">
                                                            <Col className={"register-form-content register-primar"}>
                                                                <h3>Dane płatnika</h3>
                                                                <Field name="street" type="text"
                                                                       component={renderField} placeholder="Ulica"/>
                                                                <div className={'address-wrapper'}>
                                                                    <Col xs={6}>
                                                                        <Field name="streetNumber" type="text"
                                                                               component={renderField}
                                                                               placeholder="Nr domu"/></Col>
                                                                    <Col xs={6}>
                                                                        <Field name="apartmentNumber" type="text"
                                                                               component={renderField}
                                                                               placeholder="Nr lokalu"/></Col>
                                                                </div>
                                                                <Field name="city" type="text"
                                                                       component={renderField}
                                                                       placeholder="miejscowość"/>
                                                                <Field name="postalCode" type="text"
                                                                       component={renderField}
                                                                       placeholder="Kod Pocztowy"
                                                                       mask={{
                                                                           pattern: "99-999",
                                                                           text: 'Poprawny format: 99-999'
                                                                       }}
                                                                />
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    className="mb-2 mr-sm-2"
                                                                    id="inlineInviceCheck"
                                                                    label="faktura elektroniczna"
                                                                    checked={this.state.eInvoice}
                                                                    onChange={this.handleInvoiceClick}
                                                                />
                                                            </Col>
                                                        </Tab>
                                                    </Tabs>
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col md lg={{span: 4, offset: 0, order: 2}} className={"rpa-offer"}>
                                <Container>
                                    <div className={"register-offer-wrapper"}>
                                        <div className="register-info-block mb-5">
                                            <h2 className={"mb-5"}>Abonament {product.name}</h2>
                                            {product.benefits ?
                                                <>
                                                    {product && product.benefits.map((benefit, key) => (
                                                        <h4 className={'green-dot'} key={key}>
                                                            {benefit}
                                                        </h4>
                                                    ))}
                                                </>
                                                : ''
                                            }
                                            <h4 className={'green-dot'}>
                                                Abonament odnawialny <a href="/pomoc">dowiedz się więcej</a>
                                            </h4>

                                        </div>
                                        <div className="register-info-block mb-4">
                                            <h3 className={"payu"}>Do zapłaty</h3>
                                            <div className={"price-container"}>
                                                <h4>{getPrice(product.priceGross)}<span> brutto</span>
                                                </h4>
                                                <h5>{getPrice(product.priceNet)} netto</h5>
                                            </div>

                                        </div>
                                    </div>
                                    <Button className="register-btn webinar-btn" type="submit">Kolejny krok</Button>
                                </Container>
                            </Col>
                        </Form>
                    </Row>
                </Container>
            </>
        )

    }

}

export default reduxForm({
    form: 'BuyerForm',
    enableReinitialize: true,
    destroyOnUnmount: false
})(connect(null, null)(BuyerForm));