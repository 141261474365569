import React from 'react';
import {Button, Container, Modal, Row, Spinner} from "react-bootstrap";
import connect from "react-redux/es/connect/connect";
import {categoryAllFetch} from "../../actions/category";
import {productListQuery} from "../../actions/product";
import PaidWebinarList from "./PaidWebinarList";

const mapStateToProps = state => ({
    ...state.category,
    ...state.product,
});

const mapDispatchToProps = {
    categoryAllFetch,
    productListQuery
}

class PaidWebinarListContainer extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            filterCategory: {category: []},
            show: false,
            counter: 1,
            typeFilter: {
                type: ['webinarpaid']
            },
        }
    }

    componentDidMount() {
        this.props.categoryAllFetch();
        this.props.productListQuery(this.state.typeFilter, this.state.filterCategory, 6 * this.state.counter);

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.filterCategory !== this.state.filterCategory || prevState.counter !== this.state.counter) {
            this.props.productListQuery(this.state.typeFilter, this.state.filterCategory, 6 * this.state.counter);

        }
    }

    showModal() {
        this.setState({show: true});
    };

    hideModal() {
        this.setState({show: false});
    };

    changeCounter() {
        this.setState({counter: this.state.counter + 1});
        this.myRef.current.scrollIntoView();
        window.scrollBy(0, -200);

    }

    setFilters(item) {
        if (this.state.filterCategory && this.state.filterCategory.category.includes(item)) {
            this.setState({filterCategory: {category: []}, counter: 1, show: false})


        } else {
            this.setState({filterCategory: {category: [item]}, counter: 1, show: false})
        }
    }


    render() {
        const {category_all, productQueryList, totalQuery, isFetching} = this.props
        let productsCounter = 0;
        if (productQueryList && !isFetching) {
            productsCounter = productQueryList.length;

        }
        if (isFetching) {
            return <Container className={"spinner-container"}><Spinner animation="border"/></Container>
        }
        return (
            <>
                <Container className={"filter-container"}>
                    <h2 className={"filters-headline"}>Kategorie webinarów</h2>
                    {window.innerWidth < 768 ? <>
                            <Button className="btn-filter modal-filters-control"
                                    onClick={this.showModal.bind(this)}>Wybierz</Button>
                            <Modal className="login-modal filter-modal" id={"filters-modal"} show={this.state.show}
                                   onHide={this.hideModal.bind(this)}>
                                <Container className="modal-content-container">
                                    <Row>
                                        <Modal.Header closeButton>
                                            <div className={"menu-mobile-category-badge"}>
                                                <img alt="logo" src="/logo.svg"/>
                                            </div>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Container>
                                                <Row>
                                                    <div className="filter-container">
                                                        {category_all && category_all.map((item) => (
                                                            <Button
                                                                key={item.id}
                                                                id={item.id}
                                                                label={item.name}
                                                                variant={""}
                                                                onClick={this.setFilters.bind(this, item.id)}
                                                                className={`btn-filter ${this.state.filterCategory.category.includes(item.id) ? 'active' : 'non-active'}`}>
                                                                {item.name}
                                                            </Button>
                                                        ))}
                                                        <Button
                                                            label={'Pokaż wszystkie'}
                                                            variant={""}
                                                            onClick={this.setFilters.bind(this, this.state.filterCategory.category[0])}
                                                            className={`btn-filter non-active ${this.state.filterCategory.category.length === 0 || this.state.filterCategory.category[0] === undefined ? 'active' : 'non-active'}`}>
                                                            Pokaż wszystkie
                                                        </Button>
                                                    </div>
                                                </Row>
                                            </Container>
                                        </Modal.Body>
                                    </Row>
                                </Container>
                            </Modal>
                        </>
                        :
                        <>
                            {category_all && category_all.map((item) => (
                                <Button
                                    key={item.id}
                                    id={item.id}
                                    label={item.name}
                                    variant={""}
                                    onClick={this.setFilters.bind(this, item.id)}
                                    className={`btn-filter ${this.state.filterCategory.category.includes(item.id) ? 'active' : 'non-active'}`}>
                                    {item.name}
                                </Button>
                            ))}
                            <Button
                                label={'Pokaż wszystkie'}
                                variant={""}
                                onClick={this.setFilters.bind(this, this.state.filterCategory.category[0])}
                                className={`btn-filter non-active ${this.state.filterCategory.category.length === 0 || this.state.filterCategory.category[0] === undefined ? 'active' : 'non-active'}`}>
                                Pokaż wszystkie
                            </Button>
                        </>
                    }
                </Container>
                <Container>
                    <Row>
                        <Container>
                            <h3 className={"headline-small text-center home-products-header"}>Najnowsze webinary</h3>
                        </Container>
                    </Row>
                    <Row>
                        <PaidWebinarList products={productQueryList && productQueryList}
                                         isFetching={this.props.isFetching}/>
                        {productsCounter !== totalQuery && this.props.buttonShow ?
                            <Container><Button ref={this.myRef} className="show-more-btn btn"
                                               onClick={this.changeCounter.bind(this)}>Pokaż więcej</Button>
                            </Container>
                            : ''}
                    </Row>
                </Container>
            </>
        );

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PaidWebinarListContainer);