import {requests} from "../agent";
import {
    SEARCH_REQUEST,
    SEARCH_RECEIVED,
    SEARCH_ERROR,
    SEARCH_LIST_SET_PAGE,
    SEARCH_TYPES
} from "../constants";
import queryString from "query-string";

export const searchRequest = () => ({
    type: SEARCH_REQUEST,
});

export const searchReceived = (data) => ({
    type: SEARCH_RECEIVED,
    data
});

export const searchSetPage = (page) => ({
    type: SEARCH_LIST_SET_PAGE,
    page
});

export const searchSetType = (filtr) => ({
    type: SEARCH_TYPES,
    filtr
});

export const searchError = (error) => ({
    type: SEARCH_ERROR,
    error
});

export const searchFetch = (phraze, filtr = false, page = 1, limit = 33) => {
    let urlParams = '';
    if (filtr) {
        urlParams = `&${queryString.stringify(filtr, {arrayFormat: 'bracket'})}`;
    }

    return (dispatch) => {
        dispatch(searchRequest());
        return requests.get(`/search/${phraze}?page=${page}&itemsPerPage=${limit}${urlParams}`)
            .then(response => dispatch(searchReceived(response)))
            .catch(error => dispatch(searchError(error)))
    }
};