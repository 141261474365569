import {
    REGISTER_AFFILIATE_ACCOUNT_RECEIVED,
    REGISTER_AFFILIATE_ERROR,
    REGISTER_CODE_REQUEST,
    REGISTER_CODE_SUCCESS
} from "../constants";

export const affiliateCode = (state = {
    valid: null,
    isFetching: true,
    message: null
}, action) => {
    switch (action.type) {
        case REGISTER_CODE_REQUEST:
            state = {
                valid: null,
                isFetching: true
            }
            return state;
        case REGISTER_CODE_SUCCESS:
            state = {
                ...state,
                isFetching: false,
                valid: action.data.valid,
                message: action.data.message ? action.data.message : null
            };
            return state;
        case REGISTER_AFFILIATE_ACCOUNT_RECEIVED:
            state = {
                ...state,
                isFetching: false
            }
            return state;
        case REGISTER_AFFILIATE_ERROR:
            state = {
                ...state,
                error: action.error
            };
            return state;
        default:
            return state;
    }
}