import React from 'react';
import {Col, Row, Form, Modal, Container} from "react-bootstrap";

class ProductQuizQuestion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: true,
            checkedQuestion: false,
            currentQuestion: 0,
            counterQuestion: false,
            error: false,
            answers: [],
            summary: false,
            userAnswersContent: false
        }
    }

    componentDidMount() {
        const {quizQuestionListFetch, product} = this.props.children;
        quizQuestionListFetch(product.quiz.id);
    }

    componentDidUpdate(prevProps, prevState) {
        const {quizQuestionSummary, product} = this.props.children;

        if (prevProps.children.quizSend !== this.props.children.quizSend
        && prevProps.children.summary === this.props.children.summary) {
            quizQuestionSummary(product.id, product.quiz.id);
            this.setState({summary: true})
        }

    }

    getSummary() {
        const {summary} = this.props.children;

        let tmp = React.createElement("div", { className: 'item-question-list-container' }, summary.userAnswers.map((item, key) => {
                return (
                    React.createElement("div", {
                            key: key,
                            className: `item-question ${item.isCorrect ? 'correct' : 'failed'}`,
                        },
                            React.createElement("div", {className: "item-question-title"}, item.question),
                            React.createElement("div", {className: "item-question-answer"},
                                React.createElement('span', {className: 'item-question-answer-marker'}, item.answer)
                            )
                        )
                )
            }));

        this.setState({'userAnswersContent': tmp});
    }

    setSummary() {
        const {summary} = this.props.children;

        if (!summary) {
            return '';
        }
        return (
            <div className="quiz-summary-content">
                <h4 dangerouslySetInnerHTML={{ __html: summary.summary }}/>
                <p>Twój wynik: {summary.userPoints} / {summary.questionMaxPoints}</p>
                {
                    this.state.userAnswersContent
                    ? this.state.userAnswersContent
                    : <button className="btn btn-quiz btn-inner-quiz no-arr btn-two-line" onClick={this.getSummary.bind(this)}>Sprawdź odpowiedzi</button>
                }
                <button className="btn btn-quiz btn-inner-quiz no-arr btn-two-line" onClick={this.closeQuiz.bind(this)}>Zamknij</button>
            </div>
        );
    }

    getQuestion() {
        const {questions} = this.props.children;
        let question = questions[this.state.currentQuestion];

        if (this.state.currentQuestion === questions.length) {
            return (
                <div className="quiz-summary-content">
                    <h5>Wygląda na to, że odpowiedziałeś na wszystkie pytania.</h5>
                    <button className="btn btn-quiz btn-inner-quiz no-arr" onClick={this.sendQuestion.bind(this)}>Zakończ test</button>
                </div>
            )
        }

        return (
            <div>
                <Form className="quiz-question-form">
                    {this.state.error && <div className="alert alert-danger">{this.state.error}</div>}
                    <Form.Group as={Row}>
                        <Form.Label as="legend" column sm={12}>
                            <h5>Test wiedzy</h5>
                            <h4>Pytanie: {this.state.currentQuestion+1} / {questions.length}</h4>
                            <p>{question.question}</p>
                        </Form.Label>
                        <Col sm={12}>
                            {
                                question.answers.map((item) => (
                                    <Form.Check
                                        key={item.id}
                                        type="radio"
                                        label={item.answer}
                                        name="answer-radio"
                                        id={`radio-${item.id}`}
                                        onClick={this._onOptionChange.bind(this, question.id, item.id)}
                                    />
                                ))
                            }

                        </Col>
                    </Form.Group>
                </Form>
                <button className="btn btn-arr btn-quiz btn-inner-quiz" onClick={this.nextQuestion.bind(this)}>Dalej</button>
            </div>
        );
    }

    showModal() {
        this.setState({show: true});
    }

    closeQuiz() {
        window.location.reload(false);
    }

    nextQuestion() {
        if (this.state.checkedQuestion) {
            let tmp = this.state.currentQuestion + 1;
            this.setState({currentQuestion: tmp, checkedQuestion: false});
        } else {
            this.setState({error: 'Musisz zaznaczyć odpowiedź.'})
        }
    }

    sendQuestion() {
        const {quizQuestionDelete, quizQuestionSend, product, noSummary} = this.props.children;
        if (!noSummary) {
            quizQuestionDelete(product.id, product.quiz.id);
        }
        quizQuestionSend(product.id, product.quiz.id, this.state.answers);
    }

    _onOptionChange(questionId, answerId) {
        this.setState({error: false, checkedQuestion: true})
        let tmp = this.state.answers;

        if (tmp.length > 0 ) {
            tmp = tmp.filter(item => item.questionId !== questionId);
        }

        tmp.push({
            questionId: questionId,
            answerId: answerId
        })

        this.setState({answers: tmp})
    }

    render() {
        const {questions} = this.props.children;

        if (!questions) {
            return '';
        }

        return (
            <>
                <Modal className="quiz-modal" show={this.state.show} onHide={this.closeQuiz.bind(this)}>
                        <Container className="modal-content-container">
                            <Row>
                                <Modal.Header closeButton>
                                    <img alt="logo" src="/logo.svg"/>
                                </Modal.Header>
                                <Modal.Body>
                                    <Container className={"col-sm-9 quiz-content"}>
                                        <Row>
                                            {
                                                this.state.summary
                                                    ? this.setSummary()
                                                    : this.getQuestion()
                                            }
                                        </Row>
                                    </Container>
                                </Modal.Body>
                            </Row>
                        </Container>
                    </Modal>
            </>
        )
    }
}

export default ProductQuizQuestion;