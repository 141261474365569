import React from "react";
import {Container, Row, Spinner} from "react-bootstrap";
import connect from "react-redux/es/connect/connect";
import {productListQuerySlider} from "../../../actions/product";
import {webinarMailConfirmation} from "../../../actions/webinar";
import ListItems from "../../Modules/Index/Lists/ListItems";

const mapStateToProps = state => ({
    ...state.product,
    valid: state.webinar.valid

});

const mapDispatchToProps = {
    productListQuerySlider,
    webinarMailConfirmation
};

class WebinarMailConfirmation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterCategory: {category: []},
            typeFilter: {
                type: ['webinar']
            },
        }
    }

    componentDidMount() {
        const {match, webinarMailConfirmation, productListQuerySlider} = this.props;
        webinarMailConfirmation(match.params.id, match.params.hash);
        productListQuerySlider(this.state.typeFilter, this.state.filterCategory, 3, 'nearest')
    }

    render() {
        const {productQuerySList, valid, isFetching} = this.props;
        if (isFetching) {
            return (
                <Container className="home-main-container">
                    <Row>
                        <Container className="home-products-container">
                            <br/><br/><Spinner animation="grow"/>
                        </Container>
                    </Row>
                </Container>
            );
        } else {
            return (
                <>
                    <Container fluid className={"confimaion-container"}>
                        <Container>
                            <Row>
                                {valid && valid ?
                                    <Container className={"confirmation-content text-center"}>
                                        <h1>Dziękujemy </h1>
                                        <h2>za poprawną rejestrację</h2>
                                        <p>Aby przejść do webinaru postępuj zgodnie z instrukcjami <br/>zawartymi w
                                            mailu,
                                            który
                                            otrzymasz przed webinarem.</p>
                                    </Container>
                                    :
                                    <Container className={"confirmation-content text-center"}>
                                        <h1>UWAGA!</h1>
                                        <h2>rejestracja nie powiodła się!</h2>
                                        <p>Spróbuj ponownie lub<br/>przejdź na <a href={'/'} title={"educado.pl"}>stronę
                                            główną</a>.</p>
                                    </Container>
                                }
                            </Row>
                        </Container>
                    </Container>
                    <Container className={"webinar-products-list mb-5 mt-5"}>
                        <Row>
                            <h2 className="webinar-main-head">Rekomendowane dla Ciebie</h2>
                            <ListItems
                                products={productQuerySList}/>
                        </Row>
                    </Container>
                </>
            )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebinarMailConfirmation);
