import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = process.env.REACT_APP_API_URL;

const responseBody = res => res.body;

let token = null;

const tokenPlugin = secured => {
    return (request) => {
        if (token && secured) {
            request.set('Authorization', `Bearer ${token}`);
        }
    };
};

export const requests = {
    get: (url, secured = false) => {
        return superagent.get(`${API_ROOT}${url}`).use(tokenPlugin(secured)).then(responseBody);
    },
    secGet: (url, secured = true) => {
        return superagent.get(`${API_ROOT}${url}`).use(tokenPlugin(secured)).then(responseBody);
    },
    post: (url, body = null, secured = true) => {
        return superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin(secured)).then(responseBody);
    },
    put: (url, body = null, secured = true) => {
        return superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin(secured)).then(responseBody);
    },
    del: (url, secured = true) => {
        return superagent.del(`${API_ROOT}${url}`).use(tokenPlugin(secured)).then(responseBody);
    }
};

export const JwtAuth = {
    setToken: (jwtToken) =>
        token = jwtToken
};