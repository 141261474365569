import React from 'react';
import {Container} from "react-bootstrap";

const HomePromotionContainer = () => {


    return (
        <Container className={"home-page-promotion-container"}>
            <div className={"home-page-promotion-container-content"}>
                <div className={"home-promotion-item"}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52"
                             viewBox="0 0 52 52" fill="none">
                            <path
                                d="M4.3335 17.3333C4.3335 20.2405 21.8969 28.1667 26.0002 28.1667C30.1034 28.1667 47.6668 20.2405 47.6668 17.3333C47.6668 14.4261 30.1034 6.5 26.0002 6.5C21.8969 6.5 4.3335 14.4261 4.3335 17.3333Z"
                                stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"
                                strokeLinejoin="round"/>
                            <path
                                d="M13 23.833L13.5318 36.0311C13.5424 36.2753 13.5689 36.5199 13.6398 36.7539C13.8588 37.4772 14.2626 38.1343 14.8787 38.5759C19.6985 42.0298 32.3015 42.0298 37.1213 38.5759C37.7375 38.1343 38.1411 37.4772 38.3602 36.7539C38.431 36.5199 38.4575 36.2753 38.4683 36.0311L39 23.833"
                                stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"
                                strokeLinejoin="round"/>
                            <path
                                d="M46.0415 20.583C46.0415 19.6856 45.3139 18.958 44.4165 18.958C43.519 18.958 42.7915 19.6856 42.7915 20.583H46.0415ZM42.7915 20.583V35.7653H46.0415V20.583H42.7915ZM42.9022 43.1961C43.2161 41.8748 43.7561 40.6674 44.3339 39.5366C44.8632 38.5005 45.5568 37.3019 45.9279 36.3622L42.905 35.1684C42.6084 35.9195 42.112 36.7422 41.4397 38.0578C40.8159 39.2787 40.1421 40.7536 39.7401 42.4449L42.9022 43.1961ZM49.0928 42.4449C48.6909 40.7536 48.017 39.2787 47.3932 38.0578C46.7209 36.7422 46.2245 35.9195 45.9279 35.1684L42.905 36.3622C43.2761 37.3019 43.9697 38.5005 44.499 39.5366C45.0768 40.6674 45.6168 41.8748 45.9307 43.1961L49.0928 42.4449ZM45.0998 43.8747H43.7331V47.1247H45.0998V43.8747ZM39.7401 42.4449C39.473 43.57 39.3124 45.0585 40.3724 46.1215C40.8687 46.6194 41.4808 46.8532 42.0199 46.9728C42.5572 47.092 43.1468 47.1247 43.7331 47.1247V43.8747C43.2384 43.8747 42.9217 43.8439 42.7234 43.7999C42.5271 43.7564 42.5746 43.7276 42.6738 43.827C42.7988 43.9522 42.8006 44.0692 42.7945 44.0036C42.785 43.902 42.7923 43.6589 42.9022 43.1961L39.7401 42.4449ZM45.9307 43.1961C46.0406 43.6589 46.048 43.902 46.0384 44.0036C46.0324 44.0692 46.0341 43.9522 46.1591 43.827C46.2583 43.7276 46.3058 43.7564 46.1095 43.7999C45.9112 43.8439 45.5945 43.8747 45.0998 43.8747V47.1247C45.6861 47.1247 46.2757 47.092 46.813 46.9728C47.3521 46.8532 47.9642 46.6194 48.4605 46.1215C49.5205 45.0585 49.3599 43.57 49.0928 42.4449L45.9307 43.1961Z"
                                fill="#2EE3A9"/>
                        </svg>
                    </div>
                    <p>
                        Educado to platforma, na której znajdziesz <span>setki webinarów i szkoleń
                                                    online dla profesjonalistów!</span>
                    </p>
                </div>
                <div className={"home-promotion-item"}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52"
                             viewBox="0 0 52 52" fill="none">
                            <path
                                d="M27.0835 47.6664H14.2804C10.9321 47.6664 8.26884 46.037 5.8776 43.759C0.982456 39.0953 9.01956 35.3684 12.0849 33.5432C16.9587 30.6411 22.7209 29.7067 28.1668 30.7399"
                                stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"
                                strokeLinejoin="round"/>
                            <path
                                d="M33.5835 14.083C33.5835 19.4678 29.2183 23.833 23.8335 23.833C18.4487 23.833 14.0835 19.4678 14.0835 14.083C14.0835 8.69823 18.4487 4.33301 23.8335 4.33301C29.2183 4.33301 33.5835 8.69823 33.5835 14.083Z"
                                stroke="#2EE3A9" strokeWidth="2.5"/>
                            <path
                                d="M40.4975 31.5847L42.0227 34.6603C42.2307 35.0882 42.7851 35.499 43.2531 35.5777L46.0176 36.0407C47.7853 36.3378 48.2013 37.6308 46.9274 38.9066L44.7782 41.0734C44.4144 41.4405 44.2151 42.1481 44.3278 42.6549L44.9429 45.3372C45.4282 47.4603 44.3104 48.2817 42.4473 47.172L39.8562 45.6256C39.3882 45.3459 38.6171 45.3459 38.1404 45.6256L35.5493 47.172C33.6949 48.2817 32.5684 47.4517 33.0535 45.3372L33.6689 42.6549C33.7815 42.1481 33.5822 41.4405 33.2182 41.0734L31.0691 38.9066C29.804 37.6308 30.2113 36.3378 31.9791 36.0407L34.7433 35.5777C35.2026 35.499 35.7573 35.0882 35.9653 34.6603L37.4904 31.5847C38.3224 29.9158 39.6742 29.9158 40.4975 31.5847Z"
                                stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"
                                strokeLinejoin="round"/>
                        </svg>
                    </div>
                    <p>
                        <span>Nasi eksperci to uznani w swoich branżach specjaliści,</span> którzy
                        pomogli w osiągnięciu sukcesów tysiącom klientów!
                    </p>
                </div>
                <div className={"home-promotion-item"}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
                            <path d="M30.3335 4.33301H21.6668C14.5618 4.33301 11.0093 4.33301 8.49113 6.09628C7.55947 6.74862 6.74911 7.55898 6.09677 8.49065C4.3335 11.0089 4.3335 14.5613 4.3335 21.6663C4.3335 28.7713 4.3335 32.3237 6.09677 34.8421C6.74911 35.7737 7.55947 36.5841 8.49113 37.2364C11.0093 38.9997 14.5618 38.9997 21.6668 38.9997H30.3335C37.4384 38.9997 40.9909 38.9997 43.5092 37.2364C44.4409 36.5841 45.2512 35.7737 45.9036 34.8421C47.6668 32.3237 47.6668 28.7713 47.6668 21.6663C47.6668 14.5613 47.6668 11.0089 45.9036 8.49065C45.2512 7.55898 44.4409 6.74862 43.5092 6.09628C40.9909 4.33301 37.4384 4.33301 30.3335 4.33301Z" stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"/>
                            <path d="M31.4168 47.6667L30.7332 46.7591C29.1923 44.7133 28.8101 41.5879 29.7849 39M20.5835 47.6667L21.2671 46.7591C22.808 44.7133 23.1902 41.5879 22.2154 39" stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"/>
                            <path d="M15.1665 47.667H36.8332" stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"/>
                            <path d="M17.3335 30.3333C21.3168 24.71 30.5909 24.4032 34.6668 30.3333M30.3335 17.3333C30.3335 19.7266 28.3935 21.6667 26.0002 21.6667C23.6069 21.6667 21.6668 19.7266 21.6668 17.3333C21.6668 14.9401 23.6069 13 26.0002 13C28.3935 13 30.3335 14.9401 30.3335 17.3333Z" stroke="#2EE3A9" strokeWidth="2.5" strokeLinecap="round"/>
                        </svg>

                    </div>
                    <p>
                        Szkolenia dostępne są w wygodnym modelu subskrypcyjnym, który
                        zapewnia <span>możliwość zdobywania wiedzy gdzie i kiedy chcesz!</span>
                    </p>
                </div>

            </div>
        </Container>
    )

}

export default HomePromotionContainer;