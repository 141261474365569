import React from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {convertMetaDescription, convertMetaTitle} from "../../common/utils";
import HomeExpertContainer from "../Modules/Experts/Sliders/ExpertSliderContainer";
import AwardsSlider from "../Modules/AwardsBanner/AwardsSlider";
import PaidWebinarListContainer from "./PaidWebinarListContainer";
import PaidWebinarTimer from "./PaidWebinarTimer";

class PaidWebinarIndex extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            buttonShow: true,
        }
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{convertMetaTitle('Webinary płatne')}</title>
                    <meta name="description"
                          content={convertMetaDescription('Lista webinarów płatnych.')}/>
                </Helmet>
                <Container fluid className={"webinar-list-container"}>
                    <PaidWebinarTimer/>
                    <Container>
                        <Row>
                            <Container>
                                <Row>
                                    <h1 className={"webinar-list-headline mt-5"}>Webinary płatne</h1>
                                </Row>
                            </Container>
                            <Container className={"homepage-timer-product-benefits webinar-joint-benefits"}>
                                <Col className="benefit-item item-1" lg={3} md={6} xs={12}>60-minutowe<br/>wystąpienie
                                    eksperta</Col>
                                <Col className="benefit-item item-1" lg={3} md={6} xs={12}>72-godzinny dostęp<br/>do
                                    nagrania</Col>
                                <Col className="benefit-item single item-2" lg={3} md={6} xs={12}>Imienny
                                    certyfikat</Col>
                                <Col className="benefit-item item-8" lg={3} md={6} xs={12}>Live czat z ekspertem<br/>w
                                    trakcie webinaru</Col>
                                <Col className="benefit-item item-3" lg={3} md={6} xs={12}>Dodatkowe materiały <br/>szkoleniowe</Col>
                                <Col className="benefit-item item-6" lg={3} md={6} xs={12}>Eksperci i praktycy <br/>z
                                    wielu branż</Col>
                                <Col className="benefit-item item-7" lg={3} md={6} xs={12}>Gwarancja jakości<br/>merytorycznej</Col>
                            </Container>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <PaidWebinarListContainer {...this.state}/>
                        </Row>
                    </Container>
                </Container>
                <Container className={"webinar-list-subscription-container"} fluid>
                    <Container>
                        <Row>
                            <Col className="webinar-list-subscription-image" lg={4} md={5}><img
                                src={"./webinar-list-subscription.svg"} alt={"Abonament"}/></Col>
                            <Col className="webinar-list-subscription-text" lg={6} md={7}>
                                <h3>Przegapiłeś termin? Nic straconego!</h3>
                                <p>Jeśli przegapiłeś termin webinaru live możesz obejrzeć szkolenie VOD. Wystarczy, że
                                    kupisz subskrypcje Educado i zyskasz nieograniczony dostęp do wszystkich szkoleń z
                                    wielu
                                    kategorii.</p>
                                <p> Wybierz najlepszy dla siebie abonament i wejdź do świata szkoleń online!</p>
                                <Button className="webinar-btn reverse"
                                        href={"/abonament"}>Sprawdź</Button>
                            </Col>

                        </Row>

                    </Container>
                </Container>
                <HomeExpertContainer/>
                <AwardsSlider/>
            </>
        );

    }
}


export default PaidWebinarIndex;