import {
    EXPERT_REQUEST,
    EXPERT_RECEIVED,
    EXPERT_LIST_RECEIVED,
    EXPERT_ERROR,
    EXPERT_LIST_SET_PAGE
} from "../constants";
import {hydraPageCount} from "../common/apiUtils";

export const expert = (state = {
    experts: null,
    isFetching: false,
    unauthorized: false,
    error: false
}, action) => {
    switch (action.type) {
        case EXPERT_REQUEST:
            state = {
                ...state,
                isFetching: true
            };
            return state;
        case EXPERT_RECEIVED:
            state = {
                ...state,
                experts: action.data,
                isFetching: false
            };
            return state;
        case EXPERT_LIST_RECEIVED:
            state = {
                ...state,
                experts: action.data['hydra:member'],
                pageCount: hydraPageCount(action.data),
                isFetching: false
            };
            return state;
        case EXPERT_LIST_SET_PAGE:
            return {
                ...state,
                currentPage: action.page
            };
        case EXPERT_ERROR:
            state = {
                ...state,
                isFetching: false,
                experts: null,
                error: true
            };
            return state;
        default:
            return state;
    }
}