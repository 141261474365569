import {
    USER_LOGIN_SUCCESS,
    USER_SET_DATA,
    USER_ERROR,
    USER_LOGOUT,
    USER_REQUEST,
    APP_LOAD,
    APP_REDIRECT
} from "../constants";
export const auth = (state = {
    token: null,
    userId: null,
    userData: null,
    isFetching: true
}, action) => {
    switch (action.type) {
        case APP_REDIRECT:
            return {
                ...state,
                toRedirect: action.url ? action.url : '/'
            };
        case APP_LOAD:
            state = {
                ...state,
                isAuthenticated: false,
                isFetching: false
            };
            return state;
        case USER_REQUEST:
            state = {
                ...state,
                isAuthenticated: false,
                isFetching: true
            };
            return state;
        case USER_LOGIN_SUCCESS:
            return {
                ...state,
                token: action.token,
                isFetching: true
            };
        case USER_SET_DATA:
            return {
                ...state,
                userId: action.data.id,
                isAuthenticated: true,
                accessLevel: action.data.accessInfo ? action.data.accessInfo.accessLevel : false,
                role: action.data.accessInfo ? action.data.accessInfo.role : false,
                decreaseLevel: !!(action.data.accessInfo && action.data.accessInfo.role === 'ROLE_FALLBACK_USER'),
                userData: action.data,
                isFetching: false
            };
        case USER_LOGOUT:
            return {
                ...state,
                token: null,
                userId: null,
                isAuthenticated: false,
                userData: null,
                toRedirect: '/',
                isFetching: false
            };
        case USER_ERROR:
            window.location.replace(`/`);
            return {
                ...state,
                token: null,
                userId: null,
                isAuthenticated: false,
                userData: null,
                isFetching: false
            };
        default:
            return state;
    }
}