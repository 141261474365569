import {
    REGISTER_ACCOUNT_RECEIVED,
    REGISTER_GET_ORDER,
    REGISTER_NIP_ERROR,
    REGISTER_NIP_RECEIVED,
    REGISTER_OFFER_LIST_RECEIVED,
    REGISTER_OFFER_RECEIVED,
    REGISTER_ORDER_ERROR,
    REGISTER_ORDER_RECEIVED,
    REGISTER_ORDER_UPDATE_RECEIVED,
    REGISTER_PAYMENT_RECEIVED,
    REGISTER_REQUEST,
    REGISTER_SET_ORDER,
    REGISTER_SET_TOKEN,
    REGISTER_TIN_REQUEST,
    REGISTER_WEBINAR_PRICE_ERROR,
    REGISTER_WEBINAR_PRICE_RECEIVED,
    REGISTER_WEBINAR_ADD_USER_RECEIVED,
    REGISTER_WEBINAR_ADD_USER_ERROR,
    REGISTER_WEBINAR_GET_USER_RECEIVED,
    REGISTER_WEBINAR_PRICE_REQUEST,
    REGISTER_WEBINAR_GET_USER_REQUEST,
    MULTI_FIRST_LOG_FORM_RECEIVED,
    MULTI_FIRST_LOG_ERROR
} from "../constants";

export const register = (state = {
    isFetching: false,
    status: false,
    nipFetching: true,
    multiFirstLogReceived: false,
}, action) => {
    switch (action.type) {
        case REGISTER_REQUEST:
            return {
                isFetching: true
            };
        case REGISTER_OFFER_LIST_RECEIVED:
            return {
                ...state,
                offers: action.data['hydra:member'],
                isFetching: false
            };
        case REGISTER_OFFER_RECEIVED:
            return {
                ...state,
                offer: action.data,
                isFetching: false
            };
        case REGISTER_SET_TOKEN:
            state = {
                ...state,
                registerToken: action.token,
                isFetching: false
            }
            return state;
        case REGISTER_ACCOUNT_RECEIVED:
            state = {
                ...state,
                offer: action.offer,
                registerToken: action.data.id,
                status: action.data.status,
                isFetching: false
            }
            return state;
        case REGISTER_ORDER_RECEIVED:
        case REGISTER_ORDER_UPDATE_RECEIVED:
            state = {
                ...state,
                offer: action.offer,
                registerToken: action.data.id,
                buyerStatus: action.status,
                user: action.data.user,
                buyer: action.data.buyer ? action.data.buyer : false
            }
            return state;
        case REGISTER_ORDER_ERROR:
            state = {
                ...state,
                offer: action.offer,
                isFetching: false
            }
            return state;
        case REGISTER_GET_ORDER:
            state = {
                ...state,
                registerToken: action.regToken,
                user: action.user,
                buyer: action.buyer ? action.buyer : false
            }
            return state;
        case REGISTER_SET_ORDER:
            state = {
                ...state,
                registerToken: action.regToken,
                user: action.user,
                buyer: action.buyer ? action.buyer : false
            }
            return state;
        case REGISTER_TIN_REQUEST:
            return {
                ...state,
                tinError: false,
                gusData: false,
                nipFetching: true
            };
        case REGISTER_NIP_ERROR:
            state = {
                ...state,
                tinError: action.error,
                nipFetching: false
            }
            return state;
        case REGISTER_NIP_RECEIVED:
            state = {
                ...state,
                tinError: false,
                gusData: action.data[0],
                nipFetching: false
            }
            return state;
        case REGISTER_PAYMENT_RECEIVED:
            state = {
                ...state,
                payment: action.data ? action.data : false
            }
            return state;
        case REGISTER_WEBINAR_PRICE_RECEIVED:
            state = {
                ...state,
                priceData: action.data,
                isFetching: false
            }
            return state;
        case REGISTER_WEBINAR_PRICE_REQUEST:
            state = {
                ...state,
                isFetching: true
            }
            return state;
        case REGISTER_WEBINAR_PRICE_ERROR:
            state = {
                ...state,
                error: action.error,
            }
            return state;

        case REGISTER_WEBINAR_ADD_USER_RECEIVED:
            state = {
                ...state,
                addUser: action.data
            };
            return state;
        case REGISTER_WEBINAR_ADD_USER_ERROR:
            state = {
                ...state,
                error: action.error,
            }
            return state;
        case REGISTER_WEBINAR_GET_USER_REQUEST:
            state = {
                ...state,
                isFetching: true
            }
            return state;
        case REGISTER_WEBINAR_GET_USER_RECEIVED:
            state = {
                ...state,
                getUsersList: action.data,
            }
            return state;
        case MULTI_FIRST_LOG_FORM_RECEIVED:
            state = {
                ...state,
                isFetching: false,
                multiFirstLogReceived: true
            }
            return state;
        case MULTI_FIRST_LOG_ERROR:
            state = {
                ...state,
                isFetching: false,
                error: action.error
            }
            return state;
        default:
            return state;
    }
}