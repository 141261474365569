import {
    CATEGORY_REQUEST,
    CATEGORY_RECEIVED,
    CATEGORY_LIST_RECEIVED,
    CATEGORY_ERROR,
    CATEGORY_PRODUCTS_LIST_RECEIVED,
    CATEGORY_PRODUCTS_LIST_TAG_FILTER,
    CATEGORY_ANCESTORS,
    CATEGORY_DESCENDANTS,
    CATEGORY_ALL
} from "../constants";

export const category = (state = {
    categories: null,
    category_all: null,
    products: null,
    tagFilters: null,
    isFetching: false,
    notFound: false
}, action) => {
    switch (action.type) {
        case CATEGORY_REQUEST:
            state = {
                ...state,
                isFetching: true
            };
            return state;
        case CATEGORY_RECEIVED:
            state = {
                ...state,
                categories: action.data,
                isFetching: false
            };
            return state;
        case CATEGORY_LIST_RECEIVED:
            state = {
                ...state,
                categories: action.data['hydra:member'],
                isFetching: false
            };
            return state;
        case CATEGORY_PRODUCTS_LIST_RECEIVED:
            state = {
                ...state,
                products: action.list['hydra:member'],
                isFetching: false
            };
            return state;
        case CATEGORY_PRODUCTS_LIST_TAG_FILTER:
            state = {
                ...state,
                tagFilters: action.queryParams,
            };
            return state;
        case CATEGORY_ANCESTORS:
            state = {
                ...state,
                category_ancestors: action.list,
                isFetching: false
            };
            return state;
        case CATEGORY_DESCENDANTS:
            state = {
                ...state,
                category_parent_id: action.id,
                category_descendants: action.list,
                isFetching: false
            };
            return state;
        case CATEGORY_ALL:
            state = {
                ...state,
                category_parent_id: action.id,
                category_all: action.list,
                isFetching: false
            };
            return state;
        case CATEGORY_ERROR:
            state = {
                ...state,
                isFetching: false,
                products: null,
                error: (action.error.message && action.error.message === "Not Found") ? false : true,
                notFound: true
            };
            return  state;
        default:
            return state;
    }
}