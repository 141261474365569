import React from "react";
import {Tab, Tabs} from "react-bootstrap";
import {connect} from "react-redux";
import WebinarOncomingList from "./webinary/WebinarOncomingList";
import WebinarStartedList from "./webinary/WebinarStartedList";
import WebinarCertificatesList from "./webinary/WebinarCertificatesList";
import {
    profileCompleteProductsFetch,
    profileDeleteProduct,
    profileProgressProductsFetch,
    profileSavedProductsFetch,
    profileVideoWatchListFetch,
    profileWebinarActiveFetch,
    profileWebinarCertificateFetch,
    profileWebinarFetch
} from "../../actions/profile";

const mapStateToProps = state => ({
    ...state.profile,
    profileVideoWatch: state.profileVideo,
    userData: state.auth.userData,
    progressProducts: state.profile.progressProducts,
    completeProducts: state.profile.completeProducts,
    certificates: state.profile.certificates,
    activeTrainings: state.profile.activeTrainings
});

const mapDispatchToProps = {
    profileSavedProductsFetch,
    profileVideoWatchListFetch,
    profileDeleteProduct,
    profileProgressProductsFetch,
    profileCompleteProductsFetch,
    profileWebinarFetch,
    profileWebinarCertificateFetch,
    profileWebinarActiveFetch
}

class ProfileWebinarContainer extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            activeAction: 'favourite'
        }
    }


    switchAction = (e) => {
        this.setState({
            activeAction: e
        })
    };

    render() {

        return (
            <>
                <div className="profile-product-container">
                    <Tabs
                        id="uncontrolled-tab-example"
                        activeKey={this.state.activeAction}
                        onSelect={this.switchAction.bind(this)}
                    >
                        <Tab eventKey="favourite" title="Nadchodzące">
                            <WebinarOncomingList children={this.props}/>
                        </Tab>
                        <Tab eventKey="started" title="Aktywne">
                            <WebinarStartedList children={this.props}/>
                        </Tab>
                        <Tab eventKey="certificates" title="Certyfikaty">
                            <WebinarCertificatesList children={this.props}/>
                        </Tab>

                    </Tabs>
                </div>
            </>
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileWebinarContainer);