import {
    NEWSLETTER_RECEIVED, WEBINAR_ERROR, WEBINAR_MAIL_CONFIRMATION_RECEIVED,
    WEBINAR_REGISTER_RECEIVED
} from "../constants";

export const webinar = (state = {
    isFetching: false,
    received: false,
    newsletterReceived: false,
    error: false
}, action) => {
    switch (action.type) {
        case WEBINAR_REGISTER_RECEIVED:
            state = {
                ...state,
                received: true,
                isFetching: false,
                error: false
            };
            return state;
        case WEBINAR_MAIL_CONFIRMATION_RECEIVED:
            state = {
                ...state,
                valid: action.data.valid,
                isFetching: false
            };
            return state;
        case NEWSLETTER_RECEIVED:
            state = {
                ...state,
                newsletterReceived: true,
                isFetching: false,
                error: false
            };
            return state;
        case WEBINAR_ERROR:
            state = {
                ...state,
                error: true,}
            return state;
        default:
            return state;
    }
};