import React from 'react';
import {Alert, Card, Col, Container, Row} from "react-bootstrap";
import {strNormalize} from "../../common/utils";
import dateFormat from "dateformat";
import striptags from "striptags";

const FreeWebinarList = props => {

    const {products} = props;

    if (!products)
        return (<Row>
            <Col lg={4} md={6} className={'mt-4 webinar-item-container'}>
                <a className={"webinar-item"} href={`/`}>&nbsp;</a>
            </Col>
        </Row>);

    if (products.length === 0)
        return (<Container className={"mt-5"}><Alert variant="info">Brak webinarów w kategorii!</Alert></Container>);

    return (
        <Container>
            <Row>
                {products && products.map((product, index) => (
                    <Col key={index} lg={6} xl={4} md={6} className={'mt-4 webinar-item-container'}>
                        <a className={"webinar-item"}
                           href={`/${strNormalize(product.type)}/${strNormalize(product.name)}/${(product.id)}`}>

                            <div className={"card-img-top"} width={"120"} height={"147"}
                                 style={{backgroundImage: `url(${product.imageUrl})`}}/>
                            <div className={`webinar-labels ${product.archive ? 'with-overdue' : ''}`}>
                                <div className={"archived-label"}>{product.type==='webinar' ? 'webinar bezpłatny' : product.type}</div>
                                    {product.categories ?
                                        <div
                                            className={`category-label category-${product.categories[0].id}`}>{product.categories.map((category, index) => (

                                            <span
                                                key={index}>{category.name} {product.categories.length > index + 1 && product.categories.length > 1 ? ' | ' : ''}</span>

                                        ))}</div>
                                        : ''
                                    }
                            </div>
                            <Card.Body>
                                <Card.Title title={product.name}>{product.name.length > 68 ? product.name.substring(0, 65) + '...' : product.name}</Card.Title>
                                {product.experts ?

                                    <div className={"expert-wrapper"}>{product.experts.map((expert, index) => (
                                        <div className={"webinar-item-expert"}
                                             key={index}>&nbsp;{expert.name} {expert.surname}{product.experts.length > index + 1 && product.experts.length > 1 ? ', ' : ''}</div>
                                    ))}</div>
                                    : <div className={"webinar-item-expert"}></div>}
                                <div className={"webinar-dates-container card-text"}>

                                    {product && product.timetable.map((date, index) => (
                                        <div key={index}
                                             className={date.expired ? 'webinar-date-item expired' : 'webinar-date-item'}>
                                            <span>{index === 0 ? <>1&nbsp;</> : index + 1} TERMIN</span><p>
                                            <span className={"time"}>{dateFormat(Date.parse(date.eventStartDate), 'HH:MM')}</span><span className="date">
                                            {dateFormat(Date.parse(date.eventStartDate), 'dd-mm-yyyy')}</span></p></div>
                                    ))}



                                </div>
                            </Card.Body>
                            <Card.Text className="webinar-item-lead">
                                {product.shortDescription ? <span>{striptags(product.shortDescription)}</span> : '' }

                            </Card.Text>
                            <div className="webinar-list-item-btn">Zapisz się</div>
                        </a>
                    </Col>

                ))}
            </Row>
        </Container>
    );

}


export default FreeWebinarList;