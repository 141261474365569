import React from 'react';
import {Spinner} from "react-bootstrap";


class MultiTableInfo extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        const {brand, isFetching, voucher, secondVoucher, limit} = this.props;
        if (isFetching)
            return (<Spinner animation={'grow'}/>);
        return (
            <div className={"file-import-container-content"}>
                <div className={"data-numbers"}>
                    <span>LICZBA DOSTĘPNYCH KONT W RAMACH ABONAMENTU:<strong>{brand === 1 ? sessionStorage.getItem('voucherLimit') : sessionStorage.getItem('secondVoucherLimit')}</strong></span>|
                    <span>LICZBA DODANYCH UŻYTKOWNIKÓW:<strong>
                        ({limit} z {brand === 1 ? sessionStorage.getItem('voucherLimit') : sessionStorage.getItem('secondVoucherLimit')})

                    </strong></span>
                </div>
            </div>
        );
    }
}


export default MultiTableInfo;


