import React from 'react';
import {Route, Switch} from "react-router";
import Home from "./Home";
import Footer from "./Footer";
import Header from "./Header";
import {JwtAuth} from "../agent";
import Product from "./Product";
import Category from "./Category";
import Expert from "./Expert";
import ExpertList from "./Expert/list";
import Static from "./Static";
import Profile from "./Profile";
import {connect} from "react-redux";
import {onLoadApp, userLoad, userLogout, userSetData} from "../actions/auth";
import Auth from "./Auth";
import Search from "./Search";
import Registration from "./Registration";
import RegistrationWebinar from "./RegistrationWebinar";
import RegistrationThx from "./Registration/buyer/Thx";
import AffiliationCodeThx from "./AffiliateCodes/Thx";
import AffiliateCode from "./AffiliateCodes/index";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../sass/style.sass";
import {deviceType} from "react-device-detect";
import HeaderMobile from "./HeaderMobile";
import AppOffline from "./Static/AppOffline";
import UserCom from "./UserCom";
import BrowserInfo from "./BrowserInfo";
import FreeWebinarIndex from "./Webinar/FreeWebinarIndex";
import PaidWebinarIndex from "./Webinar/PaidWebinarIndex";
import WebinarMailConfirmation from "./Product/webinar/WebinarMailConfirmation";
import RestrictedModal from "./RestrictedModal";
import Index from "./Index";
import MultiFirstLogContainer from "./Registration/MultiFirstLog/MultiFirstLogContainer";
import InvitationContainer from "./Static/InvitationContainer";

const mapStateToProps = state => ({
    ...state.auth,
    category: state.category
});

const mapDispatchToProps = {
    userLoad,
    userLogout,
    userSetData,
    onLoadApp
};

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            appOffline: false,
            restrictedModal: false
        }
    }

    componentDidMount() {
        const token = window.localStorage.getItem('jwt');

        const cid = document.URL.replace(/.*cid=([^&]*).*|(.*)/, '$1');
        const utmSource = document.URL.replace(/.*utm_source=([^&]*).*|(.*)/, '$1');
        const utmMedium = document.URL.replace(/.*utm_medium=([^&]*).*|(.*)/, '$1');
        const utmCampaign = document.URL.replace(/.*utm_campaign=([^&]*).*|(.*)/, '$1');
        const lin = document.URL.replace(/.*lin=([^&]*).*|(.*)/, '$1');

        if (cid && cid.length > 0) window.sessionStorage.setItem('cid', cid);
        if (utmSource && utmSource.length > 0) window.sessionStorage.setItem('utm_source', utmSource);
        if (utmMedium && utmMedium.length > 0) window.sessionStorage.setItem('utm_medium', utmMedium);
        if (utmCampaign && utmCampaign.length > 0) window.sessionStorage.setItem('utm_campaign', utmCampaign);
        if (lin && lin.length > 0) window.sessionStorage.setItem('lin', lin);


        if (token) {
            JwtAuth.setToken(token);
            this.props.userLoad();
        } else {
            this.props.onLoadApp();
        }
    }

    componentDidUpdate(prevProps) {
        const {userLoad, toRedirect, token, role, category, isAuthenticated} = this.props;
        if (toRedirect !== prevProps.toRedirect) {
            this.props.history.push('/');
        } else if (token && token !== prevProps.token) {
            userLoad();
        }
        if(isAuthenticated && role==="ROLE_TEST_USER" && sessionStorage.getItem('restrictedInfo') === null && !this.state.restrictedModal) {
            this.setState({restrictedModal: true})
        }
        if (category.error && category.error !== prevProps.category.error && !category.isFetching) {
            setInterval(() => {
                this.setState({appOffline: true});
            }, 10000);
        }
    }

    render() {
        const {auth, userData, isAuthenticated, userSetData, userLogout, isFetching} = this.props;

        if (this.state.appOffline || isFetching) {
            return (<div className="main"><AppOffline appOffline={this.state.appOffline}/></div>)
        }
        return (
            <div className="main">

                {
                    window.innerWidth > 991
                        ? <Header isAuthenticated={isAuthenticated} userSetData={userSetData} userLogout={userLogout}/>
                        : <HeaderMobile isAuthenticated={isAuthenticated} userSetData={userSetData}
                                        userLogout={userLogout}/>
                }
                {userData && userData.userMustFillData ?
                    <MultiFirstLogContainer userData={userData}/> :
                    <Switch>
                        <Route path="/rejestracja/:id" component={Registration}/>
                        <Route path="/rejestracja-webinar/:id" component={RegistrationWebinar}/>
                        <Route path="/zamowienie/podziekowanie" component={RegistrationThx}/>
                        <Route path="/zarejestruj-kod/podziekowanie" component={AffiliationCodeThx}/>
                        <Route path="/zarejestruj-kod" component={AffiliateCode}/>
                        <Route path="/profile" component={() => <Profile children={this.props}/>}/>
                        <Route path="/zapis/podziekowanie/:id/:hash" component={WebinarMailConfirmation}/>
                        <Route exact path="/subuser/zaproszenie/:brand/:id/:invitationKey" component={InvitationContainer}/>

                        <Route path="/aktywacja-hasla/:USER_LOGIN/:USER_TOKEN" component={Auth}/>
                        <Route path="/ekspert/:name-:surname/:id(\d+)"  component={Expert}/>
                        <Route path="/ekspert-list:page?" component={ExpertList}/>
                        <Route path="/szukaj:phraze?" component={Search}/>

                        <Route path="/:type/:name/:id(\d+)" component={Product}/>


                        <Route exact path="/" component={Home}/>

                        <Route exact path="/oswiata/" component={()=> <Index id={71} contentType={null}/>} />
                        <Route exact path="/oswiata/szkolenia" component={()=><Index id={71} contentType={'trainings'}/>}/>
                        <Route exact path="/oswiata/webinary" component={()=><Index id={71} contentType={'webinar'}/>}/>
                        <Route exact path="/oswiata/webinary/webinary-live-z-chatem"  component={()=><Index id={71} contentType={'webinarPaid'}/>}/>
                        <Route exact path="/oswiata/webinary/webinary-bezplatne" component={()=> <Index id={71} contentType={'webinarFree'}/>}/>

                        <Route exact path="/ksiegowosc-i-kadry/" component={()=> <Index id={13} contentType={null}/>} />
                        <Route exact path="/ksiegowosc-i-kadry/szkolenia" component={()=><Index id={13} contentType={'trainings'}/>}/>
                        <Route exact path="/ksiegowosc-i-kadry/webinary" component={()=><Index id={13} contentType={'webinar'}/>}/>
                        <Route exact path="/ksiegowosc-i-kadry/webinary/webinary-live-z-chatem"  component={()=><Index id={13} contentType={'webinarPaid'}/>}/>
                        <Route exact path="/ksiegowosc-i-kadry/webinary/webinary-bezplatne" component={()=> <Index id={13} contentType={'webinarFree'}/>}/>

                        <Route path="*" component={Static}/>
                    </Switch>
                }
                <Footer/>

                {this.state.restrictedModal ? <RestrictedModal/> : ''}
                <BrowserInfo/>
                <UserCom children={this.props}/>
            </div>
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(App);
