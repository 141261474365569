import React from 'react';
import {Container, Row, Spinner} from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import {deviceType} from "react-device-detect";
import {strNormalize} from "../../../../common/utils";

const ExpertSlider = props => {

    const {experts, isFetching} = props;
    if (isFetching)
        return (<Spinner animation="grow"/>);
    const responsive = {
        browser: {
            breakpoint: {max: 3000, min: 992},
            items: 4,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: {max: 991, min: 768},
            items: 3,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: {max: 767, min: 0},
            items: 1,
            slidesToSlide: 1
        }
    };

    return (
        <Container >
        <Row>
            <Container className={"carousel-wrapper"}>
                <Carousel className=""
                          swipeable={true}
                          draggable={false}
                          showDots={false}
                          responsive={responsive}
                          ssr={true}
                          infinite={true}
                          autoPlay={true}
                          autoPlaySpeed={3000}
                          keyBoardControl={true}
                          customTransition="all 1s"
                          transitionDuration={900}
                          containerClass="carousel-container"
                          removeArrowOnDeviceType={false}
                          deviceType={deviceType}
                          dotListClass="custom-dot-list-style"
                          itemClass="carousel-item-padding-40-px text-center"
                >
                    {experts && experts.map((expert, index) => (
                        <div key={index}>
                            {expert.imageUrl ?
                                <div className={"carousel-image"}
                                     style={{backgroundImage: `url(${expert.imageUrl})`}}/>
                                :
                                <div className={"carousel-image"}
                                     style={{backgroundImage: `url(/expert-default.png)`}}/>
                            }
                            <a href={`/ekspert/${strNormalize(expert.name)}-${strNormalize(expert.surname)}/${expert["@id"].split('/').pop()}`}>
                                <p>{expert && expert.nameAndSurname}</p>
                                {expert.shortDescription
                                    ? <p><span dangerouslySetInnerHTML={{__html: expert.shortDescription}}/></p>
                                    : ''
                                }
                            </a>
                        </div>
                    ))}
                </Carousel>
            </Container>
        </Row>
        </Container>
    )

}

export default ExpertSlider;





