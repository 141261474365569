import {
    QUIZ_ERROR,
    QUIZ_RECEIVED,
    QUIZ_REQUEST,
    QUIZ_QUESTION_LIST_RECEIVED,
    QUIZ_QUESTION_SEND_RECEIVED,
    QUIZ_QUESTION_SUMMARY_RECEIVED,
    QUIZ_NO_SUMMARY
} from "../constants";

export const quiz = (state = {
    quiz: null,
    quizSend: false,
    questions: null,
    summary: null,
    isFetching: false
}, action) => {
    switch (action.type) {
        case QUIZ_REQUEST:
            state = {
                ...state,
                isFetching: true
            };
            return state;
        case QUIZ_QUESTION_LIST_RECEIVED:
            return {
                ...state,
                questions: action.data,
                isFetching: false
            };
        case QUIZ_RECEIVED:
            return {
                ...state,
                quiz: action.data,
                isFetching: false
            };
        case QUIZ_QUESTION_SUMMARY_RECEIVED:
            return {
                ...state,
                summary: action.data,
                isFetching: false
            }
        case QUIZ_NO_SUMMARY:
            return {
                ...state,
                noSummary: true,
                isFetching: false
            }
        case QUIZ_ERROR:
            return {
                ...state,
                questions: null,
                quiz: null,
                error: action.error
            };
        case QUIZ_QUESTION_SEND_RECEIVED:
            return {
                ...state,
                quizSend: true,
                isFetching: false
            };
        default:
            return state;
    }
}