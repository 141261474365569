import React from 'react';
import {connect} from "react-redux";
import {Container, Row, Spinner} from "react-bootstrap";
import ExpertContainer from "../Modules/Experts/Sliders/ExpertSliderContainer";
import AwardsSlider from "../Modules/AwardsBanner/AwardsSlider";
import CategoryIndex from "./Category";
import CategoryWebinarsPaidIndex from "./Webinar/WebinarPaid";
import CategoryTrainingsIndex from "./Trainings";
import CategoryWebinarsIndex from "./Webinar";
import CategoryWebinarsFreeIndex from "./Webinar/WebinarFree";
import PromotionContainer from "../Modules/PromotionBanner/PromotionContainer";


const mapStateToProps = state => ({
    ...state.category,
    auth: state.auth
});


class Index extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentDidUpdate() {
        if (this.props.error) {
            if (!this.props.isFetching) {
                this.props.history.push(`/404`);

            } else {
                setInterval(() => {
                    this.props.history.push(`/404`);
                }, 15000); //lagi...
            }
        }
    }

    getContent(type) {
        switch (type) {
            case 'trainings':
                return (<CategoryTrainingsIndex auth={this.props.auth} id={this.props.id}/>);
            case 'webinar':
                return (<CategoryWebinarsIndex auth={this.props.auth} id={this.props.id}/>);
            case 'webinarFree':
                return (<CategoryWebinarsFreeIndex auth={this.props.auth} id={this.props.id}/>);
            case 'webinarPaid':
                return (<CategoryWebinarsPaidIndex auth={this.props.auth} id={this.props.id}/>);
            default:
                return (<CategoryIndex auth={this.props.auth} id={this.props.id}/>);
        }
    }


    render() {
        const {isFetching, auth, id} = this.props;
        if (isFetching) {
            return <Container className={"spinner-container"}><Spinner animation="border"/></Container>
        }
        return (
            <Container fluid className={"index-page-main-container"}>
                <div className={'container-content-wrapper'}>
                    <Row>
                        <div className={'position-absolute index-background'}>
                            {id === 71 ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="1920" height="914" viewBox="0 0 1920 914"
                                     fill="none">
                                    <path
                                        d="M1920 0V419.456C1881.25 431.105 1833.35 445.923 1777.76 463.86C1620.19 514.842 1542.94 351.105 1352.76 341.904C1162.57 332.703 1007.35 487.405 961.209 604.26C915.064 721.114 672.956 957.633 434.91 906.258C208.375 857.373 76.6032 772.54 0 847.48V0H1920Z"
                                        fill="url(#paint0_linear_825_39506)"/>
                                    <defs>
                                        <linearGradient id="paint0_linear_825_39506" x1="909.426" y1="235.819"
                                                        x2="981.055"
                                                        y2="837.995" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#242936"/>
                                            <stop offset="1" stopColor="#32509F"/>
                                        </linearGradient>
                                    </defs>
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="1920" height="914" viewBox="0 0 1920 914"
                                     fill="none">
                                    <path
                                        d="M1920 0.25V419.706C1881.25 431.355 1833.35 446.173 1777.76 464.11C1620.19 515.092 1542.94 351.355 1352.76 342.154C1162.57 332.953 1007.35 487.655 961.209 604.51C915.064 721.364 672.956 957.883 434.91 906.508C208.375 857.623 76.6032 772.79 0 847.73V0.25H1920Z"
                                        fill="#232936"/>
                                    <path
                                        d="M1920 0.25V419.706C1881.25 431.355 1833.35 446.173 1777.76 464.11C1620.19 515.092 1542.94 351.355 1352.76 342.154C1162.57 332.953 1007.35 487.655 961.209 604.51C915.064 721.364 672.956 957.883 434.91 906.508C208.375 857.623 76.6032 772.79 0 847.73V0.25H1920Z"
                                        fill="url(#paint0_linear_1301_18274)"/>
                                    <defs>
                                        <linearGradient id="paint0_linear_1301_18274" x1="403.394" y1="21.146"
                                                        x2="564.366" y2="1037.59" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#232936"/>
                                            <stop offset="0.197335" stopColor="#232936" stopOpacity="0.8"/>
                                            <stop offset="0.365393" stopColor="#232936" stopOpacity="0.3"/>
                                            <stop offset="0.449096" stopColor="#232936" stopOpacity="0.15"/>
                                            <stop offset="0.650905" stopColor="#FAB14C" stopOpacity="0.55"/>
                                            <stop offset="0.764992" stopColor="#FAA13F" stopOpacity="0.9"/>
                                            <stop offset="0.854733" stopColor="#FAA13F"/>
                                            <stop offset="1" stopColor="#FAA13F"/>
                                        </linearGradient>
                                    </defs>
                                </svg>}
                        </div>
                    </Row>
                    <Container className={'container-content-wrapper'}>
                        {this.getContent(this.props.contentType)}
                    </Container>
                </div>
                <Row>
                    {auth && auth.isAuthenticated ? '' : <PromotionContainer id={this.props.id}/>}
                    <ExpertContainer/>
                    <AwardsSlider/>
                </Row>
            </Container>
        )
    }
}

export default connect(mapStateToProps)(Index);