import {requests} from "../agent";
import {
    PRODUCT_ADDITIONAL_LIST_QUERY_RECEIVED, PRODUCT_ADDITIONAL_LIST_QUERY_REQUEST,
    PRODUCT_DOCUMENT_RECEIVED,
    PRODUCT_ERROR,
    PRODUCT_HOME_LIST_QUERY_RECEIVED,
    PRODUCT_HOME_LIST_QUERY_REQUEST,
    PRODUCT_L_QUERY_SLIDER_RECEIVED,
    PRODUCT_L_QUERY_SLIDER_REQUEST,
    PRODUCT_LIST_QUERY_ERROR,
    PRODUCT_LIST_QUERY_RECEIVED,
    PRODUCT_LIST_QUERY_REQUEST,
    PRODUCT_LIST_QUERY_SLIDER_RECEIVED,
    PRODUCT_LIST_QUERY_SLIDER_REQUEST,
    PRODUCT_LIST_RECEIVED,
    PRODUCT_RECEIVED,
    PRODUCT_REQUEST,
    PRODUCT_TYPES_RECEIVED,
    PRODUCT_UNLOAD,
    VIDEO_CHAPTERS
} from "../constants";
import queryString from "query-string";

/**
 * set request
 * @returns {{type: string}}
 */
export const productRequest = () => ({
    type: PRODUCT_REQUEST,
});

/**
 * set product received
 * @param data
 * @returns {{data: *, type: string}}
 */
export const productReceived = (data) => ({
    type: PRODUCT_RECEIVED,
    data
});

/**
 * set products received
 * @param data
 * @returns {{data: *, type: string}}
 */
export const productListReceived = (data) => ({
    type: PRODUCT_LIST_RECEIVED,
    data
});

/**
 * set product types received
 * @param types
 * @returns {{types: *, type: string}}
 */
export const productTypesReceived = (types) => ({
    type: PRODUCT_TYPES_RECEIVED,
    types
});

/**
 * set product error
 * @param error
 * @returns {{type: string, error: *}}
 */
export const productError = (error) => ({
    type: PRODUCT_ERROR,
    error
});

/**
 * product reset value
 * @returns {{type: string}}
 */
export const productUnload = () => ({
    type: PRODUCT_UNLOAD
});

/**
 * set product document received
 * @param document
 * @returns {{document: *, type: *}}
 */
export const productDocumentReceived = (document) => ({
    type: PRODUCT_DOCUMENT_RECEIVED,
    document
})

/**
 * get product list
 * @param limit
 * @returns {function(*): Q.Promise<any>}
 */
export const productListFetch = (limit = 3) => {
    return (dispatch) => {
        dispatch(productRequest());
        return requests.get(`/trainings?itemsPerPage=${limit}`)
            .then(response => dispatch(productListReceived(response)))
            .catch(error => dispatch(productError(error)))
    }
};

export const productVideoFetch = (id) => {
    return (dispatch) => {
        dispatch(productRequest());
        return requests.get(`//api/trainings_videos/{id}/${id}`)
            .then(response => dispatch(productReceived(response)))
            .catch(error => dispatch(productError(error)))
    }
}

/**
 * get product by id
 * @param id
 * @param auth
 * @param urlToken - urlToken
 * @returns {function(*): Q.Promise<any>}
 */
export const productFetch = (id, auth = false, urlToken) => {
    if (urlToken) {
        urlToken = `/accessKey/${urlToken}`;
    }
    return (dispatch) => {
        dispatch(productRequest());
        return requests.get(`/trainings/${id}${urlToken}`, auth, urlToken)
            .then(response => dispatch(productReceived(response)))
            .catch(error => dispatch(productError(error)))
    }
};

/**
 * get product types
 * @returns {function(*): Q.Promise<any>}
 */
export const productTypesFetch = () => {
    return (dispatch) => {
        dispatch(productRequest());
        return requests.get('/trainings/types')
            .then(response => dispatch(productTypesReceived(response)))
            .catch(error => dispatch(productError(error)))
    }
};

/**
 * get product document
 * @returns {function(*): Q.Promise<any>}
 */
export const productDocumentFetch = (id) => {
    return (dispatch) => {
        return requests.get(`/documents/${id}`, true)
            .then(response => dispatch(productDocumentReceived(response)))
            .catch(error => dispatch(productError(error)))
    }
};

export const productListQueryRequest = () => ({
    type: PRODUCT_LIST_QUERY_REQUEST,
});
export const productAdditionalListQueryRequest = () => ({
    type: PRODUCT_ADDITIONAL_LIST_QUERY_REQUEST,
});

export const productListQueryReceived = (data) => ({
    type: PRODUCT_LIST_QUERY_RECEIVED,
    data
});
export const productAdditionalListQueryReceived = (data) => ({
    type: PRODUCT_ADDITIONAL_LIST_QUERY_RECEIVED,
    data
});
export const productHomeListQueryRequest = () => ({
    type: PRODUCT_HOME_LIST_QUERY_REQUEST,
});

export const productHomeListQueryReceived = (data) => ({
    type: PRODUCT_HOME_LIST_QUERY_RECEIVED,
    data
});

export const productListQueryError = (error) => ({
    type: PRODUCT_LIST_QUERY_ERROR,
    error
});


export const productListQuery = (productType = null, productCategory = null, limit = 6, pageQuery = null) => {

    let type = productType ? `${queryString.stringify(productType, {arrayFormat: 'bracket'})}` : '';
    let page = pageQuery ? pageQuery : 1;
    let category = productCategory ? `&${queryString.stringify(productCategory, {arrayFormat: 'bracket'})}` : '';

    if (productCategory.category[0].length !== 0) {
        return (dispatch) => {
            dispatch(productListQueryRequest());
            return requests.get(`/trainings/list?${type}${category}&limit=${limit}&page=${page}`)
                .then(response => dispatch(productListQueryReceived(response)))
                .catch(error => dispatch(productListQueryError(error)))
        }


    } else {
        return (dispatch) => {
            dispatch(productListQueryRequest());
            return requests.get(`/trainings/list?${type}&limit=${limit}&page=${page}`)
                .then(response => dispatch(productListQueryReceived(response)))
                .catch(error => dispatch(productListQueryError(error)))
        }
    }

};

/*Dodatkowa akcja do pobierania szkoleń elasticiem. Jest tożasama z powyszą ze względu na konieczność rozdzielności otrzymanych propsów (patrz reducer)*/
export const productAdditionalListQuery = (productType = null, productCategory = null, limit = 6, pageQuery = null) => {

    let type = productType ? `${queryString.stringify(productType, {arrayFormat: 'bracket'})}` : '';
    let page = pageQuery ? pageQuery : 1;
    let category = productCategory ? `&${queryString.stringify(productCategory, {arrayFormat: 'bracket'})}` : '';

    if (productCategory.category[0].length !== 0) {
        return (dispatch) => {
            dispatch(productAdditionalListQueryRequest());
            return requests.get(`/trainings/list?${type}${category}&limit=${limit}&page=${page}`)
                .then(response => dispatch(productAdditionalListQueryReceived(response)))
                .catch(error => dispatch(productListQueryError(error)))
        }
    } else {
        return (dispatch) => {
            dispatch(productAdditionalListQueryRequest());
            return requests.get(`/trainings/list?${type}&limit=${limit}&page=${page}`)
                .then(response => dispatch(productAdditionalListQueryReceived(response)))
                .catch(error => dispatch(productListQueryError(error)))
        }
    }
};

export const productHomeListQuery = (productType = null, productCategory = null, limit = 9) => {

    let type = productType ? `${queryString.stringify(productType, {arrayFormat: 'bracket'})}` : '';
    let category = productCategory ? `&${queryString.stringify(productCategory, {arrayFormat: 'bracket'})}` : '';

    return (dispatch) => {
        dispatch(productHomeListQueryRequest());
        return requests.get(`/trainings/list?${type}${category}&limit=${limit}`)
            .then(response => dispatch(productHomeListQueryReceived(response)))
            .catch(error => dispatch(productListQueryError(error)))
    }
};

/**
 * SLIDER TIMER HOMEPAGE LIST
 * rozdzielenie konieczne jeśli mają renderować się 3 elementy bazujące na tym samym api
 * @returns {{type: string}}
 */
export const productListQuerySliderRequest = () => ({
    type: PRODUCT_LIST_QUERY_SLIDER_REQUEST,
});

export const productListQuerySliderReceived = (data) => ({
    type: PRODUCT_LIST_QUERY_SLIDER_RECEIVED,
    data
});

export const productListQuerySlider = (productType = null, productCategory = null, limit = 3, order = null) => {
    let type = productType ? `${queryString.stringify(productType, {arrayFormat: 'bracket'})}` : '';
    let category = productCategory ? `&${queryString.stringify(productCategory, {arrayFormat: 'bracket'})}` : '';

    return (dispatch) => {
        dispatch(productListQuerySliderRequest());
        return requests.get(`/trainings/list?${type}${category}&limit=${limit}&order=${order}`)
            .then(response => dispatch(productListQuerySliderReceived(response)))
            .catch(error => dispatch(productListQueryError(error)))
    }
};


/**
 * SLIDER PRODUCTS HOMEPAGE LIST
 *
 * @returns {{type: string}}
 */

export const videoChapters = (chapters) => {
    return {
        type: VIDEO_CHAPTERS,
        data: chapters
    }
};


/**
 * Offer fetch
 */

export const productOfferFetch = (id) => {
    return (dispatch) => {
        dispatch(productRequest());
        return requests.get(`/subscriptions/${id}`)
            .then(response => dispatch(productReceived(response)))
            .catch(error => dispatch(productError(error)))
    }
};


