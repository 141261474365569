import React from 'react';
import {Button, Container, Modal, Row, Spinner} from "react-bootstrap";
import connect from "react-redux/es/connect/connect";
import ListItems from "./ListItems";
import {productAdditionalListQuery} from "../../../../actions/product";

const mapStateToProps = state => ({
    ...state.product,
});

const mapDispatchToProps = {
    productAdditionalListQuery
}

class AdditionalListContainer extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            filterCategory: {category: [this.props.currentCategory]},
            show: false,
            counter: 1,
            typeFilter: {
                type: this.props.type
            },
        }
    }

    componentDidMount() {
        this.props.productAdditionalListQuery(this.state.typeFilter, this.state.filterCategory, 6 * this.state.counter);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.filterCategory !== this.state.filterCategory || prevState.counter !== this.state.counter) {
            this.props.productAdditionalListQuery(this.state.typeFilter, this.state.filterCategory, 6 * this.state.counter);

        }
        if (prevProps.currentCategory !== this.props.currentCategory) {
            this.setFilters(this.props.currentCategory)
        }

    }
    showModal() {
        this.setState({show: true});
    };

    hideModal() {
        this.setState({show: false});
    };

    changeCounter() {
        this.setState({counter: this.state.counter + 1});
        this.myRef.current.scrollIntoView();
        window.scrollBy(0, -200);

    }

    setFilters(item) {
        if (this.state.filterCategory && this.state.filterCategory.category.includes(item)) {
            this.setState({filterCategory: {category: []}, counter: 1, show: false})
        } else {
            this.setState({filterCategory: {category: [item]}, counter: 1, show: false})
        }
    }


    render() {
        const {productAdditionalQueryList, totalAdditionalQuery, isFetching, hideButton, id, auth} = this.props
        let productsCounter = 0;
        if (productAdditionalQueryList && !isFetching) {
            productsCounter = productAdditionalQueryList.length;

        }

        if (isFetching) {
            return <Container className={"spinner-container"}><Spinner animation="border"/></Container>
        }
        return (
            <>
                <Container>
                    <Row>
                        <ListItems products={productAdditionalQueryList && productAdditionalQueryList}
                                   isFetching={isFetching} auth={auth} id={id}/>
                        {productsCounter !== totalAdditionalQuery ?
                            <Container>
                                {hideButton && hideButton ? '' : <Button ref={this.myRef} className="show-more-btn btn"
                                               onClick={this.changeCounter.bind(this)}>Pokaż więcej</Button>
                                }
                            </Container>
                            : ''}
                    </Row>
                </Container>
            </>
        );

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AdditionalListContainer);