import React from 'react';
import Player from '@vimeo/player';
import connect from "react-redux/es/connect/connect";
import {videoChapters} from "../../../actions/product";



const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    videoChapters,
}

class ProductVimeoPlayer extends React.Component {


    componentDidMount() {
        this.createPlayer();

    }

    componentDidUpdate(prevProps, prevState) {
        this.player.destroy();
        this.createPlayer();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.props.chapter !== nextProps.chapter) {
            this.player.setCurrentTime(nextProps.chapter)
        }
        return this.props.video !== nextProps.video
            || this.props.start !== nextProps.start;
    }

    componentWillUnmount() {
        this.player.destroy();
    }

    getInitialOptions() {
        return {
            id: this.props.video,
            width: this.props.width,
            height: this.props.height,
            autopause: this.props.autopause,
            autoplay: this.props.autoplay,
            byline: this.props.showByline,
            color: this.props.color,
            controls: this.props.controls,
            loop: this.props.loop,
            portrait: this.props.showPortrait,
            title: this.props.showTitle,
            muted: this.props.muted,
            background: this.props.background,
            responsive: this.props.responsive
        };
    }


    createPlayer() {
        const {start, videoChapters} = this.props;

        this.player = new Player(this.container, this.getInitialOptions());

        let eventNames = {
            play: 'onPlay',
            pause: 'onPause',
            ended: 'onEnd',
            timeupdate: 'onTimeUpdate',
            progress: 'onProgress',
            seeked: 'onSeeked',
            texttrackchange: 'onTextTrackChange',
            cuechange: 'onCueChange',
            cuepoint: 'onCuePoint',
            volumechange: 'onVolumeChange',
            error: 'onError',
            loaded: 'onLoaded',
            durationchange: 'onDurationChange'
        };

        Object.keys(eventNames).forEach((dmName) => {
            const reactName = eventNames[dmName];
            this.player.on(dmName, (event) => {
                const handler = this.props[reactName];
                if (handler) {
                    handler(event);
                }
            });
        });

        if (typeof start === 'number') {
            this.player.setCurrentTime(start);
        }
        this.player.getChapters().then(function (chapters) {
            videoChapters(chapters);
        }).catch(function (error) {
            console.log(error)
        });

    }

    refContainer(container) {
        this.container = container;
    }

    render() {
        return (<div className="video-box" ref={this.refContainer.bind(this)}/>);
    }
}

ProductVimeoPlayer.defaultProps = {
    autopause: true,
    autoplay: false,
    showByline: true,
    controls: true,
    loop: false,
    showPortrait: true,
    showTitle: false,
    muted: false,
    background: false,
    responsive: false,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductVimeoPlayer);