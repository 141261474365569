import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import Button from "react-bootstrap/Button";
import {Spinner} from "react-bootstrap";
import {multiTableImportFile} from "../../actions/multiTable";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    multiTableImportFile
};

//  input do obsługi plików
const renderFileInput = ({input, meta: {touched, error}}) => (
    <div>
        <input
            id="file-upload"
            className="hidden-file-input"
            type="file"
            accept=".csv"
            onChange={e => input.onChange(e.target.files[0])}
        />
        <label htmlFor="file-upload" className="educado-btn educado-btn-black custom-file-upload">
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path
                    d="M10.6248 18.4596H4.5415V18.3346V18.2096H10.6248V18.3346V18.4596ZM17.7082 2.79297V11.3763H17.5832H17.4582V2.79297H17.5832H17.7082ZM3.2915 17.2096V10.5013H3.5415V17.2096H3.4165H3.2915ZM12.8748 17.3864V17.0329L16.2814 13.6263H16.6349L12.8748 17.3864ZM16.4582 1.66797V1.79297H10.9998V1.54297H16.4582V1.66797ZM12.8748 12.3763H16.1172L15.8672 12.6263H12.8748V12.5013V12.3763ZM11.7498 13.6263H11.8748V16.6187L11.6248 16.8687V13.6263H11.7498Z"
                    fill="white"/>
                <path
                    d="M10.6248 18.4596H4.5415V18.3346V18.2096H10.6248V18.3346V18.4596ZM17.7082 2.79297V11.3763H17.5832H17.4582V2.79297H17.5832H17.7082ZM3.2915 17.2096V10.5013H3.5415V17.2096H3.4165H3.2915ZM12.8748 17.3864V17.0329L16.2814 13.6263H16.6349L12.8748 17.3864ZM16.4582 1.66797V1.79297H10.9998V1.54297H16.4582V1.66797ZM12.8748 12.3763H16.1172L15.8672 12.6263H12.8748V12.5013V12.3763ZM11.7498 13.6263H11.8748V16.6187L11.6248 16.8687V13.6263H11.7498Z"
                    fill="white"/>
                <path
                    d="M10.6248 18.4596H4.5415V18.3346V18.2096H10.6248V18.3346V18.4596ZM17.7082 2.79297V11.3763H17.5832H17.4582V2.79297H17.5832H17.7082ZM3.2915 17.2096V10.5013H3.5415V17.2096H3.4165H3.2915ZM12.8748 17.3864V17.0329L16.2814 13.6263H16.6349L12.8748 17.3864ZM16.4582 1.66797V1.79297H10.9998V1.54297H16.4582V1.66797ZM12.8748 12.3763H16.1172L15.8672 12.6263H12.8748V12.5013V12.3763ZM11.7498 13.6263H11.8748V16.6187L11.6248 16.8687V13.6263H11.7498Z"
                    stroke="#232936"/>
                <path d="M3.8335 5.83464L6.3335 8.33464M6.3335 8.33464L8.8335 5.83464M6.3335 8.33464V1.66797"
                      stroke="#141B34" strokeWidth="1.5"/>
            </svg>
            Wybieram plik z dysku
        </label>
        {touched && error && <span>{error}</span>}
        <div
            className={"file-name"}>{input.value ? `Wybrany plik: ${input.value.name}` : 'Nie wybrano żadnego pliku'}</div>
    </div>
);


class CsvImportForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emptyFile: false
        }
    }

    onSubmit(event) {
        const file = event.file;
        const reader = new FileReader();
        if (file) {
            this.setState({emptyFile: false})

            reader.onload = (event) => {
                const {brand, multiTableImportFile} = this.props;
                const base64String = event.target.result;
                multiTableImportFile(brand, base64String);
            };
            reader.readAsDataURL(file);
        } else {
            this.setState({emptyFile: true})
        }
    }

    render() {
        const {handleSubmit, submitting} = this.props;

        return (
            <div className={"file-import-container-content"}>
                {/*<Spinner animation={'border'} color={"#"}/>*/}
                {/*<h2>Przetwarzamy Twój plik. Może to zająć kilka minut...</h2>*/}


                <h2>Krok 1. <span>Pobierz szablon pliku csv i wypełnij</span></h2>
                <Button onClick={()=> window.open("/wzor_lista.csv", "_blank")} className={"educado-btn download-btn educado-btn-black"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <path
                            d="M10.6248 18.4596H4.5415V18.3346V18.2096H10.6248V18.3346V18.4596ZM17.7082 2.79297V11.3763H17.5832H17.4582V2.79297H17.5832H17.7082ZM3.2915 17.2096V10.5013H3.5415V17.2096H3.4165H3.2915ZM12.8748 17.3864V17.0329L16.2814 13.6263H16.6349L12.8748 17.3864ZM16.4582 1.66797V1.79297H10.9998V1.54297H16.4582V1.66797ZM12.8748 12.3763H16.1172L15.8672 12.6263H12.8748V12.5013V12.3763ZM11.7498 13.6263H11.8748V16.6187L11.6248 16.8687V13.6263H11.7498Z"
                            fill="white"/>
                        <path
                            d="M10.6248 18.4596H4.5415V18.3346V18.2096H10.6248V18.3346V18.4596ZM17.7082 2.79297V11.3763H17.5832H17.4582V2.79297H17.5832H17.7082ZM3.2915 17.2096V10.5013H3.5415V17.2096H3.4165H3.2915ZM12.8748 17.3864V17.0329L16.2814 13.6263H16.6349L12.8748 17.3864ZM16.4582 1.66797V1.79297H10.9998V1.54297H16.4582V1.66797ZM12.8748 12.3763H16.1172L15.8672 12.6263H12.8748V12.5013V12.3763ZM11.7498 13.6263H11.8748V16.6187L11.6248 16.8687V13.6263H11.7498Z"
                            fill="white"/>
                        <path
                            d="M10.6248 18.4596H4.5415V18.3346V18.2096H10.6248V18.3346V18.4596ZM17.7082 2.79297V11.3763H17.5832H17.4582V2.79297H17.5832H17.7082ZM3.2915 17.2096V10.5013H3.5415V17.2096H3.4165H3.2915ZM12.8748 17.3864V17.0329L16.2814 13.6263H16.6349L12.8748 17.3864ZM16.4582 1.66797V1.79297H10.9998V1.54297H16.4582V1.66797ZM12.8748 12.3763H16.1172L15.8672 12.6263H12.8748V12.5013V12.3763ZM11.7498 13.6263H11.8748V16.6187L11.6248 16.8687V13.6263H11.7498Z"
                            stroke="#232936"/>
                        <path d="M3.8335 5.83464L6.3335 8.33464M6.3335 8.33464L8.8335 5.83464M6.3335 8.33464V1.66797"
                              stroke="#141B34" strokeWidth="1.5"/>
                    </svg>
                    Pobieram szablon
                </Button>
                <h2>Krok 2. <span>Wybierz plik csv z dysku</span></h2>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Field name="file" component={renderFileInput} type="file"/>
                    <h2>Krok 3. <span>Zaimportuj plik csv</span></h2>
                    <div className={'submit-file-button'}>
                        <Button className={"educado-btn educado-btn-black"} type="submit" disabled={submitting}>

                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"
                                 fill="none">
                                <g id="upload">
                                    <path id="Vector"
                                          d="M19.25 13.75V17.4167C19.25 17.9029 19.0568 18.3692 18.713 18.713C18.3692 19.0568 17.9029 19.25 17.4167 19.25H4.58333C4.0971 19.25 3.63079 19.0568 3.28697 18.713C2.94315 18.3692 2.75 17.9029 2.75 17.4167V13.75M15.5833 7.33333L11 2.75M11 2.75L6.41667 7.33333M11 2.75V13.75"
                                          stroke="#090A0A" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </g>
                            </svg>
                            Importuję
                        </Button>
                        {this.state.emptyFile ? <p>Wybierz plik</p>: ''}
                    </div>
                </form>
            </div>
        );
    }
}


export default reduxForm({
    form: 'CsvImportForm'
})(connect(mapStateToProps, mapDispatchToProps)(CsvImportForm));


