import React from 'react';
import {APP_URL} from "../../../constants";
import {Alert, Button, Card, Col, Row, Spinner} from "react-bootstrap";
import {strNormalize} from "../../../common/utils";
import dateFormat from "dateformat";
import ProgressBar from "react-bootstrap/ProgressBar";
import striptags from "striptags";

class ProductStartedList extends React.Component {

    componentDidMount() {
        const {profileProgressProductsFetch, isFetching} = this.props.children;
        if (!isFetching) {
            profileProgressProductsFetch();
        }
    }

    getCertificate(product) {
        let tmp = APP_URL + product.certificatesUsers[0];
        window.open(tmp, '_blank');
    }

    render() {

        const {progressProducts, categoryId} = this.props.children;

        if (!progressProducts)
            return (<Spinner animation="grow"/>);

        if (progressProducts.length === 0)
            return (<Alert variant="info">Brak produktów!</Alert>);

        return (
            <Row>
                {progressProducts && progressProducts.map((product, index) => (

                    <Col key={index} lg={6} xl={4} md={6} className={'mt-4 list-item-container started-list-container'}>


                        <div className={"index-list-item"}>
                            <div className={"card-img-top"} width={"120"} height={"147"}
                                 style={{backgroundImage: `url(${product.imageUrl})`}}/>
                            <div className={`webinar-labels category-${categoryId} ${product.archive ? 'with-overdue' : ''}`}>
                                <div
                                    className={"archived-label"}>Szkolenie
                                </div>
                                <div
                                    className={`category-label`}>
                                    <span>{categoryId === 71 ? 'oświata' : 'księgowość i kadry'}</span>
                                </div>


                                {product.archive ? <div className={"archived-label overdue"}>Archiwalne</div> : ''}
                            </div>
                            <div className={"training-user-item-details"}>
                                <span>{product.progress}%</span>
                                <ProgressBar now={product.progress}/>
                            </div>
                            <Card.Body>
                                <Card.Title
                                    title={product.name}>{product.name.length > 68 ? product.name.substring(0, 65) + '...' : product.name}</Card.Title>
                                {product.experts ?

                                    <div className={"expert-wrapper"}>{product.experts.map((expert, index) => (
                                        <div className={"webinar-item-expert"}
                                             key={index}>&nbsp;{expert.name} {expert.surname}{product.experts.length > index + 1 && product.experts.length > 1 ? ', ' : ''}</div>
                                    ))}</div>
                                    : <div className={"webinar-item-expert"}></div>}

                                <div className={'item-info-container'}><p>Stan prawny
                                    na: <span>{dateFormat(product.legalStatusDate, 'dd.mm.yyyy')}</span></p></div>
                            </Card.Body>
                            <Card.Text className="item-lead">
                                {product.shortDescription ? <span>{striptags(product.shortDescription)}</span> : ''}

                            </Card.Text>
                            <div className={'button-wrapper'}>
                                <Button href={`/${strNormalize(product.subType)}/${strNormalize(product.name)}/${(product.id)}`}
                                        className="educado-btn educado-btn-black">Oglądaj</Button>
                            </div>

                        </div>
                    </Col>
                ))}
            </Row>
        );
    }

}

export default ProductStartedList;