import React from 'react';
import {connect} from "react-redux";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import {profilerFetchCategories, profilerUpdateSettings} from "../../../../actions/profiler";


const mapStateToProps = state => ({
    userData: state.auth.userData,
    category_all: state.category.category_all,
    profilerData: state.profiler.profilerData,

});

const mapDispatchToProps = {
    profilerUpdateSettings,
    profilerFetchCategories
}

class HomeProfilerModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
            checkedItems: [],
            isAllChecked: false,
            checkAll: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    showModal() {
        this.setState({show: true});
    }

    hideModal() {
        this.setState({show: false});
    }

    handleSubmit() {
        const {profilerUpdateSettings} = this.props;
        this.hideModal();
        return profilerUpdateSettings(
            this.state.checkedItems
        );
    }

    handleCheckAll = () => {
        const {category_all} = this.props;
        this.setState({
            checkAll: !this.state.checkAll
        }, () => {
            if (this.state.checkAll) {
                this.setState({
                    checkedItems: []
                });
                category_all.map((i) => this.state.checkedItems.push(i.id))
            } else {
                this.setState({
                    checkedItems: []
                });

            }

            this.props.category_all.forEach((item) => this.setState({[item.name]: this.state.checkAll}))
        });
    }

    handleChange = (e) => {
        let item = parseInt(e.target.id);

        if (this.state.checkedItems.includes(item)) {
            this.state.checkedItems.splice(this.state.checkedItems.indexOf(item), 1);

        } else {
            this.state.checkedItems.push(item);

        }
        this.setState({
            [e.target.name]: e.target.checked
        }, () => {
            const uncheckedItems = this.props.category_all.filter((item) => !this.state[item.name])

            this.setState({
                checkAll: uncheckedItems.length === 0
            });
        });
    }


    render() {
        const {userData, isFetching} = this.props;

        if (null === userData || isFetching) {
            return (<i className="fas fa-spinner fa-spin"/>);
        }
        return (
            <Container className="profile-main-container" fluid>
                <Modal id={"home-profiler-modal"} show={this.state.show}
                       onHide={this.hideModal.bind(this, false)}>
                    <Container className="modal-content-container">
                        <Row>
                            <Modal.Header>
                                <img alt="logo Educado" width={86} src="/profiler-modal-icon.svg"/>
                                <h3>{userData.name}, zaznacz swoje ulubione kategorie szkoleń:</h3>
                                <button type="button" onClick={this.hideModal} className="close">×</button>
                            </Modal.Header>
                            <Modal.Body>
                                <Container className={'profiler-container'}>
                                    <Row>

                                        {this.props.category_all.map((item, i) => (
                                            <Col key={i}>
                                                <input type="checkbox" checked={this.state[item.name] || false}
                                                       id={item.id}
                                                       name={item.name}
                                                       onChange={this.handleChange}
                                                       value={item.name}
                                                />
                                                <label>{item.name}</label>

                                            </Col>
                                        ))}

                                        <div className={'btn-row'}>
                                            <div
                                                className={this.state.checkAll ? 'active register-btn btn-select-all' : 'nonactive register-btn btn-select-all'}>
                                                <input type="checkbox"
                                                       onChange={this.handleCheckAll}
                                                       checked={this.state.checkAll}/><label>{this.state.checkAll ? 'Odznacz wszystko' : 'Zaznacz wszystko'}
                                            </label>
                                            </div>
                                            <Button className="login-btn btn register-btn" variant="primary"
                                                    onClick={this.handleSubmit}>Zapisz</Button></div>
                                    </Row>
                                </Container>
                            </Modal.Body>
                        </Row>
                    </Container>
                </Modal>
            </Container>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeProfilerModal);
